import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { CiMenuBurger } from "react-icons/ci";
import { MdMenu } from "react-icons/md";
import { IoIosNotificationsOutline, IoIosSearch } from "react-icons/io";

const AuthButtons = ({ setClickSideMenu = null, type, handleLogout }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const [notify, setNotify] = useState([]);
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  useEffect(() => {
    if (user) {
      getNotification();
    }
  }, [user]);

  const getNotification = async () => {
    try {
      const res = await axios.post("/api/notificationHistory", { mberNo: user.mberNo });
      setNotify((res?.data || []).filter((not) => not.readYn === "N"));
    } catch (error) {
      console.error(error);
    }
  };

  const generateButtonData = () => {
    if (!user) {
      return [
        {
          label: "로그인",
          className: "login",
          onClick: () => navigate(`${type !== "main" ? "/" + type : ""}/login`),
        },
        { label: "회원가입", className: "type", onClick: () => navigate("/type") },
      ];
    }

    const openWindow = () => {
      user && localStorage.setItem("user", JSON.stringify(user));
      window.open(`https://1472.ai/mypage/notify`);
    };

    return [
      { label: "로그아웃", className: "logout", onClick: () => handleLogout() },
      { label: "마이페이지", className: "mypage", onClick: () => navigate("/mypage") },
      !isTablet && {
        label: "알림",
        className: "notify",
        onClick: () => openWindow(),
        component: (
          <>
            <i>
              <IoIosNotificationsOutline />
            </i>
            {notify.length > 0 && <span>({notify.length < 99 ? notify.length : "99+"})</span>}
          </>
        ),
      },
      !isTablet && {
        label: "통합검색",
        className: "search",
        icon: IoIosSearch,
        onClick: () => navigate("/mypage/search"),
      },
    ].filter(Boolean);
  };

  const buttonData = generateButtonData();

  const handleButtonClick = (clickEvent) => {
    if (setClickSideMenu) {
      setClickSideMenu(false);
    }
    clickEvent();
  };

  return (
    <>
      <div className="buttons">
        {buttonData.map((button, index) =>
          button.component ? (
            <div
              key={index}
              onClick={() => handleButtonClick(button.onClick)}
              className={button.className}>
              {button.component}
            </div>
          ) : (
            <button
              key={index}
              onClick={() => handleButtonClick(button.onClick)}
              className={button.className}>
              {button.icon ? <button.icon /> : button.label}
            </button>
          ),
        )}
        {user && !isTablet && (
          <button onClick={() => setClickSideMenu(true)} className="sideMenu">
            <MdMenu />
          </button>
        )}
      </div>
    </>
  );
};

export default AuthButtons;
