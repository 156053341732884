import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { IoMdClose } from "react-icons/io";

import { hexToRgb, isOutsideCmpny, transformEvents } from "../function/common";

import { SearchEventContainer } from "../styled/scheduler";
import axios from "axios";
import { hideLoading, showLoading } from "../../../../../store/loading";
import Loading from "../../../../../common/loading";

const SearchEvent = ({ clickEvent, searchTxt, setSearchTxt, handleDateClick, onDate }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { mySchedSet, isDarkMode } = useSelector((state) => state?.scheduler);
  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);
  const isLoading = useSelector((state) => state?.loading?.isLoading);

  const [searched, setSearched] = useState({});
  const [isSearched, setIsSearched] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const keywordEncoded = params.get("keyword");

    if (keywordEncoded) {
      const keyword = decodeURIComponent(keywordEncoded);
      setSearchTxt(keyword);
      handleSearch();

      if (params.has("keyword")) {
        params.delete("keyword");
        const newSearch = params.toString();
        const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;
        window.history.replaceState(null, "", newUrl);
      }
    }
  }, []);

  useEffect(() => {
    const transformedData = transformData(data, searchTxt);
    setSearched(transformedData);
  }, [data]);

  const getSchedule = async () => {
    dispatch(showLoading());

    try {
      const filterOrg = (org) => {
        if (!org) return [];
        return org.filter((item) => item.check).map((item) => item.orgNo);
      };

      const url = "/api/scheduleList";
      const body = {
        mberNo: user.mberNo,
        showPersonalYn: mySchedSet.personalYn ? "Y" : "N",
        cmpnyNos: filterOrg(mySchedSet.companyYnList),
        groupNos: filterOrg(mySchedSet.groupYnList),
        completeYn: mySchedSet.completeYn || "D",
        holidayYn: "Y",
      };

      const res = await axios.post(url, body);
      const schedule = res.data;

      const { events } = await transformEvents(schedule, company);
      return events;
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };
  const handleSearch = async (e) => {
    if (e) {
      e.preventDefault();

      if (!searchTxt.trim().length || !searchTxt) {
        toast.error("일정 제목을 입력해주세요.");
        return;
      }
    }

    const events = (await getSchedule()) || [];
    setData(events.filter((event) => event.title.includes(searchTxt)));

    if (searchTxt) {
      setIsSearched(true);
    }

    const transformedData = transformData(data, searchTxt);
    setSearched(transformedData);
    handleDateClick(onDate);
  };

  // 년/월/일 일정 분류
  const transformData = (data, searchTxt) => {
    return data.reduce((acc, item) => {
      if (item.title.includes(searchTxt)) {
        const startDate = moment(item.startDt).startOf("day");
        const endDate = moment(item.endDt).startOf("day");

        for (let date = startDate; date.isSameOrBefore(endDate, "day"); date.add(1, "days")) {
          const year = date.year();
          const month = date.month() + 1;
          const day = date.date();
          if (!acc[year]) acc[year] = {};
          if (!acc[year][month]) acc[year][month] = {};
          if (!acc[year][month][day]) acc[year][month][day] = [];
          acc[year][month][day].push(item);
        }
      }
      return acc;
    }, {});
  };

  const clickDate = (year, month, day) => {
    const formattedMonth = String(month).padStart(2, "0");
    const formattedDay = String(day).padStart(2, "0");
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;

    handleDateClick(formattedDate);
  };

  // 검색 초기화
  const deleteSearched = () => {
    setSearchTxt("");
    setIsSearched(false);
    setSearched({});
    handleDateClick(onDate, true);

    const params = new URLSearchParams(location.search);

    if (params.has("keyword")) {
      params.delete("keyword");
      const newSearch = params.toString();
      const newUrl = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;
      window.history.replaceState(null, "", newUrl);
    }
  };

  const isColor = (item) => {
    const gbnColor = {
      P: item.color,
      C: "0074CA",
      G: "F46F02",
    };

    const opacity = item.completeYn === "Y" ? 0.7 : 0.8;
    const selectedColor = gbnColor[item.gbn] || "000000";
    const color = `rgba(${hexToRgb(selectedColor)}, ${opacity})`;
    return color;
  };

  return (
    <SearchEventContainer>
      <div className={`searchContaier ${isDarkMode}`}>
        <form className="searchArea" onSubmit={handleSearch}>
          <div>
            <input
              type="text"
              placeholder="일정 제목을 입력해주세요"
              onChange={(e) => setSearchTxt(e.target.value)}
              value={searchTxt}
            />
            <span onClick={deleteSearched}>
              <IoMdClose />
            </span>
          </div>
          <button type="submit">검색</button>
        </form>
        {isSearched ? (
          <>
            {Object.keys(searched).length > 0 ? (
              <table className="searchResultsTable">
                <tbody>
                  {Object.entries(searched).flatMap(([year, months]) => [
                    <tr key={year}>
                      <td className="year">{year}년</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>,
                    ...Object.entries(months).flatMap(([month, days]) => [
                      <tr key={`${year}-${month}`}>
                        <td></td>
                        <td className="month">{month}월</td>
                        <td></td>
                        <td></td>
                      </tr>,
                      ...Object.entries(days).flatMap(([day, events]) => [
                        <tr key={`${year}-${month}-${day}`}>
                          <td></td>
                          <td></td>
                          <td className="day" onClick={() => clickDate(year, month, day)}>
                            {day}일
                          </td>
                          <td></td>
                        </tr>,
                        ...events.map((event, index) => (
                          <tr key={`${year}-${month}-${day}-${index}`}>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td
                              style={{
                                background:
                                  event.color && !isOutsideCmpny(event, company) && isColor(event),
                                color: event.color ? "#fff" : "#000",
                                textDecoration: event.completeYn === "Y" ? "line-through" : "none",
                              }}
                              className={`event${isOutsideCmpny(event, company) ? " outside" : ""}`}
                              onClick={() => clickEvent(event, searchTxt)}>
                              {event.title}
                            </td>
                          </tr>
                        )),
                      ]),
                    ]),
                  ])}
                </tbody>
              </table>
            ) : (
              <div className="noData">검색 결과가 없습니다.</div>
            )}
          </>
        ) : (
          <div className="noSearched">일정을 검색해주세요.</div>
        )}
      </div>
      {isLoading && <Loading />}
    </SearchEventContainer>
  );
};

export default SearchEvent;
