import styled from "styled-components";

export const CreateContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 70px 30px;
  margin: 50px 0;
  font-size: 18px;
  .submit {
    margin: 0 auto;
    button {
      width: 200px;
      background: #000;
      color: #fff;
      border-radius: 10px;
      height: 50px;
      border: none;
    }
  }
  @media screen and (max-width: 1050px) {
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    gap: 20px;
    .submit {
      margin: 70px auto 0;
      button {
        width: 150px;
        height: 40px;
        font-weight: 700;
      }
    }
  }
`;

export const Check = styled.div`
  input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #fff;
    margin-right: 10px;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      width: 7px;
      height: 13px;
      top: 50%;
      left: 50%;
      background: #fff;
      transition: 0.3s;
      transform: translate(-50%, -50%) rotate(45deg);
      border-bottom: 3px solid transparent;
      border-right: 3px solid transparent;
      opacity: 0;
    }
    &:checked::after {
      /* background: rgba(0, 0, 0, 0.5); */
      opacity: 1;
      width: 7px;
      height: 13px;
      border-color: #000;
    }
  }
  @media screen and (max-width: 767px) {
  }
`;

export const ImageUploaderContainer = styled.div`
  width: calc(50% - 15px);
  input[type="file"] {
    display: none;
  }
  label {
    display: block;
    width: 100%;
    height: 400px;
    border: 1px solid #ccc;
    border-radius: 25px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.5);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
    button {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 30px;
      border: none;
      background: transparent;
      svg {
        display: block;
        margin: auto;
      }
    }
    span {
      display: block;
      text-align: center;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    label {
      height: 200px;
    }
  }
`;

export const FormContainer = styled.ul`
  width: calc(50% - 15px);
  li {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .tit {
      margin-bottom: 5px;
    }
    .content {
      display: flex;
      gap: 10px;
    }
    input[type="text"] {
      width: 100%;
      height: 50px;
      border: 1px solid #ccc;
      border-radius: 25px;
      padding: 0 20px;
      &:focus {
        border: 1px solid #ccc !important;
        box-shadow: none;
      }
    }
    button {
      width: 120px;
      border-radius: 10px;
      border: none;
      color: #fff;
      background: #333;
    }
    em {
      font-size: 14px;
      font-style: normal;
      color: #999;
    }
    .radio-inputs {
      width: 100%;
      height: 50px;
      border: 1px solid #ccc;
      border-radius: 10px;
      label {
        width: 50%;
        cursor: pointer;
        padding: 5px 5px;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 10px;
          transition: 0.3s;
        }
      }
      input {
        display: none;
        &:checked + .name {
          background: #ff7a00;
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    .tit {
      font-weight: 700;
    }
    li input[type="text"] {
      height: 40px;
      border-radius: 10px;
      padding: 0 10px;
    }
    li .radio-inputs {
      height: 40px;
    }
  }
`;
