import { useState } from "react";
import styled from "styled-components";
import { ToastContainer } from "react-toastify";
import { useOutletContext } from "react-router";

import { ChicCmpnyContainer } from "../shopping/component/styled/chicTheme";
import { ModernCmpnyContainer } from "../shopping/component/styled/modernTheme";
import { CuteCmpnyContainer } from "../shopping/component/styled/cuteTheme";
import { SimpleCmpnyContainer } from "../shopping/component/styled/simpleTheme";
import { SmCmpnyContainer } from "../shopping/component/styled/smTheme";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";

import CompanyForm from "./component/companyForm";
import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";
import TabMenu from "./component/sm/tabMenu";
import CompanyHistory from "./component/sm/companyHistory";
import CompanyChart from "./component/sm/companyChart";
import SmBanner from "../shopping/component/themes/sm/banner";
import ChicBanner from "../shopping/component/themes/chic/banner";
import ShoppingSectionSwiper from "../shoppingSectionSwiper";

const CustomToast = styled(ToastContainer)`
  z-index: 10000;
  width: "400px";
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const Company = () => {
  const { theme } = useOutletContext();
  const [active, setActive] = useState(0);
  const menus = ["회사소개", "회사연혁", "조직도"];
  const messages = [
    "고객님을 위한 정책과\n더 나은 서비스를 제공합니다.",
    "창업부터 현재까지\n우리 회사의 역사를 소개합니다.",
    "우리 조직의 구성과\n주요 역할을 안내합니다.",
  ];

  const containers = {
    0: DefaultContaienr,
    1: ChicCmpnyContainer,
    2: ModernCmpnyContainer,
    3: CuteCmpnyContainer,
    4: SimpleCmpnyContainer,
    sm: SmCmpnyContainer,
  };

  const banners = {
    0: ShoppingSectionSwiper,
    1: ChicBanner,
    2: ModernBanner,
    3: CuteBanner,
    4: SimpleBanner,
    sm: SmBanner,
  };

  const renderContent = () => {
    const Container = containers[theme] || DefaultContaienr;
    const Banner = banners[theme];
    const activeContent = [<CompanyForm />, <CompanyHistory />, <CompanyChart />][active];

    return (
      <Container>
        {Banner && (
          <Banner
            info={
              theme === "sm"
                ? { page: menus[active], message: messages[active] }
                : { imgage: "cmpny.png", text: "company" }
            }
          />
        )}
        {theme === "sm" && <TabMenu setActive={setActive} active={active} menus={menus} />}
        {theme === "sm" ? activeContent : <CompanyForm />}
      </Container>
    );
  };

  return (
    <>
      <CustomToast
        position="top-center"
        theme="light"
        pauseOnHover
        hideProgressBar={true}
        autoClose={1000}
        closeButton={false}
      />
      {renderContent()}
    </>
  );
};

export default Company;
