import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import uuid from "react-uuid";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
  rectSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { BsCloudDownload } from "react-icons/bs";
import { IoClose } from "react-icons/io5";

import { HomepageSettingContainer } from "../styled/homePageSetting";

const MAX = 3;
const HomePageSettingForm = ({ cmpnyNo }) => {
  const navigate = useNavigate();

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const [formData, setFormData] = useState([]);
  const [isDragEnabled, setIsDragEnabled] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    getHomePageSetting();
  }, [cmpnyNo]);

  // 데이터 조회
  const getHomePageSetting = async () => {
    if (!cmpnyNo) return;

    const url = "/api/firstSettingLoad";
    const body = { cmpnyNo, delYn: "N" };

    try {
      const res = await axios.post(url, body);
      if (res.data) {
        const formattedData = formatData(res.data);
        setFormData(formattedData);
      } else {
        await defaultSetting();
      }
    } catch (error) {
      console.error("Failed to load settings:", error);
    }
  };

  const defaultSetting = async () => {
    const url = "/api/firstSettingSave";
    const body = {
      cmpnyNo,
      delYn: "N",
      bannerImgList: JSON.stringify([]),
      bannerImgTextList: JSON.stringify([]),
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    const res = await axios.put(url, body);
    if (res.status === 200) {
      console.log(res);
    }
  };

  // 데이터 형태 변경
  const formatData = (data) => {
    try {
      const imgs = JSON.parse(data.bannerImgList) || [];
      const text = JSON.parse(data.bannerImgTextList) || [];

      return imgs.map((item, index) => ({ id: uuid(), banner: item, text: text[index] || "" }));
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  // 이미지 추가
  const addImages = (files) => {
    if (files.length + formData.length > MAX) {
      toast.error("이미지는 최대 3장까지만 선택할 수 있습니다.");
      return;
    }

    if (files.length > 0) {
      const updatedFiles = Array.from(files).map((file) => ({
        file,
        banner: URL.createObjectURL(file),
        id: uuid(),
        text: "",
      }));

      setFormData((data) => [...data, ...updatedFiles]);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setDragOver(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragOver(false);
    const files = event.dataTransfer.files;
    addImages(files);
  };

  // 파일 업로드
  const handleFileChange = (event) => {
    const files = event.target.files;
    addImages(files);
  };

  // 드래그 시작
  const onDragStart = (event) => {
    setActiveId(event.active.id);
  };

  // 드래그 완료
  const onDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setFormData((data) => {
        const oldIndex = data.findIndex((item) => item.id === active.id);
        const newIndex = data.findIndex((item) => item.id === over.id);
        return arrayMove(data, oldIndex, newIndex);
      });
    }

    setActiveId(null);
  };

  // 텍스트 문구 수정
  const changeInput = (e) => {
    const { name, value } = e.target;
    setFormData((data) =>
      [...data].map((item) => (item.id === name ? { ...item, text: value } : item)),
    );
  };

  // 삭제
  const deleteBanner = (id) => {
    setFormData((data) => [...data].filter((item) => item.id !== id));
  };

  const uploadFiles = async (files) => {
    const form = new FormData();
    files.forEach((item) => {
      form.append("files", item.file, item.file.name);
    });
    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFiles";

    try {
      const res = await axios.post(url, form);
      const response = res?.data?.responseData;

      if (response && response.length > 0) {
        const originalFileNames = files.map((item) =>
          item.file.name.split(".").slice(0, -1).join("."),
        );

        return response.map((item, idx) => {
          const uploadedFileName = item.fileName.split("/").pop().split(".").slice(0, -1).join(".");
          const isSameName = originalFileNames.includes(uploadedFileName);

          return {
            ...item,
            origin: isSameName ? files[idx].file.name : undefined,
          };
        });
      }
      return null;
    } catch (error) {
      console.error("파일 업로드 실패:", error);
      return null;
    }
  };

  // 이미지 업로드
  const handleUpload = async (data) => {
    try {
      const files = data.filter((item) => item?.file);
      const uploaded = await uploadFiles(files);

      if (uploaded) {
        return data.map((item) => {
          const uploadedFile = uploaded.find((file) => file.origin === item.file?.name);
          return uploadedFile ? { ...item, banner: uploadedFile.fileName } : item;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async () => {
    let files = [...formData];

    if (files.some((item) => item?.file)) {
      files = await handleUpload(formData);
    }

    await updateBanner(files);
  };

  const updateBanner = async (files = []) => {
    const url = "/api/firstSettingSave";

    const bannerImgList = [];
    const bannerImgTextList = [];

    files.forEach((item) => {
      bannerImgList.push(item.banner);
      bannerImgTextList.push(item.text);
    });

    const body = {
      cmpnyNo,
      delYn: "N",
      bannerImgList: JSON.stringify(bannerImgList),
      bannerImgTextList: JSON.stringify(bannerImgTextList),
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    try {
      const res = await axios.put(url, body);
      if (res.status === 200) {
        toast.success("변경사항이 저장되었습니다.");
        navigate("/mypage/menuSetting");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <HomepageSettingContainer className={setting?.mode}>
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={onDragStart}
        onDragEnd={!isDragEnabled ? undefined : onDragEnd}>
        <ul className="banner">
          <li className="dragToUpload">
            <input
              type="file"
              id="fileUpload"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept="image/*"
              multiple
            />
            <label
              htmlFor="fileUpload"
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={(e) => {
                if (formData.length >= MAX) {
                  e.preventDefault();
                  toast.error("이미지는 최대 3장까지만 선택할 수 있습니다.");
                }
              }}>
              <span className="icon">
                <BsCloudDownload />
              </span>
              <span>이미지를 여기로 드래그하거나 클릭해서 추가해주세요.</span>
            </label>
          </li>
          {formData.length > 0 ? (
            <SortableContext
              items={formData.map((item) => item.id)}
              strategy={
                isMobile
                  ? verticalListSortingStrategy
                  : isTablet
                  ? rectSortingStrategy
                  : horizontalListSortingStrategy
              }>
              {formData.map((item) => (
                <SortableItem
                  key={item.id}
                  id={item.id}
                  item={item}
                  changeInput={changeInput}
                  isDragEnabled={isDragEnabled}
                  deleteBanner={deleteBanner}
                />
              ))}
            </SortableContext>
          ) : (
            <li className="banner sample">
              <div className="bannerItem">
                <div className="imgArea"></div>
                <span className="preview">예시입니다.</span>
                <input type="text" value="이미지 추가 시 삭제됩니다." readOnly />
              </div>
            </li>
          )}
          <DragOverlay>
            {activeId ? (
              <SortableItem
                id={activeId}
                item={formData.find((item) => item.id === activeId)}
                isDragOverlay={true}
              />
            ) : null}
          </DragOverlay>
        </ul>
      </DndContext>
      <div className="buttons">
        {formData.length > 1 && (
          <button onClick={() => setIsDragEnabled(!isDragEnabled)}>
            순서 변경{isDragEnabled ? "완료" : "하기"}
          </button>
        )}
        <button onClick={handleSubmit}>저장하고 다음단계</button>
      </div>
    </HomepageSettingContainer>
  );
};

export default HomePageSettingForm;

const SortableItem = ({
  id,
  item,
  changeInput,
  isDragEnabled,
  deleteBanner,
  isDragOverlay = false,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: `translate(${transform?.x || 0}px, ${transform?.y || 0}px)`,
    transition,
    cursor: isDragEnabled ? "pointer" : "default",
    // opacity: isDragOverlay ? 0.5 : 1,
  };

  return (
    <li
      ref={setNodeRef}
      style={style}
      {...(isDragEnabled ? attributes : {})}
      {...(isDragEnabled ? listeners : {})}
      className={`banner ${isDragOverlay ? " overlay" : ""}`}>
      <button onClick={() => deleteBanner(id)}>
        <IoClose />
      </button>
      <div className="bannerItem">
        <img src={imgHandler(item.banner)} alt="" />
        <span className="preview">{item.text}</span>
        <input
          type="text"
          value={item.text}
          onChange={(e) => changeInput(e)}
          name={item.id}
          placeholder="배너 텍스트를 입력해주세요."
          maxlength="30"
        />
      </div>
    </li>
  );
};
