import React, { useCallback, useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { ImageUploaderContainer } from "../styled/create";
import { BsCloudDownload } from "react-icons/bs";

const ImageUploader = ({ addImage, formData, setFormData, setImage }) => {
  const [dragOver, setDragOver] = useState(false);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    setDragOver(false);
  }, []);

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      setDragOver(false);
      const files = e.dataTransfer.files?.[0];
      addImage(files);
    },
    [addImage],
  );

  return (
    <ImageUploaderContainer>
      <label
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={(e) => {
          if (formData.imgUrl) {
            e.preventDefault();
            document.querySelector('input[name="logoImage"]').click();
          }
        }}>
        {formData.imgUrl ? (
          <>
            <img src={imgHandler(formData.imgUrl)} alt="" />
            <button
              onClick={(e) => {
                e.preventDefault();
                setFormData((data) => ({ ...data, imgUrl: "" }));
                setImage(null);
              }}>
              <IoCloseSharp />
            </button>
          </>
        ) : (
          <div className="notImage">
            <span>
              <BsCloudDownload />
            </span>
            <span>이미지를 여기로 드래그하거나 클릭해서 추가해주세요.</span>
          </div>
        )}
        <input type="file" name="logoImage" accept="image/*" onChange={addImage} />
      </label>
      <div className="preview"></div>
    </ImageUploaderContainer>
  );
};

export default ImageUploader;
