import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";
import { SideMenuActions } from "../../styled/scheduler";

const Actions = ({ onDate }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  const dateTime = (day) => {
    const formatted = moment(day).format("YYYY-MM-DD hh:mm:ss");
    const date = formatted.split(" ")[0];

    const now = moment().format("HH:00:00");
    return `${date} ${now}`;
  };

  return (
    <SideMenuActions className={isDarkMode}>
      <button
        className="exit"
        onClick={() =>
          navigate("/mypage/calendarEntry", {
            state: {
              eventInfo: {
                startDt: moment(dateTime(onDate)).startOf("hour").format("YYYY-MM-DD HH:30:00"),
                endDt: moment(dateTime(onDate))
                  .startOf("hour")
                  .add(1, "hour")
                  .format("YYYY-MM-DD HH:30:00"),
              },
            },
          })
        }>
        일정 추가
      </button>
      {isMobile && (
        <button className="exit" onClick={() => navigate("/mypage")}>
          목록으로 돌아가기
        </button>
      )}
    </SideMenuActions>
  );
};

export default Actions;
