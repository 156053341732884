import React from "react";
import { Banner } from "../../styled/chicTheme";

const ChicBanner = ({ info }) => {
  return (
    <Banner
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(/img/shopTheme/chic_bg_${info.imgage})`,
      }}>
      <span>{info.text}</span>
    </Banner>
  );
};

export default ChicBanner;
