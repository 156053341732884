import styled from "styled-components";

export const LoadingContainer = styled.div`
  position: fixed;
  z-index: 99999;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  font-size: 70px;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  span {
    display: inline-block;
    animation: rotate 1s linear infinite;
    svg {
      margin: 0;
      display: block;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const HeaderContainer = styled.header`
  padding: 0;
  position: relative;
  background: #191f28;
  &.info {
    &.scheduler {
      background: #ffd9f2;
      .headerInner nav .dropdown:hover .dropdown-menu {
        background: #ffd9f2;
      }
    }
    &.project {
      background: #fff299;
      .headerInner nav .dropdown:hover .dropdown-menu {
        background: #fff299;
      }
    }
    &.shop {
      background: #eaf7ed;
      .headerInner nav .dropdown:hover .dropdown-menu {
        background: #eaf7ed;
      }
    }
  }
  &.group {
    background: #fef9f3;
    .headerInner nav .dropdown:hover .dropdown-menu {
      background: #fef9f3;
    }
  }
  &.main {
    .headerInner nav .dropdown:hover .dropdown-menu {
      background: #191f28;
    }
  }
  &.mypage {
    padding: 0;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    color: #000;
    &.dark {
      a,
      button {
        color: #fff;
      }
      .headerInner nav .dropdown .dropdown-menu {
        background: #1e1f21;
      }
    }
    &.white {
      .headerInner nav .dropdown .dropdown-menu {
        background: rgba(255, 255, 255, 0.3);
        border-radius: 10px;
      }
    }
  }
  &.group,
  &.scheduler,
  &.project,
  &.shop,
  &.mypage.white {
    .headerInner {
      .logo,
      .sideMenu,
      a,
      .headerBtnArea button,
      .headerBtnArea a,
      .buttons .notify {
        color: #000;
      }
    }
  }
  h1 {
    display: none;
  }
  .headerInner {
    max-width: 1440px;
    padding: 10px 20px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .logo {
      font-size: 22px;
      color: #fff;
      font-weight: 700;
      height: auto;
    }
    svg {
      display: block;
      margin: auto;
    }
    .notify,
    .search {
      font-size: 20px;
    }
    nav {
      width: calc(100% - 700px);
      text-align: left;
      > ul {
        display: flex;
        flex-direction: row;
      }
      .dropdown {
        position: relative;
        display: flex;
        align-items: center;
        width: 150px;
        padding: 9px 0;
        a {
          display: inline-block;
          padding: 0;
          text-align: center;
          width: 100%;
        }
        .dropdown-menu {
          display: block;
          max-height: 0;
          padding: 0;
          width: 100%;
          opacity: 0;
          transform: none;
          position: absolute;
          overflow: hidden;
          transition: 0.5s;
          top: 100%;
          left: 50%;
          transform: translate(-50%, 0);
          gap: 30px;
          white-space: nowrap;
          z-index: 33;
          li {
            width: auto;
          }
        }
        &:hover {
          .dropdown-menu {
            max-height: 300px;
            opacity: 1;
          }
        }
      }
      li {
        margin-right: 0;
      }
    }
    .dropdown-menu {
      width: 150%;
      padding: 20px 0;
      li a {
        padding: 0;
        transition: 0.3s;
        &:hover {
          font-weight: 700;
          transform: scale(1.1);
        }
      }
    }
    .buttons {
      display: flex;
      font-size: 14px;
      align-items: center;
      gap: 10px;
      button,
      a,
      .notify {
        border: none;
        background: transparent;
        color: #fff;
      }
      button:nth-child(2),
      button:nth-child(4) {
        margin-right: 30px;
      }
      .notify {
        position: relative;
        cursor: pointer;
        span {
          position: absolute;
          top: 0;
          left: 100%;
          font-size: 0;
          /* transform: translateX(3px); */
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background: tomato;
          text-align: center;
          line-height: 15px;
        }
      }
    }
    .sideMenu {
      display: flex;
      font-size: 28px;
      border: none;
      background: transparent;
      color: #fff;
    }
  }
  @media screen and (max-width: 1050px) {
  }
  @media screen and (max-width: 768px) {
    .headerInner {
      .login_menu {
        width: auto;
        transform: translateY(0);
      }
      img.logo {
        width: 100px;
        height: 35px;
      }
    }
  }
`;

export const SideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background: #191f28;
  transition: 0.3s;
  a,
  button,
  svg path {
    stroke: #fff;
    color: #fff;
  }
  &.mypage {
    background: rgb(238, 218, 217);
    background: linear-gradient(
      90deg,
      rgba(238, 218, 217, 1) 0%,
      rgba(247, 191, 176, 1) 35%,
      rgba(238, 218, 217, 1) 100%
    );
    transition: 0.3s;
    a,
    button,
    svg path {
      stroke: #000;
      color: #000;
    }
    .wrapper .authButtons .buttons {
      a,
      button {
        background: rgba(255, 255, 255, 0.5);
      }
    }
    &.dark {
      background: #1e1f21;
      .sideMenuInfo {
        .logo,
        a,
        button,
        svg path {
          stroke: #fff;
          color: #fff;
        }
      }
      .wrapper {
        .sideMenuInfo .buttons button:last-child,
        svg path,
        .toggle,
        .menuItem li a {
          stroke: #fff;
          color: #fff;
        }
        .authButtons .buttons {
          a,
          button {
            background: rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
  button {
    border: none;
    background: transparent;
  }
  .wrapper {
    padding: 20px;
    width: 100%;
    height: 100%;
    font-size: 18px;
    .sideMenuInfo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 50px;
      .logo {
        font-size: 20px;
        font-weight: 600;
      }
      .buttons {
        font-size: 25px;
        display: flex;
        align-items: center;
        gap: 10px;
        button,
        a {
          width: 25px;
          height: 25px;
          text-align: center;
          &:last-child {
            margin-left: 20px;
          }
          display: flex;
        }
        a {
          position: relative;
          span {
            position: absolute;
            top: 0;
            left: 100%;
            font-size: 0;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: tomato;
            text-align: center;
          }
        }
      }
    }
    .authButtons {
      margin-top: 50px;
      .buttons {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        button,
        a {
          display: block;
          width: 100%;
          text-align: center;
          background: rgba(255, 255, 255, 0.1);
          padding: 10px 0;
          border-radius: 10px;
          font-size: 14px;
        }
        .login {
          order: 1;
        }
        .type {
          order: 2;
        }
        .logout {
          order: 4;
        }
        .mypage {
          order: 3;
        }
      }
    }
    .toggle {
      width: 100%;
      margin: 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span:first-child {
        font-weight: 700;
      }
      .icon {
        font-size: 20px;
        svg {
          display: block;
        }
      }
    }
    .menuItem {
      padding-left: 20px;
      li {
        margin: 10px;
      }
    }
  }
`;

export const GridSideMenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  .sideMenuInner {
    max-width: 1440px;
    padding: 0 20px;
    width: 100%;
    height: 100%;
    position: relative;
    margin: auto;
  }
  .sideMenuWrapper {
    position: absolute;
    top: 80px;
    right: 10px;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: #000;
    .sideMenuContainer {
      position: relative;
      max-width: 300px;
      .arrowUp {
        position: absolute;
        top: 1px;
        transform: translateY(-100%);
        right: 9px;
        width: 0;
        height: 0;
        border-bottom: 15px solid rgb(239, 239, 239);
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
      }
      .sideMenuHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        background: rgb(239, 239, 239);
        border-bottom: 2px solid #efefef;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        .logo {
          font-size: 20px;
          display: flex;
          align-items: center;
          gap: 5px;
          img {
            width: 30px;
          }
        }
        .close {
          button {
            font-size: 25px;
            border: none;
            background: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
      .sideMenuBody {
        max-height: 450px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .tableEditAddBtnArea {
          padding: 20px;
        }
        .tit {
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 22px;
          padding: 10px;
          background: #efefef;
          border-radius: 10px 10px 0 0;
          span {
            font-size: 18px;
          }
        }
        .border {
          border-top: 1px solid #efefef;
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin: 10px;
          li {
            width: calc((100% - 30px) / 4);
            padding: 10px 0;
            text-align: center;
            font-size: 14px;
            cursor: pointer;
            .icon {
              display: flex;
              justify-content: center;
              font-size: 20px;
              margin-bottom: 5px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1280px) {
      .sideMenuInner {
        width: 96%;
      }
    }
    @media screen and (max-width: 1050px) {
      .sideMenuInner {
        padding: 0;
      }
      .sideMenuWrapper {
        right: 0;
      }
    }
    @media screen and (max-width: 767px) {
      .sideMenuInner {
        width: 90%;
      }
    }
  }
`;

export const AppInstallContainer = styled.div`
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - 500px);
  margin: 100px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    width: 300px;
    height: 100px;
    font-size: 30px;
    border: none;
    background: #0074ca;
    color: #fff;
    border-radius: 30px;
  }
`;

export const BoardTableContainer = styled.table`
  margin: 50px 0;
  border-collapse: collapse;
  width: 100%;
  border-top: 1px solid #000;
  table-layout: fixed;
  &.dark {
    border-top: 1px solid #444;
    thead tr th,
    tbody tr td {
      border-bottom: 1px solid #444;
    }
  }
  &.white {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    thead tr th,
    tbody tr td {
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
  caption {
    height: 0;
    font-size: 0;
    line-height: 0;
    overflow: hidden;
  }
  thead tr th,
  tbody tr td {
    height: 70px;
    text-align: center;
    border-bottom: 1px solid #000;
    &:nth-child(1) {
      width: 10%;
    }
    &:nth-child(2) {
      width: 20%;
    }
    &:nth-child(3) {
      width: 40%;
    }
    &:nth-child(4) {
      width: 10%;
    }
    &:nth-child(5) {
      width: 20%;
    }
    span {
      max-width: 100%;
      display: inline-block;
    }
  }
  thead tr th {
    font-size: 20px;
  }
  tbody tr td {
    font-size: 16px;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (max-width: 1050px) {
    thead tr th {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    &.dark {
      tbody tr {
        border-bottom: 1px solid rgb(68, 68, 68);
        td {
          border-bottom: none;
          border-top: none;
        }
      }
    }
    &.white {
      tbody tr td {
        border-bottom: none;
        border-top: none;
      }
    }
    margin: 30px 0;
    thead tr th {
      display: none;
    }
    tbody {
      tr {
        display: flex;
        flex-wrap: wrap;
        width: calc(100vw - 40px);
        border-bottom: 1px solid #000;
        padding: 10px;
        gap: 10px;
        td {
          border-bottom: none;
          height: auto;
          text-align: left;
          font-size: 12px;
          &.noticeSj {
            order: 1;
            width: 100%;
          }
          &.noticeCl {
            color: #0074ca;
            order: 2;
            position: relative;
            margin-right: 10px;
            &::after {
              content: "";
              position: absolute;
              display: block;
              width: 1px;
              height: 60%;
              background: rgba(0, 0, 0, 0.3);
              top: 50%;
              left: calc(100% + 10px);
              transform: translateY(-50%);
            }
          }
          &.createDt {
            order: 3;
          }
        }
      }
    }
  }
`;

export const BoardWriteContainer = styled.div`
  &.dark {
    ul li {
      input[type="text"],
      textarea,
      select {
        background: rgb(0, 0, 0);
        color: rgb(238, 238, 238);
        border: 1px solid rgb(0, 0, 0);
        &:focus {
          border: none !important;
        }
      }
    }
  }
  ul {
    li {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .writing_tit {
        width: 150px;
      }
      .writing_cont {
        width: calc(100% - 150px);
      }
      input[type="text"],
      textarea,
      select {
        min-width: 160px;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        background: transparent;
        padding: 0px 20px;
        resize: none;
        &:focus {
          outline: none;
          border: 1px solid rgba(255, 255, 255, 0.5) !important;
          box-shadow: none;
        }
      }
      input[type="text"] {
        height: 55px;
        line-height: 55px;
        width: 100%;
      }
      select {
        height: 55px;
        line-height: 55px;
      }
      textarea {
        padding: 20px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    ul li {
      flex-wrap: wrap;
      margin-bottom: 16px;
      .writing_tit {
        width: 100%;
        margin-bottom: 5px;
      }
      .writing_cont {
        width: 100%;
        input[type="text"],
        select {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
`;
