import styled from "styled-components";

export const SwiperContainer = styled.div`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media screen and (max-width: 1400px) {
    .GroupHeaderInner {
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px) {
  }
  @media screen and (max-width: 767px) {
    .gnb {
      display: none;
    }
    .swiper-button-prev.swiper-button-white,
    .swiper-button-next.swiper-button-white {
      display: none;
    }
  }
`;

export const FeedContainer = styled.div`
  margin: 7%;
  .cardList {
    max-width: 700px;
    margin: auto;
    margin-bottom: 5em;
    li {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      .cardHeader {
        display: flex;
        align-items: center;
        background: rgba(0, 0, 0, 0.03);
        padding: 1.25rem;
        box-sizing: border-box;
        justify-content: space-between;
        .title {
          display: flex;
          align-items: center;
          gap: 7px;
          span {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: auto;
          }
          .info {
            display: flex;
            align-items: center;
            gap: 7px;
            width: calc(100% - 50px);
          }
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            overflow: hidden;
          }
          svg {
            font-size: 22px;
            margin-right: 7px;
          }
        }
        img,
        svg {
          display: block;
        }
        .dropdown {
          position: relative;
          cursor: pointer;
          font-size: 14px;
          & > svg {
            font-size: 20px;
          }
          ul {
            display: none;
            position: absolute;
            top: 150%;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            cursor: pointer;
            li {
              padding: 5px;
              cursor: pointer;
            }
          }
          &.active ul {
            display: block;
            z-index: 2;
            padding: 0;
            width: 150%;
            background-color: #fff;
          }
        }
      }
      .cardBody {
        padding: 1rem;
        .text {
          margin: 3rem 0;
          word-break: break-all;
          white-space: pre-line;
          .more {
            width: 100%;
            button {
              width: 100%;
              padding: 3px 0;
              background: transparent;
              color: #999;
              border: none;
              font-size: 12px;
            }
          }
          .contentDate {
            font-size: 10px;
            text-align: right;
            color: #c9c9c9;
            margin: 10px 0;
          }
        }

        .commentDel button {
          border: none;
          background: transparent;
          padding: 5px;
          svg {
            display: block;
            margin: auto;
          }
        }
        .commentTime {
          flex-grow: 1;
          text-align: right;
          text-overflow: clip;
          width: 17%;
          font-size: 8px;
        }
        .user {
          display: flex;
          align-items: center;
          flex-direction: column;
          .userContent {
            display: flex;
            align-items: center;
            & > img {
              width: 30px;
              height: 30px;
              object-fit: cover;
              border-radius: 50%;
              overflow: hidden;
            }
          }
          .comment {
            margin: 0;
            line-break: anywhere;
          }
          .more {
            width: 100%;
            button {
              width: 100%;
              padding: 3px 0;
              background: transparent;
              color: #999;
              border: none;
            }
          }
        }
      }
      .cardFooter {
        border-top: 1px solid rgba(0, 0, 0, 0.125);
        form {
          display: flex;
          justify-content: space-between;
          width: 100%;
          input {
            border: none;
            padding: 1rem;
            box-sizing: border-box;
            width: 100%;
          }
          button {
            border: none;
            padding: 1rem;
            color: #fff;
            display: flex;
          }
        }
      }
    }
    img {
      width: 100%;
      object-fit: contain;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 150px;
    .cardList li .cardHeader .title {
      font-size: 14px;
      gap: 10px;
      .info {
        flex-direction: column;
        align-items: baseline;
        gap: 0;
      }
    }
    .cardList li .cardHeader .title span:last-child {
      margin-left: 0;
    }
    .cardList li .cardHeader .title svg {
      /* display: none; */
    }
  }
`;

export const GroupDetailSearchMapContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;
  overflow: hidden;
  margin-bottom: 50px;
`;

export const BlogItemContainer = styled.li`
  padding: 15px;
  border: 1px solid #efefef;
  border-radius: 5px;
  cursor: pointer;
  .image {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .info {
    font-size: 15px;
    .title {
      font-weight: 600;
      display: block;
    }
    .cmpnyNm {
      color: #999;
    }
  }
  .mbers {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 10px;
    margin-top: 15px;
    span {
      background: #0074ca;
      padding: 3px 10px;
      border-radius: 10px;
      color: #fff;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 767px) {
    width: calc((100% - 15px) / 2);
    .info span {
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
`;

export const GroupDetailSearchMapSelectCmpny = styled.div`
  position: absolute;
  width: 30%;
  height: 100%;
  top: 0;
  left: 0;
  background: #fff;
  transform: translate(-100%);
  transition: 0.3s;
  opacity: 0;
  z-index: 10;
  &.active {
    transform: translateX(0);
    opacity: 1;
    > div > button {
      display: flex;
    }
  }
  > div {
    position: relative;
    width: 100%;
    height: 100%;
    > button {
      position: absolute;
      top: 10px;
      right: 0;
      display: flex;
      font-size: 20px;
      background: transparent;
      border: none;
      transform: translate(100%);
      width: 40px;
      height: 40px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 5px 5px 0;
      transition: 0.3s;
      display: none;
      border: 1px solid #fff;
      border-left: none;
      &:hover {
        border: 1px solid #0074ca;
        border-left: none;
        color: #0074ca;
        background: #efefef;
      }
    }
    .cmpny {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      font-size: 14px;
      padding: 10px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      .cmpnyLogo {
        width: 100%;
        height: 100px;
        background: #efefef;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          background: #fff;
          border-radius: 5px;
        }
      }
      .cmpnyInfo {
        display: flex;
        flex-direction: column;
      }
      .cmpnyNm {
        font-weight: 600;
        cursor: pointer;
      }
      .cmpnyMbers {
        display: flex;
        flex-wrap: wrap;
        gap: 5px 10px;
        span {
          background: #0074ca;
          padding: 3px 10px;
          border-radius: 10px;
          color: #efefef;
          font-size: 12px;
        }
      }
      .portList {
        height: 200px;
        width: 100%;
        .mySwiper {
          width: 100%;
          height: 100%;
          .swiper-slide {
            width: 85%;
          }
          .slideImageContent {
            width: 100%;
            height: 100%;
            padding: 10px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .last {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              cursor: pointer;
              gap: 5px;
              .icon {
                font-size: 15px;
                padding: 10px;
                background: #efefef;
                border-radius: 50%;
                display: flex;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    width: 300px;
  }
  @media screen and (max-width: 767px) {
    width: 250px;
  }
`;

export const StockItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  gap: 15px;
  .preview {
    width: 100%;
    aspect-ratio: 1;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    .stock {
      display: flex;
      flex-direction: column;
    }
    .prductNm,
    .cnsmrPc {
      font-weight: 700;
    }
    .cmpnyNm {
      background: #0074ca;
      padding: 2px 10px;
      border-radius: 10px;
      width: 100%;
      min-height: min-content;
      max-width: max-content;
      color: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: row;
    .preview {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .product {
      width: calc(100% - 115px);
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: auto;
      }
    }
  }
`;

export const BannerContainer = styled.div`
  display: grid;
  grid-template-columns: 70% auto;
  grid-template-rows: 25% calc(75% - 25px);
  gap: 25px;
  height: 500px;
  margin: 50px 0;
  div {
    border-radius: 30px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &:nth-child(1) {
      grid-column: 1 / 2;
      grid-row: 1 / span 2;
      background-color: #fff8dc;
    }
    &:nth-child(2) {
      grid-column: 2 / 3;
      grid-row: 1 / 2;
      background-color: #f8d3cb;
    }
    &:nth-child(3) {
      grid-column: 2 / 3;
      grid-row: 2 / 3;
      background-color: #ffffff;
    }
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 2fr 1fr;
    gap: 10px;
    margin: 30px 0;
    div {
      &:nth-child(1) {
        grid-column: 1 / span 2;
        grid-row: 1 / span 1;
      }
      &:nth-child(2) {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
        &:nth-child(3) {
          grid-column: 2 / span 1;
          grid-row: 2 / span 1;
        }
      }
    }
  }
`;

export const ScheduleContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 50px 0;
  .label {
    width: 200px;
    font-size: 24px;
    font-weight: 700;
  }
  .mySwiper {
    width: calc(100% - 220px);
    height: 72px;
  }
  .content,
  .not {
    display: flex;
    width: 100%;
    height: 100%;
    background: #fff;
    font-size: 14px;
    color: #535353;
    padding: 15px 20px;
    border-radius: 10px;
    cursor: pointer;
  }
  .not {
    align-items: center;
    justify-content: center;
  }
  .content {
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: space-between;
    span {
      width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(1) {
        font-size: 15px;
        color: #000;
        font-weight: 700;
      }
      &:nth-child(even) {
        text-align: right;
      }
    }
  }
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    margin: 30px 0;
    .label,
    .mySwiper {
      width: 100%;
    }
    .label {
      margin-bottom: 15px;
      font-size: 20px;
    }
  }
`;
