import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { NotDataList } from "../../../../styled/common";
import { ProductItem } from "../searchItem";

const Product = ({ productList, cmpnys }) => {
  const user = useSelector((state) => state?.user?.data[0]);

  return (
    <div className="productList">
      {productList.length > 0 ? (
        <ul>
          {productList.map((item) => (
            <ProductItem item={item} cmpnys={cmpnys} />
          ))}
        </ul>
      ) : (
        <NotDataList>상품이 존재하지 않습니다.</NotDataList>
      )}
    </div>
  );
};

export default Product;
