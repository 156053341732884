import React from "react";

const Feed = ({ feed, clickFeed }) => {
  const isImage = (imgUrl) => {
    return imgUrl && JSON.parse(imgUrl).length > 0;
  };

  return (
    <div className="feed">
      <span className="label">모임 피드 😀</span>
      <ul>
        {feed && feed.length > 0 ? (
          feed.map((item) => (
            <li onClick={() => clickFeed(item.feedNo)}>
              <img
                src={
                  isImage(item.imgUrl)
                    ? imgHandler(JSON.parse(item.imgUrl))
                    : "/img/function_22_bg.png"
                }
              />
              <p>{item.content}</p>
            </li>
          ))
        ) : (
          <li className="not">피드가 존재하지 않습니다.</li>
        )}
      </ul>
    </div>
  );
};

export default Feed;
