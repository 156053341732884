import React from "react";
import { PlanContainer } from "../../styled/info";
import { FaCheck } from "react-icons/fa6";

const PlanList = () => {
  const plans = {
    free: {
      name: "free",
      description: "간편한 것들만 모아서",
      monthlyPrice: 0,
      features: ["일정 관리", "프로젝트 관리", "모임 기능 제공", "개인 도메인"],
      label: "무료로 시작하기",
    },
    plus: {
      name: "plus",
      description: "시작하는 사장님들은",
      price: 10000,
      monthlyPrice: 4500,
      features: [
        "FREE의 모든 기능 포함",
        "모노티 플랫폼 입점",
        "매장 홍보 지원",
        "웹 결제 시스템 제공",
      ],
      label: "플러스",
    },
    premium: {
      name: "premium",
      description: "상생매장 사장님들을 위한",
      price: 20000,
      monthlyPrice: 9900,
      features: [
        "FREE와 PLUS의 모든 기능 포함",
        "픽업 오더",
        "매출 분석 리포트",
        "실시간 알림 발송",
      ],
      label: "프리미엄",
    },
  };

  const PlanItem = ({ value }) => {
    return (
      <li className={value.name}>
        <p>{value.description}</p>
        <strong>{value.name}</strong>
        <div className="price">
          {value.price && <p className="origin">{value.price.toLocaleString()}원</p>}
          <div>
            {value.monthlyPrice ? (
              <span>
                <strong>{value.monthlyPrice.toLocaleString()}</strong>원/월
              </span>
            ) : (
              <span>
                <strong>{value.monthlyPrice}</strong>원
              </span>
            )}
          </div>
        </div>
        <ul className="feature">
          {value.features.map((feature, index) => (
            <li>
              <span className="icon">
                <FaCheck />
              </span>
              <span>{feature}</span>
            </li>
          ))}
        </ul>
        <button>{value.label}</button>
      </li>
    );
  };

  return (
    <PlanContainer>
      <ul>
        {Object.entries(plans).map(([key, value]) => (
          <PlanItem value={value} />
        ))}
      </ul>
      <span>표시된 가격은 부가세 별도입니다.</span>
      <button>매달 정기 결제</button>
    </PlanContainer>
  );
};

export default PlanList;
