import styled from "styled-components";

export const RegisterTitleContainer = styled.div`
  margin: 0px auto 50px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  text-align: center;
  &.dark {
    color: rgba(255, 255, 255, 0.5);
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
  }
`;

export const RegisterMenuContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  text-align: center;
  &.dark {
    ul li {
      background: #000;
      &.active {
        background: rgba(37, 99, 235, 0.7);
      }
    }
  }
  ul {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    li {
      background: rgba(255, 255, 255, 0.5);
      width: 200px;
      height: 50px;
      line-height: 50px;
      border-radius: 10px;
      cursor: pointer;
      &.active {
        background: #fff;
      }
      a {
        color: rgba(0, 0, 0, 0.5);
      }
    }
  }
  @media screen and (max-width: 767px) {
    overflow-x: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
    ul {
      width: max-content;
      li {
        width: 125px;
        font-size: 14px;
        height: 35px;
        line-height: 35px;
      }
    }
  }
`;

export const RegisterFormContainer = styled.div`
  &.dark {
    ul li {
      background: #000;
      color: #eee;
      .timeArea > div select,
      .info label .checkmark,
      input[type="text"],
      .file label {
        background: #333;
        color: #eee;
      }
      .info label .checkmark {
        border: 2px solid #333;
      }
      input:checked ~ .checkmark::after {
        border-color: rgba(62, 118, 238, 1);
      }
    }
  }
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  @media screen and (max-width: 767px) {
  }
`;

export const RegisterItemContainer = styled.li`
  width: calc(50% - 10px);
  min-height: 180px;
  background: rgba(255, 255, 255, 0.5);
  padding: 30px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .message {
    font-size: 14px;
    display: block;
    /* text-align: right; */
    margin-bottom: 5px;
  }
  .file {
    width: 100%;
    display: flex;
    gap: 10px;
    input[type="file"] {
      display: none;
    }
    label {
      width: 160px;
      border: none;
      background: #fff;
      height: 52px;
      line-height: 52px;
      text-align: center;
      color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
    }
    .preview {
      width: 100px;
      height: 50px;
      max-width: calc(100% - 165px);
      /* height: 100%; */
      background: rgba(255, 255, 255, 0.5);
      border-radius: 10px;
      img {
        width: 104px;
        height: 52px;
        object-fit: contain;
      }
    }
  }
  .info {
    font-weight: 700;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    label {
      width: auto;
      display: flex;
      gap: 5px;
      align-items: center;
      .checkmark {
        position: relative;
        width: 20px;
        height: 20px;
        background: #fff;
      }
    }
    .label {
      font-size: 20px;
      font-weight: 300;
    }
  }
  input[type="text"] {
    border: none;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 52px;
    padding: 0 20px;
    color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
  .timeArea {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 10px;
    > div {
      width: calc(50% - 5px);
      span {
        display: block;
        text-align: center;
        margin-bottom: 5px;
      }
      select {
        background-color: #fff;
        width: 100%;
        height: 52px;
        border-radius: 10px;
        text-align: center;
        border: none;
        &:focus {
          outline: none;
          border: none !important;
          box-shadow: none;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    padding: 20px;
    border-radius: 20px;
    font-size: 14px;
    height: 120px;
    .info {
      font-size: 20px;
      .label {
        font-size: 12px;
      }
    }
    .timeArea > div select,
    input[type="text"] {
      height: 30px;
    }
    .file {
      align-items: center;
      .preview {
        max-width: none;
        width: 100px;
        height: 50px;
        text-align: center;
      }
      label {
        width: 100px;
        line-height: normal;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

export const RegisterIntroFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    .ck {
      color: #eee;
      background: #000;
    }
    .ck.ck-button:not(.ck-disabled):hover,
    a.ck.ck-button:not(.ck-disabled):hover,
    .ck.ck-button.ck-on,
    a.ck.ck-button.ck-on {
      background: rgba(255, 255, 255, 0.1);
    }
    .ck.ck-content,
    .ck.ck-button.ck-list-item-button,
    .ck.ck-button.ck-list-item-button.ck-on {
      background: #000;
    }
    .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content,
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused),
    .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    box-shadow: none;
    border: 1px solid #ccc;
  }
`;

export const RegisterPortFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    .font_color_black {
      color: #eee;
    }
    .actions .buttons button:last-child {
      background: #000;
      color: #eee;
    }
  }
  .actions {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    .buttons {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      gap: 20px;
      button {
        height: 40px;
        padding: 0 20px;
        border: none;
        &:first-child {
          background: rgba(37, 99, 235, 0.7);
          color: #fff;
        }
        &:last-child {
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }
    .pagination {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1050px) {
    .actions .buttons {
      top: 150%;
      transform: translateY(50%);
      gap: 10px;
      button {
        height: 30px;
        border-radius: 5px;
        font-size: 12px;
      }
    }
  }
`;

export const RegisterNoticeFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    color: #eee;
    .actions .buttons button:last-child {
      background: #000;
      color: #eee;
    }
  }
  .actions {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-wrap: wrap;
    .buttons {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: flex;
      gap: 20px;
      button {
        height: 40px;
        padding: 0 20px;
        border: none;
        &:first-child {
          background: rgba(37, 99, 235, 0.7);
          color: #fff;
        }
        &:last-child {
          background: rgba(255, 255, 255, 0.7);
        }
      }
    }
    .pagination {
      margin-top: 0;
    }
  }
  @media screen and (max-width: 1050px) {
    .actions {
      margin-top: 0px;
      .buttons {
        top: 150%;
        transform: translateY(50%);
        gap: 10px;
        button {
          height: 30px;
          border-radius: 5px;
          font-size: 12px;
        }
      }
    }
  }
`;

export const BoardSearchContainer = styled.div`
  &.dark {
    select,
    button,
    input[type="text"] {
      background: #eee;
      color: #000;
    }
  }
  .basic {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  select,
  button,
  input[type="text"] {
    background: rgba(255, 255, 255, 0.5);
    height: 55px;
    border: none;
    border-radius: 10px;
    color: rgba(0, 0, 0, 0.5);
    &:focus {
      outline: none;
      border: none !important;
      box-shadow: none;
    }
  }
  select {
    width: 230px;
    padding: 0 20px;
    cursor: pointer;
  }
  input[type="text"] {
    width: 310px;
    padding: 0 20px;
  }
  button {
    width: 55px;
  }
  @media screen and (max-width: 767px) {
    .basic {
      flex-wrap: wrap;
    }
    select,
    button,
    input[type="text"] {
      height: 30px;
      font-size: 14px;
    }
    select {
      width: 50%;
    }
    button {
      width: 20%;
    }
    input[type="text"] {
      width: calc(80% - 10px);
    }
  }
`;

export const RegisterPortViewFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    color: #eee;
    .font_color_gray {
      color: #eee;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    button {
      border: none;
      border-radius: 10px;
      padding: 10px 0;
      width: 100px;
      background: #555;
      &:last-child {
        margin-left: auto;
        background: rgba(37, 99, 235, 0.7);
      }
    }
  }
`;

export const RegisterNoticeWriteFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    color: #eee;
    .actions button:first-child {
      background: rgb(44, 45, 48);
      color: #eee;
    }
  }
  .actions {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 70px;
    button {
      width: 272px;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: rgb(255, 255, 255);
      background: rgba(37, 99, 235, 0.7);
      font-size: 16px;
      &:first-child {
        color: #000;
        background: #fff;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    font-size: 14px;
  }
`;

export const RegisterPhotoUploadFormContainer = styled.div`
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  &.dark {
    .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable),
    .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
    .cont input[type="text"] {
      border: 1px solid transparent !important;
      color: #eee;
      background: #000;
      &:focus {
        border: 1px solid rgba(255, 255, 255, 0.5) !important;
      }
    }
    .ck {
      color: #eee;
      background: #000;
    }
    .ck.ck-button:not(.ck-disabled):hover,
    a.ck.ck-button:not(.ck-disabled):hover,
    .ck.ck-button.ck-on,
    a.ck.ck-button.ck-on {
      background: rgba(255, 255, 255, 0.1);
    }
    .ck.ck-content,
    .ck.ck-button.ck-list-item-button,
    .ck.ck-button.ck-list-item-button.ck-on {
      background: #000;
      transition: 0.3s;
    }
    .ck.ck-editor__top .ck-sticky-panel .ck-sticky-panel__content,
    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
  .cont {
    margin: 70px;
    input[type="text"] {
      margin-bottom: 30px;
      width: 100%;
      border: none;
      padding: 20px;
      border: 1px solid transparent !important;
      &:focus {
        border: 1px solid rgba(255, 255, 255, 0.5) !important;
        box-shadow: none;
      }
    }
  }
  .ck.ck-editor__editable {
    height: 500px;
  }
  .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.5);
  }
  .cont {
    margin: 0;
  }
  .actions {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-top: 70px;
    button {
      width: 272px;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: rgb(255, 255, 255);
      background: rgba(37, 99, 235, 0.7);
      font-size: 16px;
      &:first-child {
        color: #000;
        background: #fff;
      }
      &:last-child {
        background: #dedede;
        color: #000;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    font-size: 14px;
  }
`;

export const RegisterNoticeViewContainer = styled.div`
  &.dark {
    color: #eee;
    .font_color_black,
    .font_color_gray,
    .board_content p {
      color: #eee;
    }
  }
  margin: 0px auto 70px;
  max-width: 1440px;
  padding: 0px 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  letter-spacing: -1px;
  .content_control {
    display: flex;
    gap: 20px;
    -webkit-box-pack: center;
    justify-content: center;
    margin-top: 70px;
    button {
      width: 272px;
      height: 40px;
      border-radius: 5px;
      border: none;
      color: rgb(255, 255, 255);
      background: rgba(37, 99, 235, 0.7);
      font-size: 16px;
      margin: 0;
      &:first-child {
        color: rgb(0, 0, 0);
        background: rgb(255, 255, 255);
      }
      &:last-child {
        color: rgb(0, 0, 0);
        background: rgb(255, 255, 255);
      }
    }
  }
`;
