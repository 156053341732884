import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import { Container } from "./component/styled/common";
import { SalesCheckContainer } from "./component/styled/salesCheck";

import SideMenu from "./component/common/sideMenu";
import PendingCount from "./component/salesCheck/pendingCount";
import SalesOverview from "./component/salesCheck/salesOverview";
import OrderHistory from "./component/salesCheck/orderHistory";
import Statistics from "./component/salesCheck/statistics";
import SettlementForm from "./component/salesCheck/settlementForm";

const SalesCheck = ({ selectCompany, stores }) => {
  const user = useSelector((state) => state?.user?.data[0]);

  useEffect(() => {
    if (!selectCompany) return;
    getCmpnyNm();
  }, [selectCompany]);

  const getCmpnyNm = async () => {
    const url = "/api/cmpnyNmLoad";
    const body = { cmpnyNo: selectCompany.value };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setCmpny([res.data]);
    }
  };

  const [active, setActive] = useState("orderHistory");
  const tabs = {
    orderHistory: "주문내역",
    salesCheck: "매출확인",
    statistics: "주문통계",
    settlementForm: "정산신청",
  };
  const [cmpny, setCmpny] = useState([]);

  const renderComponent = () => {
    switch (active) {
      case "orderHistory":
        return <OrderHistory active={active} tabs={tabs} selectCompany={selectCompany} />;
      case "salesCheck":
        return <SalesOverview active={active} tabs={tabs} />;
      case "statistics":
        return <Statistics active={active} tabs={tabs} />;
      case "settlementForm":
        return <SettlementForm active={active} tabs={tabs} />;
      default:
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}>
            준비중입니다
          </div>
        );
    }
  };

  return (
    <Container>
      <SalesCheckContainer>
        <div className="container">
          <SideMenu
            active={active}
            setActive={setActive}
            tabs={tabs}
            stores={stores}
            type="salesCheck"
          />
          <div className="content salesCheck">
            <PendingCount />
            <div className="viewer">{renderComponent()}</div>
          </div>
        </div>
      </SalesCheckContainer>
    </Container>
  );
};

export default SalesCheck;
