import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const MonthSelector = ({ toggleAnnualCalendar, userDate, changeYear, handleMonthClick }) => {
  const months = Array.from({ length: 12 }, (_, idx) => idx + 1);

  return (
    <div className="calendars">
      <div className="monthsInfo">
        <h2 onClick={toggleAnnualCalendar}>{userDate.year}년</h2>
        <div className="buttons">
          <button onClick={() => changeYear(-1)}>
            <BsChevronLeft />
          </button>
          <button onClick={() => changeYear(1)}>
            <BsChevronRight />
          </button>
        </div>
      </div>
      <div className="content">
        {months.map((month) => (
          <div key={month}>
            <span
              onClick={() => handleMonthClick(month)}
              className={userDate.month === month ? "active" : "inactive"}>
              {month}월
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MonthSelector;
