import React from "react";
import { StampPage } from "./styled";

const Stamp = () => {
  const url = getAppStoreUrl();

  return (
    <StampPage>
      <img src="/img/stamp.jpg" alt="" />
      <div>
        <button
          onClick={() => {
            if (url) {
              window.location.href = url;
            }
          }}>
          모노티 앱 다운받기
        </button>
      </div>
    </StampPage>
  );
};

export default Stamp;
