import React from "react";
import { TabMenuContainer } from "../styled/common";

const Menu = ({ tabChange, tabs, active, isCreate = false }) => {
  return (
    <TabMenuContainer>
      <ul>
        {tabs.map((ele, idx) =>
          ele ? (
            <li onClick={() => tabChange(idx)} className={active === idx ? "active" : "inactive"}>
              {ele}
            </li>
          ) : null,
        )}
      </ul>
      {isCreate && <button>피드작성</button>}
    </TabMenuContainer>
  );
};

export default Menu;
