import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getSetting } from "../../../../../../store/scheduler";
import SideMenuChoiceOption from "./sideMenuChoiceOption";
import { ChoiceList, SwitchContainer } from "../../styled/scheduler";

const Choice = () => {
  const dispatch = useDispatch();
  const { mySchedSet, isDarkMode } = useSelector((state) => state?.scheduler);
  const [isOpen, setIsOpen] = useState({
    companyYnList: true,
    groupYnList: true,
  });

  const updateSchedulerSetting = async (updateSet) => {
    try {
      const res = await axios.put("/api/toktokSetting", updateSet);
      if (res.status === 200) dispatch(getSetting(updateSet));
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckboxChange = (e) => {
    const { id, name, type } = e.target;
    const updatedSet = { ...mySchedSet };

    if (type === "radio") {
      updatedSet[name] = id.split("-")[1];
    } else if (type === "checkbox") {
      if (name === "personalYn") {
        updatedSet[name] = !updatedSet[name];
      } else {
        updatedSet[name] = updatedSet[name].map((item) =>
          item.orgNo === parseInt(id) ? { ...item, check: !item.check } : item,
        );
      }
    }
    updateSchedulerSetting(updatedSet);
  };

  const handleSelectAll = (name) => {
    const updatedItems = mySchedSet[name].map((item) => ({
      ...item,
      check: !mySchedSet[name].every((item) => item.check),
    }));
    updateSchedulerSetting({ ...mySchedSet, [name]: updatedItems });
  };

  const toggleOpen = (category) => {
    setIsOpen((prev) => ({ ...prev, [category]: !prev[category] }));
  };

  const renderRadioOptions = (options, name, checkedValue) =>
    options.map((option) => (
      <React.Fragment key={option.id}>
        <input
          type="radio"
          id={option.id}
          name={name}
          checked={checkedValue === option.value}
          onChange={handleCheckboxChange}
        />
        <label className="tab" htmlFor={option.id}>
          {option.label}
        </label>
      </React.Fragment>
    ));
  const completeYnOptions = [
    { id: "completeYn-D", label: "전체", value: "D" },
    { id: "completeYn-Y", label: "완료", value: "Y" },
    { id: "completeYn-N", label: "미완료", value: "N" },
  ];

  const viewModeOptions = [
    { id: "viewMode-D", label: "일정별", value: "D" },
    { id: "viewMode-C", label: "유형별", value: "C" },
  ];

  return (
    <ChoiceList className={isDarkMode}>
      {/* 완료 일정 선택 */}
      <div>
        <div className="label">완료 일정 선택</div>
        <SwitchContainer className={`${isDarkMode} complete`}>
          <div className="tabs">
            {renderRadioOptions(completeYnOptions, "completeYn", mySchedSet.completeYn)}
            <span className="glider"></span>
          </div>
        </SwitchContainer>
      </div>

      {/* 달력 유형 선택 */}
      <div>
        <div className="label">달력 유형 선택</div>
        <SwitchContainer className={`${isDarkMode} calendarView`}>
          <div className="tabs">
            {renderRadioOptions(viewModeOptions, "viewMode", mySchedSet.viewMode)}
            <span className="glider"></span>
          </div>
        </SwitchContainer>
      </div>

      {/* 일정 유형 선택 */}
      <div>
        <div className="label">일정 유형 선택</div>
        <ul>
          <li className="personalYn">
            <label htmlFor="personalYn">
              <input
                type="checkbox"
                checked={mySchedSet.personalYn}
                name="personalYn"
                id="personalYn"
                onChange={handleCheckboxChange}
              />
              개인
            </label>
          </li>
          {mySchedSet.companyYnList?.length > 0 && (
            <SideMenuChoiceOption
              label="전체 업무"
              name="companyYnList"
              items={mySchedSet.companyYnList}
              isOpen={isOpen}
              changeIsOpen={toggleOpen}
              changeInput={handleCheckboxChange}
              selectAllCheck={handleSelectAll}
            />
          )}
          {mySchedSet.groupYnList?.length > 0 && (
            <SideMenuChoiceOption
              label="전체 모임"
              name="groupYnList"
              items={mySchedSet.groupYnList}
              isOpen={isOpen}
              changeIsOpen={toggleOpen}
              changeInput={handleCheckboxChange}
              selectAllCheck={handleSelectAll}
            />
          )}
        </ul>
      </div>
    </ChoiceList>
  );
};

export default Choice;
