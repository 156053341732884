import React from "react";
import { SearchContainer } from "../../../styled/common";
import { IoChevronDown } from "react-icons/io5";

const Form = ({
  options,
  optionOpen,
  setOptionOpen,
  active,
  menuRef,
  setSearched,
  searched,
  handleSearch,
  tabChange,
}) => {
  return (
    <SearchContainer className="select">
      <img src="/img/group/search.png" />
      <div className="select">
        <span onClick={() => setOptionOpen(!optionOpen)}>
          {options[active].name}
          <i>
            <IoChevronDown />
          </i>
        </span>
        {optionOpen && (
          <ul ref={menuRef}>
            {options.map((opt, idx) => (
              <li onClick={() => tabChange(idx)} className={active === idx ? "active" : "inactive"}>
                {opt.name}
              </li>
            ))}
          </ul>
        )}
      </div>
      <input
        type="text"
        placeholder="검색어를 입력하세요."
        onKeyDown={(e) => handleEnterKey(e, handleSearch)}
        value={searched}
        onChange={(e) => setSearched(e.target.value)}
      />
    </SearchContainer>
  );
};

export default Form;
