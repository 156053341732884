import React from "react";

const Member = ({ item, index, group }) => {
  return (
    <li key={`${item.mberNo}-${index}`}>
      <div className="image">
        <img
          src={item.mberImgPath ? imgHandler(item.mberImgPath) : "/img/user2.png"}
          alt="member"
        />
      </div>
      <span>
        {group.gbn && item.gbnValue ? `${item.gbnValue}${group.gbn} ` : ""}
        {item.nickNm || "닉네임 미설정"}
      </span>
    </li>
  );
};

export default Member;
