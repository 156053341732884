import styled from "styled-components";

const color = {
  refrigerated: `#2a3341`,
  roomTemperature: `#FFE072`,
  carrier: `#cdb5f5`,
};

export const Container = styled.div`
  /* @font-face {
    font-family: "JSArirangHON-Regular";
    src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/JSArirangHON-RegularA1.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  } */
  width: 100vw;
  min-height: 100vh;
  cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
  button,
  a,
  input,
  label,
  textarea,
  select,
  body {
    cursor: url("/img/kiosk_cursor_hover.png") 0 0, auto;
  }
  position: relative;
  &.bg {
    background: #1e1e1e;
  }
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  font-size: 4.9444vw;
  z-index: 100;
  button {
    background: transparent;
    border: none;
    display: flex;
    color: #ccc;
  }
`;

export const Header = styled.header`
  @font-face {
    font-family: "JSArirangHON-Regular";
    src: url("https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/JSArirangHON-RegularA1.woff")
      format("woff");
    font-weight: normal;
    font-style: normal;
  }
  color: #333;
  position: relative;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  .tit {
    > span {
      height: 4.5vw;
      font-size: 3vw;
      line-height: auto;
      font-family: "JSArirangHON-Regular", sans-serif;
      margin-bottom: 3px;
    }
    display: flex;
    align-items: center;
    gap: 20px;
    .operation {
      height: 4.5vw;
      display: flex;
      align-items: center;
      flex-direction: column;
      border: none;
      background: transparent;
      justify-content: center;
      color: #2a3341;
      svg {
        display: block;
        font-size: 3vw;
      }
      span {
        font-weight: bold;
        /* font-size: 14px; */
      }
      /* img {
        height: calc(100% - 20px);
        filter: invert(100%);
        object-fit: contain;
      } */
    }
  }
  .searchForm {
    width: 400px;
    height: 52px;
    position: relative;
    color: #4d4d4d;
    font-size: 45px;
    padding: 2px 0;
    border-bottom: 3px solid #a0a0a0;
    display: flex;
    align-items: center;
    input {
      display: block;
      width: calc(100% - 130px);
      height: 100%;
      border: none;
      font-weight: 700;
      &:focus {
        border: none !important;
        border-bottom: none;
        box-shadow: none;
      }
    }
    .reset {
      width: 24px;
      height: 24px;
      border: none;
      background: transparent;
      font-size: 24px;
      background: #eee;
      border-radius: 50%;
      svg {
        display: block;
        margin: auto;
      }
    }
    .search {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      padding: 10px 30px;
      background: rgb(42, 51, 65);
      color: rgb(255, 255, 255);
      border: none;
      border-radius: 31px;
      font-size: 20px;
    }
  }
  .resStatusDate {
    position: relative;
    font-size: 20px;
    > button {
      padding: 10px 30px;
      background: rgb(42, 51, 65);
      color: rgb(255, 255, 255);
      border: none;
      border-radius: 31px;
    }
    .bg {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 900;
    }
    .calendarContent {
      position: relative;
      .reset {
        height: 50px;
        position: absolute;
        top: 10px;
        left: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        button {
          font-weight: 700;
          background: rgb(42, 51, 65);
          color: rgb(255, 255, 255);
          border-radius: 10px;
          padding: 2px 10px;
          border: none;
        }
      }
    }
    .calendar {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
      width: min-content;
      height: min-content;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      border-radius: 10px;
      overflow: hidden;
      .rdrDefinedRangesWrapper,
      .rdrDateDisplayWrapper {
        display: none;
      }
      .rdrDateRangeWrapper {
        font-size: 20px;
        font-weight: 700;
      }
      .rdrDateDisplayItem input,
      .rdrMonthAndYearPickers select,
      .rdrWeekDay,
      .rdrDay button {
        font-weight: 700;
      }
    }
  }
  .close {
    height: 4.5vw;
    font-size: 4.5vw;
    display: flex;
    gap: 10px;
    button {
      border: none;
      background: transparent;
      color: #333;
      display: flex;
      svg {
        stroke-width: 10px;
      }
    }
  }
  @media screen and (max-width: 820px) {
    .tit {
      font-size: 5.3171vw;
    }
    .searchForm {
      font-size: 14px;
      width: calc(100vw - 20vw - 100px);
      height: 35px;
      input {
        width: calc(100% - 80px);
        font-size: 20px;
      }
      .reset {
        font-size: 18px;
      }
      .search {
        font-size: 14px;
        padding: 5px 15px;
      }
    }
    .resStatusDate .calendar {
      width: 90%;
      .rdrDateRangePickerWrapper {
        width: 100%;
        .rdrCalendarWrapper {
          font-size: 14px;
          width: 100%;
          .rdrMonthAndYearWrapper,
          .rdrMonths,
          .rdrMonth {
            width: 100%;
          }
        }
      }
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 7.3841vh;
  width: 100%;
  .date {
    font-size: 2.9063vw;
    font-weight: 700;
  }
  .kioskLogo {
    width: 50vw;
    height: 100%;
    text-align: right;
    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 820px) {
    height: 6.4815vh;
    .date {
      font-size: 3.6585vw;
    }
    .kioskLogo {
      width: 38.5366vw;
    }
  }
`;

export const Table = styled.ul`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  overflow-y: auto;
  overflow-x: hidden;
  &.pickup {
    height: 49%;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
  }
  li {
    text-align: center;
    font-size: 16px;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 3px solid #f2f2f2;
    &.notUser {
      width: 100%;
      height: calc(100% - 70px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:first-child {
      font-weight: 700;
      background: #f2f2f2;
    }
    &:not(:first-child) .status span {
      font-size: 14px;
      border: 2px solid;
      padding: 2px 7px;
      font-weight: 700;
    }
    .tel,
    .duration,
    .memo,
    .time,
    .price,
    .moblphonNo {
      width: 15%;
    }
    .status,
    .type,
    .name,
    .index,
    .payType,
    .platform,
    .stamps,
    .rewardCount,
    .regionNo,
    .rewardAvailable {
      width: 10%;
    }
    .actions {
      width: 10%;
      button {
        background: #efefef;
        border: 2px solid #efefef;
        border: none;
        padding: 5px 0;
        font-weight: 700;
        width: 100%;
        border-radius: 3px;
      }
    }
  }
`;

export const List = styled.ul`
  gap: 5px;
  font-size: 18px;
  &.pickUp {
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
  }
  > li {
    display: inline-block;
    width: calc(50% - 2.5px);
    padding: 10px;
    border: 3px solid #efefef;
    border-radius: 20px;
    position: relative;
    &:not(.notUser) {
      max-height: 300px;
      height: min-content;
      &:nth-child(2n + 2) {
        margin-bottom: 5px;
      }
      &:nth-child(odd) {
        margin-right: 5px;
      }
    }
    &.notUser {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    ul {
      li {
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 7px;
        &:last-child {
          margin-bottom: 0;
        }
        span.tit {
          font-weight: 700;
        }
        span.status {
          background: #fff;
          color: #2e5aac;
          border: 2px solid #2e5aac;
          padding: 2px 7px;
          font-weight: 700;
          font-size: 12px;
        }
        &.storageCategory {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            gap: 10px;
            .box {
              height: 30px;
              width: 30px;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-position: 50% 50%;
              background-size: 60%;
              &.refrigerated {
                background-color: ${color.refrigerated};
                background-image: url("/img/sungsimdang/sungsimdang_refrigerated_storage.png");
              }
              &.roomTemperature {
                background-color: ${color.roomTemperature};
                background-image: url("/img/sungsimdang/sungsimdang_roomTemperature_storage.png");
              }
              &.carrier {
                background-color: ${color.carrier};
                background-image: url("/img/sungsimdang/sungsimdang_carrier_storage.png");
              }
            }
          }
        }
      }
    }
    .statusButton {
      position: absolute;
      top: 10px;
      right: 10px;
      background: #efefef;
      border-radius: 5px;
      border: none;
      padding: 5px 10px;
      font-weight: 700;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    > li {
      width: 100%;
      &:not(.notUser) {
        max-height: none;
        margin-bottom: 5px;
        &:nth-child(odd) {
          margin-right: 0;
        }
      }
    }
  }
`;

export const CustomInput = styled.label`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  height: 35px;
  width: 35px;
  &.block {
    display: block;
    /* margin: auto; */
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    width: 0;
    height: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid ${color.refrigerated};
    border-radius: 10px;
    background-color: transparent;
    transition: all 0.3s ease;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
      opacity: 0;
      width: 13px;
      height: 24px;
      border: solid #fff;
      border-width: 0px 3px 3px 0px;
      transition: 0.3s;
    }
  }
  input:checked ~ .checkmark {
    background: ${color.refrigerated};
    &:after {
      opacity: 1;
      transform: translate(-50%, -65%) rotate(45deg) scale(1);
      border-color: #fff;
    }
  }
  &:hover .checkmark {
    background-color: #efefef;
  }
  @media screen and (max-width: 767px) {
    width: 25px;
    height: 25px;
    .checkmark {
      border-radius: 5px;
    }
    .checkmark::after {
      width: 9px;
      height: 15px;
    }
  }
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  display: flex;
  font-size: 50px;
  z-index: 3333;
  @media screen and (max-width: 767px) {
    font-size: 30px;
    top: 5px;
    right: 5px;
  }
`;

export const Modal = styled.div`
  position: absolute;
  max-width: 900px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  border: 1px solid #a6a6a6;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: 40px;
  border-radius: 30px;
  box-sizing: border-box;
  font-weight: 700;
  overflow: hidden;
  width: 100%;
  height: 700px;
  z-index: 33;
  .modalContent {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 50px;
  }
  @media screen and (max-width: 767px) {
    width: 90%;
    height: 90%;
    max-width: none;
    font-size: 20px;
    border-radius: 15px;
    .modalContent {
      padding: 15px;
    }
  }
`;

export const Buttons = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font-size: 30px;
  button {
    width: 200px;
    border: none;
    padding: 10px 0;
    border-radius: 10px;
    background: ${color.refrigerated};
    transition: 0.3s;
    color: #fff;
    &:first-child {
      margin-right: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    font-size: 20px;
    button {
      width: calc(50% - 10px);
    }
  }
`;
