import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { getToken } from "firebase/messaging";
import axios from "axios";

import { exitFullscreen, useDisableScroll } from "../kiosk/component/function/kiosk";
import { messaging } from "../../../firebase/firebase.js";

import Pickup from "./pickup";
import SalesCheck from "./salesCheck";
import Review from "./review";
import Community from "./community";
import Menus from "./menus";
import StoreHeader from "./component/common/storeHeader";
import { AlertModal, ChoiceModal, ConfirmationModal, SelectModal } from "./component/common/modal";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const defaultModalConfig = {
  isOpen: false,
  type: "",
  title: "",
  message: [],
  handleConfirm: null,
  handleCancel: null,
};

const Store = () => {
  useDisableScroll();

  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  const stores = company.filter((item) => item.gbn === "ss") || [];

  const [activeMenu, setActiveMenu] = useState();
  const [data, setData] = useState([]);
  const [checkedOrders, setCheckedOrders] = useState();
  const [mobileActive, setMobileActive] = useState("PENDING");
  const [desktopActive, setDesktopActive] = useState("PENDING");
  const [alertModalConfigs, setAlertModalConfigs] = useState([]);
  const [modalConfig, setModalConfig] = useState({ ...defaultModalConfig });
  const [modal, setModal] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectCompany, setSelectCompany] = useState();

  useEffect(() => {
    setModal(getModalContent());
  }, [modalConfig]);

  useEffect(() => {
    if (!selectCompany) return;

    getOrders(selectCompany);
    getLocalStorage(selectCompany);

    const eventSource = initializeOrderEvent(selectCompany);
    console.log(eventSource);
    return () => {
      eventSource.close();
    };
  }, [selectCompany]);

  useEffect(() => {
    if (!stores || !stores.length) {
      exitFullscreen();
      toast.error("회사가 존재하지 않습니다.");
      navigate("/mypage");
    }

    openModal({
      type: "select",
      title: "회사 선택",
      placeholder: "회사를 선택해주세요.",
      handleChangeOption: (option) => changeOption(option),
      options: [...stores].map((item) => ({ value: item.cmpnyNo, label: item.cmpnyNm })),
      handleConfirm: (reason) => {
        if (!reason) {
          toast.error("선택된 회사가 존재하지 않습니다.");
          return;
        }
        setSelectCompany(reason);
        closeModal(true);
      },
      required: true,
    });
  }, []);

  const getModalContent = () => {
    if (!modalConfig.isOpen) return null;

    const modalProps = {
      title: modalConfig.title,
      message: modalConfig.message,
      handleConfirm: modalConfig.handleConfirm || closeModal,
      handleCancel: modalConfig?.required ? null : modalConfig.handleCancel || closeModal,
      ...modalConfig,
    };

    switch (modalConfig.type) {
      case "alert":
        return <AlertModal {...modalProps} />;
      case "confirmation":
        return <ConfirmationModal {...modalProps} />;
      case "select":
        return <SelectModal {...modalProps} active={modalConfig.active} />;
      default:
        return null;
    }
  };

  // 웹소켓 연결
  const initializeOrderEvent = (company) => {
    const eventSource = new EventSource(`https://erp.1472.ai/events?cmpnyNo=${company.value}`);
    // const eventSource = new EventSource(`http://cloud.1472.ai:8888/events?cmpnyNo=${company.value}`);

    eventSource.onopen = async () => {
      console.log("eventSource open");
    };

    eventSource.onerror = (error) => {
      console.error("알림 에러:", error);
      eventSource.close();
    };

    eventSource.onmessage = async (event) => {
      let token;
      let message = JSON.parse(event.data);
      if (message.status === "connected") return;

      const messages = {
        0: { title: "신규 주문", body: "새로운 주문이 들어왔습니다.", tab: "PENDING" },
        1: {
          title: "주문 취소",
          body: `${message.orderId}번 주문이 취소되었습니다.`,
          tab: "PICKUP",
        },
      };

      try {
        token = await getToken(messaging, {
          vapidKey:
            "BDTF9CMkogFUR-U1aUldUuwl1y3c6V9UuEYtcWoCBMXFJUj9bXOOJpsb249HJcUU4SM8j6RG5zwVe0hrUIzhNQU",
        });
      } catch (error) {
        console.error("FCM Token err:", error);
      }

      getOrders(company);

      if (Notification.permission === "granted") {
        const notification = new Notification(`${messages[message?.gbn]?.title} `, {
          body: `${messages[message?.gbn]?.body} 주문을 확인해 주세요.`,
          icon: "/img/logo.png",
        });

        notification.onclick = function () {
          window.focus();
          selectOrder({ id: message?.orderId });
          setActiveMenu("pickup");
        };

        const sound = new Audio("/source/notification.mp3");
        sound.play().catch((error) => {
          console.error("사운드 재생 오류:", error);
        });

        if (isMobile) {
          setMobileActive(messages[message?.gbn]?.tab);
        } else {
          setDesktopActive(messages[message?.gbn]?.tab);
        }
      } else {
        console.warn("알림 권한이 거부되었습니다.");
      }

      openAlertModal({
        orderId: message.orderId,
        title: `${messages[message?.gbn]?.title}`,
        message: [`${messages[message?.gbn]?.body}`, "주문을 확인해 주세요."],
      });
    };

    return eventSource;
  };

  const selectOrder = async (item) => {
    const detail = await getOrder(item.id);

    if (detail) {
      updateLocalStorage(detail.id);
      setSelected(detail);
    }
  };

  const updateLocalStorage = (id) => {
    setCheckedOrders((orders) => {
      const cmpnyNo = selectCompany?.value;
      if (!cmpnyNo) {
        return orders;
      }

      const currentOrders = orders[cmpnyNo] || [];

      if (!currentOrders.includes(id)) {
        const updatedOrders = [...currentOrders, id];

        const updatedState = {
          ...orders,
          [cmpnyNo]: updatedOrders,
        };

        localStorage.setItem("checkedOrders", JSON.stringify(updatedState));

        return updatedState;
      }

      return orders;
    });
  };

  // 주문 상세보기
  const getOrder = async (id) => {
    const query = `
      query SsOrder($id: ID!) {
        ssOrder(id: $id) {
            id
            orderFlag
            mberNo
            mberTel
            cmpnyNo
            cmpnyNm
            price
            reqTerm
            status
            createdAt
            updatedAt
            pickupBy
            orderId
            cancelReason
            products {
                productId
                quantity
                price
                name
            }
        }
      }
    `;
    const variables = { id };

    try {
      const res = await axios.post(
        "/erp/ss/order",
        { query, variables },
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      return res?.status === 200 ? res.data : null;
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  // 주문 리스트 조회
  const getOrders = async (company) => {
    const query = `
    query SsOrders($cmpnyNo: Int, $createdAt: String) {
      ssOrders(filter: { cmpnyNo: $cmpnyNo, createdAt: $createdAt }) {
       orders {
            id
            orderFlag            
            status
            createdAt
            pickupBy
            price
        }
      }
    }
  `;

    const variables = {
      cmpnyNo: company?.value,
      createdAt: moment().format("YYYY-MM-DD"),
    };

    try {
      const res = await axios.post(
        "/erp/ss/orders",
        { query, variables },
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      setData(res?.data?.orders || []);
    } catch (error) {
      console.error(error);
    }
  };
  const getLocalStorage = (company) => {
    let local = JSON.parse(localStorage.getItem("checkedOrders"));
    if (!local || typeof local !== "object" || Array.isArray(local)) {
      local = {};
    }

    if (!local.hasOwnProperty(company.value)) {
      local[company.value] = [];
    }

    console.log(local);
    setCheckedOrders(local);
  };

  const openModal = (options) => {
    setModalConfig({
      isOpen: true,
      ...options,
    });
  };

  const closeModal = (flag = false) => {
    setModalConfig(() => ({ ...defaultModalConfig }));

    if (flag) {
      setSelected(null);
    }
  };

  const changeOption = (item) => {
    setModalConfig((config) => ({ ...config, active: item }));
  };

  // 주문 알림 모달
  const closeAlertModal = (index = null) => {
    const orderId = alertModalConfigs[index].orderId;
    if (selected && selected?.id !== orderId) {
      selectOrder({ id: orderId });
    }

    setAlertModalConfigs((prevConfigs) => {
      if (index !== null) {
        return prevConfigs.filter((_, i) => i !== index);
      }
      return [];
    });
    setActiveMenu("pickup");
  };

  // 주문 알림 모달
  const openAlertModal = (options) => {
    setAlertModalConfigs((config) => {
      const newIndex = config.length;

      return [
        ...config,
        { isOpen: true, ...options, handleConfirm: () => closeAlertModal(newIndex) },
      ];
    });
  };

  // 주문 모달 포지션 계산
  const calcAlertPosition = (index, totalModals) => {
    const basePosition = 50;
    const baseOffset = 10;

    if (totalModals === 1) {
      return {
        top: `${basePosition}%`,
        left: `${basePosition}%`,
        transform: "translate(-50%, -50%)",
      };
    }

    const offset = baseOffset * index;

    return {
      top: `calc(${basePosition}% + ${offset}px)`,
      left: `calc(${basePosition}% + ${offset}px)`,
    };
  };

  const renderComponent = () => {
    switch (activeMenu) {
      case "pickup":
        return (
          <Pickup
            getOrders={getOrders}
            data={data}
            checkedOrders={checkedOrders}
            mobileActive={mobileActive}
            setMobileActive={setMobileActive}
            desktopActive={desktopActive}
            setDesktopActive={setDesktopActive}
            closeModal={closeModal}
            openModal={openModal}
            selected={selected}
            setSelected={setSelected}
            changeOption={changeOption}
            selectOrder={selectOrder}
            selectCompany={selectCompany}
          />
        );
      case "salesCheck":
        return <SalesCheck selectCompany={selectCompany} stores={stores} />;
      case "review":
        return <Review selectCompany={selectCompany} stores={stores} />;
      case "community":
        return <Community />;
      default:
        return <Menus setActiveMenu={setActiveMenu} />;
    }
  };

  return (
    <>
      {alertModalConfigs.map(
        (config, index) =>
          config.isOpen && (
            <AlertModal
              key={index}
              title={config?.title}
              message={config?.message}
              handleConfirm={() => closeAlertModal(index)}
              index={index}
              style={calcAlertPosition(index, alertModalConfigs.length)}
            />
          ),
      )}
      {modalConfig?.isOpen && modal}
      {activeMenu && <StoreHeader setActiveMenu={setActiveMenu} selectCompany={selectCompany} />}
      {renderComponent()}
    </>
  );
};

export default Store;
