import React from "react";
import { Check } from "../../../../styled/create";

const TableMember = ({ mber, handleSelect, chkMbers, clickButton }) => {
  return (
    <tr key={mber.mberNo}>
      <td>
        <Check>
          <input
            type="checkbox"
            checked={chkMbers[mber.mberNo] || false}
            onChange={() => handleSelect(mber.mberNo)}
          />
        </Check>
      </td>
      <td>{mber.nickNm ? mber.nickNm : "닉네임 미설정"}</td>
      <td>
        <button onClick={() => clickButton([mber], true)}>수락</button>
        <button onClick={() => clickButton([mber], false)}>거절</button>
      </td>
    </tr>
  );
};

export default TableMember;
