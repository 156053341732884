import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";

const DateCell = ({ info, holidays, isHolidayOpen, getWeatherForDate }) => {
  const { isDarkMode } = useSelector((state) => state?.scheduler);
  const current = moment(info.date).format("YYYY-MM-DD");
  const target = holidays.find((h) => current === moment(h.startDt).format("YYYY-MM-DD"));
  const dateFormatted = moment(info.date).format("D");

  const handleContext = (current) => {
    console.log(current);
  };

  return target ? (
    <>
      <div
        onClick={() => isHolidayOpen(target)}
        className="holiday"
        style={{
          color: isDarkMode ? "#efefef" : "#1b1b1b",
        }}>
        {target.icon && <img src={`/img/scheduler/${target.icon}`} alt={target.title} />}
        {target.title}
      </div>
      <div className="date" onContextMenu={() => handleContext(current)}>
        <div>{getWeatherForDate(info)}</div>
        <span style={{ color: `#${target.color}` }}>{dateFormatted}</span>
      </div>
    </>
  ) : (
    <>
      <div></div>
      <div className="date" onContextMenu={() => handleContext(current)}>
        <div>{getWeatherForDate(info)}</div>
        <span>{dateFormatted}</span>
      </div>
    </>
  );
};

export default DateCell;
