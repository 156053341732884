import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

import { handleLogin } from "../../store/user";
import { getSetting } from "../../store/scheduler";

import LoginForm from "../login/component/loginForm";
import { useMediaQuery } from "react-responsive";

const ShoppingLogin = () => {
  const user = useSelector((state) => state?.user?.data[0]);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const { url } = useParams();
  const { homepageInfo } = useOutletContext();
  const [logo, setLogo] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (url && homepageInfo?.logoImage) {
      setLogo(homepageInfo.logoImage);
    }
    if (user) {
      dispatch(handleLogin(user));
      navigate("/shop");
    }
  }, []);

  const getMyDomain = async (cmpnyNo) => {
    if (!cmpnyNo) return "";

    const url = "/api/domainSettingLoad";
    const req = { delYn: "N", cmpnyNo };

    const res = await axios.post(url, req);

    console.log(res);

    if (res.data.domain) {
      return res.data.domain;
    } else {
      return "";
    }
  };

  const getSchedulerSetting = async (mber) => {
    const domain = await getMyDomain(mber.cmpnyNo);

    const apiUrl = "/api/toktokSettingList";
    const body = { mberNo: mber.mberNo };
    const res = await axios.post(apiUrl, body);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));
      navigate(`/shop${url ? `/${url}` : ""}`);
    }
  };

  return (
    <div style={{ paddingBottom: isMobile ? 50 : 0 }}>
      <LoginForm getSchedulerSetting={getSchedulerSetting} url={url} logo={logo} />
    </div>
  );
};

export default ShoppingLogin;
