import React from "react";

const NoKeyword = () => {
  return (
    <article className="boundary pdb_70">
      <section id="secResult" className="pdt_40 pdb_30 font_24 text_center">
        <span>검색어를 입력해 주세요.</span>
      </section>
    </article>
  );
};

export default NoKeyword;
