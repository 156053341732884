import styled from "styled-components";

export const CuteContainer = styled.main`
  min-height: 100vh;
  font-family: "Pretendard";
  /* common */
  img {
    user-select: none;
  }
  .dot {
    width: 25px;
    height: 25px;
    background: #ffc96f;
    border-radius: 50%;
  }
  .label {
    align-items: center;
    display: flex;
    font-size: 56px;
    font-weight: 700;
    gap: 20px;
    max-width: 1440px;
    padding: 0 20px;
    margin: 0 auto 60px;
  }
  .info {
    font-size: 56px;
    font-weight: 700;
    text-align: center;
    span {
      font-size: 24px;
      font-weight: normal;
      display: block;
      margin: 30px 0 60px;
    }
  }

  /* common */
  .introduce {
    margin: 170px auto;
    max-width: 1440px;
    padding: 0 20px;
    .introduceContent {
      height: 700px;
      position: relative;
      display: flex;
      align-items: center;
      .bg {
        z-index: 1;
        width: 1160px;
        height: 100%;
        background: #fff9f9;
        position: absolute;
        right: 0;
        bottom: 0;
      }
      .box {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        z-index: 3;
        gap: 65px;
        width: 100%;
        .viewarea {
          width: calc(100% - 430px);
          height: 400px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        button {
          font-size: 30px;
          background: #ffc96f;
          padding: 5px 10px;
          border-radius: 10px;
          font-weight: 700;
        }
        .content {
          font-weight: 700;
          font-size: 30px;
          width: 300px;
          span {
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
          }
          button {
            margin-top: 30px;
          }
        }
      }
    }
  }
  .product {
    margin: 120px 0;
    .label {
      justify-content: end;
    }
    .products {
      padding: 130px 0;
      background: #fff9f9;
      .swiperContent {
        position: relative;
        max-width: 1440px;
        padding: 0 20px;
        margin: auto;
      }
      .productSwiper {
        height: 610px;
        .swiper-slide {
          &:nth-child(even) .productSlide {
            padding-bottom: 90px;
          }
          &:nth-child(odd) .productSlide {
            padding-top: 70px;
            padding-bottom: 20px;
          }
        }
        .productSlide {
          height: 100%;
          width: 100%;
          .box {
            background: #fff;
            height: 100%;
            width: 100%;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            cursor: pointer;
            img {
              width: 100%;
              height: calc(100% - 80px);
              object-fit: cover;
            }
            .productInfo {
              width: 100%;
              span {
                width: 100%;
                font-size: 24px;
                display: inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
              p {
                font-size: 20px;
                color: #555555;
              }
            }
          }
        }
        .swiper-pagination-progressbar {
          background: rgba(0, 0, 0, 0.1);
          height: 4px;
          top: auto;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
        }
        .swiper-pagination-progressbar-fill {
          background: #ffc96f;
          height: 100%;
        }
      }
    }
  }
  .portfolio {
    margin: 120px 0;
    .content {
      max-width: 1440px;
      padding: 0 20px;
      margin: 0 auto;
      width: 100%;
      position: relative;
      .productSwiper {
        height: 503px;
      }
      .box {
        width: 100%;
        height: 100%;
        cursor: pointer;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 50px;
        border: none;
        z-index: 10;
        background: transparent;
        svg {
          display: block;
          path {
            stroke: #ffc96f;
          }
        }
        &.prev {
          left: -100px;
        }
        &.next {
          right: -100px;
        }
      }
    }
    .more {
      height: 50px;
      display: flex;
      align-items: center;
      max-width: 1400px;
      margin: 70px auto 0;
      .line {
        width: calc(100% - 130px);
        height: 1px;
        background: #ffcf80;
      }
      button {
        width: 130px;
        height: 100%;
        border-radius: 50px;
        font-size: 20px;
        border: 1px solid #ffcf80;
        background: transparent;
        font-weight: 700;
      }
    }
  }
  .news {
    position: relative;
    max-width: 1440px;
    padding: 0 20px;
    margin: 170px auto;
    .label {
      justify-content: flex-end;
    }
    .newsContent {
      width: 100%;
      .box {
        display: inline-block;
        width: calc((100% - 60px) / 4);
        margin-right: 20px;
        background: #fff9f9;
        margin-bottom: 20px;
        &:nth-child(4n) {
          margin-right: 0;
        }
        .newsInfo {
          width: 100%;
          padding: 20px 0;
          cursor: pointer;
          span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 20px;
            &:nth-child(1) {
              font-size: 24px;
              font-weight: 600;
            }
            &:nth-child(2) {
              padding: 20px;
              font-size: 16px;
              color: #6a6a6a;
              font-weight: 600;
            }
            &:last-child {
              padding-top: 20px;
              border-top: 1px solid #ffcf80;
              font-size: 16px;
              color: #6a6a6a;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .introduce,
    .portfolio,
    .news {
      margin: 60px auto;
    }

    .product {
      margin: 60px auto;
      .label {
        padding: 0 20px;
      }
      .products {
        padding: 50px 20px;
      }
    }
    .introduce .introduceContent {
      height: 500px;
      .bg {
        width: 100%;
      }
      .box {
        justify-content: flex-start;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .label {
      gap: 10px;
      font-size: 24px;
      margin-bottom: 40px;
    }
    .info {
      font-size: 20px;
      span {
        font-size: 14px;
      }
    }
    .dot {
      width: 10px;
      height: 10px;
    }
    .introduce .introduceContent {
      height: auto;
      .bg {
        display: none;
      }
      .box {
        width: 100%;
        gap: 20px;
        .viewarea {
          width: 50%;
          height: 200px;
        }
        .content {
          max-width: calc(50% - 20px);
          font-size: 16px;
          button {
            font-size: 16px;
          }
        }
      }
    }
    .product .products .productSwiper {
      height: 400px;
      .productSlide .box {
        padding: 10px;
        img {
          height: calc(100% - 50px);
        }
        .productInfo {
          span {
            font-size: 16px;
          }
          p {
            font-size: 14px;
          }
        }
      }
    }
    .news .newsContent {
      .box {
        width: calc(50% - 10px);
        margin-bottom: 20px;
        &:nth-child(odd) {
          margin-right: 20px;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 150px;
    .introduce .introduceContent .box {
      flex-direction: column;
      justify-content: baseline;
      .viewarea,
      .content {
        width: 100%;
        max-width: 100%;
        button {
          margin-top: 20px;
          font-size: 14px;
        }
      }
    }

    .news .newsContent {
      .box {
        width: 100%;
        .newsInfo span:nth-child(1) {
          font-size: 18px;
        }
        &:nth-child(odd) {
          margin-right: 0;
        }
        &:nth-child(even) {
          margin-right: 0;
        }
      }
    }
  }
`;

export const CuteBannerContainer = styled.div`
  position: relative;
  height: 750px;
  .bg {
    height: 650px;
    background: #ffc96f;
  }
  .banners {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 1400px;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 1400px) {
    height: 400px;
    .bg {
      height: 300px;
      background: #ffc96f;
    }
    .banners {
      width: calc(100% - 40px);
    }
  }
  @media screen and (max-width: 1050px) {
    height: 300px;
    .bg {
      height: 250px;
      background: #ffc96f;
    }
    .banners {
      width: calc(100% - 40px);
    }
  }
  @media screen and (max-width: 767px) {
    height: 200px;
    .bg {
      height: 150px;
      background: #ffc96f;
    }
    .banners {
      width: calc(100% - 40px);
    }
  }
`;

export const CuteProductsContainer = styled.div`
  main#contProductsList {
    margin-top: 0;
    font-family: "Pretendard";
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
    }
    .subTop_bnr_01 {
      display: none;
    }
    #secBrdcrmb {
      display: none;
    }
    .font_color_gray_light {
      border-bottom: 2px solid #ffc96f;
      padding-bottom: 10px;
      font-size: 18px;
      .fa.fa-bars {
        margin-right: 5px;
      }
      strong {
        color: #000;
      }
    }
    .categorys ul {
      box-shadow: none;
      background: #faf8f7;
      color: #9f9f9f;
    }
    #secList {
      .col_3 {
        border: 1px solid #b6b6b6;
        cursor: pointer;
        a {
          font-weight: normal;
          p {
            text-align: center;
            padding: 0;
            margin: 10px;
          }
          &:hover {
            color: #9f9f9f;
          }
        }
      }
    }
    ul.pagination {
      li {
        border: none;
      }
      li.active,
      li:hover {
        background: #ffc96f;
        a {
          color: #333;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    article.boundary {
      padding: 50px 20px;
      margin: 0;
      width: 100%;
    }
  }
  @media screen and (max-width: 1050px) {
    article.boundary {
      margin: 0;
      width: 100%;
    }
    main#contProductsList #secListTop .count,
    main#contProductsList .categorys ul li {
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsList {
      margin-bottom: 100px;
      .col_3 p.font_18 {
        font-size: 14px;
      }
    }
  }
`;

export const CuteCmpnyContainer = styled.div`
  main#contCompany {
    margin: 0;
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
    }
    .subTop_bnr_01,
    h2,
    #secBrdcrmb {
      display: none;
    }
    h3,
    .company_content,
    .address_btn_wrapper,
    .google_map_sub_wrapper {
      width: 100%;
      font-size: 20px;
    }
    h3 {
      text-align: center;
      font-size: 36px;
    }
    #secMap {
      h3 {
        &::before {
          display: none;
        }
        span {
          padding: 0;
          border: none;
        }
      }
      .addr {
        display: flex;
        align-items: center;
        justify-content: end;
        gap: 10px;
        button {
          margin: 0;
          background: #ffc96f;
          color: #000;
          font-weight: 700;
          padding: 5px;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    main#contCompany {
      h3 {
        font-size: 32px;
      }
      .company_content,
      .address_btn_wrapper,
      .google_map_sub_wrapper {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    main#contCompany {
      margin-bottom: 100px;
      h3 {
        font-size: 26px;
      }
    }
  }
`;

export const CuteNoticeContainer = styled.div`
  main#contNoticeList {
    margin: 0;
    font-family: "Pretendard";
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
    }
    table {
      border: none;
      border-top: 1px solid #ffc96f;
      thead {
        background: transparent;
        border: none;
        tr th {
          border-bottom: 1px solid #ffc96f;
        }
      }
      tbody {
        tr {
          background: transparent;
          border: none;
          td {
            border-bottom: 1px solid #ddd;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .subTop_bnr_01,
    #secBrdcrmb {
      display: none;
    }
    ul.pagination {
      li {
        border: none;
      }
      li.active,
      li:hover {
        background: #ffc96f;
        a {
          color: #333;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    main#contNoticeList {
      table {
        border-bottom: 1px solid #ddd;
        th {
          font-size: 16px;
        }
        td {
          font-size: 14px;
          border-bottom: none;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    main#contNoticeList {
      margin-bottom: 100px;
      table {
        border-bottom: 1px solid #ffc96f;
        tbody tr {
          width: 100%;
          padding: 10px 0;
          border-bottom: 1px solid #ddd;
          &:last-child {
            border-bottom: none;
          }
          td {
            border-bottom: none;
          }
        }
      }
    }
  }
`;

export const CuteNoticeViewContainer = styled.div`
  min-height: 100vh;
  main#noticeView,
  main#contProductsList {
    margin-top: 0;
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
    }
    .shop_port_title,
    #secBrdcrmb,
    .shop_port_detail_content {
      width: 100%;
    }
    .subTop_bnr_01 {
      display: none;
    }
  }
  @media screen and (max-width: 1050px) {
  }
  @media screen and (max-width: 767px) {
    main#noticeView,
    main#contProductsList {
      margin-bottom: 100px;
    }
  }
`;

export const CuteProductsView = styled.div`
  main#contProductsView {
    margin: 0 auto;
    font-family: "Pretendard";
    .boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
    }
    .subTop_bnr_01 {
      display: none;
    }
    #secInfo .btns button {
      border-radius: 10px;
      &.btn_wht {
        border: 1px solid #ffcf80;
      }
      &.btn_blk {
        color: #000;
        background: #ffcf80;
        border: transparent;
      }
    }
    .prd_img_wrapper > img {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    main#contProductsView {
      margin-bottom: 100px;
    }
  }
`;

export const CuteSearchContaienr = styled.div`
  font-family: "Pretendard";
  img {
    user-select: none;
  }
  main#contSearch {
    margin-top: 0;
    article.boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
      .shop_port_detail_content,
      .shop_port_title,
      #secBrdcrmb {
        width: 100%;
      }
    }
    h2 {
      display: none;
    }
    #secSchbox .sch_bar {
      border: 1px solid rgb(255, 201, 111) !important;
      input[type="text"]:focus {
        border: none !important;
      }
    }
    .subTop_bnr_01 {
      display: none;
    }
    .font_color_red {
      color: #000;
    }
    span.title {
      font-weight: 700;
    }
  }
`;

export const CuteCartContaienr = styled.div`
  img {
    user-select: none;
  }
  main#contCart {
    margin-top: 0;
    article.boundary {
      max-width: 1440px;
      width: 100%;
      padding: 70px 20px;
      .shop_port_detail_content,
      .shop_port_title {
        width: 100%;
      }
    }
    #secBrdcrmb,
    .subTop_bnr_01 {
      display: none;
    }
    .products {
      font-size: 16px;
      border-top: 1px solid #b6b6b6;
      border-left: 1px solid #b6b6b6;
      border-right: 1px solid #b6b6b6;
      label {
        display: flex;
        gap: 5px;
        align-items: center;
        input[type="checkbox"] {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          padding: 0;
          width: 0;
          height: 0;
        }
        span {
          font-weight: 700;
        }
        .checkmark {
          position: relative;
          top: 0;
          left: 0;
          display: inline-block;
          height: 25px;
          width: 25px;
          border: 1px solid #b6b6b6;
          border-radius: 7px;
          background-color: transparent;
          transition: all 0.3s ease;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg) scale(0);
            opacity: 0;
            width: 10px;
            height: 15px;
            border: solid #fff;
            border-width: 0px 3px 3px 0px;
            transition: 0.3s;
          }
        }
        input[type="checkbox"]:checked ~ .checkmark {
          background: #ffc96f;
          border: 1px solid transparent;
          &:after {
            opacity: 1;
            transform: translate(-50%, -65%) rotate(45deg) scale(1);
          }
        }
        &:hover .checkmark {
          background-color: #efefef;
        }
      }
      li {
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #b6b6b6;
        &.header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          button {
            background: transparent;
          }
        }
        &:not(.header) {
          font-size: 14px;
          position: relative;
          display: flex;
          align-items: center;
          gap: 10px;
          .image {
            width: 100px;
            height: 100px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .productInfo {
            width: calc(100% - 180px);
            > span {
              display: block;
              margin-bottom: 5px;
            }
            .productName {
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-weight: 500;
            }
            .brand {
              font-size: 12px;
              color: #aaa;
            }
            .price {
              font-size: 20px;
              font-weight: 900;
              margin-bottom: 10px;
            }
            .amount {
              display: flex;
              align-items: center;
              justify-content: space-between;
              border: 1px solid #aaa;
              border-radius: 5px;
              width: 120px;
              span {
                display: inline-block;
                width: 60px;
                text-align: center;
                font-weight: 700;
              }
              button {
                width: 30px;
                height: 25px;
                border: none;
                background: transparent;
              }
            }
          }
          > button {
            background: transparent;
            color: #b6b6b6;
          }
        }
      }
    }
    #secTable .tb_footer {
      flex-wrap: wrap;
      gap: 30px 60px;
      .total {
        width: 100%;
        text-transform: uppercase;
        font-weight: 900;
      }
      .btn_order {
        background: #ffc96f;
        color: #000;
        text-shadow: none;
      }
      .price_detail {
        border-right: none;
      }
      .price_sum dd,
      .font_color_red {
        color: #e7b661;
      }
      .font900 {
        font-weight: 700;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    main#contCart {
      .subTop_bnr_01 {
        height: 310px;
        font-size: 40px;
      }
      .products {
        font-size: 14px;
        li {
          padding: 15px;
          position: relative;
        }
        label .checkmark {
          width: 20px;
          height: 20px;
          border-radius: 5px;
          ::after {
            width: 8px;
            height: 12px;
          }
        }
        li:not(.header) {
          padding-top: 45px;
          align-items: flex-start;
          label {
            position: absolute;
            top: 15px;
            left: 15px;
          }
          > button {
            position: absolute;
            top: 15px;
            right: 15px;
          }
          .image {
            width: 70px;
            height: 70px;
            border-radius: 5px;
          }
          .productInfo {
            width: calc(100% - 80px);
            .price {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    article.boundary {
      margin-bottom: 100px;
    }
    main#contCart {
      #secTable {
        border-top: 1px solid #ababab;

        dd {
          color: #555;
        }
        .tb_footer .price_detail {
          border-bottom: 1px solid #ababab;
        }
      }
      .products li {
        label .checkmark {
          width: 20px;
          height: 20px;
          ::after {
            width: 8px;
            height: 12px;
            border-width: 0 2px 2px 0;
          }
        }
        &:not(.header) {
          flex-wrap: wrap;
          .image {
            width: 70px;
            height: 70px;
          }
          .productInfo {
            width: 100%;
            .productName {
              color: #555;
            }
            .brand {
              display: none;
            }
            .price {
              font-size: 14px;
              font-weight: 700;
              margin-bottom: 5px;
            }
          }
        }
        &:last-child {
          border-bottom: none;
        }
      }
      #secTable .tb_footer {
        font-size: 14px;
        .btn_order {
          font-size: 16px;
        }
      }
    }
  }
`;
