import moment from "moment";
import React from "react";

import { IoCaretDown, IoCaretUp } from "react-icons/io5";

import SecretPost from "./secretPost";
import { SecretPostContainer } from "../styled/notice";
import { useNavigate } from "react-router";

const Inquiry = ({ item, selected, selectItem, handleDeleteInquiry, setIsDetail, setImages }) => {
  const navigate = useNavigate();
  const isSelected = (item) => {
    return selected && item && item.id === selected.id;
  };

  const navEditPage = () => {
    navigate("/help/write", { state: { inquiry: selected } });
  };

  return (
    <li key={`${item.id}_${item.title}`}>
      <div className="title" onClick={() => selectItem(item.id)}>
        <div className="tit">{item.title}</div>
        <div className={`status ${!item.status ? "pending" : "completed"}`}>
          <span>{!item.status ? "대기중" : "답변완료"}</span>
        </div>
        <div className="date">{moment(item.updateAt || item.createdAt).format("YYYY-MM-DD")}</div>
        <div className="toggle">{!isSelected(item) ? <IoCaretDown /> : <IoCaretUp />}</div>
      </div>
      <SecretPostContainer className={`content ${isSelected(item) ? "open" : ""}`}>
        {isSelected(item) && (
          <SecretPost
            selected={selected}
            isSelected={isSelected}
            handleDeleteInquiry={handleDeleteInquiry}
            setIsDetail={setIsDetail}
            setImages={setImages}
            navEditPage={navEditPage}
          />
        )}
      </SecretPostContainer>
    </li>
  );
};

export default Inquiry;
