import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import Pagination from "react-js-pagination";
import axios from "axios";

import { IoMdSearch } from "react-icons/io";

import { MembershipContainer } from "../../../styled/groupDetails";

import Modal from "../../../../../../common/modal";
import Search from "../../../common/search";
import TableMember from "./membership/tableMember";
import Thead from "./membership/thead";
import { NoData } from "../../../styled/common";
import ListMember from "./membership/listMember";

const Membership = ({ group, openModal, closeModal }) => {
  const { id } = useParams();

  const user = useSelector((state) => state?.user?.data[0]);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [pendings, setPendings] = useState([]);
  const [selected, setSelected] = useState(1);
  const [searched, setSearched] = useState("");
  const [checked, setChecked] = useState({});
  const [total, setTotal] = useState(0);
  const [isAllCheck, setIsAllCheck] = useState(false);

  useEffect(() => {
    getApplyMber(getApplyMberCond(1));
  }, []);

  // 가입 대기 멤버 조회 조건
  const getApplyMberCond = (num = 1) => {
    const groupNo = Number(id);
    const pageSize = 10;
    const pageNumber = num - 1;

    const body = {
      groupNo,
      delYn: "N",
      approvalYn: "N",
      offset: pageNumber * pageSize,
      pageNumber: pageNumber,
      pageSize,
      paged: true,
    };

    if (isNaN(searched)) body.mberNm = searched;
    return body;
  };

  // 가입 대기 멤버 조회
  const getApplyMber = async (cond) => {
    const url = "/api/groupMberList";
    const body = { ...cond };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setPendings(res.data.content);
        const initiChkState = {};
        res.data.content.forEach((item) => {
          initiChkState[item.mberNo] = false;
        });
        setChecked(initiChkState);
        setTotal(res.data.totalElements);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 승인, 거절 클릭 시
  const onSubmit = async (items, state) => {
    if (!items || items.length < 1) return;

    const requests = items.map((item) => {
      const url = "/api/groupMber";
      const body = {
        seq: item.seq,
        groupNo: id,
        mberNo: item.mberNo,
        auth: 4,
        createMberId: user.userName,
        updateMberId: user.userName,
        delYn: state ? "N" : "Y",
        approvalYn: state ? "Y" : "N",
        isApproval: state ? "Y" : null,
      };

      if (item.nickNm && group.nickUseYn === "Y") body.nickNm = item.nickNm;
      if (item.gbnValue && group.gbn) body.gbnValue = item.gbnValue;

      return axios.put(url, body);
    });

    try {
      await Promise.all(requests);
      toast.success(`${state ? "수락" : "거절"}되었습니다.`);
      await getApplyMber(getApplyMberCond(1));
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  // 전체선택
  const handleSelectAll = (e) => {
    const newChkState = {};
    pendings.forEach((member) => {
      newChkState[member.mberNo] = e.target.checked;
    });
    setChecked(newChkState);
    setIsAllCheck(e.target.checked);
  };

  // 개별선택
  const handleSelect = (mberNo) => {
    setChecked((chkMbers) => {
      const updatedChkMbers = {
        ...chkMbers,
        [mberNo]: !chkMbers[mberNo],
      };

      const allChecked = pendings.every((member) => updatedChkMbers[member.mberNo]);
      setIsAllCheck(allChecked);

      return updatedChkMbers;
    });
  };

  // 검색, 페이지
  const onPageChange = (num) => {
    setSelected(num);
    getApplyMber(getApplyMberCond(num));
    setChecked({});
  };

  // 검색
  const onSearch = () => {
    if (!searched) {
      toast.error("검색어를 입력해주세요.");
      return;
    }
    if (isNaN(searched) && searched.length < 2) {
      toast.error("멤버 이름을 좀 더 구체적으로 입력해주세요.");
      return;
    }
    const telreg = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    if (!isNaN(searched) && !telreg.test(searched)) {
      toast.error("유효하지 않은 휴대폰 번호입니다.");
      return;
    }

    setSelected(1);
    getApplyMber(getApplyMberCond(1));
  };

  // 다중 데이터 보내기
  const onClickSubmitAll = (state) => {
    const selectMbers = [];
    pendings.forEach((item) => {
      if (checked[item.mberNo]) {
        selectMbers.push(item);
      }
    });
    if (selectMbers.length < 1) {
      toast.error("선택된 멤버가 존재하지 않습니다.");
      return;
    }

    clickButton(selectMbers, state);
  };

  const clickButton = (mber, flag) => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: "멤버 삭제",
      message: [`${mber.length}명의 모임 가입을`, `${flag ? "수락" : "거절"}하시겠습니까?`],
      handleConfirm: () => onSubmit(mber, flag),
      handleCancel: () => closeModal(),
    });
  };

  return (
    <MembershipContainer>
      <Search
        placeholder="닉네임 혹은 전화번호를 입력해주세요."
        searched={searched}
        setSearched={setSearched}
        handleSearch={onSearch}
      />
      <div className="actions">
        <button onClick={() => onClickSubmitAll(true)}>전체수락</button>
        <button onClick={() => onClickSubmitAll(false)}>전체거절</button>
      </div>
      {isMobile ? (
        pendings && pendings.length > 0 ? (
          <ul>
            {pendings.map((mber) => (
              <ListMember
                mber={mber}
                handleSelect={handleSelect}
                chkMbers={checked}
                clickButton={clickButton}
              />
            ))}
          </ul>
        ) : (
          <NoData>멤버가 존재하지 않습니다.</NoData>
        )
      ) : (
        <table>
          <Thead isAllChecked={isAllCheck} handleSelectAll={handleSelectAll} />
          <tbody>
            {pendings && pendings.length > 0 ? (
              pendings.map((mber) => (
                <TableMember
                  mber={mber}
                  handleSelect={handleSelect}
                  chkMbers={checked}
                  clickButton={clickButton}
                />
              ))
            ) : (
              <tr>
                <td colSpan={3}>가입 승인 대기 요청이 존재하지 않습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      <Pagination
        activePage={selected}
        itemsCountPerPage={10}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={onPageChange}
      />
    </MembershipContainer>
  );
};

export default Membership;
