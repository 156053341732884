import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { PageInfoContainer } from "../styled/common";
import { FcHome } from "react-icons/fc";

const PageInfo = ({ title, setActive = null }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user?.data[0]);

  return (
    <>
      <PageInfoContainer>
        <div className="info">
          <div className="title">
            {!isNaN(id) && (
              <i onClick={() => navigate("/group")}>
                <FcHome />
              </i>
            )}
            <span onClick={() => (isNaN(id) ? navigate("/group") : setActive(0))}>{title}</span>
          </div>
          {user && (
            <div className="actions">
              <button onClick={() => navigate("/group/my")}>내 모임</button>
              <button onClick={() => navigate("/group/feed")}>전체 소식</button>
              <button onClick={() => navigate("/group/create")}>나의 모임 만들기</button>
            </div>
          )}
        </div>
      </PageInfoContainer>
    </>
  );
};

export default PageInfo;
