import React from "react";
import { List } from "../styled/common";
import { useFieldDefinitions, DataRenderer } from "./useFieldDefinitions";

const ListRow = ({ item, fieldDefinitions, handleModalOpen, type }) => (
  <li>
    <ul>
      {fieldDefinitions.map(({ key, label, render }) => (
        <li key={key}>
          <span className="tit">{label}</span>
          {render(item)}
        </li>
      ))}
    </ul>
    <button
      onClick={() =>
        handleModalOpen(
          ["resStatus", "coupon"].some((t) => type.includes(t)) ? item : "COMPLETED",
          item,
        )
      }
      className="statusButton">
      {type.includes("resStatus") ? "상세보기" : "관리"}
    </button>
  </li>
);

const KioskList = ({ data = [], type = [], handleModalOpen = null }) => {
  const fieldDefinitions = useFieldDefinitions(type);

  return (
    <List
      className={type.includes("resStatus") ? "resStatus" : "pickUp"}
      style={{ height: data.length > 0 ? "auto" : "100%" }}>
      <DataRenderer
        data={data}
        fieldDefinitions={fieldDefinitions}
        handleModalOpen={handleModalOpen}
        type={type}
        renderRow={ListRow}
      />
    </List>
  );
};

export default KioskList;
