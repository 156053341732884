import React from "react";
import { BannerContainer } from "../styled/group";

const Banner = () => {
  const banners = Array.from({ length: 3 });
  return (
    <BannerContainer>
      {banners.map((_, idx) => (
        <div>
          <img src={`/img/group/banner_${idx + 1}.png`} />
        </div>
      ))}
    </BannerContainer>
  );
};

export default Banner;
