import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import FeedSwiper from "./component/common/feedSwiper";
import Loading from "../../common/loading";

import { FeedContainer } from "./component/styled/group";

import axios from "axios";
import Modal from "../../common/modal";

import { hideLoading, showLoading } from "../../store/loading";
import uuid from "react-uuid";
import { GroupContainer } from "./component/styled/common";
import PageInfo from "./component/common/pageInfo";
import { CreateFeedContainer } from "./component/styled/feedWrite";
import Info from "./component/feedWrite/info";
import Form from "./component/feedWrite/form";
import { useModal } from "../../hooks/useModal";
import Actions from "./component/feedWrite/actions";

const FeedWrite = () => {
  const user = useSelector((state) => state?.user?.data[0]);
  const isLoading = useSelector((state) => state?.loading?.isLoading);

  const { modal, openModal, closeModal } = useModal();

  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const origin = location?.state?.feed;

  const [grp, setGrp] = useState(null);
  const [my, setMy] = useState([]);
  const [feed, setFeed] = useState({ nickNm: "", gbn: 0, groupName: "", imgUrl: [], content: "" });
  const [files, setFiles] = useState([]);

  useEffect(() => {
    if (!user) {
      toast.error("로그인 후 이용 가능합니다.");
      navigate("/group");
    } else {
      getMyGroup();
    }

    if (origin) {
      const updated =
        (origin.imgUrl &&
          JSON.parse(origin.imgUrl).map((item) => ({
            path: item,
            id: uuid(),
          }))) ||
        [];

      setFeed({
        ...origin,
        imgUrl: updated,
        content: origin.realContent ? origin.realContent : origin.content || "",
      });
      // setFiles(Array.from({ length: updated.length }).fill(""));

      setGrp(origin.gbn || id);
    }
  }, []);

  const getMyGroup = async () => {
    const url = "/api/groupCondList";
    const req = getDataCond();
    const res = await axios.post(url, req);
    if (res.status === 200) {
      setMy(res.data.content);
    }
  };

  const getDataCond = () => {
    return {
      mberNo: user.mberNo,
      delYn: "N",
      offset: 0,
      pageNumber: 1,
      pageSize: 1,
      paged: false,
    };
  };

  const checkValidation = async () => {
    if (feed.feedNo) {
      const delCheck = await feedDelYnCheck(feed.feedNo);
      if (delCheck === "Y") {
        toast.error("게시물이 존재하지 않습니다.");
        navigate("/group/feed");
        return false;
      }
    }

    if (grp === "0") {
      toast.error("모임을 선택해주세요.");
      return false;
    }

    if (!feed.content || !feed.content.trim() || !feed.content.replaceAll("\n", "")) {
      toast.error("내용이 존재하지 않습니다.");
      return false;
    }
    return true;
  };

  useEffect(() => {});

  const replaceImages = (localData, serverData) => {
    console.log(feed);

    const updatedData = localData.map((local) => {
      const matched = serverData.find((server) => local.fileNm && server.origin === local.fileNm);

      if (matched) {
        return {
          path: matched.fileName,
        };
      }

      return local;
    });

    return updatedData.map((item) => item.path);
  };

  const handleSubmit = async () => {
    try {
      if (!checkValidation()) return;

      let uploadedImages;
      if (feed.imgUrl.length > 0) {
        const serverData = (await uploadImages()) || [];
        uploadedImages = replaceImages(feed.imgUrl, serverData);
      }

      dispatch(showLoading());

      const url = "/api/feedList";
      const body = {
        content: feed.content.trim(),
        imgUrl: uploadedImages ? JSON.stringify(uploadedImages) : JSON.stringify([]),
        mberNo: user.mberNo,
        mberNm: user.name,
        delYn: "N",
        gbn: feed.gbn,
        createMberId: user.userName,
        updateMberId: user.userName,
      };

      if (feed.feedNo) {
        body.feedNo = feed.feedNo;
      }

      console.log(body);
      const res = await axios.put(url, body);
      console.log(res);

      if (res.status === 200) {
        toast.success(`게시글이 ${feed.createDt ? "수정" : "작성"}되었습니다.`);
        const groupNo = location?.state?.groupNo;

        if (groupNo) {
          navigate(`/group/${groupNo}`, { state: { isFeed: true } });
        } else {
          navigate("/group/feed");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  const changeImg = async (e) => {
    const files = e.target.files;

    if (files) {
      const form = {
        images: [],
        files: [],
      };

      for (const item of files) {
        const id = uuid();
        const path = await readFileAsDataURL(item);

        form.images.push({ fileNm: item.name, type: item.type, path, id });
        form.files.push({ id, file: item });
      }

      setFeed((data) => ({ ...data, imgUrl: [...data.imgUrl, ...form.images] }));
      setFiles((data) => [...data, ...form.files]);
    }
  };

  // 이미지 업로드
  const uploadImages = async () => {
    try {
      const form = new FormData();

      files.forEach((item) => {
        form.append("files", item.file, item.file.name);
      });

      form.append("ids", "N");
      form.append("mberNo", user.mberNo);

      const url = "/api/uploadFiles";
      const res = await axios.post(url, form);

      const response = res?.data?.responseData;

      if (response && response.length > 0) {
        const originalFileNames = files.map((item) =>
          item.file.name.split(".").slice(0, -1).join("."),
        );

        return response.map((item, idx) => {
          const uploadedFileName = item.fileName.split("/").pop().split(".").slice(0, -1).join(".");
          const isSameName = originalFileNames.includes(uploadedFileName);

          return {
            ...item,
            origin: isSameName ? files[idx].file.name : undefined,
          };
        });
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  useEffect(() => {
    console.log(files);
    console.log(feed.imgUrl);
  }, [feed.imgUrl]);

  // 수정 완료 시 삭제여부
  const feedDelYnCheck = async (feedNo) => {
    const url = "/api/feedList";
    const request = {
      feedNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
    };

    const res = await axios.post(url, request);
    return res?.data?.content[0].delYn;
  };

  // 클립보드
  const handlePaste = async (e) => {
    const items = e.clipboardData.items;
    const imageFilePromises = [];

    for (let item of items) {
      if (item.kind === "file" && item.type.startsWith("image/")) {
        const file = item.getAsFile();
        const mockEvent = { target: { files: [file] } };
        imageFilePromises.push(changeImg(mockEvent));
      }
    }
  };

  const deleteImages = (item) => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: "이미지 삭제",
      message: [`이미지를 삭제하시겠습니까?`],
      handleConfirm: () => {
        setFeed((data) => ({
          ...data,
          imgUrl: [...data.imgUrl].filter((ele) => ele.id !== item.id),
        }));
        setFiles(files.filter((img) => img.id !== item.id));
        closeModal();
      },
      handleCancel: () => closeModal(),
    });
  };

  return (
    <GroupContainer>
      {modal}
      <div className="inner">
        <PageInfo title="MOIM - 협력과 소통의 공간" />
        <CreateFeedContainer>
          <Info
            feed={feed}
            setFeed={setFeed}
            location={location}
            myGrp={my}
            changeImg={changeImg}
          />
          <Form
            feed={feed}
            setFeed={setFeed}
            grp={grp}
            myGrp={my}
            handleModal={deleteImages}
            handlePaste={handlePaste}
          />
          <Actions openModal={openModal} closeModal={closeModal} handleSubmit={handleSubmit} />
        </CreateFeedContainer>
      </div>
      {isLoading && <Loading />}
    </GroupContainer>
  );
};

export default FeedWrite;
