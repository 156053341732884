import React, { useState } from "react";
// import { FreeMode, Navigation, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const ProductSwiper = ({ media }) => {
  if (!media) return null;

  return (
    <div className="swiper_wrap">
      <div id="mainSwiper" className="swiper">
        <Swiper
          id="prdSwiper"
          slidesPerView={1}
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          loop={true}
          speed={500}>
          {media.map((item) => (
            <SwiperSlide>
              <img src={item ? imgHandler(item) : "/img/function_11_bg.png"} onError={onErrorImg} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="swiper-button-prev swiper-button-white" />
      <div className="swiper-button-next swiper-button-white" />
    </div>
  );
};

export default ProductSwiper;
