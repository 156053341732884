import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { ModalBackground, Select } from "../../styled/common";
import ModalHeader from "./modalHeader";

// 여러 개 중 하나 선택
const SelectModal = ({
  title = "",
  message = [],
  active = null,
  handleConfirm = null,
  handleCancel = null,
  handleChangeOption = null,
  options = [],
  placeholder = "",
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isCustomInput, setIsCustomInput] = useState(false);
  const [customInputValue, setCustomInputValue] = useState("");

  const handleOptionSelect = (item) => {
    if (item === "직접 입력") {
      setIsCustomInput(true);
    } else {
      setIsCustomInput(false);
      setCustomInputValue("");
    }
    handleChangeOption(item);
    setIsOptionsOpen(false);
  };

  const showSelection = (active) => {
    if (!active) {
      return placeholder;
    }
    if (typeof active === "string") {
      return active;
    }
    if (typeof active === "object") {
      return active.label;
    }
    return "";
  };

  return (
    <ModalBackground>
      <Select>
        <ModalHeader title={title} handleCancel={handleCancel} />
        <div className={`modalContent ${isOptionsOpen ? " open" : ""}`}>
          <div className="message">
            <strong>{message}</strong>
          </div>
          <div className="selected" onClick={() => setIsOptionsOpen(!isOptionsOpen)}>
            <span>{showSelection(active)}</span>
            <button>
              <FiChevronDown />
            </button>
            <div className="choiceOption">
              <ul>
                {options.map((item) => (
                  <li
                    key={item}
                    onClick={() => handleOptionSelect(item)}
                    className={item === active ? "active" : ""}>
                    <span>{typeof item === "string" ? item : item.label}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {isCustomInput && !isOptionsOpen && (
            <input
              type="text"
              className="customInput"
              value={customInputValue}
              onChange={(e) => setCustomInputValue(e.target.value)}
              placeholder="직접 입력"
            />
          )}
          <div className="modalActions">
            {handleCancel && <button onClick={handleCancel}>닫기</button>}
            <button onClick={() => handleConfirm(isCustomInput ? customInputValue : active)}>
              확인
            </button>
          </div>
        </div>
      </Select>
    </ModalBackground>
  );
};

export default SelectModal;
