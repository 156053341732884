import React, { useEffect, useRef, useState } from "react";
import { ProjectCalendarContainer, ProjectDetailSection } from "../../common/styled/project";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  hexToRgb,
  isEventAllTime,
  transformEvents,
} from "../../../../scheduler/component/function/common";
import moment from "moment";
import {
  CalendarActions,
  CalendarContainer,
} from "../../../../scheduler/component/styled/scheduler";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { useSelector } from "react-redux";

const ProjectCalendar = ({ schedule, participant }) => {
  const [data, setData] = useState([]);
  const calendarRef = useRef();
  const [onDate, setOnDate] = useState(moment().format("YYYY-MM-DD"));
  const [onPrevDate, setOnPrevDate] = useState(moment().format("YYYY-MM-DD"));
  const company = useSelector((state) => state?.company?.data);

  useEffect(() => {
    getEvents();
  }, []);

  const getEvents = async () => {
    const { events } = await transformEvents(schedule, company);
    setData(events);
  };

  // 배경 변경
  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(onDate);
    }

    const prevDateElements = document.querySelectorAll(
      `[data-date="${moment(onPrevDate).format("YYYY-MM-DD")}"]`,
    );
    const currentDateElements = document.querySelectorAll(
      `[data-date="${moment(onDate).format("YYYY-MM-DD")}"]`,
    );

    prevDateElements.forEach((item) => item.classList.remove("selected"));
    currentDateElements.forEach((item) => item.classList.add("selected"));

    setOnPrevDate(onDate);
  }, [onDate]);

  // 날짜 클릭 시
  const handleDateClick = async (date) => {
    const target = moment(date).format("YYYY-MM-DD");
    setOnDate(target);
  };

  // 일정 순서
  const customEventOrder = (a, b) => {
    const aIsHoliday = a.id.includes("holiday");
    const bIsHoliday = b.id.includes("holiday");

    if (aIsHoliday && !bIsHoliday) {
      return -1;
    } else if (!aIsHoliday && bIsHoliday) {
      return 1;
    }

    const startDiff = a.start - b.start;
    if (startDiff !== 0) {
      return startDiff;
    }

    const durationDiff = b.end - b.start - (a.end - a.start);
    if (durationDiff !== 0) {
      return durationDiff;
    }

    return a.title.localeCompare(b.title);
  };

  return (
    <ProjectDetailSection>
      <ProjectCalendarContainer>
        <CalendarContainer>
          <CalendarActions>
            <button
              onClick={() => handleDateClick(moment().format("YYYY-MM-DD"))}
              className="today">
              오늘
            </button>
            <div className="month">
              <button
                onClick={() =>
                  handleDateClick(moment(onDate).subtract(1, "months").format("YYYY-MM-01"))
                }>
                <BsChevronLeft />
              </button>
              <button
                onClick={() =>
                  handleDateClick(moment(onDate).add(1, "months").format("YYYY-MM-01"))
                }>
                <BsChevronRight />
              </button>
            </div>
          </CalendarActions>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            locale={"ko"}
            contentHeight={"auto"}
            events={data}
            dayMaxEvents={3}
            moreLinkContent={(info) => `+${info.num}`}
            eventOrder={customEventOrder}
            dayCellContent={(info) => {
              const current = info.date;
              const dateFormatted = moment(current).format("D");

              return (
                <>
                  <div></div>
                  <div className="date">
                    <span>{dateFormatted}</span>
                  </div>
                </>
              );
            }}
          />
        </CalendarContainer>
      </ProjectCalendarContainer>
    </ProjectDetailSection>
  );
};

export default ProjectCalendar;
