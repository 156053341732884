import HomepageEndForm from "./component/homepageEnd/homepageEndForm";
import { Container } from "../styled/mypage";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

export default function HomepageEnd() {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <Container className={setting?.mode}>
      <HomepageEndForm />
    </Container>
  );
}
