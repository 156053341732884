import React from "react";
import Checkbox from "./checkbox";
import { FormContainer } from "../styled/create";

const Form = ({
  formData,
  isGbnChk,
  setIsGbnChk,
  isDisplay,
  setIsDisplay,
  isApprovalUse,
  setIsApprovalUse,
  checkDuplGroupName,
  changeInput,
}) => {
  return (
    <FormContainer>
      <li className="grpName">
        <div className="tit">모임 이름</div>
        <div className="content">
          <input
            type="text"
            name="groupName"
            value={formData.groupName}
            onChange={(e) => changeInput(e)}
          />
          <button onClick={checkDuplGroupName}>중복 확인</button>
        </div>
      </li>
      <li>
        <Checkbox
          checked={isGbnChk}
          setChecked={setIsGbnChk}
          label={"명칭 사용 여부"}
          className={"isGbn"}
        />
      </li>
      {isGbnChk && (
        <li>
          <div className="tit">
            명칭 <em>ex) 기, 급</em>
          </div>
          <div className="content">
            <input type="text" name="gbn" value={formData.gbn} onChange={(e) => changeInput(e)} />
          </div>
        </li>
      )}
      <li>
        <div className="tit">모임 설정</div>
        <div className="radio-inputs content">
          <label className="radio">
            <input
              type="radio"
              name="radio"
              checked={isDisplay}
              onChange={(e) => setIsDisplay(true)}
            />
            <span className="name">전체공개</span>
          </label>
          <label className="radio">
            <input
              type="radio"
              name="radio"
              checked={!isDisplay}
              onChange={(e) => setIsDisplay(false)}
            />
            <span className="name">비공개</span>
          </label>
        </div>
      </li>
      <li>
        <Checkbox
          checked={!isDisplay || isApprovalUse}
          setChecked={setIsApprovalUse}
          label={"가입승인제"}
          className={"isApprovalUse"}
        />
      </li>
    </FormContainer>
  );
};

export default Form;
