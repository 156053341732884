import React from "react";
import moment from "moment";

const CustomEvent = ({ eventInfo }) => {
  const { event } = eventInfo;

  const color = {
    borderColor: event.borderColor,
  };
  const imgStyle = {
    width: 15,
    height: 15,
    marginRight: 3,
  };

  const start = moment(event.start).format("YYYY-MM-DD");
  const end = moment(event.end).format("YYYY-MM-DD");
  const isMultiDayEvent = start !== end;

  if (isMultiDayEvent || event.display === "block") {
    return (
      <div className="fc-event-main-frame">
        <div className="fc-event-time"></div>
        <div className="fc-event-title-container">
          <div className="fc-event-title fc-sticky">
            {event.extendedProps.icon && (
              <img
                src={`/img/scheduler/${event.extendedProps.icon}`}
                style={imgStyle}
                alt={event.title}
              />
            )}
            {eventInfo.event.title}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="fc-daygrid-event-dot" style={color}></div>
        <div className="fc-event-time"></div>
        <div className="fc-event-title">
          {event.extendedProps.icon && (
            <img
              src={`/img/scheduler/scheduler_icon_${event.extendedProps.icon}`}
              style={imgStyle}
              alt={event.title}
            />
          )}
          {eventInfo.event.title}
        </div>
      </>
    );
  }
};

export default CustomEvent;
