import { Link } from "react-router-dom";
import styled from "styled-components";

export const CustomLink = styled(Link)`
  cursor: pointer;
  &::after {
    content: ">";
    display: inline-block;
    padding: 0 10px;
    font-family: cursive;
  }
  &:hover {
    color: #000;
  }
`;
