import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import axios from "axios";

import PageInfo from "./component/common/pageInfo";
import ImageUploader from "./component/create/imageUploader";
import Form from "./component/create/form";

import { CreateContainer } from "./component/styled/create";
import { GroupContainer } from "./component/styled/common";

import { useModal } from "../../hooks/useModal";

const CreateGroup = () => {
  const user = useSelector((state) => state?.user?.data[0]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    groupName: "",
    gbn: "",
    imgUrl: "",
    displayYn: "Y",
  });
  const [image, setImage] = useState();
  const [isGbnChk, setIsGbnChk] = useState(false);

  const [isDisplay, setIsDisplay] = useState(true);
  const [isApprovalUse, setIsApprovalUse] = useState(false);
  const [duplGroupName, setDuplGroupName] = useState(false);

  const { modal, openModal, closeModal } = useModal();

  const changeInput = (e) => {
    const { name, value } = e.target;

    if (name === "groupName" && duplGroupName) {
      setDuplGroupName(false);
    }

    setFormData((data) => ({ ...data, [name]: value }));
  };

  // 이미지 추가
  const addImage = (input) => {
    const file = input instanceof File ? input : input?.target?.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      const imageUrl = reader.result;
      setFormData((data) => ({
        ...data,
        imgUrl: imageUrl,
      }));

      setImage(file);

      // input reset (file input의 경우만 초기화)
      if (input?.target?.value) {
        input.target.value = "";
      }
    };
  };

  // 이미지값 반환
  const uploadLogoImage = async (item) => {
    const form = new FormData();
    form.append("file", item);
    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFile";
    const res = await axios.post(url, form);

    return res?.data?.responseData?.fileName;
  };

  // 빈값, 중복 체크
  const checkSubmitCond = () => {
    if (!formData.groupName) {
      toast.error("모임명을 설정해주세요.");
      return;
    }

    if (!duplGroupName) {
      toast.error("모임명 중복 확인을 해주세요.");
      return;
    }

    if (isGbnChk && !formData.gbn) {
      toast.error("명칭을 설정해주세요.");
      return;
    }

    openModal({
      isOpen: true,
      type: "confirmation",
      title: `모임 생성`,
      message: ["모임을 생성하시겠습니까?"],
      handleConfirm: () => onSubmit(),
      handleCancel: () => closeModal(),
    });
  };

  // 저장
  const onSubmit = async () => {
    const url = "/api/group";
    let body = {
      ...formData,
      createMberId: user.userName,
      updateMberId: user.userName,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "N",
      nickUseYn: "Y",
      displayYn: isDisplay ? "Y" : "N",
      approvalUseYn: !isDisplay || isApprovalUse ? "Y" : null,
    };

    if (image) {
      const uploaded = await uploadLogoImage(image);
      if (!uploaded) {
        closeModal();
        return;
      }
      body.imgUrl = uploaded;
    }

    const emptyValues = Object.keys(body).reduce((acc, key) => {
      acc[key] = !body[key] ? null : body[key];
      return acc;
    }, {});

    body = { ...emptyValues };

    const responses = await axios.put(url, body);
    const res = responses.data;
    console.log(res);
    if (res.success) {
      toast.success("모임이 생성되었습니다.");
      signGrp(res.rtnModel.groupNo);
    }
  };

  // 모임 가입
  const signGrp = async (groupNo) => {
    const url = "/api/groupMber";
    const body = {
      groupNo,
      mberNo: user.mberNo,
      auth: 0,
      createMberId: user.userName,
      updateMberId: user.userName,
      delYn: "N",
    };
    const res = await axios.put(url, body);

    if (res.status === 200) {
      navigate(`/group/${groupNo}`);
    } else {
      toast.error("잠시후 다시 시도해주세요");
    }
  };

  // 모임명 중복체크
  const checkDuplGroupName = async () => {
    if (!formData.groupName) {
      toast.error("모임명이 존재하지 않습니다.");
      return;
    }

    const url = "/api/duplGroupName";
    const body = {
      groupName: formData.groupName,
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      if (res.data.success) {
        toast.success("사용 가능한 모임명입니다.");
        setDuplGroupName(true);
      } else {
        toast.error("이미 사용 중인 모임명입니다.");
        setDuplGroupName(false);
      }
    }
  };

  return (
    <GroupContainer>
      {modal}
      <div className="inner">
        <PageInfo title="MOIM - 협력과 소통의 공간" />
        <CreateContainer>
          <ImageUploader
            addImage={addImage}
            formData={formData}
            setFormData={setFormData}
            setImage={setImage}
          />
          <Form
            formData={formData}
            isGbnChk={isGbnChk}
            setIsGbnChk={setIsGbnChk}
            isDisplay={isDisplay}
            setIsDisplay={setIsDisplay}
            isApprovalUse={isApprovalUse}
            setIsApprovalUse={setIsApprovalUse}
            checkDuplGroupName={checkDuplGroupName}
            changeInput={changeInput}
          />
          <div className="submit">
            <button onClick={checkSubmitCond}>확인</button>
          </div>
        </CreateContainer>
      </div>
    </GroupContainer>
  );
};

export default CreateGroup;
