import { Link, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import HomePageSettingMenu from "../common/homepageSettingMenu";
import RegisterTitle from "./registerTitle";
import RegisterMenu from "./registerMenu";
import CustomModal from "../../../../../common/customModal";
import Modal from "../../../../../common/modal";

import "../../component/styled/port.css";

import { setRegisterIndex } from "../../../../../store/compoIndex";
import { ImgWrap } from "../../../../shopping/portfolio/portfolioView/styled";
import { RegisterPortViewFormContainer } from "../styled/register";
import { toast } from "react-toastify";

export default function RegisterPortViewForm() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const portNo = params.portNo;

  const setting = useSelector((state) => state?.user?.setting);
  const index = useSelector((state) => state.compoIndex.register);
  const user = useSelector((state) => state?.user?.data[0]);

  const session = JSON.parse(sessionStorage.getItem("persist:root"));
  const userData = JSON.parse(session?.user ? session?.user : null);

  const [cmpnyNo, setCmpnyNo] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    const company = JSON.parse(sessionStorage.getItem("homepageCompany"));

    if (!company) {
      console.log("선택된 회사가 존재하지 않습니다.");
      navigate("/mypage");
    } else {
      setCmpnyNo(company.cmpnyNo);
    }

    const isAuth = userData?.data?.length;
    if (!isAuth || isAuth === 0) {
      return;
    }
    getPortfolio();
  }, []);

  const getPortfolio = async () => {
    const apiUrl = "/api/portMgmtList";
    const body = {
      portNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 12,
      paged: true,
    };
    const response = await axios.post(apiUrl, body);
    const res = response.data.content;
    const htmlString = res[0]?.contents;
    const contents = document.getElementById("contents");
    contents.innerHTML = htmlString;
    setPortfolio(res[0]);
  };

  const deletePortfolio = async () => {
    let url = "/api/portfolio";
    let request = {
      portNo,
      cmpnyNo: cmpnyNo,
      notitleYn: "Y",
      useYn: "N",
      createMberId: user.userName,
      updateMberId: user.userName,
    };
    const res = await axios.put(url, request);
    if (res.data.success === true) {
      navigate("/mypage/register", { state: { deletePort: true } });
      dispatch(setRegisterIndex(2));
    }
  };

  const moveToPage = () => {
    navigate("/mypage/register");
    dispatch(setRegisterIndex(2));
  };

  const handlEChanggo = async () => {
    if (!user) {
      navigate("/login");
    } else {
      const url = "/api/domainSettingLoad";
      const req = {
        delYn: "N",
        cmpnyNo: cmpnyNo,
      };

      const res = await axios.post(url, req);
      if (res.data.domain) {
        window.open(`https://1472.ai/shop/${res.data.domain}/portfolio?page=0`);
        user && localStorage.setItem("user", JSON.stringify(user));
      } else {
        navigate("/mypage/homepage");
      }
    }
  };

  return (
    <RegisterPortViewFormContainer className={setting?.mode}>
      <div className="content">
        <HomePageSettingMenu />
        <RegisterTitle />
        <RegisterMenu index={index} />
        <section className="port_view_wrapper">
          <p>{portfolio.title}</p>
        </section>
        <section className="port_view_wrapper">
          <div style={{ width: "100%" }}>
            <div
              id="secBrdcrmb"
              className="font_14 pdb_20 text_right shop_port_menu"
              style={{ width: "100%" }}>
              <div style={{ display: "flex", gap: "12px" }}>
                <span className="author_name font_14 font_color_gray font300">
                  <span className="sound_only">작성자</span>
                  <i className="fa fa-pencil-square-o" aria-hidden="true" />{" "}
                  {portfolio?.createMberId}
                </span>
                <span className="author_date font_14 font_color_gray font300">
                  <span className="sound_only">작성일</span>
                  <i className="fa fa-clock-o" aria-hidden="true" />{" "}
                  {portfolio?.updateDt || portfolio?.createDt}
                </span>
                <button
                  onClick={handlEChanggo}
                  style={{ border: "none", background: "transparent" }}>
                  홈페이지에서 보기
                </button>
              </div>
              <div style={{ display: "flex", gap: 15 }}>
                <Link onClick={() => navigate(`/mypage/registerPhotoUpload/${portNo}`)}>
                  <span>수정</span>
                </Link>
                <Link onClick={() => setModalOpen(true)}>
                  <span>삭제</span>
                </Link>
                <Link to={`/mypage/register`}>
                  <span style={{ cursor: "pointer" }}>전체 포트폴리오 보기</span>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="port_detail_wrapper">
          <strong className="port_content" id="contents"></strong>
        </section>

        {portfolio?.dtlList?.length !== 0 && (
          <section>
            {portfolio?.dtlList?.map((item) => (
              <ImgWrap key={item.portDtlNo}>
                <img
                  src={imgHandler(item.imgDtlUrl)}
                  style={{ width: "100%" }}
                  onError={onErrorImg}
                />
              </ImgWrap>
            ))}
          </section>
        )}
        <div className="actions">
          <button onClick={() => setModalOpen(true)} className="btn_normal_cancel port_btn">
            <span>삭제</span>
          </button>
          <button
            onClick={() => navigate(`/mypage/registerPhotoUpload/${portNo}`)}
            className="btn_normal_save port_btn">
            <span>수정</span>
          </button>
          <button onClick={moveToPage}>
            <span>목록</span>
          </button>
        </div>
        <Modal
          modalState={modalOpen}
          handleModalState={() => setModalOpen(false)}
          html={
            <CustomModal
              title={`포트폴리오`}
              content={`포트폴리오를 삭제 하시겠습니까?`}
              handleConfirm={deletePortfolio}
              handleCancel={() => setModalOpen(false)}
            />
          }
          w="300px"
          h="auto"
        />
      </div>
    </RegisterPortViewFormContainer>
  );
}
