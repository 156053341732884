import moment from "moment";
import { weatherStates } from "./dateInfo";

// 작성자 동일여부 확인
export const isSameUser = (user, writer) => {
  if (!user || !writer || !user.mberNo || !writer.mberNo) return false;
  return user.mberNo.toString() === writer.mberNo.toString();
};

// 하루종일 여부 확인
export const isEventAllTime = (item) => {
  const { startDt, endDt } = item;
  if (!startDt || !endDt) return false;

  const [dateStart, timeStart] = startDt.split(" ");
  const [dateEnd, timeEnd] = endDt.split(" ");

  return timeStart === "00:00:00" && timeEnd === "23:59:59";
};

// 색상 추출
export const hexToRgb = (hex) => {
  if (!hex) return "";

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((char) => char + char)
      .join("");
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
};

// 외부업체 체크
export const isOutsideCmpny = (item, company) => {
  if (!item || !company) return false;
  if (item.gbn !== "C") {
    return false;
  }
  if (company.some((cmpny) => cmpny.cmpnyNo === item.orgNo)) {
    return false;
  }
  return true;
};

export const isWeather = (weather) => {
  const state = weatherStates[weather];
  if (!state) return <></>;

  const iconChar = String.fromCodePoint(parseInt(state.icon, 16));

  return <span style={{ color: state.color }}>{iconChar}</span>;
};

// 특정 날짜를 기준으로 날짜 범위 설정
export const getDateRange = (baseDate = moment()) => {
  const month = moment(baseDate).startOf("month");
  return {
    startDt: month.subtract(10, "days").format("YYYY-MM-DD"),
    endDt: month.add(41, "days").format("YYYY-MM-DD"),
  };
};

// 선택한 날짜가 범위 안에 있는지 체크
export const isDateInRange = (onDate, currentDate) => {
  const date = moment(onDate, "YYYY-MM-DD");
  const start = moment(currentDate.startDt, "YYYY-MM-DD");
  const end = moment(currentDate.endDt, "YYYY-MM-DD");

  return date.isBetween(start, end, undefined, "[]");
};

export const filterOrg = (org) => {
  if (!org) return [];
  return org.filter((item) => item.check).map((item) => item.orgNo);
};

const getColorByGbn = (gbn) => {
  switch (gbn) {
    case "P":
      return "139859";
    case "C":
      return "0074ca";
    case "G":
      return "f06f02";
    case "O":
      return "ED5583";
    default:
      return "cccccc";
  }
};

// 이벤트 그룹화 (유형별)
export const tallyEvents = (data = []) => {
  const result = [];

  data.forEach((item) => {
    const { startDt, endDt, gbn } = item;

    if (item.dday !== "Y" && gbn !== "H") {
      const startDate = startDt.split(" ")[0];
      const endDate = endDt.split(" ")[0];

      const duration = moment(endDate).diff(moment(startDate), "days");

      for (let i = 0; i <= duration; i++) {
        const current = moment(startDate).add(i, "days").format("YYYY-MM-DD");
        const existingEvent = result.some((event) => event.start === current && event.gbn === gbn);

        if (existingEvent) {
          const eventIndex = result.findIndex(
            (event) => event.start === current && event.gbn === gbn,
          );
          result[eventIndex].title++;
        } else {
          const color = `rgba(${hexToRgb(getColorByGbn(gbn))}, 0.8)`;
          result.push({
            start: current,
            gbn: gbn,
            title: 1,
            backgroundColor: color,
            borderColor: color,
          });
        }
      }
    }
  });

  return result;
};

// FullCalendar 형식 맞추기
export const transformEvents = async (origin = [], company) => {
  const events = [];
  const holidays = [];

  origin.forEach((item) => {
    const end = adjustEndDate(item.endDt);
    const opacity = getOpacity(item.completeYn);
    const color = getEventColor(item, company, opacity);
    const isDday = item.dday === "Y";

    const isHoliday = ["S", "H"].includes(item.gbn);
    const start = moment(item.startDt);

    const event = {
      ...item,
      backgroundColor: isDday ? "#333333" : color,
      borderColor: isDday ? "#333333" : color,
      id: item.scheduleNo,
      start: isHoliday ? start.format("YYYY-MM-DD 23:59:58") : item.startDt,
      allDay: false,
      end: end.format("YYYY-MM-DD HH:mm:ss"),
      display:
        isEventAllTime(item) ||
        isHoliday ||
        isDday ||
        start.format("YYYY-MM-DD") !== moment(item.endDt).format("YYYY-MM-DD")
          ? "block"
          : "list-item",
    };

    isHoliday ? holidays.push(event) : events.push(event);
  });

  return { events, holidays };
};

// 종료 시간 조정
const adjustEndDate = (endDt) => {
  let end = moment(endDt);
  if (end.format("HH:mm:ss") === "00:00:00") {
    end = end.add(1, "seconds");
  }
  return end;
};

const getOpacity = (completeYn) => (completeYn === "Y" ? 0.5 : 0.8);

const getEventColor = (item, company, opacity) => {
  const gbnColor = {
    P: item.color,
    C: isOutsideCmpny(item, company) ? "ed5583" : "0074CA",
    G: "F46F02",
    H: "D42625",
    S: "999999",
  };

  const selectedColor = gbnColor[item.gbn] || "000000";
  return `rgba(${hexToRgb(selectedColor)}, ${opacity})`;
};

// 클래스명과 일치하는 데이터 찾기
export const getIdFromClass = (data, item) => {
  const match = item.className.match(/schedule-(\d+)/);

  let id;
  if (match) {
    id = parseInt(match[1]);

    const event = data.find((item) => item.scheduleNo === id);
    return event;
  }

  return null;
};

// 날짜가 범위의 조회 날짜와 다른지
export const isMonthDifferent = (onDate, range) => {
  const { startDt, endDt } = range;

  const onDateMonth = moment(onDate).format("YYYY-MM");

  const diffInDays = moment(endDt).diff(startDt, "days");
  const middle = moment(startDt)
    .add(Math.floor(diffInDays / 2), "days")
    .format("YYYY-MM");

  return middle !== onDateMonth;
};

// 컬러값에 따른 클래스명 지정
export const assignColorClass = (hex) => {
  if (!hex || typeof hex !== "string") {
    return "EM";
  }

  const color = hex.toUpperCase();

  switch (color) {
    case "0074CA":
      return "C";
    case "F46F02":
      return "G";
    case "ED5583":
      return "O";
    case "FFC455":
      return "GS";
    case "8F2D56":
      return "BB";
    case "A7A3D9":
      return "LL";
    case "38C6BD":
      return "TT";
    case "B350C3":
      return "VL";
    default:
      return "EM";
  }
};

export const createDailyEvent = (data = []) => {
  const newEvents = [];
  const eventsCount = {};

  data.forEach((item) => {
    if (item.dday === "Y") {
      return;
    }

    const { startDt, endDt, color, id, title } = item;
    const [startDate] = startDt.split(" ");
    const [endDate] = endDt.split(" ");

    const duration = moment(endDate).diff(moment(startDate), "days");
    for (let i = 0; i <= duration; i++) {
      const current = moment(startDate).add(i, "days").format("YYYY-MM-DD");
      if (!eventsCount[current] || eventsCount[current] < 3) {
        const className = `schedule${assignColorClass(color)}`;
        newEvents.push({
          title,
          id,
          start: current,
          className,
        });
        eventsCount[current] = (eventsCount[current] || 0) + 1;
      }
    }
  });

  return newEvents;
};
