import styled from "styled-components";

export const ExchangeModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  .slideWrapper {
    flex-flow: row nowrap;
    display: flex;
    flex: 1;
    width: 100%;
    transition: transform 0.5s ease;
    height: 100%;
    overflow: hidden;
    margin: auto;
  }
  .slideContent {
    flex: 0 0 50%;
    height: 100%;
    position: relative;
    transform: translateX(0);
    flex-basis: 100%;
    flex-shrink: 0;
    transition: 0.3s;
    width: 100%;
  }
  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .slideWrapper {
      height: calc(100% - 150px);
      flex: none;
      margin: 0;
    }
  }
`;

export const UserInfoContainer = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > ul {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    > li {
      width: 50%;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    gap: 10px;
    .tabmenu {
      display: flex;
      align-items: center;
      gap: 5px;
      li {
        height: 30px;
        background: #ddd;
        font-size: 20px;
        padding: 2px 15px;
        border-radius: 15px;
        &.active {
          background: #2a3341;
          color: #fff;
        }
      }
    }
  }
  .guide {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    i {
      text-align: center;
      display: inline-block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background: #000;
      color: #fff;
      font-style: normal;
      font-size: 25px;
    }
    &:hover .rewards {
      opacity: 1;
      width: 330px;
      padding: 10px;
    }
  }
  .rewards {
    height: auto;
    width: 0;
    position: absolute;
    flex-direction: column;
    z-index: 50;
    top: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.9);
    border-radius: 20px;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    opacity: 0;
    transition: 0.3s;
    padding: 0;
    li {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      img {
        width: 40px;
        aspect-ratio: 1;
        object-fit: contain;
      }
      span {
        font-size: 16px;
        display: block;
        &:last-child {
          font-weight: normal;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 20px;
    flex-wrap: wrap;
    gap: 10px;
    > ul {
      width: 100%;
    }
    .actions {
      justify-content: space-between;
      width: 100%;
      .tabmenu li {
        font-size: 14px;
        font-weight: normal;
        height: 26px;
        padding: 4px 15px;
      }
    }
    .guide {
      margin-left: auto;
      &:hover .rewards {
        width: calc(100vw - 100px);
      }
      .rewards li span {
        font-size: 14px;
      }
      .rewards li img {
        width: 25px;
      }
    }
  }
`;

export const StampListContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .stamps {
    overflow: auto;
    height: calc(100% - 100px);
    font-size: 20px;
    border-top: 1px solid #ccc;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
    &.history {
      height: 100%;
      overflow-y: hidden;
      text-align: center;
      .header {
        font-weight: normal;
        text-align: center;
      }
      .idx {
        width: 10%;
        border-right: 1px solid #ccc;
      }
      .con {
        width: 90%;
      }
    }
    li {
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      position: relative;
      &.header {
        background: #ddd;
      }
      .con,
      .idx {
        border-right: 1px solid #ccc;
      }
      .idx {
        width: 10%;
        display: inline-block;
        font-weight: normal;
        text-align: center;
        padding: 5px 0;
      }
      .con,
      .tit {
        padding: 5px 0;
        width: 45%;
        display: inline-block;
        font-weight: normal;
        text-align: center;
      }
      &.not {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          font-weight: normal;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .stamps {
      font-size: 14px;
      height: calc(100% - 70px);
      &.history {
        .idx,
        .con {
          border-bottom: none;
        }
      }
      li {
        &.header {
          display: none;
        }
        .tit,
        .con {
          text-align: left;
          padding: 5px 10px;
        }
        .tit {
          display: block;
          width: 100%;
          background: #eee;
        }
        .idx {
          border-bottom: 1px solid #ccc;
        }
        .con {
          width: 90%;
          border-right: none;
          border-bottom: 1px solid #ccc;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    div button.submit {
      padding: 5px 0;
    }
  }
`;

export const KioskCouponContainer = styled.div`
  padding: 1.0833vw;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  .content {
    height: calc(100% - 11vw - 11vh);
    width: 100%;
    padding: 3px 20px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
  }
  .pagination {
    margin-top: 0;
    gap: 10px;
    display: flex;
    list-style: none;
    padding: 0;

    li {
      border-radius: 5px;
      transition: 0.3s;
      border: 3px solid #efefef;
      a {
        font-size: 22px;
        display: block;
        padding: 10px 15px;
        text-decoration: none;
      }
      &.disabled {
        background: #efefef;
        pointer-events: none;
      }
      &.active,
      &:hover:not(.disabled) {
        background: #2a3341;
        /* background: transparent; */
        border-color: #2a3341;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .content {
      padding: 0 10px;
      height: calc(100% - 20vh);
    }
  }
`;
