import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import axios from "axios";
import CompanyAddForm from "../companyAddPage/companyAddForm";

const Management = ({ company }) => {
  const [formData, setFormData] = useState({
    ...company,
  });
  const [stampShop, setStampShop] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isSangsaeng, setIsSangsaeng] = useState(false);
  const [isStamp, setIsStamp] = useState(false);
  const [isPaymentYn, setIsPaymentYn] = useState(false);

  useEffect(() => {
    if (company && company.bizrno) {
      setIsDuplicate(true);
    }
    console.log(company);
    if (company && company.gbn === "ss") {
      setIsSangsaeng(true);
      getStampShop(company.cmpnyNo);
    }
  }, []);

  const getStampShop = async (cmpnyNo) => {
    const url = "/api/stamp-shop/list";
    const body = { cmpnyNo };

    try {
      const res = await axios.post(url, body);
      const data = res.data?.[0];
      setStampShop(data);
      setIsStamp(data?.id ? true : false);
      setIsPaymentYn(data.paymentYn === "Y" ? true : false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <CompanyAddForm
        company={company}
        formData={formData}
        setFormData={setFormData}
        isDuplicate={isDuplicate}
        setIsDuplicate={setIsDuplicate}
        stampShop={stampShop}
        setStampShop={setStampShop}
        isSangsaeng={isSangsaeng}
        setIsSangsaeng={setIsSangsaeng}
        isStamp={isStamp}
        setIsStamp={setIsStamp}
        isPaymentYn={isPaymentYn}
        setIsPaymentYn={setIsPaymentYn}
      />
    </>
  );
};

export default Management;
