import { useOutletContext } from "react-router";

import BoardView from "../../../../common/board/boardView";
import ModernBanner from "../../shopping/component/themes/modern/banner";
import CuteBanner from "../../shopping/component/themes/cute/banner";
import SmBanner from "../../shopping/component/themes/sm/banner";
import ChicBanner from "../../shopping/component/themes/chic/banner";

import { ModernNoticeViewContainer } from "../../shopping/component/styled/modernTheme";
import { CuteNoticeViewContainer } from "../../shopping/component/styled/cuteTheme";
import { ChicNoticeViewContainer } from "../../shopping/component/styled/chicTheme";
import SimpleBanner from "../../shopping/component/themes/simple/banner";
import { DefaultContaienr } from "../../shopping/component/styled/defaultTheme";
import ShoppingSectionSwiper from "../../shoppingSectionSwiper";

const ShoppingNoticeView = () => {
  const { theme } = useOutletContext();

  const NoticeView = () => (
    <main id="noticeView" className="sub_page_mobile_mt">
      {theme === 1 && (
        <div className="subTop_bnr subTop_bnr_01">
          <span>NEWS</span>
        </div>
      )}
      <article className="boundary pdt_70 pdb_70">
        <BoardView isShop={true} />
      </article>
    </main>
  );

  const containers = {
    0: DefaultContaienr,
    1: ChicNoticeViewContainer,
    2: ModernNoticeViewContainer,
    3: CuteNoticeViewContainer,
    4: CuteNoticeViewContainer,
    sm: CuteNoticeViewContainer,
  };

  const banners = {
    0: ShoppingSectionSwiper,
    1: ChicBanner,
    2: ModernBanner,
    3: CuteBanner,
    4: SimpleBanner,
    sm: SmBanner,
  };

  const renderComponent = () => {
    const Container = containers[theme] || DefaultContaienr;
    const Banner = banners[theme];

    return (
      <Container>
        {Banner && (
          <Banner
            info={
              theme === "sm"
                ? { page: "공지사항", message: "에스엠정보통신의\n새로운 소식을 알려드립니다." }
                : { imgage: "notice.png", text: "notice" }
            }
          />
        )}
        <NoticeView />
      </Container>
    );
  };

  return <>{renderComponent()}</>;
};

export default ShoppingNoticeView;
