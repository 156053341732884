import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { useMediaQuery } from "react-responsive";

import ProductSwiper from "./productsView/productSwiper";
import ProductInfo from "./productsView/productInfo";
import ProductDetail from "./productsView/productDetail";
import { ProductViewContainer } from "../../styled/shopping";
// import { useModal } from "../../../../hooks/useModal";

export default function ProductsViewForm({ product }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { url, theme, cmpnyNo } = useOutletContext();
  // const { modal, openModal, closeModal } = useModal();

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [amount, setAmount] = useState(1);

  const searchQuery = location?.state?.keyword || "";
  const selectedQuery = location?.state?.selected;
  const pageQuery = location?.state?.page || 0;
  const moveToUrl = searchQuery
    ? `/shop/${url}/search?keyword=${searchQuery}`
    : `/shop/${url}/productsList?code=${selectedQuery?.code || ""}&brand=${
        selectedQuery?.brand || ""
      }&page=${pageQuery}`;

  const updateCount = (increment = true) => {
    setAmount((prevCount) => (increment ? prevCount + 1 : Math.max(prevCount - 1, 1)));
  };

  const createMarkup = () => {
    const detailImgString = product?.dtlHtmlCn;
    return { __html: detailImgString };
  };

  const cartMove = () => {
    navigate(`/shop/${url}/cart`);
  };

  // 바로구매
  const handleCheckout = () => {
    // alert("현재는 구매가 불가능합니다.");
    // return;

    const newProduct = { ...product, amount };
    navigate(`/shop/${url}/order`, { state: { products: [newProduct] } });
  };

  // 장바구니 담기
  const handleCart = () => {
    // alert("현재는 구매가 불가능합니다.");
    // return;

    const local = JSON.parse(localStorage.getItem("localProducts")) || [];
    const existingProduct = local.find((item) => item.id === product.id);

    if (existingProduct) {
      existingProduct.amount += amount;
    } else {
      local.push({ ...product, amount });
    }

    localStorage.setItem("localProducts", JSON.stringify(local));

    // openModal({
    //   type: "confirmation",
    //   title: "장바구니 이동",
    //   message: [`상품이 장바구니에 담겼습니다.`, `장바구니로 이동하시겠습니까?`],
    //   handleConfirm: () => cartMove(),
    //   handleCancel: () => closeModal(),
    // });
  };

  const isTheme = () => {
    const themes = ["0"];
    return !themes.includes(theme.toString());
  };

  return (
    <ProductViewContainer className="sub_page_mobile_mt">
      <main id="contProductsView">
        {theme === 1 && (
          <div className="subTop_bnr subTop_bnr_01">
            <span>PRODUCT</span>
          </div>
        )}
        <article className="boundary pdt_70 pdb_70">
          <section
            id="secBrdcrmb"
            className="font_18 pdb_70 text_right"
            style={{ fontWeight: "400" }}>
            <Link to={`/shop/${url}/productsList`}>
              <span style={{ cursor: "pointer" }}>전체 제품 보기</span>
            </Link>
          </section>
          <section id="secInfo" className="pdb_70">
            <ProductSwiper media={product?.media} />
            <ProductInfo
              product={product}
              amount={amount}
              handleCheckout={handleCheckout}
              handleCart={handleCart}
              updateCount={updateCount}
            />
          </section>
          <ProductDetail createMarkup={createMarkup} />
          <div className="prd_btn_wrapper">
            <div
              className="content_control"
              style={
                product?.dtlHtmlCn
                  ? { width: isMobile || isTheme() ? "100%" : "75%" }
                  : { width: "100%" }
              }>
              <button
                type="button"
                className="btn_board_list white"
                style={{ width: "53.33px", borderRadius: "3px", background: "#4e515d" }}
                onClick={() => navigate(moveToUrl)}>
                목록
              </button>
            </div>
          </div>
        </article>
      </main>
      {/* {modal} */}
    </ProductViewContainer>
  );
}
