import React from "react";
import { SmHistoryContainer } from "../../../shopping/component/styled/smTheme";

const CompanyHistory = () => {
  const history = {
    2018: ["충만치킨 대전충청지사 50호점 달성", "마초부엌 시청점 오픈", "SM할인마트 오픈"],
    2017: ["배달프로그램 계약체결 (퀵낭)", "스마트컴 (주차)사업"],
    2016: ["마초부엌 프랜차이즈 계약 체결"],
    2015: ["㈜스마트로 업무제휴"],
    2014: ["SM ERP 자체프로그램 개발", "충만치킨 지사 계약 체결"],
    2013: [
      "㈜나이스정보통신 업무제휴",
      "SM F&C 프랜차이즈 사업진출",
      "또친 지사 계약 체결",
      "법인차량 보급",
    ],
    2012: ["㈜투게더포스 업무제휴"],
    2010: ["㈜솔비포스 업무제휴"],
    2009: ["한국정보통신 ㈜한국정보통신 업무제휴"],
    2008: ["축협충남북지사와 업무제휴"],
    2007: ["법인사업자 KSNET 대전 충.남북 총판 ㈜SM정보통신 설립", "법인영업 사업 진출"],
    2006: [
      "현재위치 '리치빌상가' 확장이전",
      "즉시결제 사업부 프로그램 개발",
      "포스 사업 진출",
      "㈜NNP테크놀러지와 업무제휴",
      "㈜포스뱅크와 업무제휴",
      "㈜솔비넷과 업무제휴",
    ],
    2005: ["유천정보통신 자대리점 유치", "매일정보통신 자대리점 유치"],
    2003: ["㈜엠씨페이와 업무제휴", "현성정보통신 자대리점 유치"],
    2002: ["즉시결제 사업 진출", "신신정보통신 자대리점 유치"],
    2001: ["개인사업자 SM정보통신 설립"],
    1995: ["㈜KSNET과 업무제휴"],
  };

  return (
    <SmHistoryContainer>
      {Object.entries(history)
        .sort(([a], [b]) => b - a)
        .map(([year, events]) => (
          <div>
            <span className="year">{year}</span>
            <ul>
              {events.map((event, index) => (
                <li key={index}>
                  <span>{event}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
    </SmHistoryContainer>
  );
};

export default CompanyHistory;
