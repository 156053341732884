import styled from "styled-components";

export const HomepageSubMenuContainer = styled.div`
  max-width: 1440px;
  padding: 0px 20px;
  margin: 0 auto;
  &.dark {
    ul {
      background: rgb(44, 45, 48);
      li.active {
        background: rgba(0, 0, 0, 0.5);
        a {
          color: #fff;
        }
      }
    }
  }
  ul {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    margin-bottom: 70px;
    height: 60px;
    line-height: 50px;
    padding: 5px;
    box-sizing: border-box;
    li {
      display: inline-block;
      cursor: pointer;
      width: 50%;
      text-align: center;
      border-radius: 20px;
      font-size: 28px;
      font-weight: bold;
      letter-spacing: -1px;
      transition: 0.3s;
      a {
        transition: 0.3s;
        color: rgba(80, 80, 80, 0.5);
      }
      &.active {
        background: rgba(250, 250, 250, 0.5);
      }
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      margin-bottom: 20px;
      height: 40px;
      line-height: 30px;
      border-radius: 5px;
      li {
        font-size: 14px;
        border-radius: 5px;
        font-weight: normal;
        &.active {
          a {
            color: #000;
          }
        }
      }
    }
  }
`;

export const HomepageFormContainer = styled.div`
  max-width: 1440px;
  padding: 0px 20px;
  margin: 0 auto;
  font-size: 24px;
  &.dark {
    > div {
      color: #fff;
      table tbody tr td a,
      table tbody tr td button {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }
  > div {
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: -1px;
    a,
    button {
      color: rgba(0, 0, 0, 0.5);
    }
    table {
      width: 100%;
      colgroup col {
        &:first-child {
          width: 40%;
        }
        &:last-child {
          width: 100%;
        }
      }
      thead tr th,
      tbody tr td {
        height: 70px;
        text-align: center;
      }
      thead tr th {
      }
      tbody tr td {
        a,
        button {
          padding: 10px 20px;
          border-radius: 25px;
          display: inline-block;
          background: #fff;
          margin-right: 30px;
          border: none;
          i {
            margin-right: 5px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    > div {
      table {
        colgroup col {
          &:first-child {
            width: 100%;
          }
          &:last-child {
            width: 100%;
          }
        }
        thead {
          display: none;
        }
        tbody tr {
          td {
            font-size: 20px;
            display: block;
            width: 100%;
            text-align: left;
            a,
            button {
              padding: 0 20px;
              height: 100%;
            }
            &:nth-child(odd) {
              height: auto;
              line-height: normal;
              margin-bottom: 10px;
            }
            &:nth-child(even) {
              margin-bottom: 20px;
              height: 40px;
              line-height: 40px;
              display: flex;
              align-items: center;
            }
          }
          &:last-child td:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    > div {
      padding: 20px;
      table {
        thead tr th {
          font-size: 16px;
          padding-bottom: 10px;
        }
        tbody tr {
          td {
            font-size: 12px;
            line-height: 30px;
            height: 30px;
            a,
            button {
              margin-right: 5px;
              padding: 0 10px;
            }
            &:nth-child(even) {
              margin-bottom: 15px;
              height: 30px;
              line-height: 30px;
            }
          }
          &:last-child td:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

export const MakeCompanyDomainContainer = styled.div`
  &.dark {
    .makeForm {
      input {
        background: rgba(255, 255, 255, 0.2);
        color: #eee;
      }
      button {
        background: #000;
        color: #eee;
      }
    }
  }
  .makeForm {
    max-width: 1440px;
    margin: 0 auto 100px;
    padding: 0 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 22px;
    gap: 20px;
    input {
      background: rgba(255, 255, 255, 0.5);
      width: calc(100% - 500px);
      height: 50px;
      border: none;
      padding: 0 20px;
      border-radius: 20px;
      &:focus {
        border: none !important;
        box-shadow: none;
      }
    }
    button {
      width: 200px;
      height: 50px;
      border-radius: 20px;
      border: none;
      background: rgba(255, 255, 255, 1);
    }
  }
  @media screen and (max-width: 767px) {
    .makeForm {
      font-size: 16px;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 10px;
      p {
        width: 100%;
        text-align: left;
      }
      input {
        width: calc(100% - 110px);
      }
      button {
        width: 100px;
      }
      input,
      button {
        height: 35px;
      }
    }
  }
`;
