import { useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
S;
import * as S from "../styled";
import { useRegex } from "../../../../hooks/useRegex";
import Loading from "../../common/ShopLoading";
import BoardTable from "../../../../common/board/boardTable";
import useQueryString from "../../../../hooks/useQueryString";
import { useMediaQuery } from "react-responsive";
import SearchSection from "./searchSection";
import NoKeyword from "./noKeyword";

export default function SearchForm() {
  const navigate = useNavigate();

  const queryString = useQueryString("keyword");
  const { cmpnyNo, url, theme } = useOutletContext();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [isLoading, setIsLoading] = useState();
  const [count, setCount] = useState({ product: 0, portfolio: 0, notice: 0 });
  const [searched, setSearched] = useState({ product: [], portfolio: [], notice: [] });
  const [keyword, setKeyword] = useState(queryString);
  const [displayKeyword, setDisplayKeyword] = useState(queryString);

  useEffect(() => {
    if (queryString) {
      getSearchData();
    }
  }, []);

  // 상품조회
  const getPrduct = async () => {
    if (!cmpnyNo) return;

    try {
      const url = "/erp/vendorProducts/get";
      const query = `
        query VendorProducts(
          $cmpnyNo: Int!
          $name: String
        ) {
          vendorProducts(
            filter: {
              cmpnyNo: $cmpnyNo
              name: $name
            }
          ) {
            totalCount
            products {
              id
              name
              brand
              categoryId
              media
              price
            }
          }
        }
      `;

      const variables = { cmpnyNo: parseInt(cmpnyNo, 10), name: keyword ? keyword : "" };

      const body = { query, variables };
      const res = await axios.post(url, body);

      if (res.status === 200) {
        return { count: res.data.totalCount, data: res.data.products };
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 포트폴리오 조회
  const getPortfolio = async () => {
    const url = "/api/portMgmtList";
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
    };

    if (keyword !== "") {
      body.keyword = keyword;
    }

    try {
      const res = await axios.post(url, body);
      return { count: res.data.totalElements, data: res.data.content };
    } catch (error) {
      console.log(error);
    }
  };

  // 공지조회
  const getNotice = async (page = 0) => {
    const url = "/api/noticeList";
    const body = {
      gbn: "N",
      delYn: "N",
      cmpnyNo: cmpnyNo,
      offset: 10 * page,
      pageNumber: page,
      pageSize: 10,
      paged: true,
    };

    if (keyword !== "") {
      body.noticeSj = keyword;
    }

    const res = await axios.post(url, body);
    count.notice = res.data.totalElements;
    // setCount({ ...count });
    // setNotices(res.data.content);
    // setSearched((data) => ({ ...data, notice: res.data.content }));
    return { count: res.data.totalElements, data: res.data.content };
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (checkValidation(keyword)) {
        getSearchData();
        navigate(`/shop/${url ? `${url}/` : ""}search?keyword=${keyword}`);
      }
    }
  };

  const clickSearch = () => {
    if (checkValidation(keyword)) {
      getSearchData();
      navigate(`/shop/${url ? `${url}/` : ""}search?keyword=${keyword}`);
    }
  };

  const checkValidation = (value) => {
    if (value.length < 2) {
      toast.error("검색어 두 글자 이상 입력해 주세요.");
      return false;
    } else {
      return true;
    }
  };

  const getSearchData = async () => {
    setIsLoading(true);
    try {
      const results = await Promise.all([getPrduct(), getPortfolio(), getNotice()]);
      const keys = ["product", "portfolio", "notice"];

      const counts = {};
      const data = {};
      keys.forEach((key, i) => {
        counts[key] = results[i]?.count || 0;
        data[key] = results[i]?.data || [];
      });

      setCount(counts);
      setSearched(data);
      setDisplayKeyword(keyword);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <main id="contSearch" className="sub_page sub_page_mobile_mt">
      <div className="subTop_bnr subTop_bnr_01">{theme === 1 && <span>SEARCH</span>}</div>
      <article className="boundary pdt_70 pdb_40">
        <h2 className="font_36 pdb_30 font600 text_center">통합검색</h2>
        <section id="secSchbox" className="">
          <div className="sch_bar text_center font_16">
            <input
              type="text"
              placeholder="검색어를 입력해 주세요."
              defaultValue={queryString}
              onChange={(e) => setKeyword(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <button onClick={clickSearch}>
              <img src="/img/icon_search.png" alt="검색" />
            </button>
          </div>
        </section>
      </article>
      {!displayKeyword ? (
        <NoKeyword />
      ) : (
        <article className="boundary" style={{ paddingBottom: isMobile ? 100 : 70 }}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <section className="pdt_40 pdb_30 font_24 text_center">
                <strong className="font_color_red">{`'${displayKeyword}' `}</strong>
                검색 결과 총<b> {count.product + count.portfolio + count.notice}</b>건
              </section>
              {count.product > 0 && (
                <SearchSection title="상품" items={searched.product} url={url} />
              )}
              {count.portfolio > 0 && (
                <SearchSection title="포트폴리오" items={searched.portfolio} url={url} />
              )}
              {count.notice > 0 && (
                <>
                  <S.TitleText>공지사항</S.TitleText>
                  <BoardTable notices={searched.notice} isShop={true} url={url} />
                </>
              )}
            </>
          )}
        </article>
      )}
    </main>
  );
}
