import axios from "axios";

import { Provider } from "react-redux";
import { store } from "./store/store";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

import ShoppingHeader from "./components/shopping/header/shoppingHeader";
import ShoppingFooter from "./components/shopping/footer/shoppingFooter";
import MobileFooter from "./components/shopping/footer/mobileFooter";

import styled from "styled-components";
import FooterScorllTopBtn from "./components/shopping/common/FooterScorllTopBtn";
import { useMediaQuery } from "react-responsive";

const CustomToast = styled(ToastContainer)`
  z-index: 10000;
  width: "400px";
  font-weight: 600;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

const AppS = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { url } = useParams();

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [cmpnyNo, setCmpnyNo] = useState("");
  const [homepageInfo, setHomepageInfo] = useState(null);
  const [isMatch, setIsMatch] = useState(false);
  const [theme, setTheme] = useState("0");
  const [menus, setMenus] = useState([]);

  const urls = ["/shop/login", "/shop/login/", "/shop/cart", "/shop/cart/"];
  const mainUrls = ["/shop/", "/shop"];

  useEffect(() => {
    if (url) {
      checkedDomain();
    } else {
      setTheme("0");
      setHomepageInfo(null);
    }
  }, [url]);

  useEffect(() => {
    if (urls.includes(pathname) || mainUrls.includes(pathname)) {
      setIsMatch(true);
    } else {
      setIsMatch(false);
    }
  }, [pathname]);

  function ScrollToTop() {
    useEffect(() => {
      if (!pathname.includes("/company")) {
        window.scrollTo(0, 0);
      }

      changeBodyClassName();
    }, [pathname]);

    return null;
  }

  // 회사번호 조회
  const checkedDomain = async () => {
    const requestUrl = "/api/domainSettingLoad";
    const body = {
      delYn: "N",
      domain: url,
    };

    const response = await axios.post(requestUrl, body);
    const cmpnyNo = response.data.cmpnyNo;
    if (!cmpnyNo) {
      toast.error("홈페이지가 존재하지 않습니다.");
      navigate("/shop");
    } else {
      setCmpnyNo(cmpnyNo);
      await getMySetting(cmpnyNo);
      await getMenuSetting(cmpnyNo);
    }
  };

  // 홈페이지 정보 조회
  const getMySetting = async (cmpnyNo) => {
    const url = "/api/infoSettingLoad";
    const request = {
      cmpnyNo,
      delYn: "N",
    };

    const response = await axios.post(url, request);
    if (!response.data) {
      response.data = {};
      response.data.cmpnyNo = cmpnyNo;
    }

    document.title = response.data.logoText ?? response.data.cmpnyNm;
    setHomepageInfo(response.data);

    if (cmpnyNo === 15922) {
      setTheme("sm");
    } else {
      const type = response.data?.type || 0;
      setTheme(type);
    }
  };

  // 메뉴 조회
  const getMenuSetting = async (cmpnyNo) => {
    const url = "/api/menuSetting";
    const body = { cmpnyNo: cmpnyNo };
    const res = await axios.post(url, body);

    if (res.status === 200 && res.data.length > 0) {
      const data = res.data
        .filter((item) => item.delYn === "N" && item.displayYn === "Y")
        .sort((a, b) => {
          if (a.seq > b.seq) {
            return 1;
          }
          if (a.seq < b.seq) {
            return -1;
          }
          return 0;
        });

      setMenus(data);
    } else {
      const data = createDefaultMenu();
      setMenus(data);
    }
  };

  const createDefaultMenu = (cmpnyNo) => {
    const names = ["회사소개", "제품소개", "포트폴리오", "공지사항"];
    const data = [];

    for (let i = 0; i < names.length; i++) {
      data.push({
        menuType: `${i}`,
        displayYn: "N",
        beforeMenuName: names[i],
        afterMenuName: "",
        seq: i + 1,
        cmpnyNo: cmpnyNo,
        delYn: "N",
      });
    }

    return data;
  };

  return (
    <Provider store={store}>
      <ScrollToTop />
      <CustomToast
        position="top-center"
        theme="light"
        pauseOnHover
        hideProgressBar={true}
        autoClose={1000}
        closeButton={false}
      />
      <div className="sub_wrap">
        <ShoppingHeader
          key={`Header-${url}-${cmpnyNo}-${theme}`}
          url={url}
          homepageInfo={homepageInfo}
          theme={theme}
          isMatch={isMatch}
          menus={menus}
        />
        <Outlet
          context={{ cmpnyNo, url, homepageInfo, theme, menus }}
          key={`Outlet-${url}-${cmpnyNo}-${theme}`}
        />
        <FooterScorllTopBtn theme={theme} />
        {isMobile ? (
          <MobileFooter key={`MobileFooter-${url}-${cmpnyNo}`} url={url} menus={menus} />
        ) : (
          <ShoppingFooter
            key={`ShoppingFooter-${url}-${cmpnyNo}-${theme}`}
            homepageInfo={homepageInfo}
            theme={theme}
            isMatch={isMatch}
          />
        )}
      </div>
    </Provider>
  );
};

export default AppS;
