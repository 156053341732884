import { useOutletContext } from "react-router";

import CuteBanner from "../shopping/component/themes/cute/banner";
import ModernBanner from "../shopping/component/themes/modern/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";
import SearchForm from "./component/searchForm";
import SmBanner from "../shopping/component/themes/sm/banner";
import ChicBanner from "../shopping/component/themes/chic/banner";

import { ModernSearchContaienr } from "../shopping/component/styled/modernTheme";
import { ChicSearchContaienr } from "../shopping/component/styled/chicTheme";
import { SimpleSearchContaienr } from "../shopping/component/styled/simpleTheme";
import { CuteSearchContaienr } from "../shopping/component/styled/cuteTheme";
import { SmSearchContaienr } from "../shopping/component/styled/smTheme";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";

const Search = () => {
  const { theme } = useOutletContext();

  const banners = {
    1: ChicBanner,
    2: ModernBanner,
    3: CuteBanner,
    4: SimpleBanner,
    sm: SmBanner,
  };

  const containers = {
    0: DefaultContaienr,
    1: ChicSearchContaienr,
    2: ModernSearchContaienr,
    3: CuteSearchContaienr,
    4: SimpleSearchContaienr,
    sm: SmSearchContaienr,
  };

  const getBannerInfo = (theme) => {
    if (theme === "sm") {
      return {
        page: `통합 검색`,
        message: `필요한 정보를 빠르게 찾을 수 있도록\n검색 기능을 제공합니다.`,
      };
    }
    return { imgage: "search.jpg", text: "search" };
  };

  const renderComponent = () => {
    const Container = containers[theme] || DefaultContaienr;
    const Banner = banners[theme];

    return (
      <Container>
        {Banner && <Banner info={getBannerInfo(theme)} />}
        <SearchForm />
      </Container>
    );
  };

  return <>{renderComponent()}</>;
};

export default Search;
