import styled from "styled-components";

export const HamburgerMenuContainer = styled.div`
  position: fixed;
  top: 50%;
  right: 100px;
  transform: translateY(-50%);
  z-index: 999;
  li {
    padding: 10px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    background: #fff;
    color: #4d219b;
    font-size: 24px;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    transition: 0.3s;
    .readOnly {
      font-size: 0;
      opacity: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.active {
      background: #333;
      color: #fff;
    }
  }
  @media screen and (max-width: 1700px) {
    top: 100%;
    transform: translateY(-70px);
    right: 70px;
    ul {
      position: relative;
      &.close {
        top: 0;
        left: 0;
        li:not(:first-child) {
          width: 0;
          padding: 0;
          height: 0;
          box-shadow: none;
          opacity: 0;
          margin: 0;
        }
      }
      &.open {
        li:nth-child(2) {
          transform: translateY(-65px);
        }
        li:nth-child(3) {
          transform: translateY(-130px);
        }
        li:nth-child(4) {
          transform: translateY(-195px);
        }
        li:nth-child(5) {
          transform: translateY(-260px);
        }
        li:nth-child(6) {
          transform: translateY(-325px);
        }
      }
      li {
        position: absolute;
      }
    }
  }
  @media screen and (max-width: 767px) {
  }
`;

export const JoinFormContainer = styled.div`
  min-height: calc(100vh - 100px);
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  font-size: 18px;
  padding: 50px 0;
  justify-content: space-evenly;
  max-width: 1000px;
  width: 100%;
  margin: auto;
  .label {
    width: 100%;
    font-size: 38px;
    font-weight: 700;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  button {
    border: none;
    border-radius: 25px;
    height: 50px;
  }
  .image {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
    img {
      border-radius: 10px;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: contain;
    }
  }
  .form {
    width: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    div {
      width: 100%;
    }
    label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      span {
        width: 150px;
      }
      button {
        width: 150px;
        background: #333;
        color: #fff;
      }
    }
    p {
      margin-bottom: 10px;
      font-weight: 500;
    }
    input {
      width: calc(100% - 160px);
      padding: 0 20px;
      border-radius: 25px;
      height: 50px;
      border: 1px solid rgb(202, 202, 202);
      background: #fff;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus {
        border: 1px solid rgb(202, 202, 202) !important;
        box-shadow: none;
        outline: none;
      }
    }
  }
  .submit {
    width: 300px;
    background: #ff7a00;
    color: #fff;
    margin: 0 auto;
    font-weight: 700;
  }
  @media screen and (max-width: 1050px) {
    .label {
      font-size: 24px;
    }
    .form {
      font-size: 16px;
      padding: 0;
    }
    .submit {
      width: 200px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    .label {
      font-size: 20px;
    }
    .form {
      padding: 0;
      font-size: 14px;

      label {
        input {
          width: calc(100% - 100px);
        }
        span,
        button {
          width: 100px;
        }
      }
    }
    .submit {
      width: 150px;
      font-size: 14px;
    }
  }
`;

export const HomeContainer = styled.div`
  margin-top: 40px;
  .label {
    font-size: 24px;
    font-weight: 700;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    button {
      background: transparent;
      border: none;
      color: #999;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
  .feed {
    ul {
      display: flex;
      gap: 20px;
      li {
        &.not {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          cursor: default;
        }
        cursor: pointer;
        border: 1px solid #e1e1e1;
        width: calc((100% - 60px) / 4);
        height: 400px;
        border-radius: 40px;
        overflow: hidden;
        padding: 20px;
        background: #fff;
        img {
          border-radius: 20px;
          width: 100%;
          height: 300px;
          object-fit: cover;
          margin-bottom: 20px;
        }
        p {
          height: 44px;
          width: 100%;
          font-size: 18px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
  .members {
    margin: 50px 0;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        background: #fff;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        svg {
          display: block;
          margin: auto;
        }
      }
    }
    .mySwiper {
      width: calc(100% - 200px);
      .swiper-slide {
        text-align: center;
      }
    }
    .image {
      border-radius: 50%;
      width: 100%;
      aspect-ratio: 1;
      border-radius: 50%;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .content {
      margin-top: 20px;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  @media screen and (max-width: 1050px) {
    .feed ul li {
      height: 300px;
      img {
        height: calc(100% - 60px);
      }
      p {
        height: 40px;
        font-size: 16px;
      }
    }
    .members {
      .mySwiper {
        width: calc(100% - 150px);
      }
      .content {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .label {
      font-size: 20px;
    }
    .feed ul {
      flex-wrap: wrap;
      li {
        border-radius: 20px;
        padding: 10px;
        width: calc(50% - 10px);
        height: 200px;
        img {
          border-radius: 10px;
          margin-bottom: 10px;
          height: calc(100% - 44px);
        }
        p {
          font-size: 14px;
          height: 34px;
        }
      }
    }
    .members {
      button {
        display: none;
      }
      .content {
        font-size: 14px;
        margin-top: 10px;
      }
      .mySwiper {
        width: 100%;
      }
    }
  }
`;

export const MembersContainer = styled.div`
  .invite {
    margin: 50px 0 0;
    background: #fff;
    border: 1px solid #e1e1e1;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
  }
  .members {
    margin: 30px 0 50px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 30px;
    li {
      padding: 0 0 20px 0;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 20px;
      border-bottom: 1px solid #e1e1e1;
      .image {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      span {
        width: calc(100% - 100px);
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .members li {
      font-size: 16px;
      .image {
        width: 70px;
        height: 70px;
      }
      span {
        width: calc(100% - 80px);
      }
    }
  }
  @media screen and (max-width: 767px) {
    .members {
      display: block;
      li {
        padding: 15px 0;
        font-size: 14px;
        .image {
          width: 50px;
          height: 50px;
        }
        span {
          width: calc(100% - 60px);
        }
      }
    }
  }
`;

export const SearchContainer = styled.div`
  .searched {
    margin: 50px 0;
  }
  .label {
    font-size: 24px;
    font-weight: 700;
    display: block;
    margin-bottom: 10px;
    strong {
      color: rgb(255, 122, 0);
      display: inline-block;
      margin-left: 10px;
    }
  }
  .result {
    font-size: 16px;
    margin: 30px 0px;
    padding: 20px;
    border-radius: 20px;
    background: #efefef;
    ul {
      gap: 20px;
      li {
        background: #fff;
        padding: 15px;
        border-radius: 5px;
        border: 1px solid rgb(239, 239, 239);
        cursor: pointer;
      }
    }
    &.stockList {
      li {
        width: calc((100% - 80px) / 5);
      }
      .cmpnyNm {
        font-size: 12px;
      }
    }
    &.blogList {
      li {
        width: calc((100% - 60px) / 4);
      }
    }
    &.scheduleList,
    &.projectList {
      li {
        width: calc((100% - 40px) / 3);
      }
    }
    &.feedList {
      li {
        width: calc((100% - 60px) / 4);
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
    }
  }
  @media screen and (max-width: 1050px) {
    .label {
      font-size: 20px;
    }
    .result {
      font-size: 14px;
      &.stockList ul li {
        width: calc((100% - 40px) / 3);
      }

      &.scheduleList ul li,
      .projectList ul li {
        width: calc((100% - 20px) / 2);
      }
      &.blogList ul li {
        width: calc((100% - 40px) / 3);
      }
      &.feedList ul li {
        width: calc((100% - 40px) / 3);
      }
    }
  }
  @media screen and (max-width: 767px) {
    .label {
      font-size: 16px;
    }
    .result {
      &.stockList,
      &.scheduleList,
      &.projectList,
      &.blogList,
      &.feedList {
        ul li {
          width: 100%;
        }
      }
    }
  }
`;

export const SearcedEChanggo = styled.div`
  .productList {
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      li {
        background: #fff;
        padding: 15px;
        width: calc((100% - 60px) / 4);
        font-size: 16px;
        border-radius: 15px;
        .preview {
          width: 100%;
          aspect-ratio: 1;
          overflow: hidden;
          border-radius: 10px;
          cursor: pointer;
          margin-bottom: 10px;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
        .product {
          span {
            display: block;
            &.prductNm,
            &.cnsmrPc {
              font-weight: 700;
            }
            &:nth-child(3) {
              color: #999;
              font-size: 14px;
              margin: 10px 0;
            }
          }
        }
        .cmpnyMbers {
          span {
            display: inline-block;
            font-size: 12px;
            padding: 2px 5px;
            background: #333;
            color: #fff;
            border-radius: 5px;
          }
        }
      }
    }
  }
`;

export const InviteModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  padding: 20px;
  .content {
    width: 500px;
    height: 500px;
    background: #fff;
    padding: 20px;
    border-radius: 20px;
    font-size: 16px;
    .close {
      width: 30px;
      height: 30px;
      border: none;
      background: transparent;
      margin-left: auto;
      font-size: 24px;
      display: block;
      margin-bottom: 20px;
    }
    .search {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      input[type="text"],
      button {
        height: 50px;
        border: 1px solid #333;
        color: #000;
        border-radius: 10px;
      }
      input[type="text"] {
        width: calc(100% - 110px);
        padding: 0 10px;
        &:focus {
          border: 1px solid #333 !important;
          box-shadow: none;
        }
      }
      button {
        width: 100px;
        background: #333;
        color: #fff;
        font-size: larger;
        svg {
          display: block;
          margin: auto;
        }
      }
    }
    .searched {
      width: 100%;
      height: calc(100% - 110px);
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #999;
        border-radius: 10px;
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
      }
      ul {
        border-top: 1px solid #cacaca;
        li {
          display: flex;
          align-items: center;
          height: 50px;
          border-bottom: 1px solid #cacaca;
          &:first-child {
            background: #fef9f3;
          }
          div {
            text-align: center;
            &:nth-child(1) {
              width: 20%;
            }
            &:nth-child(2) {
              width: 50%;
            }
            &:nth-child(3) {
              width: 30%;
            }
          }
          button {
            background: #333;
            color: #fff;
            padding: 2px 8px;
            border-radius: 5px;
            border: none;
            &.signedUp {
              background: #ddd;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .content {
      font-size: 14px;
      .search {
        input[type="text"],
        button {
          height: 40px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .content {
      .search {
        margin-bottom: 20px;
        input[type="text"] {
          width: calc(100% - 50px);
        }
        button {
          width: 40px;
        }
      }
      .searched ul {
        border-top: none;
        li {
          border: 1px solid #cacaca;
          margin-bottom: 15px;
          border-radius: 10px;
          flex-wrap: wrap;
          height: auto;
          &:first-child {
            display: none;
          }
          &:last-child {
            margin-bottom: 0;
          }
          div {
            height: 30px;
            line-height: 30px;
            &:nth-child(1) {
              order: 1;
              width: 50%;
            }
            &:nth-child(2) {
              order: 3;
              width: 100%;
              border-top: 1px solid #cacaca;
            }
            &:nth-child(3) {
              order: 2;
              width: 50%;
              border-left: 1px solid #cacaca;
              button {
                background: transparent;
                color: #000;
                font-weight: 700;
                &.signedUp {
                  color: #999;
                  font-weight: normal;
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SettingContainer = styled.div`
  margin: 50px 0;
  font-size: 18px;
  > .content {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: auto;
    gap: 20px;
    background: #fff;
    border-radius: 30px;
    border: 1px solid rgb(225, 225, 225);
    padding: 50px;
  }
  @media screen and (max-width: 1050px) {
    > .content {
      padding: 30px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    > .content {
      padding: 20px;
      font-size: 14px;
    }
  }
`;

export const EditProfileContainer = styled.div`
  button {
    height: 50px;
    color: #fff;
    border-radius: 10px;
    border: none;
  }
  .form {
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      input {
        width: calc(70% - 170px);
        padding: 0 20px;
        border-radius: 25px;
        height: 50px;
        border: 1px solid rgb(202, 202, 202);
        &:focus {
          border: 1px solid rgb(202, 202, 202) !important;
          box-shadow: none;
        }
      }
      button {
        background: #333;
      }
      button,
      span {
        width: 150px;
      }
    }
  }
  .submit {
    display: block;
    margin: 50px auto 0;
    background: #ff7a00;
    width: 200px;
  }
  @media screen and (max-width: 1050px) {
    .form div {
      gap: 10px;
      input {
        width: calc(100% - 110px);
        height: 40px;
        border-radius: 10px;
        padding: 0 10px;
      }
      button {
        height: 40px;
      }
      span,
      button {
        width: 100px;
      }
    }
    .submit {
      width: 150px;
      height: 40px;
      font-weight: 700;
    }
  }
`;

export const EditMemberContainer = styled.div`
  > div {
    margin-top: 0;
  }
  .invite {
    margin: 50px 0;
    background: #fff;
    border: 1px solid #e1e1e1;
    font-size: 16px;
    padding: 10px;
    border-radius: 5px;
  }
  table {
    width: 100%;
    border: 1px solid #cacaca;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #cacaca;
      height: 50px;
      text-align: center;
      &:nth-child(1) {
        width: 300px;
      }
      &:nth-child(3) {
        width: 200px;
      }
      &:nth-child(4) {
        width: 100px;
      }
    }
    th {
      background: #333;
      color: #fff;
    }
    select {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      padding-left: 10px;
      cursor: pointer;
      &:focus {
        box-shadow: none;
        border: none !important;
      }
    }
    button {
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
    }
  }
  @media screen and (max-width: 1050px) {
    .invite {
      margin: 30px 0;
    }
    table {
      th,
      td {
        height: 40px;
        &:nth-child(1) {
          width: 30%;
        }
        &:nth-child(3) {
          width: 20%;
        }
        &:nth-child(4) {
          width: 15%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .invite {
      font-size: 14px;
    }
    ul {
      li {
        border: 1px solid #cacaca;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        border-radius: 10px;
        &:last-child {
          margin-bottom: none;
        }
      }
      div {
        width: 50%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        &:nth-child(1) {
          font-weight: 700;
        }
        &:nth-child(1),
        &:nth-child(2) {
          border-bottom: 1px solid #cacaca;
        }
        &:nth-child(4),
        &:nth-child(2) {
          border-left: 1px solid #cacaca;
        }
      }
      select {
        width: 100%;
        height: 100%;
        border: none;
        outline: none;
        text-align: center;
        cursor: pointer;
        &:focus {
          box-shadow: none;
          border: none !important;
        }
      }
      button {
        width: 100%;
        height: 100%;
        border: none;
        background: transparent;
      }
    }
  }
`;

export const MembershipContainer = styled.div`
  input[type="checkbox"] {
    margin-right: 0;
  }
  > div:first-child {
    margin-top: 0;
  }
  .actions {
    margin: 50px 0;
    button {
      background: #fff;
      border: 1px solid #e1e1e1;
      font-size: 16px;
      padding: 10px;
      border-radius: 5px;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  table {
    margin: 50px 0;
    width: 100%;
    border: 1px solid #cacaca;
    border-collapse: collapse;
    th,
    td {
      border: 1px solid #cacaca;
      height: 50px;
      text-align: center;
      &:nth-child(1) {
        width: 100px;
      }
      &:nth-child(2) {
      }
      &:nth-child(3) {
        width: 300px;
        button {
          width: 120px;
          height: 35px;
          /* background: #ccc;
          border: none; */
          border-radius: 10px;
          font-weight: 700;
          &:first-child {
            background: #ff7a00;
            margin-right: 10px;
            color: #fff;
            border: none;
          }
          &:last-child {
            background: transparent;
            border: 1px solid #ccc;
          }
        }
      }
    }
    th {
      background: #333;
      color: #fff;
    }
    select {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      padding-left: 10px;
      cursor: pointer;
      &:focus {
        box-shadow: none;
        border: none !important;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .actions {
      margin: 30px 0;
    }
  }
  @media screen and (max-width: 767px) {
    .actions button {
      font-size: 14px;
    }
    ul {
      li {
        border: 1px solid #cacaca;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        border-radius: 10px;
        overflow: hidden;
        &:last-child {
          margin-bottom: none;
        }
      }
      div {
        text-align: center;
        height: 40px;
        line-height: 40px;
        &:nth-child(1) {
          width: 40px;
          border-right: 1px solid #cacaca;
        }
        &:nth-child(2) {
          width: calc(100% - 40px);
        }
        &:nth-child(3) {
          width: 100%;
          border-top: 1px solid #cacaca;
          display: flex;
        }
      }
      button {
        width: 50%;
        height: 100%;
        border: none;
        background: transparent;
        &:first-child {
          background: #ff7a00;
          color: #fff;
        }
        &:last-child {
          background: #efefef;
        }
      }
    }
  }
`;

export const DeleteGroupContainer = styled.div`
  text-align: center;
  font-size: 18px;
  .msg {
    .title {
      font-size: 26px;
      font-weight: 600;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      svg {
        font-size: 30px;
        color: #bf0000;
      }
    }
    .info p strong {
      color: #bf0000;
      font-weight: 500;
    }
  }
  .confirm {
    margin: 20px 0;
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  button {
    margin: 50px 0 0;
    border: none;
    background: #333;
    text-align: center;
    width: 200px;
    height: 50px;
    border-radius: 10px;
    color: rgb(255, 255, 255);
  }
  @media screen and (max-width: 1050px) {
    font-size: 16px;
    button {
      width: 150px;
      height: 40px;
      font-weight: 700;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    button {
      font-size: 14px;
    }
    .msg .title {
      font-size: 16px;
    }
  }
`;

export const DeleteMessage = styled.div`
  font-size: 16px;
  margin: 10px 0;
  strong {
    color: #bf0000;
  }
`;

export const JoinContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50px);
  z-index: 999;
  .join {
    width: 300px;
    height: 70px;
    font-size: 20px;
    border-radius: 50px;
    border: none;
    background: #ff7a00;
    font-weight: 700;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  &.form {
    left: 0;
    transform: none;
    width: 100vw;
    height: 100vh;
    background: #fef9f3;
  }
`;

export const GroupTitle = styled.h3`
  margin: 50px 0;
  font-size: 32px;
  font-weight: 900;
  text-align: center;
  @media screen and (max-width: 767px) {
    margin: 30px 0;
    font-size: 24px;
  }
`;

export const EditGroupContainer = styled.div`
  > div {
    margin: 0;
    gap: 0 30px;
  }
  .submit {
    margin: 0 auto;
    button {
      display: block;
      margin: 50px auto 0px;
      background: rgb(255, 122, 0);
      width: 200px;
    }
  }
  @media screen and (max-width: 1050px) {
    .submit button {
      width: 150px;
      height: 40px;
      font-weight: 700;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      margin-top: 20px;
    }
    .submit button {
      font-size: 14px;
    }
  }
`;
