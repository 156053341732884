import React from "react";
import { DropdownContainer } from "../../styled/common";
import { FiMoreVertical } from "react-icons/fi";

const Dropdown = ({ active, setActive, feed, editFeed, handleDeleteModal, user, menuRef }) => {
  return (
    <DropdownContainer
      onClick={() => (active === feed.feedNo ? setActive(null) : setActive(feed.feedNo))}>
      <FiMoreVertical />
      {active === feed.feedNo && (
        <ul>
          {user.mberNo === feed.mberNo && <li onClick={() => editFeed(feed)}>수정</li>}
          <li onClick={() => handleDeleteModal(feed, "feed")}>삭제</li>
        </ul>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
