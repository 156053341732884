import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import RegisterTitle from "./component/register/registerTitle";
import RegisterMenu from "./component/register/registerMenu";
import HomePageSettingMenu from "./component/common/homepageSettingMenu";
import BoardView from "../../../common/board/boardView";

import { setRegisterIndex } from "../../../store/compoIndex";
import { Container } from "../styled/mypage";
import PageInfo from "../pageInfo";
import { RegisterNoticeViewContainer } from "./component/styled/register";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

export default function RegisterNoticeView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const index = useSelector((state) => state.compoIndex.register);
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const [cmpnyNo, setCmpnyNo] = useState();

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("register")) {
      dispatch(setRegisterIndex(3));
    }

    const company = JSON.parse(sessionStorage.getItem("homepageCompany"));

    if (!company) {
      console.log("선택된 회사가 존재하지 않습니다.");
      navigate("/mypage");
    } else {
      setCmpnyNo(company.cmpnyNo);
    }
  }, []);

  return (
    <Container className={setting?.mode}>
      <PageInfo label="공지사항" />
      <div className="content">
        <HomePageSettingMenu />
        <RegisterTitle />
        <RegisterMenu index={index} />
        <RegisterNoticeViewContainer className={setting?.mode}>
          <BoardView userName={user?.userName} cmpnyNo={cmpnyNo} setting={setting} />
        </RegisterNoticeViewContainer>
      </div>
    </Container>
  );
}
