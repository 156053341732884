import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { mainSection } from "../../main/component/function/common";
import { SectionSwiperContainer } from "../styled/info";

const Visual = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const extracted = pathname.includes("/info/") ? pathname.split("/info/")[1] : null;
  const [info, setInfo] = useState();

  useEffect(() => {
    const data = mainSection.find((item) => item.key === extracted);
    setInfo(data);
  }, [pathname]);

  return (
    <SectionSwiperContainer className={extracted}>
      <div className="inner">
        <div className="content">
          <span className="title">{info?.title}</span>
          <strong>{info?.info}</strong>
          <span>{info?.details}</span>
          {info ? (
            <div className="buttons">
              <button onClick={() => navigate(info.path)}>바로가기</button>
            </div>
          ) : (
            <div className="buttons">
              <button onClick={() => openAppInstall(true)}>
                <img src="/img/main_visual_apple.png" alt="apple" />
                <span>App Store</span>
              </button>
              <button onClick={() => openAppInstall(false)}>
                <img src="/img/main_visual_playstore.png" alt="playstore" />
                <span>GooglePlay</span>
              </button>
            </div>
          )}
        </div>
        <div className="img">
          <img src={`/img/main_section_${info?.key}.png`} />
        </div>
      </div>
    </SectionSwiperContainer>
  );
};

export default Visual;
