import React from "react";
import { PlanComparisonContainer } from "../../styled/info";
import { FaCheck } from "react-icons/fa6";

const PlanComparison = () => {
  const plans = [
    { feature: "일정 관리", free: true, plus: true, premium: true },
    { feature: "프로젝트 관리", free: true, plus: true, premium: true },
    { feature: "모임 기능 제공", free: true, plus: true, premium: true },
    { feature: "개인 도메인", free: true, plus: true, premium: true },
    { feature: "모노티 플랫폼 입점", free: false, plus: true, premium: true },
    { feature: "매장 홍보", free: false, plus: true, premium: true },
    { feature: "웹 결제 시스템", free: false, plus: true, premium: true },
    { feature: "픽업 오더", free: false, plus: false, premium: true },
    { feature: "매출 분석 리포트", free: false, plus: false, premium: true },
    { feature: "실시간 알림 발송", free: false, plus: false, premium: true },
  ];

  const planHeaders = [
    { name: "free", price: 0 },
    { name: "plus", price: { origin: "10,000원/월", discount: "4,500원/월" } },
    { name: "premium", price: { origin: "20,000원/월", discount: "9,900원/월" } },
  ];

  return (
    <PlanComparisonContainer>
      <div className="header">
        <div></div>
        {planHeaders.map((plan, index) => (
          <div className={plan.name}>
            <strong>{plan.name}</strong>
            {plan.price ? (
              <div className="price">
                <span className="origin">{plan.price.origin}</span>
                <span>{plan.price.discount}</span>
              </div>
            ) : (
              <div className="price">
                <span>0원</span>
              </div>
            )}
          </div>
        ))}
      </div>
      {plans.map((plan, index) => (
        <div className="row">
          <div>{plan.feature}</div>
          {planHeaders.map((header, idx) => (
            <div>{plan[header.name] ? <FaCheck /> : <span>-</span>}</div>
          ))}
        </div>
      ))}
    </PlanComparisonContainer>
  );
};

export default PlanComparison;
