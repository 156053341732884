import moment from "moment";
import "moment/locale/ko";
import { Link } from "react-router-dom";
import { FaInstagram, FaFacebookF } from "react-icons/fa6";
import { useRegex } from "../../../../hooks/useRegex";

const DefaultFooter = ({ homepageInfo, cmpnyInfo, theme = null }) => {
  const { BussinessFormat, TelFormat } = useRegex();

  const renderLink = (url, label, ICON) =>
    url ? (
      <Link to={url} target="_blank" className={label.toLowerCase()}>
        <div className="icon">
          <ICON />
        </div>
        <span>{label}</span>
      </Link>
    ) : null;

  const formatTime = (time) => moment(time, "H").format("A h시");

  const getOperatingHours = (startTime, endTime, defaultStart, defaultEnd) => {
    const start = startTime || defaultStart;
    const end = endTime || defaultEnd;
    return start === end ? "24시간" : `${formatTime(start)} - ${formatTime(end)}`;
  };

  const HOMEPAGE_INFO = [
    [
      homepageInfo?.csNumber && `CS CENTER: ${TelFormat(homepageInfo.csNumber)}`,
      `상담시간: ${getOperatingHours(homepageInfo.csTimeStart, homepageInfo.csTimeEnd, "9", "18")}`,
      `점심시간: ${getOperatingHours(
        homepageInfo.breakTimeStart,
        homepageInfo.breakTimeEnd,
        "12",
        "13",
      )}`,
    ].filter(Boolean),
    [
      cmpnyInfo?.rprNm && `대표자명: ${cmpnyInfo.rprNm}`,
      cmpnyInfo?.bizrno && `사업자등록번호: ${BussinessFormat(cmpnyInfo.bizrno)}`,
      cmpnyInfo?.adres && `주소: ${cmpnyInfo.adres}`,
      cmpnyInfo?.telno && `대표전화: ${TelFormat(cmpnyInfo.telno)}`,
    ].filter(Boolean),
  ];

  const renderList = (list) =>
    list.map((arr, index) => (
      <ul key={index}>
        {arr.map((item, idx) => (
          <li key={idx}>{item}</li>
        ))}
      </ul>
    ));

  return (
    <div className="footer_content">
      <div className="footer_info">
        {homepageInfo?.logoImage ? (
          <img
            src={theme === "sm" ? "/img/shopTheme/sm_logo.png" : imgHandler(homepageInfo.logoImage)}
          />
        ) : (
          <span>{homepageInfo?.cmpnyNm}</span>
        )}
      </div>
      <div className="footer_list">
        {renderList(HOMEPAGE_INFO)}
        <ul>
          <li>호스팅 업체: 노타이틀</li>
          <li>Copyright © 노타이틀 All Rights Reserved.</li>
          <li>
            {homepageInfo.facebookUrl || homepageInfo.instagramUrl ? (
              <div className="external_link">
                {renderLink(homepageInfo.facebookUrl, "Facebook", FaInstagram)}
                {renderLink(homepageInfo.instagramUrl, "Instagram", FaFacebookF)}
              </div>
            ) : null}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DefaultFooter;
