import React from "react";

const Product = ({ item, selected, toggleCheckbox, openModal, deleteProduct, changeAmount }) => {
  return (
    <li>
      <label>
        <input
          type="checkbox"
          checked={selected.includes(item.id)}
          onChange={() => toggleCheckbox(item.id)}
        />
        <span className="checkmark"></span>
      </label>
      <div className="image">
        <img src={imgHandler(item.media)} alt={item.name} />
      </div>
      <div className="productInfo">
        <span className="productName">{item.name}</span>
        {/* <span className="brand">{item.brand}</span> */}
        <span className="price">{(item.amount * item.price).toLocaleString()}원</span>
        <div className="amount">
          <button onClick={() => changeAmount(item.id, false)}>-</button>
          <span>{item.amount}</span>
          <button onClick={() => changeAmount(item.id, true)}>+</button>
        </div>
      </div>
      <button
        onClick={() =>
          openModal({
            type: "confirmation",
            title: "상품 삭제",
            message: [`상품을 삭제하시겠습니까?`],
            handleConfirm: () => deleteProduct(item.id),
          })
        }>
        삭제
      </button>
    </li>
  );
};

export default Product;
