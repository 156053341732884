import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import DaumPostcode from "react-daum-postcode";
import { useNavigate } from "react-router";

import { CompanyAddFormContainer } from "../styled/company";
import { SubmitButton } from "../../../styled/mypage";
import { AddrSearchModal } from "../../../scheduler/component/styled/calendarEntry";

import { addCompany, updateCompany } from "../../../../../store/company";
import { useModal } from "../../../../../hooks/useModal";

export default function CompanyAddForm({
  company,
  formData,
  setFormData,
  isDuplicate,
  setIsDuplicate,
  stampShop,
  isSangsaeng,
  setIsSangsaeng,
  isStamp,
  setIsStamp,
  isPaymentYn,
  setIsPaymentYn,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const setting = useSelector((state) => state?.user?.setting);
  const user = useSelector((state) => state?.user?.data[0]);

  const [showPostCode, setShowPostCode] = useState(false);

  const { modal, openModal, closeModal } = useModal();

  const openPostCode = () => setShowPostCode(true);
  const closePostCode = () => setShowPostCode(false);

  // 사업자 중복검사
  const alreadyExists = async () => {
    const bizrnoRegex = /^[0-9]{10}$/;
    if (!formData.bizrno || !bizrnoRegex.test(formData.bizrno)) {
      toast.error("유효하지 않은 사업자 번호입니다.");
      return;
    }

    const url = "/api/duplBizrNo";
    const body = { bizrno: formData.bizrno };

    try {
      const res = await axios.post(url, body);

      const message = res.data.message;

      if (message === "isNotExist") {
        toast.success("사용할 수 있는 사업자 번호입니다.");
        setIsDuplicate(true);
      } else {
        if (message === "isExist") {
          toast.error(`이미 사용 중인 사업자 번호입니다.`);
          setIsDuplicate(false);
        } else {
          toast.error(message);
          setIsDuplicate(false);
        }
      }
    } catch (error) {
      toast.error(`이미 사용 중인 사업자 번호입니다.`);
      setIsDuplicate(false);
    }
  };

  // 유효성 검사
  const validateFormData = () => {
    const fieldLabels = {
      cmpnyNm: "회사 이름",
      rprNm: "대표자명",
    };

    for (let key in fieldLabels) {
      if (textNotExist(formData[key])) {
        const label = fieldLabels[key] || "";
        toast.error(`${chooseParticle(label, "을를")} 입력해주세요.`);
        return;
      }
    }

    if (formData.bizrno && !isDuplicate) {
      toast.error(`사업자번호 중복 확인을 해주세요.`);
      return;
    }

    if (isSangsaeng && !formData.adres) {
      toast.error("회사 주소를 입력해주세요.");
      return;
    }

    openModal({
      isOpen: true,
      type: "confirmation",
      title: `회사 ${company ? "수정" : "추가"}`,
      message: [`${formData.cmpnyNm}`, `회사를 ${company ? "수정" : "추가"}하시겠습니까?`],
      handleConfirm: () => onSubmit(),
      handleCancel: () => closeModal(),
    });
  };

  const onSubmit = async () => {
    let form = { ...formData };
    if (formData.adres && company.adres !== formData.adres) {
      form = { ...form, ...(await searchAddress(formData.adres)) };
    }

    await createCmpny(form);
  };

  // 회사 추가 및 수정
  const createCmpny = async (data) => {
    const url = "/api/cmpny";
    const body = {
      ...data,
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    if (data.latitude) {
      body.latitude = data.latitude;
    }
    if (data.longitude) {
      body.longitude = data.longitude;
    }
    if (isSangsaeng) {
      body.gbn = "ss";
    } else {
      body.gbn = "";
    }

    const res = await axios.put(url, body);

    if (res.data) {
      const cmpnyNo = res.data.rtnModel.cmpnyNo;
      const bizData = { ...data, cmpnyNo };

      if (stampShop?.id || isStamp) {
        await createStampShop(cmpnyNo);
      }

      // 회사 수정이 아닐 경우
      if (!company) {
        await createMber(bizData);
      } else {
        toast.success("회사가 수정되었습니다.");
        getCompany(cmpnyNo);
        closeModal();
      }
    }
  };

  // 회사 추가 시 멤버 추가
  const createMber = async (data) => {
    const url = "/api/cmpnyMber";
    const body = {
      cmpnyNo: data.cmpnyNo,
      mberNo: user.mberNo,
      mberNm: user.name,
      mberTel: user.moblphonNo,
      auth: "ADMIN",
      isWorking: true,
      isDeleted: false,
      userId: user.userName,
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    const res = await axios.put(url, body);
    console.log(res);

    if (res.data.success) {
      toast.success("회사가 추가되었습니다.");
      getCompany(data.cmpnyNo);
      navigate("/mypage");
    }
  };

  // 회사 조회
  const getCompany = async (cmpnyNo) => {
    const url = "/api/cmpny";

    const body = { cmpnyNo: cmpnyNo };

    try {
      const res = await axios.post(url, body);
      const data = res.data;

      if (company) {
        dispatch(updateCompany(data));
      } else {
        dispatch(addCompany(data));
      }
      navigate("/mypage/company", { state: { cmpny: data } });
    } catch (error) {
      console.log(error);
    }
  };

  const changeInput = (e) => {
    const { name, checked, type } = e.target;

    if (!name) return;

    const { value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));

    if (name === "bizrno" && isDuplicate) {
      setIsDuplicate(false);
    }
  };

  // 카카오 주소
  const userAddress = (data) => {
    const extraAddress = [data.bname, data.buildingName].filter(Boolean).join(", ");
    const fullAddress = data.address + (extraAddress ? ` (${extraAddress})` : "");

    setFormData((data) => {
      const updated = { ...data, adres: fullAddress };
      return updated;
    });

    closePostCode();
  };

  // 위도 경도 찾기
  const searchAddress = async (address) => {
    try {
      const url = "/api/getCoordinates";
      const body = { address };
      const res = await axios.post(url, body);
      const data = res?.data?.documents;

      if (res.status === 200 && data && data.length > 0) {
        return { latitude: data[0].y, longitude: data[0].x };
      } else {
        throw new Error("No address");
      }
    } catch (error) {
      return null;
    }
  };

  // 스탬프 매장
  const createStampShop = async (cmpnyNo) => {
    const url = "/api/stamp-shop/save";
    const body = {
      ...stampShop,
      cmpnyNo: cmpnyNo,
      paymentYn: isPaymentYn ? "Y" : "N",
      gbn: "ss",
      delYn: isStamp ? "N" : "Y",
    };
    try {
      const res = await axios.post(url, body);

      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <CompanyAddFormContainer className={setting?.mode}>
      <h5 className="sound_only">사업자 정보 입력</h5>
      <div className="viewer">
        <p>
          <em>*</em>
          <span>표시는 필수 입력 정보입니다.</span>
        </p>
        <ul>
          <li className="cmpnyNm">
            <strong>
              회사명<em>*</em>
            </strong>
            <input
              type="text"
              name="cmpnyNm"
              onChange={(e) => changeInput(e)}
              value={formData.cmpnyNm}
            />
          </li>
          <li className="cmpnyNm">
            <strong>
              대표자명<em>*</em>
            </strong>
            <input
              type="text"
              name="rprNm"
              onChange={(e) => changeInput(e)}
              value={formData.rprNm}
            />
          </li>
          <li className="bizrno">
            <strong>사업자번호</strong>
            <input
              type="text"
              name="bizrno"
              onChange={(e) => changeInput(e)}
              value={formData.bizrno}
            />
            <button onClick={() => alreadyExists()}>중복체크</button>
          </li>
          <li className="adres">
            <strong className="tit">주소</strong>
            <div>
              <input
                type="text"
                name="adres"
                placeholder="주소찾기 버튼을 눌러주세요"
                value={formData.adres}
                readOnly
              />
              <button
                type="button"
                name="button"
                className="btn_line gray btn_duplicate"
                onClick={openPostCode}>
                <span>주소찾기</span>
              </button>
            </div>
            <input
              type="text"
              name="dtlAdres"
              placeholder="상세주소를 입력하세요."
              value={formData.dtlAdres}
              onChange={(e) => changeInput(e)}
            />
          </li>
          <li className="ss">
            <strong className="tit">상생매장여부</strong>
            <label>
              <input
                type="checkbox"
                checked={isSangsaeng}
                onChange={(e) => {
                  if (isStamp) {
                    setIsStamp(false);
                  }
                  setIsSangsaeng(e.target.checked);
                }}
              />
              <span className="checkmark"></span>
            </label>
            <span>상생매장이 아님에도 체크할 경우 정보가 삭제될 수 있습니다.</span>
          </li>
          <li className="ss">
            <strong className="tit">스탬프매장여부</strong>
            <label>
              <input
                type="checkbox"
                checked={isStamp && isSangsaeng}
                onChange={(e) => {
                  if (isPaymentYn) {
                    setIsPaymentYn(false);
                  }
                  setIsStamp(e.target.checked);
                }}
              />
              <span className="checkmark"></span>
            </label>
            <span>스탬프 매장은 상생 매장을 선택해야 이용 가능합니다.</span>
          </li>
          {isStamp && (
            <li className="ss">
              <strong className="tit">결제스탬프여부</strong>
              <label>
                <input
                  type="checkbox"
                  checked={isPaymentYn}
                  onChange={(e) => setIsPaymentYn(e.target.checked)}
                />
                <span className="checkmark"></span>
              </label>
              <span>결제 이용 시 스탬프 적립 매장 여부를 선택할 수 있습니다.</span>
            </li>
          )}
        </ul>
      </div>
      <SubmitButton>
        <button onClick={() => validateFormData()}>
          <span>추가하기</span>
        </button>
      </SubmitButton>
      {modal}
      {showPostCode && (
        <AddrSearchModal
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closePostCode();
            }
          }}>
          <div className="modalContent">
            <DaumPostcode onComplete={userAddress} />
            <button onClick={closePostCode}>닫기</button>
          </div>
        </AddrSearchModal>
      )}
    </CompanyAddFormContainer>
  );
}
