import { useMediaQuery } from "react-responsive";
import { Link, useOutletContext } from "react-router-dom";
import Product from "./product";
import Total from "./total";
import { useEffect, useState } from "react";
import { useModal } from "../../../../hooks/useModal";

const temp = Array.from({ length: 3 }).map((_, index) => ({
  id: index + 1,
  image: "prdct_01",
  productName: "LED 60W 라잇 직사각 방등/거실등 660형 삼성모듈사용, 2년 무상 AS",
  price: 60000,
  brand: "노타이틀 부천지사",
  ship: 3000,
  amount: 1,
}));
// const temp = JSON.parse(localStorage.getItem("localProducts")) || [];

export default function CartForm() {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const { url, theme } = useOutletContext();
  const [selected, setSelected] = useState([]);
  const [products, setProducts] = useState(JSON.parse(localStorage.getItem("localProducts")) || []);
  const { modal, openModal, closeModal } = useModal();

  const toggleCheckbox = (id) => {
    if (selected.includes(id)) {
      setSelected((data) => data.filter((item) => item !== id));
    } else {
      setSelected((data) => [...data, id]);
    }
  };

  const toggleAll = () => {
    if (selected.length === products.length) {
      setSelected([]);
    } else {
      setSelected(products.map((item) => item.id));
    }
  };

  const deleteSelected = () => {
    console.log("선택삭제", selected);

    setProducts((data) => {
      const updated = data.filter((item) => !selected.includes(item.id));
      localStorage.setItem("localProducts", JSON.stringify(updated));
      return updated;
    });

    setSelected([]);
    setModalConfig(() => ({ ...defaultModalConfig }));
  };

  const deleteProduct = (id) => {
    console.log("아이템 직접 삭제", id);

    setProducts((data) => {
      const updated = data.filter((item) => item.id !== id);
      localStorage.setItem("localProducts", JSON.stringify(updated));
      return updated;
    });

    setModalConfig(() => ({ ...defaultModalConfig }));
    setSelected([]);
  };

  const changeAmount = (id, increment = true) => {
    setProducts((data) => {
      const updated = data.map((item) =>
        item.id === id
          ? {
              ...item,
              amount: increment ? item.amount + 1 : Math.max(1, item.amount - 1),
            }
          : item,
      );

      localStorage.setItem("localProducts", JSON.stringify(updated));
      return updated;
    });
  };

  return (
    <main id="contCart" className="sub_page sub_page_mobile_mt">
      <div className="subTop_bnr subTop_bnr_01">{theme === 1 && <span>CART</span>}</div>
      <article className="boundary pdt_70" style={{ paddingBottom: isMobile ? 100 : 70 }}>
        <h2 className="font_36 pdb_30 font600 text_center">{theme === 2 ? "CART" : "장바구니"}</h2>
        {/* <section id="secBrdcrmb" className="font_18 pdb_20 text_right">
          <Link to={`/shop/${url}`}>
            <i className="xi-home-o font_20"></i>
          </Link>
          <Link to={url ? `/shop/${url}/cart` : `/shop/cart`}>장바구니</Link>
        </section> */}
        <ul className="products">
          <li className="header">
            <label>
              <input
                type="checkbox"
                checked={selected.length === products.length && products.length > 0}
                onChange={toggleAll}
              />
              <span className="checkmark"></span>
              <span>전체 선택</span>
            </label>
            <button
              onClick={() => {
                if (!selected.length) return;

                openModal({
                  type: "confirmation",
                  title: "선택 삭제",
                  message: [`선택한 ${selected.length}개의 상품을 삭제하시겠습니까?`],
                  handleConfirm: () => deleteSelected(),
                  handleCancel: () => closeModal(),
                });
              }}>
              선택 삭제
            </button>
          </li>
          {products.map((item) => (
            <Product
              item={item}
              selected={selected}
              toggleCheckbox={toggleCheckbox}
              openModal={openModal}
              deleteProduct={deleteProduct}
              changeAmount={changeAmount}
            />
          ))}
        </ul>
        <Total selected={selected} products={products} />
      </article>
      {modal}
    </main>
  );
}
