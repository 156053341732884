import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import { useNavigate, useOutletContext } from "react-router";
import axios from "axios";
import { useSelector } from "react-redux";

import ImageUploadAdapterPlugin from "../../../../mypage/homepageSetting/component/utils/ImageUploadAdapter";

import { HelpContainer } from "../../../shopping/component/styled/defaultTheme";
import uuid from "react-uuid";

const HelpForm = ({ formData, setFormData, resetData, openModal, closeModal, getInquiries }) => {
  const { cmpnyNo } = useOutletContext();

  const user = useSelector((state) => state?.user?.data[0]);

  const ckeditorInstanceRef = useRef(null);

  const handleEditorChange = (_, editor) => {
    const newContent = editor.getData();
    setFormData((data) => ({ ...data, content: newContent }));
  };

  const changeInput = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
  };

  const imgageCheck = async () => {
    const images = extractImages(formData.content);
    await uploadImage(images);
  };

  const extractImages = (content) => {
    const imgRegex = /<img.*?src=['"](data:image\/\w+;base64,.*?)['"].*?>/g;
    const matches = content.match(imgRegex);
    const images = [];

    if (matches) {
      matches.forEach((match) => {
        const dataUriMatch = match.match(/src=['"](data:image\/\w+;base64,.*?)['"]/);
        if (dataUriMatch && dataUriMatch[1]) {
          images.push(dataUriMatch[1]);
        }
      });
    }

    return images;
  };

  const uploadImage = async (images) => {
    try {
      const formData = new FormData();
      images.forEach((image) => {
        const blob = dataURItoBlob(image);
        const extension = blob.type.split("/")[1];
        formData.append("files", blob, `${uuid()}.${extension}`);
      });
      formData.append("ids", "N");
      formData.append("mberNo", user.mberNo);

      const response = await fetch("/api/uploadFiles", {
        method: "POST",
        body: formData,
      });
      const { responseData: imageDatas } = await response.json();
      const imageUrls = imageDatas.map((item) => item.fileName);
      const updatedContent = replaceImagesWithUrls(formData.content, imageUrls);

      lastSubmit(updatedContent);
    } catch (error) {
      console.error("업로드 중 오류 발생:", error);
    }
  };

  const replaceImagesWithUrls = (content, imageUrls) => {
    let updatedContent = formData.content;
    const dataUriRegex = /src=['"]data:image\/[^;'"]+;base64,([^'"]+)['"]/g;
    let matches = updatedContent.match(dataUriRegex);
    imageUrls.forEach((imageUrl, index) => {
      updatedContent = updatedContent.replace(matches[index], `src="${imgHandler(imageUrl)}"`);
    });
    return updatedContent;
  };

  const lastSubmit = async (reqContent) => {
    const url = `/erp/inquiry/${formData.id ? "update" : "create"}`;
    const body = {
      ...formData,
      content: reqContent,
      mberNo: user.mberNo,
      cmpnyNo: cmpnyNo,
    };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        toast.success(`문의가 ${formData.id ? "수정" : "작성"}되었습니다.`);
        getInquiries();
        resetData();
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.title || !formData.content) {
      toast.error("제목과 내용을 적어주세요.");
      return;
    }

    openModal({
      isOpen: true,
      type: "confirmation",
      title: `${formData?.id ? "문의 수정" : "문의 작성"}`,
      message: [`문의를 ${formData?.id ? "수정" : "작성"}하시겠습니까?`],
      handleConfirm: () => imgageCheck(),
      handleCancel: () => closeModal(),
    });
  };

  return (
    <HelpContainer>
      <div className="title">
        <div className="tit">문의제목</div>
        <div className="cont">
          <input
            type="text"
            placeholder="문의제목을 작성해주세요."
            onChange={changeInput}
            name="title"
            value={formData.title}
          />
        </div>
      </div>
      <div className="content">
        <div className="tit">문의내용</div>
        <div className="cont">
          <CKEditor
            editor={ClassicEditor}
            data={formData.content}
            onChange={handleEditorChange}
            config={{
              extraPlugins: [ImageUploadAdapterPlugin],
              toolbar: ["bold", "italic", "imageUpload"],
            }}
            ref={ckeditorInstanceRef}
          />
        </div>
      </div>
      <div className="buttons">
        <button onClick={handleSubmit}>확인</button>
        <button onClick={resetData}>취소</button>
      </div>
    </HelpContainer>
  );
};

export default HelpForm;
