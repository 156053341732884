import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import { IoArrowBack } from "react-icons/io5";
import { GroupDetailSearchMapSelectCmpny } from "../../../../styled/group";
import { isGrpMberGbnValue } from "../../../../function/group";
import { toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { GrLinkNext } from "react-icons/gr";

const MapSelected = ({
  selectedCmpny,
  setSelectedCmpny,
  cmpnys,
  selectedCmpnyPorts,
  swiperRef,
}) => {
  const cmpnyDetails = cmpnys[selectedCmpny?.cmpnyNo];
  const user = useSelector((state) => state?.user?.data[0]);

  const openCmpnyPage = async (portNo = null) => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: selectedCmpny.cmpnyNo,
    };

    const res = await axios.post(url, req);

    if (res.status === 200 && res.data) {
      user && localStorage.setItem("user", JSON.stringify(user));

      if (portNo) {
        if (isNaN(portNo)) {
          window.open(`https://1472.ai/shop/${res.data.domain}/portfolio?page=0`);
        } else {
          window.open(`https://1472.ai/shop/${res.data.domain}/portfolioView/${portNo}`);
        }
      } else {
        window.open(`https://1472.ai/shop/${res.data.domain}`);
      }
    } else {
      toast.error("홈페이지가 존재하지 않습니다.");
    }
  };

  return (
    <GroupDetailSearchMapSelectCmpny className={`${selectedCmpny ? "active" : ""}`}>
      <div>
        <button onClick={() => setSelectedCmpny(null)}>
          <IoArrowBack />
        </button>
        <div className="cmpny">
          <div className="cmpnyLogo" onClick={() => openCmpnyPage()}>
            <img
              src={
                selectedCmpny?.logoImage
                  ? imgHandler(selectedCmpny.logoImage)
                  : "/img/application_price_1.png"
              }
              alt="selected"
            />
          </div>
          <div className="cmpnyInfo">
            <span className="cmpnyNm" onClick={() => openCmpnyPage()}>
              {selectedCmpny?.cmpnyNm}
            </span>
            {selectedCmpny?.adres && <span>{selectedCmpny.adres}</span>}
            {selectedCmpny?.telno && <span>{selectedCmpny.telno}</span>}
          </div>
          <div className="cmpnyMbers">
            {cmpnyDetails && cmpnyDetails.map((item) => isGrpMberGbnValue(item))}
          </div>
          {selectedCmpnyPorts && selectedCmpnyPorts.length > 0 && (
            <div className="portList">
              <Swiper
                ref={swiperRef}
                className="mySwiper"
                modules={[Pagination]}
                direction={"horizontal"}
                slidesPerView={"auto"}
                spaceBetween={0}>
                {selectedCmpnyPorts.map((item, idx) => (
                  <SwiperSlide key={idx}>
                    <div className="slideImageContent">
                      <img
                        src={imgHandler(item.imgUrl) || "/img/service_3.png"}
                        onClick={() => openCmpnyPage(item.portNo)}
                      />
                    </div>
                  </SwiperSlide>
                ))}
                <SwiperSlide>
                  <div className="slideImageContent">
                    <div onClick={() => openCmpnyPage("list")} className="last">
                      <div className="icon">
                        <GrLinkNext />
                      </div>
                      <span>더보기</span>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </GroupDetailSearchMapSelectCmpny>
  );
};

export default MapSelected;
