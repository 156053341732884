import React from "react";
import FeedSwiper from "../common/feedSwiper";
import { FormContainer } from "../styled/feedWrite";
import { useSelector } from "react-redux";
import { FeedSwiperContainer } from "../styled/common";

const Form = ({ handleModal, handlePaste, feed, setFeed }) => {
  const user = useSelector((state) => state?.user?.data[0]);

  return (
    <FormContainer>
      <div className="writer">
        <img src={"/img/user.png"} />
        <div>
          {!parseInt(feed.gbn) || <span>&#91;{feed.groupName}&#93;</span>}
          <span className="nickNm">{user.name}</span>
          <p>설정한 닉네임으로 대체됩니다.</p>
        </div>
      </div>
      {feed.imgUrl && feed.imgUrl.length > 0 && (
        <FeedSwiperContainer>
          <FeedSwiper img={feed.imgUrl} handleModal={handleModal} />
        </FeedSwiperContainer>
      )}
      <div
        className="content"
        style={{ borderTop: (!feed.imgUrl || !feed.imgUrl.length) && "none" }}>
        <textarea
          placeholder="내용을 입력해주세요"
          onChange={(e) => setFeed((data) => ({ ...data, content: e.target.value }))}
          value={feed.content}
          onPaste={handlePaste}></textarea>
      </div>
    </FormContainer>
  );
};

export default Form;
