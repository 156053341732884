import React from "react";
import Card from "./card";
import { SectionContaier } from "../../styled/info";

const CardList = ({ cards, title, type }) => {
  return (
    <SectionContaier className={type}>
      <div className="inner">
        <div className="info">
          <span>
            {title.split("\n").map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            ))}
          </span>
        </div>
        <ul>
          {cards.map((card, index) => (
            <Card key={`${type}_${index}`} index={index} card={card} type={type} />
          ))}
        </ul>
      </div>
    </SectionContaier>
  );
};

export default CardList;
