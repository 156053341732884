import React, { useEffect, useState } from "react";
import HelpBoard from "./help/helpBoard";
import HelpForm from "./help/helpForm";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useOutletContext } from "react-router";
import axios from "axios";
import { useModal } from "../../../../hooks/useModal";

const Help = ({ setImages, setIsDetail }) => {
  const navigate = useNavigate();

  const { url, cmpnyNo } = useOutletContext();
  const user = useSelector((state) => state?.user?.data[0]);

  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(0);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(null);
  const [inquiries, setInquiries] = useState([]);
  const [total, setTotal] = useState(0);
  const [formData, setFormData] = useState({ title: "", content: "" });

  const { modal, openModal, closeModal } = useModal();

  useEffect(() => {
    if (!user) {
      toast.error("로그인 후 이용 가능합니다.");
      navigate(`/shop${url ? `/${url}` : ""}/login`);
    } else {
      getInquiries();
    }
  }, []);

  // 문의 리스트 조회
  const getInquiries = async (page = 1) => {
    if (loading) return;

    const url = "/erp/inquiryList";
    const body = { mberNo: user.mberNo, cmpnyNo, page: page, limit: 10 };

    try {
      setLoading(true);
      const res = await axios.post(url, body);
      console.log(res);
      setInquiries(res.data.inquiry || []);
      setTotal(res.data.totalCount || 0);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 문의 삭제
  const deleteInquiry = async () => {
    if (loading) return;
    const url = "/erp/inquiry/delete";
    const body = {
      id: selected.id,
      mberNo: user.mberNo,
    };

    try {
      setLoading(true);
      const res = await axios.post(url, body);
      if (res.status === 200) {
        if (page !== 1) {
          setPage(1);
        }
        getInquiries();
        setSelected(null);
        closeModal();
        toast.success("문의글이 삭제되었습니다.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 문의 상세 조회
  const getInquiryDetail = async (id) => {
    if (loading) return;

    const url = "/erp/inquiry";
    const body = { id };

    try {
      setLoading(true);
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 문의 토글 선택
  const selectItem = async (id) => {
    if (!id) return;
    if (selected && selected.id === id) {
      setSelected(null);
    } else {
      const detail = await getInquiryDetail(id);
      setSelected(detail);
    }
  };

  // 문의 삭제
  const handleDeleteInquiry = () => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `문의 삭제`,
      message: [`해당 문의글을 삭제하시겠습니까?`],
      handleConfirm: () => deleteInquiry(),
      handleCancel: () => closeModal(),
    });
  };

  // 페이지 변경
  const changePage = async (newPage) => {
    setPage(newPage);
    await getInquiries(newPage);
    setSelected(null);
  };

  const resetData = () => {
    setSelected(null);
    setFormData({ title: "", content: "" });
    setActive(0);
    setPage(1);
  };

  return (
    <>
      {modal}
      {
        [
          <HelpBoard
            setActive={setActive}
            inquiries={inquiries}
            setIsDetail={setIsDetail}
            setImages={setImages}
            total={total}
            changePage={changePage}
            page={page}
            selected={selected}
            selectItem={selectItem}
            formData={formData}
            setFormData={setFormData}
            handleDeleteInquiry={handleDeleteInquiry}
          />,
          <HelpForm
            setActive={setActive}
            formData={formData}
            setFormData={setFormData}
            resetData={resetData}
            openModal={openModal}
            closeModal={closeModal}
            getInquiries={getInquiries}
          />,
        ][active]
      }
    </>
  );
};

export default Help;
