import React, { useEffect, useState } from "react";
import SalesCheckInfo from "./common/salesCheckInfo";
import SortOptions from "./common/sortOptions";
import OrderList from "./orderHistory/orderList";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";

const sampleProducts = [
  { name: "Product A", quantity: 1, price: 10000 },
  { name: "Product B", quantity: 2, price: 15000 },
  { name: "Product C", quantity: 1, price: 20000 },
];

const OrderHistory = ({ active, tabs, selectCompany }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const [current, setCurrent] = useState({ page: 1, pageSize: 8 });
  const [totalElements, setTotalElements] = useState(10);
  const [data, setData] = useState([]);
  const [searched, setSearched] = useState({ status: "all", createdAt: null });

  useEffect(() => {
    if (current) {
      getOrders(current);
    }
  }, [current]);

  useEffect(() => {
    if (!selectCompany) return;

    const page = { page: 1, pageSize: 8 };
    setCurrent(page);
    setSearched({ status: "all", createdAt: null });

    getOrders(page);
  }, [selectCompany]);

  const getOrders = async (current) => {
    const query = `
    query SsOrders(
      $cmpnyNo: Int
      $status: SsStatusType
      $page: Int
      $pageSize: Int
      $createdAt: String
      
    ) {
      ssOrders(filter: { cmpnyNo: $cmpnyNo, status: $status, Page: $page, PageSize: $pageSize, createdAt: $createdAt }) {
        orders {
          id
          orderFlag
          price
          status
          createdAt
          price
          products {
            media
            name
            quantity
            price
          }
          cancelReason
        }
        totalCount
      }
    }
  `;

    const variables = {
      cmpnyNo: selectCompany.value,
      status: searched.status && searched.status !== "all" ? searched.status : null,
      createdAt: searched.createdAt ? moment(searched.createdAt).format("YYYY-MM-DD") : null,
      page: current.page,
      pageSize: current.pageSize,
    };

    console.log(variables);

    try {
      const res = await axios.post(
        "/erp/ss/orders",
        { query, variables },
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      setData(res?.data?.orders || []);
      setTotalElements(res?.data?.totalCount);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <SalesCheckInfo
        active={active}
        tabs={tabs}
        searched={searched}
        setSearched={setSearched}
        getOrders={getOrders}
        current={current}
        setCurrent={setCurrent}
      />
      <OrderList
        data={data}
        type="orderHistory"
        current={current}
        setCurrent={setCurrent}
        totalElements={totalElements}
      />
    </>
  );
};

export default OrderHistory;
