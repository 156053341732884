import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  companyInfo: [],
};

export const company = createSlice({
  name: "company",
  initialState,
  reducers: {
    createCompanyInfo: (state, action) => {
      state.companyInfo = action.payload;
    },
    createCompany: (state, action) => {
      state.data = action.payload;
    },
    updateCompany: (state, action) => {
      const updatedCompany = action.payload;
      state.data = state.data.map((item) =>
        item.cmpnyNo === updatedCompany.cmpnyNo ? updatedCompany : item,
      );
    },
    addCompany: (state, action) => {
      state.data = [...state.data, action.payload];
    },
    deleteCompany: (state, action) => {
      state.data = state.data.filter((item) => item.cmpnyNo !== action.payload);
    },
    resetCompany: (state) => {
      state.data = [];
      state.companyInfo = [];
    },
  },
});

export const {
  createCompany,
  updateCompany,
  addCompany,
  deleteCompany,
  resetCompany,
  createCompanyInfo,
} = company.actions;
export default company;
