import styled from "styled-components";

export const CustomInput = styled.label`
  position: relative;
  cursor: pointer;
  height: 20px;
  width: 20px;
  &.block {
    display: block;
    margin: auto;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    padding: 0;
    width: 0;
    height: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 6px;
    background-color: transparent;
    transition: all 0.3s ease;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
      width: 7px;
      height: 13px;
      border: solid #fff;
      border-width: 0 3px 3px 0;
    }
  }
  input:checked ~ .checkmark {
    /* background-color: #000; */
    /* border-color: #000; */
    &:after {
      display: block;
      /* animation: checkboxExpand 0.3s ease forwards; */
      transform: translate(-50%, -50%) rotate(45deg) scale(1);
      border-color: #0074ca;
    }
  }
  &:hover .checkmark {
    background-color: #efefef;
  }

  @keyframes checkboxExpand {
    0% {
      transform: translate(-50%, -50%) rotate(45deg) scale(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(45deg) scale(1);
    }
  }
`;

export const HomePageSettingMenuContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px 50px;
  letter-spacing: -1px;
  &.dark {
    li {
      background: #000;
      color: rgba(255, 255, 255, 0.5);
      &.active {
        background: #333;
        color: #fff;
      }
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    font-size: 24px;
    li {
      width: calc((100% - 25px) / 6);
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.5);
      text-align: center;
      transition: 0.3s;
      color: rgba(0, 0, 0, 0.5);
      height: 63px;
      line-height: 63px;
      cursor: pointer;
      &.active {
        background: #fafafa;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    ul {
      font-size: 18px;
      li {
        height: 50px;
        line-height: 50px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      gap: 10px;
      font-size: 14px;
      line-height: 14px;
      li {
        width: calc((100% - 20px) / 3);
        height: 35px;
        line-height: 35px;
      }
    }
  }
`;

export const HomepageEditContainer = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto 70px;
  padding: 0 20px;
  letter-spacing: -1px;
  &.dark {
    .info {
      color: #eee;
    }
    ul {
      border: 1px solid #000;
      li {
        border-bottom: 1px solid #000;
        &:nth-child(3),
        &:nth-child(4) {
          border-bottom: none;
        }
        span {
          color: #eee;
        }
        span:first-child {
          background: #000;
        }
      }
    }
  }
  .info {
    font-size: 28px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 50px;
    text-align: center;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid rgba(255, 255, 255, 0.5);
    li {
      width: 50%;
      display: flex;
      height: 60px;
      line-height: 60px;
      font-size: 20px;
      color: rgba(0, 0, 0, 0.5);
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      a {
        color: rgba(0, 0, 0, 0.5);
      }
      span {
        display: block;
        width: 50%;
        text-align: center;
        &:first-child {
          background: rgba(255, 255, 255, 0.5);
        }
      }
      &:nth-child(3),
      &:nth-child(4) {
        border-bottom: none;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    &.dark {
      ul li:nth-child(3) {
        border-bottom: 1px solid #000;
      }
    }
    ul li {
      width: 100%;
      font-size: 18px;
      height: 50px;
      line-height: 50px;
      &:nth-child(3) {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      }
      span:first-child {
        width: 40%;
      }
      span:last-child {
        width: 60%;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .info {
      font-size: 16px;
    }
    ul li {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
  }
`;

export const Modal = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3333;
  &.dark {
    .modal {
      background: #000;
      border: 1px solid #000;
      box-shadow: none;
    }
    .modalContent {
      background: #000;
    }
  }
  .modal {
    position: absolute;
    max-width: 765px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border: 1px solid #a6a6a6;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    font-size: 28px;
    border-radius: 30px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
    z-index: 33;
  }
  .modalContent {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 30px;
    background: #fff;
  }
  .slideWrapper {
    flex-flow: row nowrap;
    display: flex;
    flex: 1;
    width: 100%;
    transition: transform 0.5s ease;
    height: 100%;
    overflow: hidden;
    margin: auto;
  }
  .slideContent {
    flex: 0 0 50%;
    height: 100%;
    position: relative;
    transform: translateX(0);
    flex-basis: 100%;
    flex-shrink: 0;
    transition: 0.3s;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 1050px) {
    .modal {
      width: 90%;
      font-size: 20px;
    }
    .modalInfo {
      font-size: 20px;
    }
  }
  @media screen and (max-width: 767px) {
    .modalInfo {
      font-size: 14px;
    }
  }
`;

export const ThumbnailUploadContainer = styled.li`
  input[type="file"] {
    display: none;
  }
  label {
    border: 1px solid #b3b3b3;
    border-radius: 50%;
    color: #b3b3b3;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: relative;
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      svg {
        display: block;
      }
    }
  }
  .preview {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    li {
      aspect-ratio: 1;
      border-radius: 10px;
      overflow: hidden;
      border: 1px solid #a9a9a9;
      position: relative;
      background: rgba(0, 0, 0, 0.1);
      img,
      video {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      button {
        position: absolute;
        color: #000;
        border: none;
        background: transparent;
        top: 5px;
        right: 5px;
      }
    }
  }
`;
