import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

import { DetailEventContainer, MobileEventContentBox } from "./component/styled/scheduler";
import { GoToBackHeader } from "./component/styled/common";

import { IoMdArrowBack } from "react-icons/io";
import { MdOutlinePhotoSizeSelectActual } from "react-icons/md";
import { LuListTodo } from "react-icons/lu";

import {
  calculateDday,
  keysToRemove,
  transformTodo,
  updateTodos,
} from "./component/function/scheduler";

import ImageDetailViewer from "../../../common/imageDetailViewer";
import MobileEventDetailView from "./component/detailEvent/mobileEventDetailView";
import MobileEventGallery from "./component/detailEvent/mobileEventGallery";
import SelectedScope from "./component/common/selectedScope";
import { isOutsideCmpny } from "./component/function/common";
import { useModal } from "../../../hooks/useModal";

const DetailEvent = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const { modal, openModal, closeModal } = useModal();

  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  const [eventInfo, setEventInfo] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMsg, setModalMsg] = useState("");
  const [isDday, setIsDday] = useState(false);
  const [viewType, setViewType] = useState(0);
  const transformValue = (viewType * -100) / 3;
  const [isDetail, setIsDetail] = useState(false);
  const [imageOrder, setImageOrder] = useState([]);
  const [isSelectedScope, setIsSelectedScope] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [originalImages, setOriginalImages] = useState([]);
  const [images, setImages] = useState([]);
  const [isRelatedPerson, setIsRelatedPerson] = useState();
  const [todos, setTodos] = useState([]);

  const navigatePath = () => {
    let basePath = isDday ? "/mypage/ddayEntry" : "/mypage/calendarEntry";
    if (location.search) {
      const params = new URLSearchParams(location.search);

      const keyword = params.get("keyword");
      const path = params.get("path");
      const encodedKeyword = keyword ? encodeURIComponent(keyword) : null;

      let queryParams = [];

      if (path) {
        queryParams.push(`path=${path}`);
      }
      if (encodedKeyword) {
        queryParams.push(`keyword=${encodedKeyword}`);
      }

      const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";

      return `${basePath}${queryString}`;
    }
    return basePath;
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }
    getEvent();
  }, []);

  // 일정 조회
  const getEvent = async () => {
    const url = "/api/scheduleList";
    const body = {
      scheduleNo: id,
      mberNo: user.mberNo,
      delYn: "N",
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      const data = res.data[0];
      console.log(data);

      if (!data) {
        toast.error("존재하지 않는 일정입니다.");
        navigate("/mypage");
        return;
      }

      getImages();
      setEventInfo(data);

      if (data.dday === "Y") {
        setIsDday(true);
      } else {
        await getTodos(data);
      }
      if (
        data.gbn === "P" ||
        data.managerNo === user.mberNo ||
        user.mberNo in (res.data.workerList ?? {})
      ) {
        setIsRelatedPerson(true);
      } else {
        setIsRelatedPerson(false);
      }
    }
  };

  // 투두 조회
  const getTodos = async (data = eventInfo) => {
    const url = "/api/todo/by-schedule";
    const body = { scheduleNo: id };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      const todos = res.data.rtnModel;
      const updatedTodos = updateTodos(todos, data.memo);

      console.log("updatedTodos", updatedTodos);
      setTodos(updatedTodos);
    }
  };

  const isTodoExists = () => {
    if (!todos || !todos.length || todos.every((todo) => !todo.id)) {
      handleScheduleCopy({ ...eventInfo });
    } else {
      todoCopy({ ...eventInfo, todo: todos });
    }
  };

  // 일정 삭제
  const handleDelete = async () => {
    const url = "/api/schedule";
    const body = {
      ...eventInfo,
      delYn: "Y",
    };

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success("일정이 삭제되었습니다.");
      navigateWithParams();
    }
  };

  // 일정 완료
  const handleEventComplete = async (state) => {
    const url = "/api/schedule";
    const body = keysToRemove(eventInfo);
    body.completeYn = state;

    const res = await axios.put(url, body);
    if (res.status === 200) {
      getEvent();
    }
  };

  const updateTodoState = (todos, name, state) => {
    return todos.map((todoItem) =>
      todoItem.todoNo.toString() === name ? { ...todoItem, completeYn: state } : todoItem,
    );
  };

  // 일정 복사
  const todoCopy = (event) => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `메모 복사`,
      message: [`일정의 메모를 복사하시겠습니까?`],
      handleConfirm: () => handleScheduleCopy(event, true),
      handleCancel: () => handleScheduleCopy(event),
    });
  };

  const handleScheduleCopy = async (event, flag = false) => {
    const item = keysToRemove(event, ["completeYn", "mberNo", "scheduleNo"]);

    let body = { ...item, mberNo: user.mberNo };

    if (item.projectNo) {
      const isExist = await getProjectMbers(item.projectNo, user);

      if (!isExist) {
        body = {
          ...body,
          workerList: null,
          managerNo: null,
          managerNm: "",
          projectNo: null,
          projectNm: null,
        };
      }
    }

    navigate("/mypage/calendarEntry", {
      state: {
        eventInfo: body,
        todoInfo: flag ? transformTodo(body.todo) : null,
        infoState: "copy",
      },
    });
  };

  // 프로젝트 참여자 조회
  const getProjectMbers = async (projectNo) => {
    const url = "/api/participantList";
    const body = {
      projectNo,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const list = res.data.content;

      if (!list.find((item) => item.mberNo === user.mberNo)) {
        return false;
      } else {
        return true;
      }
    }
  };

  const EventActions = () => {
    return (
      viewType !== 2 && (
        <div className="btnArea">
          {!isOutsideCmpny(eventInfo, company) && (
            <>
              <button
                onClick={() =>
                  navigate(navigatePath(), {
                    state: { eventInfo, todoInfo: isDday ? null : transformTodo(todos) },
                  })
                }>
                수정
              </button>
              <button
                onClick={() =>
                  openModal({
                    isOpen: true,
                    type: "confirmation",
                    title: `${isDday ? "디데이" : "일정"} 삭제`,
                    message: [`${isDday ? "디데이를" : "일정을"} 삭제하시겠습니까?`],
                    handleConfirm: () => handleDelete(),
                    handleCancel: () => closeModal(),
                  })
                }>
                삭제
              </button>
            </>
          )}
          {/* )} */}
          <button onClick={isTodoExists}>복사</button>
          {!isOutsideCmpny(eventInfo, company) && (
            <button onClick={() => handleEventComplete(eventInfo.completeYn !== "Y" ? "Y" : "N")}>
              {eventInfo.completeYn !== "Y" ? "완료" : "완료취소"}
            </button>
          )}
          {/* )} */}
        </div>
      )
    );
  };

  // 이미지 다운
  const downloadImage = async (image) => {
    // URL
    if (!image.startsWith("data:image")) {
      try {
        const url = imgHandler(image);
        const res = await axios.post("/api/getFile", { url }, { responseType: "arraybuffer" });
        const blob = new Blob([res.data]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = image.split("/").pop();
        link.click();
        toast.success("이미지가 저장되었습니다.");
      } catch (error) {
        console.error("이미지 다운로드 실패:", error);
      }
    } else {
      // Base64
      const a = document.createElement("a");
      a.href = image;
      a.download = "image.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      toast.success("이미지가 저장되었습니다.");
    }
    setShowMenu(false);
    setSelectedImage(null);
  };

  const DropdownMenu = ({ x, y, deleteImages }) => (
    <div
      className="imageDelBox"
      onClick={(e) => {
        e.target === e.currentTarget && setShowMenu(false);
      }}>
      <div className="dropdownMenu" style={{ top: y, left: x }}>
        <button onClick={() => downloadImage(selectedImage.path)}>이미지 저장</button>
        {isRelatedPerson && <button onClick={() => deleteImages(selectedImage)}>삭제</button>}
      </div>
    </div>
  );

  // 마우스 우클릭
  const handleContextMenu = (e, img) => {
    e.preventDefault();
    setShowMenu(true);
    setMenuPosition({ x: e.clientX, y: e.clientY });
    setSelectedImage(img);
  };

  // 사진 삭제
  const deleteImages = async (item) => {
    const isEncodedImage = item.path.startsWith("data:image");
    const image = item.path;

    if (isEncodedImage) {
      setImages(images.filter((img) => img.path !== image));
      setOriginalImages(originalImages.filter((img) => img.path !== image));
    } else {
      const url = "/api/photoDelete";
      const body = { photoNos: item.photoNo };
      const res = await axios.post(url, body);
      if (res.data.length) {
        getImages();
      }
    }
    toast.success("사진이 삭제되었습니다.");
    setShowMenu(false);
    setSelectedImage(null);
  };

  // 이미지 조회
  const getImages = async () => {
    const url = "/api/photoList";
    const request = {
      refeNo: id,
    };

    const res = await axios.post(url, request);
    setImages(res.data);
  };

  const navigateWithParams = () => {
    if (location.search) {
      const params = new URLSearchParams(location.search);

      const keyword = params.get("keyword");
      const path = params.get("path");
      const encodedKeyword = encodeURIComponent(keyword);

      if (path) {
        navigate(`${path}${keyword ? `?keyword=${encodedKeyword}` : ""}`);
        return;
      }
      if (!!keyword) {
        navigate(`/mypage/scheduler?keyword=${encodedKeyword}`);
        return;
      }

      const project = params.get("project");
      if (!!project) {
        navigate(`/mypage/project/${project}?schedule`);
        return;
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      {modal}
      <DetailEventContainer className={isDarkMode} onContextMenu={(e) => e.preventDefault()}>
        <GoToBackHeader className={isDarkMode}>
          <span
            className="icon"
            onClick={() => {
              if (viewType !== 0) {
                setViewType(0);
              } else {
                navigateWithParams();
              }
            }}>
            <IoMdArrowBack />
          </span>
          <span>일정 상세보기</span>
          {isDday ? (
            <span style={{ fontSize: 13, marginLeft: 10, fontWeight: 700 }}>
              {calculateDday(eventInfo.startDt)}
            </span>
          ) : (
            <div className="selectTypeArea">
              {viewType !== 2 && (
                <button onClick={() => setViewType(2)}>
                  <MdOutlinePhotoSizeSelectActual />
                </button>
              )}
              {eventInfo?.todo.length > 0 && viewType !== 1 && (
                <button onClick={() => setViewType(1)}>
                  <LuListTodo />
                </button>
              )}
            </div>
          )}
        </GoToBackHeader>
        {eventInfo && (
          <>
            <MobileEventContentBox>
              <div
                className="eventContentWrapper"
                style={{ transform: `translateX(${transformValue}%)` }}>
                <div className="eventContent">
                  <MobileEventDetailView
                    item={eventInfo}
                    isDday={isDday}
                    todos={todos}
                    getTodos={getTodos}
                  />
                </div>
                <div className="eventContent">
                  <MobileEventGallery
                    eventInfo={eventInfo}
                    setIsDetail={setIsDetail}
                    setImageOrder={setImageOrder}
                    handleContextMenu={handleContextMenu}
                    isRelatedPerson={isRelatedPerson}
                    originalImages={originalImages}
                    setOriginalImages={setOriginalImages}
                    setImages={setImages}
                    images={images}
                    deleteImages={deleteImages}
                    getImages={getImages}
                  />
                </div>
              </div>
            </MobileEventContentBox>
            <EventActions />
          </>
        )}
        {showMenu && (
          <DropdownMenu x={menuPosition.x} y={menuPosition.y} deleteImages={deleteImages} />
        )}
        {isDetail && <ImageDetailViewer images={imageOrder} setIsDetail={setIsDetail} />}
        {isSelectedScope && (
          <SelectedScope
            type="delete"
            item={eventInfo}
            setItem={setEventInfo}
            isModalOpen={setIsSelectedScope}
            submit={handleDelete}
          />
        )}
      </DetailEventContainer>
    </>
  );
};

export default DetailEvent;
