import { useState } from "react";
import { useOutletContext } from "react-router";

import { ChicNoticeContainer } from "../shopping/component/styled/chicTheme";
import { ModernNoticeContainer } from "../shopping/component/styled/modernTheme";
import { CuteNoticeContainer } from "../shopping/component/styled/cuteTheme";
import { SmNoticeContainer } from "../shopping/component/styled/smTheme";
import { SimpleNoticeContainer } from "../shopping/component/styled/simpleTheme";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";

import NoticeForm from "./component/noticeForm";
import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";
import SmBanner from "../shopping/component/themes/sm/banner";
import TabMenu from "../company/component/sm/tabMenu";
import Faq from "./component/faq";
import Help from "./component/help";
import ChicBanner from "../shopping/component/themes/chic/banner";

import { findTargetMenuName } from "../common/function/shopping";
import ImageDetailViewer from "../../../common/imageDetailViewer";
import ShoppingSectionSwiper from "../shoppingSectionSwiper";

const ShoppingNotice = () => {
  const { theme, menus } = useOutletContext();
  const [active, setActive] = useState(0);
  const [isDetail, setIsDetail] = useState(false);
  const [images, setImages] = useState([]);

  const menu = ["공지사항", "자주 묻는 질문", "1:1 문의"];
  const messages = [
    "에스엠정보통신의\n새로운 소식을 알려드립니다.",
    "고객님을 위한 정책과\n더 나은 서비스를 제공합니다.",
    "내용을 상세히 기재해 주시면\n보다 정확한 답변을 드릴 수 있습니다.",
  ];

  const components = [
    <NoticeForm />,
    <Faq />,
    <Help setImages={setImages} setIsDetail={setIsDetail} />,
  ];
  const banners = {
    0: <ShoppingSectionSwiper />,
    1: <ChicBanner info={{ imgage: "notice.png", text: "notice" }} />,
    2: <ModernBanner />,
    3: <CuteBanner />,
    4: <SimpleBanner />,
    sm: <SmBanner info={{ page: menu[active], message: messages[active] }} />,
  };

  const containers = {
    0: DefaultContaienr,
    1: ChicNoticeContainer,
    2: ModernNoticeContainer,
    3: CuteNoticeContainer,
    4: SimpleNoticeContainer,
    sm: SmNoticeContainer,
  };

  const renderComponent = () => {
    const Container = containers[theme] || DefaultContaienr;
    const Banner = banners[theme];

    return (
      <Container>
        {Banner}
        {theme !== 1 && (
          <h2 className="font_36 pdt_70 font600 text_center">
            {findTargetMenuName(menus, "공지사항")}
          </h2>
        )}
        <TabMenu setActive={setActive} active={active} menus={menu} />
        {components[active]}
        {isDetail && <ImageDetailViewer images={images} setIsDetail={setIsDetail} />}
      </Container>
    );
  };

  return <>{renderComponent()}</>;
};

export default ShoppingNotice;
