import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import { EventGalleryContainer } from "../../styled/scheduler";

import DragToUpload from "./eventGallery/dragToUpload";
import DropImageCollection from "./eventGallery/dropImageCollection";

const EventGallery = ({
  eventInfo,
  images,
  setImages,
  uploadImages,
  originalImages,
  setOriginalImages,
  getImages,
}) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const isRelatedPerson =
    eventInfo.gbn === "P" ||
    eventInfo.managerNo === user.mberNo ||
    user.mberNo in (eventInfo.workerList ?? {});

  useEffect(() => {
    getImages();
  }, []);

  // 파일 추가
  const handleFilesAdded = useCallback((files) => {
    if (!isRelatedPerson) {
      toast.error("사진 업로드 권한이 없습니다.");
      return;
    }

    if (files.length) {
      const imgArr = [];
      let loadedCount = 0;

      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();

        reader.onload = (event) => {
          imgArr[i] = { path: event.target.result };
          loadedCount++;
          files[i].path = event.target.result;

          if (loadedCount === files.length) {
            setOriginalImages((prev) => [...prev, ...files]);
            setImages((prev) => [...prev, ...imgArr.filter(Boolean)]);
          }
        };
        reader.readAsDataURL(files[i]);
      }
    }
  }, []);

  // 사진 삭제
  const deleteImages = async (item) => {
    const isEncodedImage = item.path.startsWith("data:image");
    const image = item.path;

    if (isEncodedImage) {
      setImages(images.filter((img) => img.path !== image));
      setOriginalImages(originalImages.filter((img) => img.path !== image));
    } else {
      const url = "/api/photoDelete";
      const body = { photoNos: item.photoNo };
      const res = await axios.post(url, body);
      if (res.data.length) {
        getImages();
      }
    }
    toast.success("사진이 삭제되었습니다.");
    setShowMenu(false);
    setSelectedImage(null);
  };

  return (
    <EventGalleryContainer>
      <DropImageCollection
        images={images}
        deleteImages={deleteImages}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
        isRelatedPerson={isRelatedPerson}
      />
      <DragToUpload onFilesAdded={handleFilesAdded} />
      <div className="gallery btnArea">
        <button onClick={() => uploadImages()}>저장</button>
      </div>
    </EventGalleryContainer>
  );
};

export default EventGallery;
