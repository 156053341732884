import { useSelector } from "react-redux";
import { HomepageEndTitleContainer } from "../styled/homepageEnd";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";

export default function HomepageEndTitle() {
  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);
  const company = useSelector((state) => state?.company?.data);
  const [session, setSession] = useState();
  const [selectedCompany, setSelectedCompany] = useState();

  useEffect(() => {
    const session = JSON.parse(sessionStorage.getItem("homepageCompany"));
    const target = company.find((cmpny) => cmpny.cmpnyNo === session.cmpnyNo);

    if (!session) {
      console.log("선택된 회사가 존재하지 않습니다.");
      navigate("/mypage");
    } else {
      setSession(session);
    }

    if (target) {
      setSelectedCompany(target);
    }
  }, []);

  return (
    <HomepageEndTitleContainer className={setting?.mode}>
      <p>
        <strong>{user?.name}</strong>님<br />
        홈페이지 설정이 완료되었습니다.
        <br />
      </p>
      <span>지금 접속하여 회사소개, 제품 등 수정된 정보를 확인해보세요.</span>
      <ul>
        <li>
          <span>유형</span>
          <span>{selectedCompany?.cmpnySe || selectedCompany?.bizcnd}</span>
        </li>
        <li>
          <span>사업자번호</span>
          <span>{selectedCompany?.bizrno}</span>
        </li>
        <li>
          <span>회사이름</span>
          <span>{selectedCompany?.cmpnyNm}</span>
        </li>
        <li>
          <span>도메인설정</span>
          <span>
            <a href={`https://1472.ai/shop/${session?.domain}`}>
              https://1472.ai/shop/{session?.domain}
            </a>
          </span>
        </li>
      </ul>

      <div className="buttons">
        <a href={`https://1472.ai/shop/${session?.domain}`}>
          <span>홈페이지 바로가기</span>
        </a>
        <a href="/mypage">
          <span>대시보드 바로가기</span>
        </a>
      </div>
    </HomepageEndTitleContainer>
  );
}
