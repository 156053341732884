import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { HiMiniEllipsisVertical } from "react-icons/hi2";
import { IoSettingsSharp } from "react-icons/io5";

const Homepage = ({ label, className, handleButtonClick }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  const navigate = useNavigate();

  const [domain, setDomain] = useState([]);
  const [companyInfo, setCompanyInfo] = useState({});

  useEffect(() => {
    checkedDomain();
  }, []);

  const checkedDomain = async () => {
    const url = "/api/domainSettingLoad";

    const promises = company.map((item) => {
      const body = { cmpnyNo: item.cmpnyNo, delYn: "N" };
      return axios.post(url, body);
    });

    try {
      const res = await Promise.all(promises);
      const data = res.filter((item) => item.data.delYn === "N").map((item) => item.data);

      setDomain(data);
    } catch (error) {
      console.log(error);
    }
  };

  const openNewTab = (item) => {
    window.open(`http://1472.ai/shop/${item}`);
    user && localStorage.setItem("user", JSON.stringify(user));
  };

  const navSettingPage = (item) => {
    sessionStorage.setItem("homepageCompany", JSON.stringify({ ...item }));
    navigate("/mypage/homepageSetting");
  };

  return (
    <div>
      <div className="info">
        <span className="label">{label}</span>
        <button data-name={className} onClick={handleButtonClick}>
          <HiMiniEllipsisVertical />
        </button>
      </div>
      <ul>
        {domain &&
          domain.length > 0 &&
          domain.map((item) => (
            <li>
              <span className="cmpny" onClick={() => openNewTab(item.domain)}>
                https://1472.ai/shop/{item.domain}
              </span>
              <button onClick={() => navSettingPage(item)}>
                <IoSettingsSharp />
              </button>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default Homepage;
