import React from "react";
import { calcRewardAvailable, isPlatform, resStatus, sales } from "../function/kiosk";
import { useRegex } from "../../../../../hooks/useRegex";
import moment from "moment";

export const useFieldDefinitions = (type) => {
  const { TelFormat } = useRegex();

  const allField = {
    index: { label: "예약번호", render: ({ id }) => <span>{id}</span> },

    name: { label: "이름", render: ({ mberNm }) => <span>{mberNm}</span> },
    tel: { label: "전화번호", render: ({ tel }) => <span>{TelFormat(tel)}</span> },
    moblphonNo: {
      label: "전화번호",
      render: ({ moblphonNo }) => <span>{TelFormat(moblphonNo)}</span>,
    },
    platform: { label: "플랫폼", render: ({ os }) => <span>{isPlatform(os)}</span> },
    payType: {
      label: "결제방식",
      render: ({ payType }) => <span>{sales[payType]?.label || ""}</span>,
    },
    duration: {
      label: "보관날짜",
      render: ({ reservationDay }) => <span>{reservationDay || ""}</span>,
    },
    time: {
      label: "보관시작시간",
      render: ({ reservationStartTime }) => <span>{reservationStartTime || ""}</span>,
    },
    memo: { label: "메모", render: ({ memo }) => <span>{memo || ""}</span> },
    price: {
      label: "가격",
      render: ({ price }) => <span>{`${price?.toLocaleString() || 0}원`}</span>,
    },
    status: {
      label: "보관상태",
      render: ({ reservationStatus }) => (
        <span
          className="status"
          style={{
            color: resStatus[reservationStatus]?.color || "",
            borderColor: resStatus[reservationStatus]?.color || "",
          }}>
          {resStatus[reservationStatus]?.label || ""}
        </span>
      ),
    },
    stamps: {
      label: "스탬프개수",
      render: ({ stampCount }) => <span>{`${stampCount?.toLocaleString() || 0}개`}</span>,
    },
    rewardCount: {
      label: "지급횟수",
      render: ({ rewardCount }) => <span>{`${rewardCount?.toLocaleString() || 0}개`}</span>,
    },
    rewardCount: {
      label: "지급횟수",
      render: ({ rewardCount }) => <span>{`${rewardCount?.toLocaleString() || 0}개`}</span>,
    },
    rewardAvailable: {
      label: "교환가능개수",
      render: ({ rewardCount, stampCount }) => (
        <span>{`${calcRewardAvailable(rewardCount, stampCount)}개`}</span>
      ),
    },
  };

  const fieldConfig = {
    default: ["index", "name", "tel", "platform", "payType", "duration", "time", "memo"],
    resStatus: ["index", "name", "tel", "platform", "payType", "price", "status", "time", "memo"],
    coupon: ["name", "moblphonNo", "stamps", "rewardCount", "rewardAvailable"],
  };

  const fields = fieldConfig[type] || fieldConfig.default;
  return fields.map((key) => ({ key, ...allField[key] }));
};

export const DataRenderer = ({ data, fieldDefinitions, handleModalOpen, type, renderRow }) => {
  if (!data || !data.length) {
    return <li className="notUser">사용자가 존재하지 않습니다.</li>;
  }

  return <>{data.map((item) => renderRow({ item, fieldDefinitions, handleModalOpen, type }))}</>;
};
