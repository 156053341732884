import React from "react";
import { SmChartContainer } from "../../../shopping/component/styled/smTheme";

const organizationData = {
  name: "(주)에스엠정보통신\n대표",
  children: [
    {
      name: "부사장",
      children: [
        {
          name: "브라더컴퍼니\n대표",
          children: [
            {
              name: "체인사업부",
              children: [
                { name: "충만치킨&충남지사" },
                { name: "마초부엌&섬섬옥수" },
                { name: "SM할인마트" },
                { name: "동소예" },
              ],
            },
          ],
        },
        {
          name: "상무이사",
          children: [
            {
              name: "POS 영업관리부",
              children: [{ name: "POS영업1팀" }, { name: "POS영업2팀" }, { name: "POS영업3팀" }],
            },
            {
              name: "고객지원부",
              children: [{ name: "SM.COM" }, { name: "고객지원팀" }],
            },
            {
              name: "전략기획부",
              children: [
                { name: "스마트컴(무인주차관리)" },
                { name: "CCTV설치 및 관리" },
                { name: "배달콜대행관리" },
              ],
            },
          ],
        },
      ],
    },
  ],
};

const CompanyChart = () => {
  const renderTree = (node) => {
    return (
      <li key={node.name}>
        <span className={node.name.includes("대표") ? "emphasize" : ""}>
          {node.name && node.name.includes("\n")
            ? node.name.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : node.name}
        </span>
        {node.children && <ul>{node.children.map((child) => renderTree(child))}</ul>}
      </li>
    );
  };

  return (
    <SmChartContainer>
      <div className="content">
        <div className="tree">
          <ul>{renderTree(organizationData)}</ul>
        </div>
      </div>
    </SmChartContainer>
  );
};

export default CompanyChart;
