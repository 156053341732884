import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MdMenu } from "react-icons/md";
import { useMediaQuery } from "react-responsive";

import { HeaderContainer } from "./styled/common";

import SideMenu from "./sideMenu";
import AuthButtons from "./header/authButtons";
import Navigation from "./header/navigation";
import TooltipSideMenu from "./tooltipSideMenu";
import useScrollLock from "../hooks/useScrollLock";

import { onClickLogout } from "../store/user";
import { resetSetting } from "../store/scheduler";
import { resetCompany } from "../store/company";
import { useModal } from "../hooks/useModal";

const Header = ({ type = "" }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const [clickSideMenu, setClickSideMenu] = useState(false);

  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const { modal, openModal, closeModal } = useModal();

  const menuItems = [
    {
      title: "서비스",
      link: "#",
      items: [
        { link: user ? "/group" : "/info/group", label: "모임" },
        { link: user ? "/mypage/scheduler" : "/info/scheduler", label: "캘린더" },
        { link: user ? "/mypage/projectList" : "/info/project", label: "프로젝트" },
        { link: user ? "/mypage/homepage" : "/info/shop", label: "e층창고" },
        { link: "/info/subscribe", label: "구독" },
      ],
    },
    {
      title: "공지/안내",
      link: "/notice",
      items: [
        { link: "/notice", label: "공지사항" },
        { link: "/releaseNotes", label: "개발자 노트" },
      ],
    },
    {
      title: "고객센터",
      link: "",
      items: [
        { link: "/faq", label: "자주하는 질문" },
        { link: "/help", label: "1:1 문의" },
      ],
    },
  ];

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const extracted = () => {
    if (pathname.includes("info")) {
      return "info " + pathname.split("/info/")[1] || "";
    }
    if (pathname.includes("group")) {
      return "group";
    }
    if (pathname.includes("mypage") && setting) {
      return `mypage ${setting?.mode ? setting.mode : "white"}`;
    }
    if (type) {
      return type;
    }
    return "";
  };

  useScrollLock(clickSideMenu);
  useEffect(() => {
    if (clickSideMenu && isTablet) {
      document.documentElement.style.height = "100vh";
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.height = "auto";
      document.documentElement.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [clickSideMenu]);

  const onClickModalCheck = async () => {
    await dispatch(onClickLogout());
    await dispatch(resetSetting());
    await dispatch(resetCompany());
    navigate("/login");
    closeModal();
  };

  const handleLogout = () => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `로그아웃`,
      message: ["로그아웃하시겠습니까?"],
      handleConfirm: () => onClickModalCheck(),
      handleCancel: () => closeModal(),
    });
  };

  return (
    <>
      {modal}
      <HeaderContainer className={extracted()}>
        <div className="headerInner">
          <h1>{service.welcome}</h1>
          <Link to="/" className="logo">
            <div>MONOTI</div>
          </Link>
          {!isTablet ? (
            <>
              <Navigation menuItems={menuItems} setClickSideMenu={setClickSideMenu} />
              <div className="headerBtnArea">
                <AuthButtons
                  type={type}
                  handleLogout={handleLogout}
                  setClickSideMenu={setClickSideMenu}
                />
              </div>
            </>
          ) : (
            <button onClick={() => setClickSideMenu(true)} className="sideMenu">
              <MdMenu />
            </button>
          )}
        </div>
        {clickSideMenu && !isTablet ? (
          <TooltipSideMenu setClickSideMenu={setClickSideMenu} clickSideMenu={clickSideMenu} />
        ) : (
          <SideMenu
            handleLogout={handleLogout}
            setClickSideMenu={setClickSideMenu}
            clickSideMenu={clickSideMenu}
            menuItems={menuItems}
            extracted={extracted}
          />
        )}
      </HeaderContainer>
    </>
  );
};
export default Header;
