import React from "react";
import moment from "moment";

const TableMember = ({ group, item, handleAuthChange, handleDeleteChange }) => {
  const authOptions = [
    { value: 0, label: "마스터" },
    { value: 1, label: "부방장" },
    { value: 2, label: "간부" },
    { value: 3, label: "행동대장" },
    { value: 4, label: "일반" },
  ];

  return (
    <tr key={item.mberNo}>
      <td>{group.nickUseYn === "Y" && item.nickNm ? item.nickNm : "닉네임 미설정"}</td>
      <td>{moment(item.createDt).format("YYYY-MM-DD HH:mm")}</td>
      <td>
        <select value={item.auth} onChange={(e) => handleAuthChange(item, e.target.value)}>
          {authOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </td>
      <td>
        <button onClick={() => handleDeleteChange(item)}>삭제</button>
      </td>
    </tr>
  );
};

export default TableMember;
