import React from "react";
import { ContextMenuContainer } from "../styled/scheduler";
import { useNavigate } from "react-router";
import moment from "moment";

const ContextMenu = ({ context, setContext }) => {
  const navigate = useNavigate();

  const dateTime = (day) => {
    const [date] = day.split(" ");
    const now = moment().format("HH:30:00");
    return `${date} ${now}`;
  };

  const clickAddEvent = (isNotDday = true) => {
    setContext({ x: 0, y: 0, date: "" });
    navigate(`/mypage/${isNotDday ? "calendarEntry" : "ddayEntry"}`, {
      state: {
        eventInfo: {
          startDt: moment(dateTime(context.date)).startOf("hour").format("YYYY-MM-DD HH:30:00"),
          endDt: moment(dateTime(context.date))
            .startOf("hour")
            .add(1, "hour")
            .format("YYYY-MM-DD HH:30:00"),
          pushMinute: 60,
        },
      },
    });
  };

  return (
    <ContextMenuContainer
      onClick={(e) => e.target === e.currentTarget && setContext({ x: 0, y: 0, date: "" })}>
      <div style={{ top: context.y, left: context.x }}>
        <button onClick={() => clickAddEvent()}>일정추가</button>
        <button onClick={() => clickAddEvent(false)}>디데이추가</button>
      </div>
    </ContextMenuContainer>
  );
};

export default ContextMenu;
