import React from "react";
import { MessageContainer } from "./styled/common";
import { useNavigate } from "react-router";

const Message = ({ url, logo }) => {
  const navigate = useNavigate();

  const companyLogo = () => {
    if (!logo || !url)
      return <img src="/img/logo.png" onClick={() => navigate("/")} className="monoti" />;
    if (logo)
      return (
        <img src={imgHandler(logo)} onClick={() => (url ? navigate("/") : null)} className="shop" />
      );
  };

  return (
    <MessageContainer>
      {companyLogo()}
      <span>
        회원이 되시면 더 많은 혜택을
        <br />
        경험할 수 있습니다
      </span>
    </MessageContainer>
  );
};

export default Message;
