import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { StoreReviewsContainer } from "../styled/review";
import ReviewItem from "./reviewItem";
import DetailReview from "./detailReview";
import { FaChevronLeft } from "react-icons/fa";
import Rating from "./rating";
import moment from "moment";
import ImageDetailViewer from "../../../../../common/imageDetailViewer";

const StoreReviews = ({ selectCompany }) => {
  const [reviews, setReviews] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [viewType, setViewType] = useState(0);
  const [selected, setSelected] = useState();
  const [requestedPages, setRequestedPages] = useState([]);
  const [content, setContent] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [imageOrder, setImageOrder] = useState([]);
  const [isDetail, setIsDetail] = useState(false);
  const [products, setProducts] = useState([]);

  const transformValue = viewType * -100;
  const itemsPerPage = 5;

  useEffect(() => {
    resetPage();

    if (!selectCompany) return;
    getReviews(page);

    const observer = new IntersectionObserver(handleObserver, { threshold: 0.5 });
    const observerTarget = document.getElementById("observer");
    if (observerTarget) {
      observer.observe(observerTarget);
    }
    return () => {
      observer.disconnect();
    };
  }, [selectCompany]);

  useEffect(() => {
    const totalPages = Math.ceil(total / itemsPerPage);
    if (page > totalPages || !totalPages) return;
    if (requestedPages.includes(page)) return;

    getReviews(page);
  }, [page]);

  useEffect(() => {
    if (selected) {
      setViewType(1);
      getOrder(selected.orderId);
    } else {
      setViewType(0);
      setProducts([]);
    }
  }, [selected]);

  const resetPage = () => {
    setReviews([]);
    setPage(1);
    setTotal(0);
    setSelected(null);
    setRequestedPages([]);
    setIsDetail(false);
    setImageOrder([]);
  };

  // 주문 상세보기
  const getOrder = async (id) => {
    const query = `
        query SsOrder($id: ID!) {
          ssOrder(id: $id) {
              id
              products {
                  productId
                  quantity
                  price
                  name
                  media
              }
          }
        }
    `;
    const variables = { id };

    try {
      const res = await axios.post(
        "/erp/ss/order",
        { query, variables },
        {
          headers: { "Content-Type": "application/json" },
        },
      );

      console.log(res);
      setProducts(res.data.products);
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const getReviews = async (currentPage = 1) => {
    if (requestedPages.includes(page)) return;
    setRequestedPages((prev) => [...prev, page]);

    const url = "/erp/ss/reviews";
    const body = { cmpnyNo: selectCompany.value, page: currentPage, pageSize: itemsPerPage };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      setReviews((prev) => [...prev, ...res.data.reviews]);
      setTotal(res.data.totalCount);
    }
  };

  const getDetailReview = async (reviewId) => {
    const url = "/erp/ss/review";
    const body = { reviewId };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      return res.data;
    }
  };

  const clickReview = async (id) => {
    const detail = await getDetailReview(id);

    setSelected(detail || null);
    setContent(detail?.ownerReply?.content?.replace(/\\n/g, "\n") || "");
    setIsEdit(false);
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((page) => page + 1);
    }
  };

  const handleImageClick = (images, index) => {
    setIsDetail(true);

    const list = images.map((item) => ({ path: item }));

    const reorderedImages = [...list.slice(index, list.length), ...list.slice(0, index)];

    setImageOrder(reorderedImages);
  };

  const updateReview = (id, ownerReply) => {
    setReviews((data) => data.map((item) => (item.id === id ? { ...item, ownerReply } : item)));
  };

  return (
    <StoreReviewsContainer>
      <div className="slideWrapper">
        <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
          <ul className="reviews">
            {reviews.length > 0 ? (
              reviews.map((item) => <ReviewItem item={item} clickReview={clickReview} />)
            ) : (
              <li className="notReviews">리뷰가 존재하지 않습니다.</li>
            )}
            <li id="observer" style={{ height: "10px" }}></li>
          </ul>
        </div>
        <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
          <DetailReview
            selected={selected}
            setSelected={setSelected}
            clickReview={clickReview}
            content={content}
            setContent={setContent}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            handleImageClick={handleImageClick}
            updateReview={updateReview}
            products={products}
          />
        </div>
      </div>

      {isDetail && <ImageDetailViewer images={imageOrder} setIsDetail={setIsDetail} />}
    </StoreReviewsContainer>
  );
};

export default StoreReviews;
