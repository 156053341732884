import axios from "axios";
import React, { useEffect, useState } from "react";
import { HiMiniEllipsisVertical } from "react-icons/hi2";
import { useSelector } from "react-redux";
import CompanySwiper from "./swiper/companySwiper";
import { useMediaQuery } from "react-responsive";

const Company = ({ label, className, handleButtonClick }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  return (
    <div>
      <div className="info">
        <span className="label">{label}</span>
        <button data-name={className} onClick={handleButtonClick}>
          <HiMiniEllipsisVertical />
        </button>
      </div>
      <CompanySwiper data={company} perView={isMobile ? 1.5 : 2.3} />
    </div>
  );
};

export default Company;
