import { useOutletContext, useParams } from "react-router";
import { useEffect, useState } from "react";
import axios from "axios";

import ProductsViewForm from "./component/productsViewForm";
import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";
import SmBanner from "../shopping/component/themes/sm/banner";
import ChicBanner from "../shopping/component/themes/chic/banner";

import { CuteProductsView } from "../shopping/component/styled/cuteTheme";
import { ModernProductsView } from "../shopping/component/styled/modernTheme";
import { SimpleProductsView } from "../shopping/component/styled/simpleTheme";
import { ChicProductsView } from "../shopping/component/styled/chicTheme";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";
import ShoppingSectionSwiper from "../shoppingSectionSwiper";

const ProductsView = () => {
  const { cmpnyNo, theme } = useOutletContext();
  const { prdNo } = useParams();

  const [product, setProduct] = useState();

  useEffect(() => {
    if (cmpnyNo) {
      getCmpnyPrductList();
    }
  }, [cmpnyNo]);

  const getCmpnyPrductList = async () => {
    try {
      const url = "/erp/vendorProducts/get";
      const query = `
      query VendorProducts(
        $id: ID
        $cmpnyNo: Int!
      ) {
        vendorProducts(
          filter: {
            id: $id
            cmpnyNo: $cmpnyNo
          }
        ) {
          totalCount
          products {
            id
            cmpnyNo
            name            
            brand
            categoryId
            media            
            quantity
            price
          }
        }
      }
    `;

      const variables = { cmpnyNo: parseInt(cmpnyNo, 10), id: prdNo };
      const body = { query, variables };
      const res = await axios.post(url, body);

      if (res.status === 200) {
        setProduct(res.data.products?.[0]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const banners = {
    0: ShoppingSectionSwiper,
    1: ChicBanner,
    2: ModernBanner,
    3: CuteBanner,
    4: SimpleBanner,
    sm: SmBanner,
  };

  const containers = {
    0: DefaultContaienr,
    1: ChicProductsView,
    2: ModernProductsView,
    3: CuteProductsView,
    4: SimpleProductsView,
    sm: SimpleProductsView,
  };

  const renderContent = () => {
    const getBannerInfo = (theme) => {
      if (theme === "sm") {
        return {
          page: "제품소개",
          message: "에스엠정보통신의 제품을\n 소개해드립니다.",
        };
      }
      return { imgage: "prd.png", text: "product" };
    };

    const Container = containers[theme] || DefaultContaienr;
    const Banner = banners[theme];

    return (
      <Container>
        {Banner && <Banner info={getBannerInfo(theme)} />}
        <ProductsViewForm product={product} />
      </Container>
    );
  };

  return <>{renderContent()}</>;
};

export default ProductsView;
