import React, { useEffect, useState } from "react";
import { HelpButton, HelpList, PageContainer } from "./component/styled/notice";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import Pagination from "react-js-pagination";

import PageInfo from "./component/common/pageInfo";
import Inquiry from "./component/helpPage/inquiry";
import ImageDetailViewer from "../../common/imageDetailViewer";

import { useModal } from "../../hooks/useModal";

const HelpPage = () => {
  const user = useSelector((state) => state?.user?.data[0]);

  const navigate = useNavigate();

  const { modal, openModal, closeModal } = useModal();

  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState(null);
  const [inquiries, setInquiries] = useState([]);
  const [total, setTotal] = useState(0);
  const [detailInquiries, setDetailInquiries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!user) {
      toast.error("로그인 후 이용 가능합니다.");
      navigate("/login");
    } else {
      getInquiries();
    }
  }, []);

  // 문의 목록 조회
  const getInquiries = async (page = 1) => {
    if (loading) return;

    const url = "/erp/inquiryList";
    const body = { mberNo: user.mberNo, cmpnyNo: 16421, page: page, limit: 10 };

    try {
      setLoading(true);
      const res = await axios.post(url, body);
      console.log(res);
      setInquiries(res.data.inquiry || []);
      setTotal(res.data.totalCount || 0);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 문의 상세 조회
  const getInquiryDetail = async (id) => {
    if (loading) return;

    const url = "/erp/inquiry";
    const body = { id };

    try {
      setLoading(true);
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 문의 토글 선택
  const selectItem = async (id) => {
    if (!id) return;
    if (selected && selected.id === id) {
      setSelected(null);
    } else {
      const target = detailInquiries.find((inq) => inq && inq.id === id);
      if (target) {
        setSelected(target);
      } else {
        const detail = await getInquiryDetail(id);
        setSelected(detail);
        setDetailInquiries((data) => [...data, detail].filter(Boolean));
      }
    }
  };

  // 문의 삭제 버튼 클릭
  const handleDeleteInquiry = () => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `문의 삭제`,
      message: [`해당 문의글을 삭제하시겠습니까?`],
      handleConfirm: () => deleteInquiry(),
      handleCancel: () => closeModal(),
    });
  };

  // 삭제
  const deleteInquiry = async () => {
    if (loading) return;
    const url = "/erp/inquiry/delete";
    const body = {
      id: selected.id,
      mberNo: user.mberNo,
    };

    try {
      setLoading(true);
      const res = await axios.post(url, body);
      if (res.status === 200) {
        if (page !== 1) {
          setPage(1);
        }
        getInquiries();
        setSelected(null);
        closeModal();
        toast.success("문의글이 삭제되었습니다.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 페이지 변경 시
  const changePage = async (newPage) => {
    setPage(newPage);
    await getInquiries(newPage);
    setSelected(null);
  };

  return (
    <PageContainer>
      {modal}
      <PageInfo title="1:1 문의" content={"모노티의 다양한 소식을 알려드립니다."} />
      <HelpButton>
        <button onClick={() => navigate("/help/write")}>문의작성</button>
      </HelpButton>
      <HelpList>
        <li className="header">
          <div className="tit">제목</div>
          <div className="status">답변상태</div>
          <div className="date">등록일</div>
          <div className="toggle"></div>
        </li>
        {inquiries && inquiries.length > 0 ? (
          inquiries.map((item) => (
            <Inquiry
              item={item}
              selected={selected}
              selectItem={selectItem}
              handleDeleteInquiry={handleDeleteInquiry}
              setIsDetail={setIsDetail}
              setImages={setImages}
            />
          ))
        ) : (
          <li className="not">문의 내역이 존재하지 않습니다.</li>
        )}
      </HelpList>
      <Pagination
        activePage={page}
        itemsCountPerPage={10}
        totalItemsCount={total}
        pageRangeDisplayed={3}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={(page) => changePage(page)}
      />
      {isDetail && <ImageDetailViewer images={images} setIsDetail={setIsDetail} />}
    </PageContainer>
  );
};

export default HelpPage;
