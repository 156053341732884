import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";

import { SideMenuContainer } from "../styled/scheduler";
import { createDailyEvent } from "../function/common";

import Calendar from "./sideMenu/calendar";
import Choice from "./sideMenu/choice";
import Actions from "./sideMenu/actions";

export default function SideMenu({
  data,
  sideCalendarRef,
  userDate,
  setUserDate,
  toggleSideMenu,
  toggleAgendaContent,
  handleDateClick,
  onDate,
}) {
  const [dataByDate, setDataByDate] = useState([]);
  const [version, setVersion] = useState(0);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  useEffect(() => {
    const newEvents = createDailyEvent(data);

    setDataByDate(newEvents);
    setVersion((v) => v + 1);
  }, [data]);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [isAnnualCalendar, setIsAnnualCalendar] = useState(false);

  const clickDate = (info) => {
    handleDateClick(info.date);
    toggleSideMenu(false);
    toggleAgendaContent(true);
  };

  return (
    <SideMenuContainer
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          toggleSideMenu(false);
        }
      }}
      className={`sidemenu ${isDarkMode}`}
      style={{ transform: `translateX(${isMobile ? "-150%" : "0"})` }}>
      <div className="wrap">
        <Actions />
        <Calendar
          isAnnualCalendar={isAnnualCalendar}
          setIsAnnualCalendar={setIsAnnualCalendar}
          handleDateClick={handleDateClick}
          onDate={onDate}
          userDate={userDate}
          setUserDate={setUserDate}
          data={dataByDate}
          clickDate={clickDate}
          sideCalendarRef={sideCalendarRef}
          version={version}
        />
        <Choice />
      </div>
    </SideMenuContainer>
  );
}
