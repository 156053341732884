import React, { useEffect, useRef, useState } from "react";
import { ScheduleContainer } from "../styled/group";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router";

const Schedule = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user?.data[0]);
  const { mySchedSet } = useSelector((state) => state.scheduler);

  const swiperRef = useRef();

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [data, setData] = useState([]);

  useEffect(() => {
    if (user) {
      getSchedule();
    }
  }, []);

  const getSchedule = async () => {
    try {
      const url = "/api/scheduleList";
      const body = {
        mberNo: user.mberNo,
        showPersonalYn: "N",
        delYn: "N",
        cmpnyNos: [],
        groupNos: mySchedSet.groupYnList.map((grp) => grp.orgNo),
      };
      const res = await axios.post(url, body);
      if (res.status === 200) {
        console.log(res);
        setData([...(res.data || [])].sort((a, b) => b.scheduleNo - a.scheduleNo));
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <ScheduleContainer>
      <span className="label">나의 모임 일정 😀</span>
      <Swiper
        className="mySwiper"
        ref={swiperRef}
        slidesPerView={data && data.length > 0 ? (isMobile ? 1.5 : isTablet ? 2.4 : 3.5) : 1}
        spaceBetween={isMobile ? 20 : 50}>
        {data && data.length > 0 ? (
          data.map((item, i) => (
            <SwiperSlide key={i}>
              <div
                className="content"
                onClick={() => navigate(`/mypage/detailEvent/${item.scheduleNo}`)}>
                <span>{item.title}</span>
                <span>{item.completeYn === "Y" ? "완료" : "미완료"}</span>
                <span>{item.orgNm}</span>
                <span>{moment(item.startDt).format("YYYY.MM.DD")}</span>
              </div>
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide>
            <div
              className="not"
              onClick={() => navigate(user ? "/mypage/calendarEntry" : "/login")}>
              <span>모임 일정을 만들어보세요!</span>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </ScheduleContainer>
  );
};

export default Schedule;
