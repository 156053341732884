import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import axios from "axios";
import Modal from "../../../../../../common/modal";
import { useSelector } from "react-redux";
import { EditProfileContainer } from "../../../styled/groupDetails";

const EditProfile = ({ group, member, setActive, openModal, closeModal, getGroupMembers }) => {
  const { id } = useParams();

  const user = useSelector((state) => state?.user?.data[0]);

  const [formData, setFormData] = useState({
    nickNm: member?.nickNm || "",
    gbnValue: member?.gbnValue || "",
  });
  const [isDuplNickNm, setIsDuplNickNm] = useState(false);
  const [loading, setLoading] = useState(false);

  // 닉네임 중복 체크
  const chkDuplNickNm = async () => {
    if (!formData.nickNm || !formData.nickNm.trim()) {
      toast.error("닉네임을 입력해주세요.");
      return;
    }
    if (formData.nickNm.length < 2 || formData.nickNm.length > 12) {
      toast.error("닉네임의 길이는 2~12자리여야 합니다.");
      return;
    }

    const url = "/api/duplNickName";
    const body = {
      groupNo: id,
      delYn: "N",
      nickNm: formData.nickNm,
    };

    const res = await axios.post(url, body);
    if (res.data.success) {
      toast.success("사용 가능한 닉네임입니다.");
      setIsDuplNickNm(true);
    } else {
      toast.error("이미 사용 중인 닉네임입니다.");
      setIsDuplNickNm(false);
    }
  };

  // 닉네임 변경
  const changeInput = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));

    if (name === "nickNm") {
      setIsDuplNickNm(false);
    }
  };

  const checkValidation = () => {
    if (group?.gbn && !formData.gbnValue) {
      toast.error("기수를 입력해주세요.");
      return false;
    }
    if (member.nickNm !== formData.nickNm && !isDuplNickNm) {
      toast.error("닉네임 중복 확인을 해주세요.");
      return false;
    }
    if (loading) {
      toast.error("수정이 진행 중입니다.");
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (!checkValidation()) {
      return;
    }

    openModal({
      isOpen: true,
      type: "confirmation",
      title: `정보 수정`,
      message: [" 프로필을 수정하시겠습니까?"],
      handleConfirm: () => handleConfirm(),
      handleCancel: () => closeModal(),
    });
  };

  const handleConfirm = async () => {
    setLoading(true);

    const url = "/api/groupMber";
    const body = {
      ...member,
      groupNo: id,
      delYn: "N",
      mberNo: user.mberNo,
      nickNm: formData.nickNm,
      gbnValue: formData.gbnValue,
      createMberId: user.userName,
      updateMberId: user.userName,
    };
    try {
      const res = await axios.put(url, body);

      if (res.status === 200) {
        await getGroupMembers(group);
        toast.success("프로필이 변경되었습니다.");
        closeModal();
        setActive(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <EditProfileContainer>
      <div className="form">
        {group.gbn && (
          <div className="gbn">
            <input type="text" name="gbnValue" value={formData.gbnValue} onChange={changeInput} />
            <span>{group.gbn}</span>
          </div>
        )}
        <div className="nickNm">
          <input type="text" name="nickNm" value={formData.nickNm} onChange={changeInput} />
          <button onClick={chkDuplNickNm}>중복확인</button>
        </div>
      </div>
      <button onClick={handleSubmit} className="submit">
        확인
      </button>
    </EditProfileContainer>
  );
};

export default EditProfile;
