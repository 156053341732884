import React from "react";
import { Table } from "../styled/common";
import { useFieldDefinitions, DataRenderer } from "./useFieldDefinitions";

const TableHeader = ({ fieldDefinitions }) => (
  <li>
    {fieldDefinitions.map(({ key, label }) => (
      <div key={key} className={key}>
        <span>{label}</span>
      </div>
    ))}
    <div className="actions"></div>
  </li>
);

const TableRow = ({ item, fieldDefinitions, handleModalOpen, type }) => (
  <li>
    {fieldDefinitions.map(({ key, render }) => (
      <div key={key} className={key}>
        {render(item)}
      </div>
    ))}
    <div className="actions">
      <button
        onClick={() =>
          handleModalOpen(
            ["resStatus", "coupon"].some((t) => type.includes(t)) ? item : "COMPLETED",
            item,
          )
        }
        className="statusButton">
        {type.includes("resStatus") ? "상세보기" : "관리"}
      </button>
    </div>
  </li>
);

const KioskTable = ({ data = [], type = [], handleModalOpen = null }) => {
  const fieldDefinitions = useFieldDefinitions(type);

  return (
    <Table
      className={type.includes("resStatus") ? "resStatus" : "pickUp"}
      style={{ height: data.length > 0 ? "auto" : "100%" }}>
      <TableHeader fieldDefinitions={fieldDefinitions} />
      <DataRenderer
        data={data}
        fieldDefinitions={fieldDefinitions}
        handleModalOpen={handleModalOpen}
        type={type}
        renderRow={TableRow}
      />
    </Table>
  );
};

export default KioskTable;
