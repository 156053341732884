import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Container } from "../styled/mypage";

import PageInfo from "../pageInfo";
import { useLocation, useNavigate } from "react-router";
import TabMenu from "./component/companyPage/tabMenu";
import axios from "axios";
import Members from "./component/companyPage/members";
import Answer from "./component/companyPage/answer";
import Management from "./component/companyPage/management";
import { useModal } from "../../../hooks/useModal";

const CompanyPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { modal, openModal, closeModal } = useModal();

  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);

  const [todayWeather, setTodayWeather] = useState(null);
  const [company, setCompany] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const cmpny = location?.state?.cmpny;

    if (!cmpny) {
      console.log("선택된 회사가 존재하지 않습니다.");
      navigate("/mypage");
    } else {
      setCompany(cmpny);
      // getCmpnyMber(0, cmpny.cmpnyNo);
    }

    getWeather();

    const initKakao = async () => {
      if (!Kakao.isInitialized()) {
        // Kakao.init('b9886faecde9faa67a51166600a9d107'); // 개발 환경에 맞는 API 키 사용
        Kakao.init("4970bf08e349ccf4b295296035944317"); // 운영 환경에 맞는 API 키 사용
      }
    };

    initKakao();
  }, []);

  const getWeather = async () => {
    const url = "/api/weather";
    const today = moment().format("YYYY-MM-DD");
    const req = {
      startDt: today,
      endDt: today,
    };
    const res = await axios.post(url, req);
    if (res.status === 200) {
      setTodayWeather(res.data[0]);
    }
  };

  const tabMenu = ["멤버관리", "1:1문의", "정보수정"];

  return (
    <Container className={setting.mode}>
      {modal}
      <div className="content">
        <PageInfo label={company?.cmpnyNm} todayWeather={todayWeather} />
        <TabMenu tabMenu={tabMenu} active={activeIndex} setActive={setActiveIndex} />
        {
          [
            <Members company={company} closeModal={closeModal} openModal={openModal} />,
            <Answer company={company} />,
            <Management company={company} />,
          ][activeIndex]
        }
      </div>
    </Container>
  );
};

export default CompanyPage;
