import { useState } from "react";
import "./board.css";
import { BoardWriteContainer } from "../styled/common";
import { useSelector } from "react-redux";

const BoardWrite = ({ selected, notice, selectList, titleRef, contentRef, setSelected }) => {
  const setting = useSelector((state) => state?.user?.setting);
  const handleSelect = (e) => {
    setSelected(e.target.value);
  };

  return (
    <BoardWriteContainer className={setting?.mode}>
      <ul>
        <li>
          <div className="writing_tit">제목</div>
          <div className="writing_cont">
            <input
              type="text"
              placeholder="제목을 입력하세요."
              defaultValue={notice?.noticeSj || ""}
              ref={titleRef}
              onChange={(e) => (titleRef.current.value = e.target.value)}
            />
          </div>
        </li>
        <li>
          <div className="writing_tit">카테고리</div>
          <div className="writing_cont">
            <select onChange={handleSelect} value={selected}>
              {selectList.map((item, index) => (
                <option value={item} key={index}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </li>
        <li>
          <div className="writing_tit">내용</div>
          <div className="writing_cont">
            <textarea
              defaultValue={notice?.noticeCn}
              ref={contentRef}
              onChange={(e) => (contentRef.current.value = e.target.value)}
            />
          </div>
        </li>
      </ul>
    </BoardWriteContainer>
  );
};

export default BoardWrite;
