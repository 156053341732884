import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import uuid from "react-uuid";
import axios from "axios";
import { toast } from "react-toastify";

import { RiArrowGoBackFill } from "react-icons/ri";

import { ProfileImages, ProjectDetailCommentContainer } from "../../../common/styled/project";

import ProjectDetailCommentItem from "./projectDetailCommentItem";
import ProjectDetailCommentNewItem from "./projectDetailCommentNewItem";

import { targetWebSocket } from "../../../common/function/project";

const ProjectDetailCommentList = ({
  project,
  selectedRoom,
  setSelectedRoom,
  webSockets,
  comments,
  handleImageClick,
  handleContextMenu,
  isReply,
  selectedComment,
  resetSelectComment,
  setRoomConnection,
  newComment,
  setNewComment,
  participant,
}) => {
  const { projectNo } = useParams();
  const user = useSelector((state) => state?.user?.data[0]);

  const textareaRef = useRef();
  const listRef = useRef();
  const MAX_LENGTH = 200;

  // 마지막 댓글로 스크롤
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [comments, isReply, newComment.updateFiles]);

  const isCADFile = (fileName) => {
    const cadExtensions = [
      ".dwg",
      ".dxf",
      ".dwt",
      ".dws",
      ".dgn",
      ".dwf",
      ".stl",
      ".igs",
      ".step",
      ".3ds",
      ".prt",
      ".sat",
      ".sldprt",
      ".sldasm",
      ".slddrw",
    ];

    return cadExtensions.some((ext) => fileName.toLowerCase().endsWith(ext));
  };

  // 이미지 파일만 Data URL로 변환
  const processFiles = async (files) => {
    let imgList = [];
    let fileList = [];

    const filePromises = Array.from(files).map(async (file) => {
      const filePath = await readFileAsDataURL(file);
      if (file.type.startsWith("image/") && !isCADFile(file.name)) {
        imgList.push({ fileNm: file.name, type: file.type, filePath, fileId: uuid() });
      } else {
        fileList.push({ fileNm: file.name, type: file.type, filePath, fileId: uuid() });
      }
    });

    await Promise.all(filePromises);

    return { imgList, fileList };
  };

  // 파일 업로드
  const updateFilesFromInput = async (e) => {
    const files = e.target.files;

    if (files) {
      const { imgList, fileList } = await processFiles(files);

      const updated = {
        imgList: [...newComment.imgList, ...imgList],
        fileList: [...newComment.fileList, ...fileList],
        updateFiles: [...newComment.updateFiles, ...imgList, ...fileList],
      };

      setNewComment((prev) => ({
        ...prev,
        ...updated,
      }));
    }
  };

  // 파일 서버 저장
  const uploadFilesToServer = async (files) => {
    try {
      let form = new FormData();
      files.forEach((file) => {
        let blob = dataURItoBlob(file.filePath);
        form.append("files", blob, `${file.fileNm}`);
      });
      form.append("ids", "N");
      form.append("mberNo", user.mberNo);

      const url = "/api/uploadFiles";
      const res = await axios.post(url, form);

      if (res.status === 200) {
        const responseData = res.data.responseData;
        const data = responseData.map((item, idx) => ({
          ...item,
          originalName: files[idx].fileNm,
        }));
        console.log(data);
        return data;
      }
    } catch (error) {
      console.error("업로드 중 오류 발생:", error);
    }
  };

  // 댓글 입력
  const handleContentChange = (e) => {
    const content = e.target.value;
    setNewComment((prevComment) => ({
      ...prevComment,
      content: content,
    }));

    handleResizeHeight();
  };

  // 댓글 파일 삭제
  const handleDeleteFile = (item) => {
    if (item?.projectFileNo) {
      // 서버에 저장된 파일
      setNewComment((prev) => ({
        ...prev,
        deleteFiles: [...prev.deleteFiles, item],
      }));
    } else {
      // 로컬
      const type = item.type.startsWith("image") ? "imgList" : "fileList";

      setNewComment((prev) => ({
        ...prev,
        [type]: prev[type].filter((file) => file.fileId !== item.fileId),
        updateFiles: prev.updateFiles.filter((file) => file.fileId !== item.fileId),
      }));
    }
  };

  // 댓글 등록
  const handleComment = async () => {
    if (textNotExist(newComment.content) && !newComment.updateFiles.length) {
      toast.error("내용이 존재하지 않습니다.");
      return;
    }

    const target = targetWebSocket(webSockets.current, selectedRoom);
    if (!target || !project) return;

    let update = { imgs: [], files: [], videos: [] };
    if (newComment.updateFiles.length > 0) {
      const uploaded = await uploadFilesToServer(newComment.updateFiles);

      uploaded.forEach((file) => {
        if (file.fileType.startsWith("image") && !isCADFile(file.fileType)) {
          update.imgs.push({ ...file, fileNm: file.originalName, filePath: file.fileName });
        } else if (file.fileType.startsWith("video")) {
          update.videos.push({ ...file, fileNm: file.originalName, filePath: file.fileName });
        } else {
          update.files.push({ ...file, fileNm: file.originalName, filePath: file.fileName });
        }
      });
    }

    const body = {
      content: newComment.content,
      req: {
        projectNo,
        gbn: project?.gbn,
        mberNo: user.mberNo,
        orgNo: project?.gbn === "C" ? participant.orgNo : project.orgNo,
        content: newComment.content,
        roomId: selectedRoom.roomId,
        createMberId: user.userName,
        updateMberId: user.userName,
        delYn: "N",
      },
      ...update,
    };

    if (selectedComment && isReply) {
      body.req.replyNo = selectedComment.projectCommentNo;
    }

    target.send(JSON.stringify(body));

    setNewComment({
      projectCommentNo: null,
      content: "",
      imgList: [],
      fileList: [],
      updateFiles: [],
      deleteFiles: [],
    });

    textareaRef.current.style.height = "auto";
    resetSelectComment();
  };

  // 채팅방 참여 회사
  const partCmpny = (orgInfos) => {
    const classNames = ["one", "two", "three", "four"];
    const className = classNames[Math.min(orgInfos.length, 4) - 1];

    const imageElements = orgInfos
      .slice(0, 4)
      .map((info, index) => (
        <img
          key={index}
          src={info.orgImg ? imgHandler(info.orgImg) : "/img/function_22_bg.png"}
          alt={`cmpny${index + 1}`}
        />
      ));

    return <div className={className}>{imageElements}</div>;
  };

  // 답장 원본 찾기
  const handleFindReply = (item) => {
    const target = document.querySelector(`.comment-${item.replyNo}`);

    if (target) {
      const keyframes = [
        { transform: "translateY(0px)" },
        { transform: "translateY(3px)" },
        { transform: "translateY(0px)" },
        { transform: "translateY(-3px)" },
        { transform: "translateY(0px)" },
      ];

      const options = {
        duration: 200,
        iterations: Math.floor(Math.random() * 2) + 3,
        easing: "ease-in-out",
      };

      target.scrollIntoView({ behavior: "smooth", block: "nearest" });
      target.animate(keyframes, options);
    }
  };

  const notParticipant = (e) => {
    if (e) e.preventDefault();
    toast.error("프로젝트 참여자만 댓글을 남길 수 있습니다.");
  };

  const handleKeyDown = (e) => {
    if (e.nativeEvent.isComposing) return;
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleComment();
    }
  };

  // 룸 연결 해제
  const disconnectRoom = () => {
    if (selectedRoom) {
      setRoomConnection(selectedRoom.roomId);
      setSelectedRoom(null);
    }
  };

  // textarea 높이 조절
  const handleResizeHeight = () => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";

    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  };

  // 이미지 붙여넣기
  const handlePaste = async (e) => {
    const items = e.clipboardData.items;
    const imageFilePromises = [];

    for (let item of items) {
      if (item.kind === "file" && item.type.startsWith("image/")) {
        const file = item.getAsFile();
        const mockEvent = { target: { files: [file] } };
        imageFilePromises.push(updateFilesFromInput(mockEvent));
      }
    }
  };

  const preventDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = Array.from(e.dataTransfer.files);
    if (files.length > 0) {
      const mockEvent = {
        target: {
          files: files,
        },
      };

      updateFilesFromInput(mockEvent);
    }
  };

  return (
    <ProjectDetailCommentContainer>
      <div className="projectFeedHeader">
        <div className="writerInfo">
          {selectedRoom?.orgInfos && (
            <ProfileImages>{partCmpny(selectedRoom?.orgInfos)}</ProfileImages>
          )}
          <div>
            {selectedRoom?.orgInfos && (
              <span>
                {selectedRoom?.orgInfos.map((orgInfo, idx) => (
                  <React.Fragment key={idx}>
                    {orgInfo?.orgNm}
                    {idx !== selectedRoom?.orgInfos.length - 1 && ", "}
                  </React.Fragment>
                ))}
              </span>
            )}
          </div>
        </div>
        <div className="projectFeedHeaderButton">
          <button onClick={disconnectRoom}>
            <RiArrowGoBackFill />
          </button>
        </div>
      </div>
      <input
        type="file"
        className="roomInput"
        id="fileUpload"
        onChange={updateFilesFromInput}
        onClick={preventDefault}
      />
      <label
        className="list"
        htmlFor="fileUpload"
        onDragOver={preventDefault}
        onDragLeave={preventDefault}
        onDrop={handleDrop}>
        <ul ref={listRef}>
          {comments?.length > 0 &&
            comments.map((item) => (
              <ProjectDetailCommentItem
                item={item}
                project={project}
                handleImageClick={handleImageClick}
                handleContextMenu={handleContextMenu}
                handleDeleteFile={handleDeleteFile}
                handleFindReply={handleFindReply}
              />
            ))}
          <ProjectDetailCommentNewItem
            project={project}
            isReply={isReply}
            selectedComment={selectedComment}
            resetSelectComment={resetSelectComment}
            newComment={newComment}
            handleDeleteFile={handleDeleteFile}
            handleComment={handleComment}
            notParticipant={notParticipant}
            updateFilesFromInput={updateFilesFromInput}
            textareaRef={textareaRef}
            MAX_LENGTH={MAX_LENGTH}
            handleKeyDown={handleKeyDown}
            handleContentChange={handleContentChange}
            handlePaste={handlePaste}
          />
        </ul>
      </label>
    </ProjectDetailCommentContainer>
  );
};

export default ProjectDetailCommentList;
