import React, { useState } from "react";
import Search from "../../common/search";
import { MembersContainer } from "../../styled/groupDetails";
import Pagination from "react-js-pagination";
import Member from "./members/member";
import InviteModal from "./members/inviteModal";

const itemsPerPage = 8;

const Members = ({ members, group }) => {
  const [page, setPage] = useState(1);
  const [searched, setSearched] = useState("");
  const [filtered, setFiltered] = useState(members);
  const [current, setCurrent] = useState(members.slice(0, itemsPerPage));
  const [isInvite, setIsInvite] = useState(false);

  const updateCurrentMembers = (page, membersList) => {
    const startIdx = (page - 1) * itemsPerPage;
    const endIdx = startIdx + itemsPerPage;
    setCurrent(membersList.slice(startIdx, endIdx));
  };

  const changePage = (page) => {
    setPage(page);
    updateCurrentMembers(page, filtered);
  };

  const handleSearch = () => {
    if (!searched || !searched.trim()) {
      setFiltered(members);
      setPage(1);
      updateCurrentMembers(1, members);
      return;
    }

    const result = members.filter((item) => (item.nickNm ? item.nickNm.includes(searched) : false));
    setFiltered(result);
    setPage(1);
    updateCurrentMembers(1, result);
  };

  return (
    <MembersContainer>
      <Search
        placeholder="닉네임을 입력해주세요."
        searched={searched}
        setSearched={setSearched}
        handleSearch={handleSearch}
      />
      <button className="invite" onClick={() => setIsInvite(true)}>
        멤버 초대
      </button>
      <ul className="members">
        {current.map((item, index) => (
          <Member item={item} index={index} group={group} />
        ))}
      </ul>
      <Pagination
        activePage={page}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={filtered.length}
        pageRangeDisplayed={3}
        prevPageText="‹"
        nextPageText="›"
        onChange={changePage}
      />
      {isInvite && <InviteModal members={members} setIsInvite={setIsInvite} group={group} />}
    </MembersContainer>
  );
};

export default Members;
