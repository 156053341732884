import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";

import { IoChevronDown } from "react-icons/io5";

import { SearchContainer } from "../../styled/groupDetails";

import useOutsideClick from "../../../../../hooks/useOutsideClick";

import Form from "./search/form";
import Reseult from "./search/reseult";
import EChanggo from "./search/eChanggo";
import Schedule from "./search/schedule";

const options = [
  { name: "전체", type: null },
  { name: "e층창고", type: null },
  { name: "친구소식", type: "B" },
  { name: "일정", type: "S" },
  { name: "소식", type: "F" },
  { name: "프로젝트", type: "P" },
];

const Search = () => {
  const { id } = useParams();

  const menuRef = useRef();

  const [selectedCmpny, setSelectedCmpny] = useState(null);
  const [selectedCmpnyPorts, setSelectedCmpnyPorts] = useState([]);
  const [optionOpen, setOptionOpen] = useState(false);
  const [active, setActive] = useState(0);
  const [searched, setSearched] = useState("");
  const [data, setData] = useState({});
  const [cmpnys, setCmpnys] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [productList, setProductList] = useState([]);
  const [totalProductPage, setTotalProductPage] = useState(0);
  const [cmpnyNos, setCmpnyNos] = useState([...Object.keys(cmpnys)]);
  const [pageNumber, setPageNumber] = useState(1);

  const user = useSelector((state) => state?.user?.data[0]);

  useEffect(() => {
    if (id) {
      getGrpCmpnyList();
    }
  }, []);

  useOutsideClick(menuRef, () => {
    setOptionOpen(false);
  });

  const getGrpCmpnyList = async () => {
    const url = "/api/groupCmpnyList";
    const body = { groupNos: [parseInt(id, 10)] };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      setCmpnys(res.data.result);
    }
  };

  const tabChange = (idx) => {
    setActive(idx);
    setOptionOpen(false);
  };

  const handleSearch = async (keyword = "") => {
    const url = "/api/combineSearch";
    const body = {
      groupNo: id,
      searchWord: keyword ? keyword : searched,
      mberNo: user.mberNo,
    };

    if (active) {
      body.searchType = options[active].type;
    }

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setIsSearch(true);

        const data = res.data;
        const projectItems = [
          ...(data.projectList?.cmpnyProjectList || []),
          ...(data.projectList?.fixedProjectList || []),
          ...(data.projectList?.groupProjectList || []),
          ...(data.projectList?.otherProjectList || []),
        ];

        const filtered = {
          stockList: data.stockList || [],
          scheduleList: data.scheduleList || [],
          blogList: data.blogList || [],
          feedList: data.feedList || [],
          projectList: projectItems,
        };

        setData(filtered);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 상품 검색
  const handleProductSearch = async () => {
    setProductList([]);
    await getProductSearch(1);

    setPageNumber(1);
    setSelectedCmpny(null);

    handleSearch();
  };

  // 상품 조회
  const getProductSearch = async (page = 1) => {
    const url = "/api/combineSearch";
    const body = {
      groupNo: id,
      searchType: "E",
      searchWord: searched,
      ...productCond(page),
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const stocks = res.data.stockPageList;

      setProductList((product) => {
        const newProducts = stocks.content.filter(
          (item) => !product.some((prev) => prev.prdNo === item.prdNo),
        );
        return [...product, ...newProducts];
      });

      setTotalProductPage(stocks.totalPages);

      if (searched) {
        nonDuplicateCmpny(stocks.content);
      } else {
        setCmpnyNos([...Object.keys(cmpnys)]);
      }
    }
  };

  const nonDuplicateCmpny = (data) => {
    const cmpnys = data.map((item) => item.cmpnyNo);
    const uniqueCmpnyNos = new Set(cmpnys);
    setCmpnyNos([...uniqueCmpnyNos]);
  };

  const productCond = (number) => {
    const pageSize = 8;
    const pageNumber = number - 1;

    return {
      offset: pageNumber * pageSize,
      pageNumber: pageNumber,
      pageSize,
      paged: searched ? false : true,
    };
  };

  const componentMap = {
    1: (
      <EChanggo
        cmpnys={cmpnys}
        searchWord={searched}
        setSearchWord={setSearched}
        productList={productList}
        totalProductPage={totalProductPage}
        setTotalProductPage={setTotalProductPage}
        cmpnyNos={cmpnyNos}
        setCmpnyNos={setCmpnyNos}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        selectedCmpny={selectedCmpny}
        setSelectedCmpny={setSelectedCmpny}
        selectedCmpnyPorts={selectedCmpnyPorts}
        setSelectedCmpnyPorts={setSelectedCmpnyPorts}
        getProductSearch={getProductSearch}
      />
    ),
    3: <Schedule data={data.scheduleList} isSearch={isSearch} searchWord={searched} />,
  };

  return (
    <SearchContainer>
      <Form
        options={options}
        optionOpen={optionOpen}
        setOptionOpen={setOptionOpen}
        active={active}
        menuRef={menuRef}
        setSearched={setSearched}
        searched={searched}
        handleSearch={active === 1 ? handleProductSearch : handleSearch}
        tabChange={tabChange}
      />
      <div className="searched">
        {!componentMap?.[active] ? (
          <Reseult
            data={data}
            cmpnys={cmpnys}
            searched={searched}
            tabChange={tabChange}
            active={active}
          />
        ) : (
          componentMap[active]
        )}
      </div>
    </SearchContainer>
  );
};

export default Search;
