import React, { useEffect, useState } from "react";
import Members from "./home/members";
import { HomeContainer } from "../../styled/groupDetails";
import Slide from "../../common/slide";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";
import Feed from "./home/feed";
import { toast } from "react-toastify";

const Home = ({ members, group, changeTab, member, tabClick }) => {
  const { id } = useParams();
  const user = useSelector((state) => state?.user?.data[0]);

  const [blog, setBlog] = useState([]);
  const [feed, setFeed] = useState([]);

  useEffect(() => {
    if (!id || isNaN(id)) return;

    getBlog();
    getFeed();
  }, [id]);

  const getBlog = async () => {
    const url = "/api/combineSearch";
    const body = {
      groupNo: id,
      searchType: "B",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setBlog(res.data.blogPageList.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFeed = async () => {
    const url = "/api/grpFeedList";
    const body = {
      mberNo: user.mberNo,
      gbn: [id],
      offset: 0,
      pageNumber: 0,
      pageSize: 4,
      paged: true,
    };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setFeed(res.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickSlide = async (item) => {
    const url = "/api/domainSettingLoad";
    const req = {
      delYn: "N",
      cmpnyNo: item.cmpnyNo,
    };

    const res = await axios.post(url, req);
    if (res.data.domain) {
      window.open(`https://1472.ai/shop/${res.data.domain}/portfolioView/${item.portNo}`);
      user && localStorage.setItem("user", JSON.stringify(user));
    } else {
      toast.error("홈페이지가 존재하지 않습니다.");
    }
  };

  return (
    <HomeContainer>
      <Feed
        feed={feed}
        clickFeed={(id) => (member ? changeTab(0) : toast.error("그룹 가입 후 이용 가능합니다."))}
      />
      <Members members={members} group={group} member={member} tabClick={tabClick} />
      <Slide
        info={{
          title: `${group.groupName}의\n소식들😉`,
          description: "다양한 소식을 확인해보세요!",
          nav: { label: "자세히 보러가기", func: () => changeTab(1) },
          notDataMessage: "소식이 존재하지 않습니다.",
        }}
        data={blog}
        clickSlide={(item) =>
          member ? clickSlide(item) : toast.error("그룹 가입 후 이용 가능합니다.")
        }
        type="blog"
      />
    </HomeContainer>
  );
};

export default Home;
