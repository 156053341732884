import React from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { showEventTime, transformTodoContent } from "../../function/scheduler";

import { CiAlarmOn, CiMap, CiTextAlignLeft } from "react-icons/ci";
import { PiProjectorScreenChartLight, PiUser, PiUsersFour } from "react-icons/pi";

import EventListItem from "../../common/eventListItem";
import { EventInfoDetailList } from "../../styled/scheduler";
import axios from "axios";

const EventDetailView = ({ item, todos, getTodos }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  const memberNameMatch = (mberNo) => {
    if (item?.workerList[mberNo]) return item.workerList[mberNo];
    if (item.managerNo === mberNo) return item.managerNm;
    return "";
  };

  const updateTodo = async (item) => {
    const url = "/api/todo";
    const body = {
      ...item,
      isCompleted: !item.isCompleted,
      mentions: item.mentions.map((mention) => mention.mberNo),
    };

    const res = await axios.put(url, [body]);

    if (res.status === 200) {
      await getTodos();
    }
  };

  return (
    <>
      {item.dday === "N" ? (
        <>
          <EventInfoDetailList>
            <EventListItem icon={<CiAlarmOn />} title="날짜">
              {showEventTime(item.startDt, item.endDt)}
            </EventListItem>
            <EventListItem icon={<CiMap />} title="위치" addClass="place">
              {item.place && item.place}
              {item.placeDetail && ` ${item.placeDetail}`}
              {!item.placeDetail && !item.place && "주소가 존재하지 않습니다"}
            </EventListItem>
            {item.gbn !== "P" && (
              <>
                {item.projectNo > 0 && item.projectNm && (
                  <EventListItem icon={<PiProjectorScreenChartLight />} title="프로젝트">
                    <span
                      onClick={() => navigate(`/mypage/project/${item.projectNo}`)}
                      style={{ cursor: "pointer" }}>
                      {item.projectNm}
                    </span>
                  </EventListItem>
                )}
                <EventListItem icon={<PiUser />} title={item.gbn !== "G" ? "담당자" : "주최자"}>
                  {item.managerNm}
                </EventListItem>
                <EventListItem
                  icon={<PiUsersFour />}
                  title={item.gbn !== "G" ? "실무자" : "참여자"}
                  addClass="workers">
                  {item.workerList &&
                    Object.entries(item.workerList).map(([key, worker], i, arr) => (
                      <div className="worker" key={i}>
                        <span
                          style={
                            user.mberNo === parseInt(key)
                              ? { fontWeight: 600, color: isDarkMode ? "#bb86fc" : "#0074ca" }
                              : null
                          }>
                          {worker}
                        </span>
                        {i !== arr.length - 1 && ", "}
                      </div>
                    ))}
                </EventListItem>
              </>
            )}
            <EventListItem icon={<CiTextAlignLeft />} title="메모" addClass="memo">
              <ul>
                {todos.map((item) => (
                  <li>
                    {item.id && (
                      <label>
                        <input
                          type="checkbox"
                          checked={item.isCompleted}
                          onChange={() => updateTodo(item)}
                        />
                        <span className="checkmark"></span>
                      </label>
                    )}
                    <span>{transformTodoContent(item.content)}</span>
                    {item.mentions.length > 0 && (
                      <div className="mentions">
                        {item.mentions.map((mention) => (
                          <span>@{memberNameMatch(mention.mberNo)}</span>
                        ))}
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </EventListItem>
          </EventInfoDetailList>
        </>
      ) : (
        <EventInfoDetailList>
          <EventListItem icon={<CiAlarmOn />} title="날짜">
            {moment(item.startDt).format("YYYY-MM-DD")}
          </EventListItem>
          <EventListItem icon={<CiTextAlignLeft />} title="메모" addClass="memo dDay">
            {item.memo && item.memo.includes("\n")
              ? item.memo.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))
              : item.memo}
          </EventListItem>
        </EventInfoDetailList>
      )}
    </>
  );
};

export default EventDetailView;
