import HomePageSettingMenu from "./component/common/homepageSettingMenu";
import HomePageSettingForm from "./component/homepage/homePageSettingForm";
import { Container } from "../styled/mypage";
import { useSelector } from "react-redux";
import PageInfo from "../pageInfo";
import { useEffect, useState } from "react";
import HomepageEdit from "./component/common/homepageEdit";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const HomepageSetting = () => {
  const navigate = useNavigate();
  const setting = useSelector((state) => state?.user?.setting);
  const [cmpnyNo, setCmpnyNo] = useState();

  useEffect(() => {
    const company = JSON.parse(sessionStorage.getItem("homepageCompany"));

    if (!company) {
      console.log("선택된 회사가 존재하지 않습니다.");
      navigate("/mypage");
    } else {
      setCmpnyNo(company.cmpnyNo);
    }
  }, []);

  return (
    <Container className={setting?.mode}>
      <PageInfo label="첫 화면 설정" />
      <div className="content">
        <HomePageSettingMenu />
        <HomepageEdit cmpnyNo={cmpnyNo} type="배너" />
        <HomePageSettingForm cmpnyNo={cmpnyNo} />
      </div>
    </Container>
  );
};

export default HomepageSetting;
