import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import axios from "axios";
import uuid from "react-uuid";

import HomePageSettingMenu from "../common/homepageSettingMenu";
import RegisterTitle from "./registerTitle";
import RegisterMenu from "./registerMenu";
import ImageUploadAdapterPlugin from "../utils/ImageUploadAdapter";
import Modal from "../../../../../common/modal";
import CustomModal from "../../../../../common/customModal";

import { setRegisterIndex } from "../../../../../store/compoIndex";
import { RegisterPhotoUploadFormContainer } from "../styled/register";

export default function RegisterPhotoUploadForm({ fileBoxImages }) {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.data[0]);
  const setting = useSelector((state) => state?.user?.setting);
  const index = useSelector((state) => state.compoIndex.register);

  const ckeditorInstanceRef = useRef(null);

  const [cmpnyNo, setCmpnyNo] = useState();
  const [content, setContent] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState();
  const [serverTitleImg, setServerTitleImg] = useState();

  const portNo = params.portNo;

  useEffect(() => {
    const company = JSON.parse(sessionStorage.getItem("homepageCompany"));

    if (!company) {
      console.log("선택된 회사가 존재하지 않습니다.");
      navigate("/mypage");
    } else {
      setCmpnyNo(company.cmpnyNo);
    }

    if (portNo) {
      getPortfolio();
    }

    if (Array.isArray(fileBoxImages) && fileBoxImages.length > 0) {
      const imageHtml = fileBoxImages
        .map((item) => {
          return `<figure class="image"><img src="${imgHandler(item.filePath)}"></figure>`;
        })
        .join("");
      const thumbnail = fileBoxImages[0]?.filePath;

      setContent(imageHtml);
      setServerTitleImg(thumbnail);
    }
  }, []);

  const getPortfolio = async () => {
    const apiUrl = "/api/portMgmtList";
    const body = {
      portNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 12,
      paged: true,
    };
    const response = await axios.post(apiUrl, body);
    const res = response.data.content;
    setTitle(res[0].title);
    setContent(res[0].contents);
    setServerTitleImg(res[0].imgUrl);
  };

  const handleEditorChange = (_, editor) => {
    const newContent = editor.getData();
    setContent(newContent);
  };

  const saveAndNext = async () => {
    const images = extractImages(content);
    await uploadImage(images);
  };

  const extractImages = (content) => {
    const imgRegex = /<img.*?src=['"](data:image\/\w+;base64,.*?)['"].*?>/g;
    const matches = content.match(imgRegex);
    const images = [];

    if (matches) {
      matches.forEach((match) => {
        const dataUriMatch = match.match(/src=['"](data:image\/\w+;base64,.*?)['"]/);
        if (dataUriMatch && dataUriMatch[1]) {
          images.push(dataUriMatch[1]);
        }
      });
    }

    return images;
  };

  const uploadImage = async (images) => {
    console.log(images);
    let formData = new FormData();
    images.forEach((image, index) => {
      let blob = dataURItoBlob(image);
      let extension = blob.type.split("/")[1];
      formData.append("files", blob, `${uuid()}.${extension}`);
    });
    formData.append("ids", "N");
    formData.append("mberNo", user.mberNo);

    let titleImg = "";
    fetch("/api/uploadFiles", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        return data.responseData;
      })
      .then((imageDatas) => {
        let imageUrls = [];
        imageDatas.map((item) => imageUrls.push(item.fileName));
        titleImg = imageUrls[0];
        const reqContent = replaceImagesWithUrls(content, imageUrls);
        setContent(reqContent);
        return reqContent;
      })
      .then((reqContent) => lastSubmit(reqContent, titleImg))
      .catch((error) => {
        console.error("업로드 중 오류 발생:", error);
      });
  };

  const replaceImagesWithUrls = (content, imageUrls) => {
    let updatedContent = content;
    const dataUriRegex = /src=['"]data:image\/[^;'"]+;base64,([^'"]+)['"]/g;
    let matches = updatedContent.match(dataUriRegex);
    imageUrls.forEach((imageUrl, index) => {
      updatedContent = updatedContent.replace(matches[index], `src="${imgHandler(imageUrl)}"`);
    });
    setContent(updatedContent);
    return updatedContent;
  };

  const lastSubmit = async (reqContent, titleImg) => {
    let url = "/api/portfolio";
    let request = {
      cmpnyNo,
      notitleYn: "Y",
      title: title,
      contents: reqContent,
      useYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
      imgUrl: titleImg ? titleImg : serverTitleImg,
    };
    if (portNo) {
      request.portNo = Number(portNo);
    }

    const res = await axios.put(url, request);
    if (res.data.success === true) {
      if (portNo) {
        navigate("/mypage/register", { state: { editPort: true } });
      } else {
        navigate("/mypage/register", { state: { uploadPort: true } });
      }
      dispatch(setRegisterIndex(2));
    }
  };

  const openModal = () => {
    if (!checkValidation()) {
      return;
    }
    setModalOpen(true);
  };

  const checkValidation = () => {
    if (!title) {
      toast.error("제목을 입력해 주세요.");
      return false;
    }
    if (!content) {
      toast.error("내용을 입력해 주세요.");
      return false;
    }
    return true;
  };

  const moveToPage = () => {
    navigate("/mypage/register");
    dispatch(setRegisterIndex(2));
  };

  return (
    <RegisterPhotoUploadFormContainer className={setting?.mode}>
      <HomePageSettingMenu />
      <RegisterTitle />
      <RegisterMenu index={index} />
      <div className="cont">
        <input
          type="text"
          placeholder="제목을 입력하세요."
          defaultValue={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={handleEditorChange}
          config={{
            extraPlugins: [ImageUploadAdapterPlugin],
            toolbar: ["bold", "italic", "|", "link", "imageUpload", "mediaEmbed"],
            uiSettings: {
              height: "500px",
            },
          }}
          ref={ckeditorInstanceRef}
        />
        <div className="actions">
          <button onClick={() => navigate(-1)}>
            <span>취소</span>
          </button>
          <button onClick={openModal}>
            <span>{portNo ? "수정" : "저장"}</span>
          </button>
          <button onClick={moveToPage}>
            <span>목록</span>
          </button>
        </div>
      </div>
      <Modal
        modalState={modalOpen}
        handleModalState={() => setModalOpen(false)}
        html={
          <CustomModal
            title={`포트폴리오`}
            content={`포트폴리오를 ${portNo ? "수정" : "등록"} 하시겠습니까?`}
            handleConfirm={saveAndNext}
            handleCancel={() => setModalOpen(false)}
          />
        }
        w="300px"
        h="auto"
      />
    </RegisterPhotoUploadFormContainer>
  );
}
