import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { hideLoading, showLoading } from "../../../store/loading";

import SearchAll from "./component/searchAll";
import SearchSchedule from "./component/searchSchedule";
import SearchGroup from "./component/searchGroup";
import SearchFeed from "./component/searchFeed";
import SearchProject from "./component/searchProject";
import SearchEChanggo from "./component/searchEChanggo";
import SearchForm from "./component/searchForm";

import { SearchContainer } from "./styled/search";
import Loading from "../../../common/loading";
import { useSelector } from "react-redux";
import axios from "axios";
import ProjectHeader from "../project/component/common/component/projectHeader";
import TooltipSideMenu from "../../../common/tooltipSideMenu";
import { useMediaQuery } from "react-responsive";
import { LuLayoutGrid } from "react-icons/lu";

const IntegratedSearchPage = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.data[0]);
  const isLoading = useSelector((state) => state?.loading?.isLoading);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [active, setActive] = useState(0);
  const [searchWord, setSearchWord] = useState("");
  const [searched, setSearched] = useState({
    scheduleList: [],
    groupList: [],
    feedList: [],
    projectList: [],
    stockList: [],
  });
  const [isSearch, setIsSearch] = useState(false);
  const [clickSideMenu, setClickSideMenu] = useState(false);

  const tabs = [
    { searchType: null, name: "전체" },
    { searchType: "S", name: "일정" },
    { searchType: "G", name: "모임" },
    { searchType: "F", name: "소식" },
    { searchType: "P", name: "프로젝트" },
    { searchType: "E", name: "e층창고" },
  ];

  const handleSearch = async () => {
    dispatch(showLoading());

    const url = "/api/combineSearch";
    const body = {
      mberNo: user.mberNo,
      searchWord,
    };

    try {
      const res = await axios.post(url, body);
      const data = res.data;

      const projectItems = [
        ...(data.projectList?.cmpnyProjectList || []),
        ...(data.projectList?.fixedProjectList || []),
        ...(data.projectList?.groupProjectList || []),
        ...(data.projectList?.otherProjectList || []),
      ];

      const filtered = {
        scheduleList: data.scheduleList || [],
        groupList: data.groupList || [],
        feedList: data.feedList || [],
        projectList: projectItems,
        stockList: data.stockList || [],
      };

      setSearched(filtered);
    } catch (error) {
      console.error(error);
    } finally {
      if (!isSearch) setIsSearch(true);
      dispatch(hideLoading());
    }
  };

  const Components = ({ index }) => {
    return (
      <>
        {
          [
            <SearchAll
              tabChange={tabChange}
              isSearch={isSearch}
              searched={searched}
              searchWord={searchWord}
            />,
            <SearchSchedule data={searched.scheduleList} isSearch={isSearch} />,
            <SearchGroup data={searched.groupList} isSearch={isSearch} />,
            <SearchFeed data={searched.feedList} isSearch={isSearch} />,
            <SearchProject data={searched.projectList} isSearch={isSearch} />,
            <SearchEChanggo data={searched.stockList} isSearch={isSearch} />,
          ][index]
        }
      </>
    );
  };

  const tabChange = (idx) => {
    setActive(idx);
  };

  return (
    <SearchContainer>
      <ProjectHeader
        clickSideMenu={clickSideMenu}
        setClickSideMenu={setClickSideMenu}
        pageInfo="통합검색"
      />
      <div className="searchSection">
        <div className="searchInner">
          {isMobile && (
            <button className="sideMenuButton" onClick={() => setClickSideMenu(!clickSideMenu)}>
              <LuLayoutGrid />
            </button>
          )}
          <ul className="tabs">
            {tabs.map((item, idx) => (
              <li onClick={() => tabChange(idx)} className={active === idx ? "active" : ""}>
                {item.name}
              </li>
            ))}
          </ul>
          <SearchForm
            searchWord={searchWord}
            setSearchWord={setSearchWord}
            handleSearch={handleSearch}
          />
          {<Components index={active} />}
        </div>
      </div>
      {isLoading && <Loading />}
      {clickSideMenu && (
        <TooltipSideMenu
          clickSideMenu={clickSideMenu}
          setClickSideMenu={setClickSideMenu}
          isMargin={false}
        />
      )}
    </SearchContainer>
  );
};

export default IntegratedSearchPage;
