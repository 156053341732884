import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import { SecionSwiperContainer } from "./shopping/component/styled/defaultTheme";

export default function ShoppingSectionSwiper() {
  const { cmpnyNo } = useOutletContext();
  const [res, setRes] = useState([]);

  useEffect(() => {
    if (cmpnyNo) {
      getMySetting(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getMySetting = async (cmpnyNo) => {
    const url = "/api/firstSettingLoad";
    const body = {
      cmpnyNo: cmpnyNo,
      delYn: "N",
    };
    try {
      const res = await axios.post(url, body);
      console.log(res);

      if (res?.data === "") {
        res.data = {};
      }

      if (res?.data?.bannerImgList) {
        res.data.bannerImgList = JSON.parse(res.data.bannerImgList);
      } else {
        res.data.bannerImgList = [];
      }

      if (res?.data?.bannerImgTextList) {
        res.data.bannerImgTextList = JSON.parse(res.data.bannerImgTextList);
      } else {
        res.data.bannerImgTextList = [];
      }
      setRes(res);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SecionSwiperContainer className="section section_swiper" id="sec0">
      {res?.data?.bannerImgList?.length !== 0 ? (
        <>
          <Swiper
            modules={[Navigation]}
            slidesPerView={1}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
            loop={true}
            speed={2000}
            autoplay={{
              delay: 4500,
              disableOnInteraction: false,
            }}
            className="mySwiper">
            {res?.data?.bannerImgList.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="swiper-wrapper">
                  <div
                    className="slide swiper-slide"
                    id={i}
                    style={{ background: "none", position: "relative" }}>
                    <img
                      src={`${imgHandler(item)}`}
                      style={{ width: "100%", height: "100%", objectFit: "contain" }}
                    />
                    <div
                      className="slide_text_warp text_center"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%,-50%)",
                      }}>
                      <span className="font_72 font_color_white font900">
                        {res?.data?.bannerImgTextList[i]}
                      </span>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>

          <div
            className="swiper-button-prev swiper-button-white"
            style={{ filter: "invert(100%)" }}></div>
          <div
            className="swiper-button-next swiper-button-white"
            style={{ filter: "invert(100%)" }}></div>
        </>
      ) : (
        <div
          className="not"
          style={{
            background: ``,
            width: "100%",
          }}></div>
      )}
    </SecionSwiperContainer>
  );
}
