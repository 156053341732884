import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import "./company.css";
import ShopLoading from "../../common/ShopLoading";
import ShoppingSectionSwiper from "../../shoppingSectionSwiper";
import { useMediaQuery } from "react-responsive";
import { findTargetMenuName } from "../../common/function/shopping";
import { Container as MapDiv, NaverMap, Marker } from "react-naver-maps";

const defaultAddress = "경기도 부천시 신흥로 223";
const domainUrl = window.location.href;

export default function CompanyForm() {
  const navermaps = window.naver.maps;

  const { cmpnyNo, url, theme, homepageInfo, menus, links } = useOutletContext();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const mapRef = useRef(null);
  const scrollRef = useRef();

  const shopCompany = useSelector((state) => state.compoIndex.shopCompany);

  const [totalLamp] = useState(domainUrl.includes("marygold"));
  const [htmlData, setHtmlData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cmpnyInfo, setCmpnyInfo] = useState("");
  const [center, setCenter] = useState({ lat: 36.348768969298746, lng: 127.39696329741778 });

  useEffect(() => {
    if (cmpnyNo) {
      getCmpnyInfo();
      getMySetting();
    } else {
      setIsLoading(false);
    }
  }, [cmpnyNo]);

  useEffect(() => {
    if (shopCompany === 0) {
      window.scrollTo(0, 0);
    } else {
      setTimeout(() => {
        scrollRef?.current?.scrollIntoView({ behavior: "auto" });
      }, 200);
    }
  }, [shopCompany]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.panTo(new window.naver.maps.LatLng(center.lat, center.lng));
    }
  }, [center]);

  const getMySetting = async () => {
    const url = "/api/infoSettingLoad";
    const request = {
      cmpnyNo: cmpnyNo,
      delYn: "N",
    };
    const response = await axios.post(url, request);
    const htmlString = response?.data?.cmpnyIntroduce;

    if (htmlString) {
      const contents = document.getElementById("contents");
      contents.innerHTML = htmlString;
    }
    setHtmlData(htmlString);
    setIsLoading(false);
  };

  const getCmpnyInfo = async () => {
    const url = "/api/cmpnyInfo";
    const body = {
      cmpnyNo,
    };
    const res = await axios.post(url, body);
    if (res) {
      console.log(res.data);
      setCmpnyInfo(res.data);
      setCenter({ lat: res.data.latitude, lng: res.data.longitude });
    }
  };

  const copyAddress = () => {
    if (!cmpnyInfo.adres) {
      toast.error("주소가 존재하지 않습니다.");
      return;
    }
    navigator.clipboard.writeText(cmpnyInfo.adres);
    toast.success("주소가 복사 되었습니다.");
  };

  const getThemeText = (textType) => {
    const textMap = {
      company: {
        0: "",
        1: findTargetMenuName(menus, "회사소개"),
        2: findTargetMenuName(menus, "회사소개"),
        3: findTargetMenuName(menus, "회사소개"),
        4: homepageInfo?.cmpnyNm ? homepageInfo?.cmpnyNm : findTargetMenuName(menus, "회사소개"),
        sm: "",
      },
      location: {
        0: "찾아오시는 길",
        1: "COMPANY",
        2: "WAY TO COME",
        3: "오시는 길",
        4: "오시는 길",
        sm: "오시는 길",
      },
    };

    return textMap[textType]?.[theme] || "";
  };

  return (
    <main id="contCompany" className="sub_page_mobile_mt">
      {homepageInfo?.cmpnyNo === 29787 && (
        <div id="contIdx">
          <ShoppingSectionSwiper />
        </div>
      )}
      {theme === 1 && (
        <div className="subTop_bnr subTop_bnr_01">
          <span>COMPANY</span>
        </div>
      )}
      {totalLamp && (
        <div>
          <img src={`/img/totallamp79/totallamp_banner.png`} alt="" />
        </div>
      )}
      <article className="boundary pdt_70 pdb_70">
        <h2 className="font_36 pdb_30 font600 text_center">
          {findTargetMenuName(menus, "회사소개")}
        </h2>
        {/* <section id="secBrdcrmb" className="font_18 pdb_20 text_right">
          <Link to={`/shop/${url}`}>
            <i className="xi-home-o font_20"></i>
          </Link>
          <span>회사소개</span>
        </section> */}
        {theme !== 1 && (
          <h3 className="font_24 company_address_h1">
            <span>{getThemeText("company")}</span>
          </h3>
        )}
        <section
          id="secMsg"
          className={!htmlData ? "text_center" : "company_wrapper"}
          style={
            homepageInfo?.cmpnyNo === 29787 && !isMobile
              ? { fontSize: 30, textAlign: "center" }
              : {}
          }>
          {isLoading && <ShopLoading />}
          <strong className="company_content" id="contents"></strong>
          {!htmlData && cmpnyInfo && (
            <h1 className="company_h1_font_size">안녕하세요. {cmpnyInfo.cmpnyNm} 입니다.</h1>
          )}
        </section>

        <section id="secMap" className="pdt_70" ref={scrollRef}>
          <h3 className="font_24 company_address_h1">
            <span>{getThemeText("location")}</span>
          </h3>
          {cmpnyInfo?.adres && (
            <div className="addr pdb_30 font_18 font_color_gray_dark address_btn_wrapper">
              <span>{cmpnyInfo.adres}</span>
              <button className="addr_copy font_16 address_btn_wrapper_btn" onClick={copyAddress}>
                <i className="fa fa-copy"></i> 복사
              </button>
            </div>
          )}
          <MapDiv
            style={{
              width: "100%",
              height: 400,
            }}>
            <NaverMap
              ref={mapRef}
              center={new navermaps.LatLng(center.lat, center.lng)}
              defaultZoom={13}>
              <Marker position={new navermaps.LatLng(center.lat, center.lng)} title="회사 위치" />
            </NaverMap>
          </MapDiv>
        </section>
      </article>
    </main>
  );
}
