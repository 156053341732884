import React from "react";
import { Ckeckbox, InquiryListContainer } from "../../styled/company";
import Pagination from "react-js-pagination";

const InquiryList = ({
  inquiries,
  page,
  changePage,
  total,
  selectItem,
  showStatus,
  setShowStatus,
}) => {
  return (
    <InquiryListContainer className="viewer">
      <div className="label">
        <span>문의목록</span>
        <Ckeckbox>
          <span>대기중 문의만</span>
          <input
            type="checkbox"
            checked={!showStatus}
            onChange={(e) => setShowStatus(!e.target.checked)}
          />
        </Ckeckbox>
      </div>
      <ul className="inquiryList">
        {inquiries && inquiries.length > 0 ? (
          <>
            <li className="header">
              <div className="id">순서</div>
              <div className="title">제목</div>
              <div className="status">답변상태</div>
              <div className="mberNm">작성자</div>
            </li>
            {inquiries.map((item) => (
              <li onClick={() => selectItem(item.id)}>
                <div className="id">{item.id}</div>
                <div className="title">{item.title}</div>
                <div className="status">
                  {item.status ? (
                    <span className="completed">답변완료</span>
                  ) : (
                    <span className="pending">대기</span>
                  )}
                </div>
                <div className="mberNm">{item.mberNm}</div>
              </li>
            ))}
          </>
        ) : (
          <li className="not">문의 내역이 존재하지 않습니다.</li>
        )}
      </ul>
      {total > 0 && (
        <Pagination
          activePage={page}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={3}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={(page) => changePage(page)}
        />
      )}
    </InquiryListContainer>
  );
};

export default InquiryList;
