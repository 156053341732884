import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import HomepageEdit from "../../component/common/homepageEdit";
import { useSelector } from "react-redux";
import axios from "axios";
import uuid from "react-uuid";
import {
  AddMenuContainer,
  MenuSettingFormContainer,
  MenuSettingFormTable,
} from "../styled/menuSetting";
import { SubmitButton } from "../../../styled/mypage";
import { CustomInput } from "../styled/common";

export default function MenuSettingForm() {
  const location = useLocation();
  const navigate = useNavigate();

  const saveMessage = location.state?.saveMessage;

  const setting = useSelector((state) => state?.user?.setting);
  const user = useSelector((state) => state?.user?.data[0]);

  const [cmpnyNo, setCmpnyNo] = useState();
  const [menus, setMenus] = useState([]);
  const [newMenu, setNewMenu] = useState({ menuType: "0", afterMenuName: "회사소개" });

  const menuType = {
    0: "HTML",
    1: "PRODUCT",
    2: "PHOTO",
    3: "BOARD",
  };

  const names = ["회사소개", "제품소개", "포트폴리오", "공지사항"];

  const createDefaultMenu = (cmpnyNo) => {
    const data = [];

    for (let i = 0; i < names.length; i++) {
      data.push({
        menuId: uuid(),
        menuType: `${i}`,
        displayYn: "Y",
        beforeMenuName: names[i],
        afterMenuName: "",
        seq: i + 1,
        cmpnyNo: cmpnyNo,
        delYn: "N",
      });
    }

    return data;
  };

  useEffect(() => {
    const company = JSON.parse(sessionStorage.getItem("homepageCompany"));

    if (!company) {
      console.log("선택된 회사가 존재하지 않습니다.");
      navigate("/mypage");
    } else {
      setCmpnyNo(company.cmpnyNo);
    }

    getMenuSetting(company.cmpnyNo);

    if (saveMessage) {
      toast.success("변경사항이 저장되었습니다.");
    }
  }, []);

  const getMenuSetting = async (cmpnyNo) => {
    const url = "/api/menuSetting";
    const body = {
      cmpnyNo: cmpnyNo,
    };
    const res = await axios.post(url, body);

    if (res.status === 200 && res.data.length > 0) {
      const data = res.data
        .filter((item) => item.delYn === "N")
        .map((item) => ({ ...item, menuId: uuid() }));

      setMenus(data);
    } else {
      const data = createDefaultMenu(cmpnyNo);
      setMenus(data);
    }
  };

  const changeInput = (e, item) => {
    const { menuId } = item;
    const { name, value, type } = e.target;
    let isCheck;

    if (type === "checkbox") {
      const checked = e.target.checked;
      if (name === "delYn") {
        isCheck = checked ? "Y" : "N";
      } else if (name === "displayYn") {
        isCheck = checked ? "N" : "Y";
      }
      setMenus((data) =>
        data.map((menu) => (menu.menuId === menuId ? { ...menu, [name]: isCheck } : menu)),
      );
    }
    if (type === "text" || type === "number") {
      setMenus((data) =>
        data.map((menu) => (menu.menuId === menuId ? { ...menu, [name]: value } : menu)),
      );
    }
  };

  const changeNewMenu = (e) => {
    const { name, value } = e.target;
    setNewMenu((data) => ({
      ...data,
      [name]: value,
      ...(name === "menuType" && { afterMenuName: names[value] }),
    }));
  };

  const checkValidation = () => {
    const filtered = menus.filter((item) => item.delYn !== "Y");

    if (filtered.some((item) => !item.seq)) {
      toast.error("메뉴의 순서가 존재하지 않습니다.");
      return false;
    }
    if (filtered.some((item) => item.seq < 1)) {
      toast.error("메뉴의 순서는 1번부터 지정 가능합니다.");
      return false;
    }

    const menuIndex = filtered.map((item) => String(item.seq));
    const indexSet = new Set(menuIndex);

    if (menuIndex.length !== indexSet.size) {
      toast.error("중복된 순서가 존재합니다.");
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!checkValidation()) {
      return;
    }

    const url = "/api/menuSetting";
    const body = [...menus]
      .filter((item) => (item.id && item.id > 0) || item.delYn === "N")
      .map((item) => ({ ...item, createMberId: user.userName, updateMberId: user.userName }));

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success("메뉴 설정이 완료되었습니다.");
      navigate("/mypage/themeSetting");
    }
  };

  const handleAddClick = () => {
    const newObj = {
      menuId: uuid(),
      menuType: newMenu.menuType,
      displayYn: "Y",
      beforeMenuName: names[newMenu.menuType],
      afterMenuName: names[newMenu.menuType] === newMenu.afterMenuName ? "" : newMenu.afterMenuName,
      seq: menus.length + 1,
      cmpnyNo: cmpnyNo,
      delYn: "N",
    };

    setMenus((data) => [...data, newObj]);
    setNewMenu({ menuType: "0", afterMenuName: "회사소개" });
  };

  return (
    <>
      <HomepageEdit cmpnyNo={cmpnyNo} type="메뉴" />
      <MenuSettingFormContainer className={setting?.mode}>
        <MenuSettingFormTable className={setting?.mode}>
          <colgroup>
            <col width="15%" />
            <col width="15%" />
            <col width="15%" />
            <col width="25%" />
            <col width="15%" />
            <col width="15%" />
          </colgroup>
          <thead>
            <tr>
              <th>메뉴타입</th>
              <th>메뉴 숨기기</th>
              <th>메뉴명</th>
              <th>변경할 메뉴명</th>
              <th>삭제여부</th>
              <th>순서</th>
            </tr>
          </thead>
          <tbody>
            {menus.map((item) => (
              <tr>
                <td>
                  <span>{menuType[item.menuType]}</span>
                </td>
                <td>
                  <div>
                    <CustomInput className="block">
                      <input
                        type="checkbox"
                        checked={item.displayYn === "N"}
                        name="displayYn"
                        onChange={(e) => changeInput(e, item)}
                      />
                      <span className="checkmark"></span>
                    </CustomInput>
                  </div>
                </td>
                <td>
                  <span className="text_center">{item.beforeMenuName}</span>
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="변경할 메뉴명을 입력하세요."
                    name="afterMenuName"
                    value={item.afterMenuName}
                    onChange={(e) => changeInput(e, item)}
                  />
                </td>
                <td>
                  <div>
                    <CustomInput className="block">
                      <input
                        type="checkbox"
                        checked={item.delYn === "Y"}
                        name="delYn"
                        onChange={(e) => changeInput(e, item)}
                      />
                      <span className="checkmark"></span>
                    </CustomInput>
                  </div>
                </td>
                <td>
                  <input
                    type="number"
                    placeholder="1번부터 지정가능합니다."
                    value={item.seq}
                    name="seq"
                    onChange={(e) => changeInput(e, item)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </MenuSettingFormTable>
        <AddMenuContainer className={setting?.mode}>
          <span>메뉴 추가</span>
          <input
            type="text"
            placeholder="메뉴명"
            name="afterMenuName"
            value={newMenu.afterMenuName}
            onChange={changeNewMenu}
          />
          <select name="menuType" value={newMenu.menuType} onChange={changeNewMenu}>
            {Object.keys(menuType).map((key) => (
              <option key={key} value={key}>
                {menuType[key]}
              </option>
            ))}
          </select>
          <button type="button" name="button" onClick={handleAddClick}>
            <span>추가</span>
          </button>
        </AddMenuContainer>
        <SubmitButton>
          <button onClick={handleSubmit}>
            <span>저장하고 다음 단계</span>
          </button>
        </SubmitButton>
      </MenuSettingFormContainer>
    </>
  );
}
