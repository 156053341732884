import React from "react";
import { useSelector } from "react-redux";
import { useRegex } from "../../../../../../hooks/useRegex";
import { AiOutlineUserDelete } from "react-icons/ai";

const Member = ({ company, item, editWorkStatus, openModal, closeModal, deleteMber }) => {
  const { TelFormat } = useRegex();

  const setting = useSelector((state) => state?.user?.setting);
  const user = useSelector((state) => state?.user?.data[0]);

  const isCurrentUser = user?.mberNo === item.mberNo;

  return (
    <li>
      <div className="dot">
        <div></div>
      </div>
      <div className="mberNm">{item.mberNm}</div>
      <div className="username">{item.userId}</div>
      <div className="moblphonNo">{TelFormat(item.mberTel)}</div>
      <div className="work">
        <div
          className="tabs"
          onClick={() => editWorkStatus(item)}
          style={{
            background: item.isWorking ? "rgba(37, 99, 235, 0.7)" : "#bbb",
          }}>
          <span
            className="glider"
            style={{
              transform: `translate(${item.isWorking ? "100%, -50%" : "0, -50%"})`,
            }}></span>
        </div>
      </div>
      <div className="delete">
        {isCurrentUser && (
          <button
            onClick={() =>
              openModal({
                isOpen: true,
                type: "confirmation",
                title: `${isCurrentUser ? "회사 탈퇴" : "멤버 삭제"}`,
                message: [
                  `${company?.cmpnyNm}에서`,
                  `${
                    isCurrentUser ? "탈퇴" : chooseParticle(item.mberNm, "을를") + " 삭제"
                  }하시겠습니까?`,
                ],
                handleConfirm: () => deleteMber(item),
                handleCancel: () => closeModal(),
              })
            }>
            <AiOutlineUserDelete />
          </button>
        )}
      </div>
    </li>
  );
};

export default Member;
