import React from "react";
import { calculateDday } from "../../function/scheduler";
import moment from "moment";

const DDay = ({ item, clickEvent, index }) => {
  return (
    <li key={item.id} onClick={() => clickEvent(item)} className="dDay">
      <div>
        <div className="title">
          {item.icon && (
            <img src={`/img/scheduler/scheduler_icon_${item.icon}`} alt="" style={{ width: 20 }} />
          )}
          <span
            className="font600"
            style={{
              textDecoration: item.completeYn === "Y" ? "line-through" : "none",
            }}>
            {item.title}
          </span>
        </div>
        <p>{moment(item.startDt).format("YYYY-MM-DD")}</p>
      </div>
      <div>{calculateDday(item.startDt)}</div>
    </li>
  );
};

export default DDay;
