import React from "react";
import { StampListContainer } from "../styled/coupon";
import moment from "moment";

const StampHistory = ({ transformValue, history }) => {
  return (
    <StampListContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <ul className="stamps history">
        {history.length > 0 ? (
          <>
            <li className="header">
              <span className="idx"></span>
              <span className="con">교환날짜</span>
            </li>
            {history.map((item, index) => (
              <li>
                <span className="idx">{history.length - index}</span>
                <span className="con">{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</span>
              </li>
            ))}
          </>
        ) : (
          <li className="not">
            <span>교환내역이 존재하지 않습니다.</span>
          </li>
        )}
      </ul>
    </StampListContainer>
  );
};

export default StampHistory;
