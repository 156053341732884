import React from "react";
import { SearchContainer } from "../styled/common";

const Search = ({ placeholder = "", searched = "", setSearched = null, handleSearch = null }) => {
  return (
    <SearchContainer>
      <img src="/img/group/search.png" />
      <input
        type="text"
        placeholder={placeholder}
        value={searched}
        onChange={(e) => setSearched(e.target.value)}
        onKeyDown={(e) => handleEnterKey(e, handleSearch)}
      />
    </SearchContainer>
  );
};

export default Search;
