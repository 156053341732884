import React from "react";
import Comment from "./comment";
import { FiSend } from "react-icons/fi";
import { CommentsContainer } from "../../styled/common";

const Comments = ({ feed, commentShowMore, onSubmit, text, setText, handleDeleteModal }) => {
  return (
    <CommentsContainer>
      {feed.comments && feed.comments.length > 3 && (
        <button
          className="more"
          onClick={() =>
            commentShowMore(feed.feedNo, feed.comments.length > feed.showComments ? "more" : "fold")
          }>
          {feed.comments.length > feed.showComments ? "더보기" : "접기"}
        </button>
      )}
      {feed.comments && feed.comments.length > 0 && (
        <ul className="comments">
          {feed.comments.slice(-feed.showComments).map((item) => (
            <Comment item={item} handleDeleteModal={handleDeleteModal} feed={feed} />
          ))}
        </ul>
      )}
      <form onSubmit={(e) => onSubmit(e, feed.feedNo)}>
        <input
          type="text"
          placeholder="댓글 달기"
          value={text[feed.feedNo] || ""}
          name={feed.feedNo}
          onChange={(e) => setText({ ...text, [e.target.name]: e.target.value })}
        />
        <button
          className={text[feed.feedNo] && text[feed.feedNo].trim() ? "active" : "inactive"}
          type="submit">
          <FiSend />
        </button>
      </form>
    </CommentsContainer>
  );
};

export default Comments;
