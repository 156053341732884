import styled from "styled-components";

export const StampPage = styled.div`
  min-height: 100vh;
  font-family: "Pretendard";
  max-width: 1440px;
  margin: auto;
  width: 100%;
  padding: 50px 20px;
  user-select: none;
  img {
    width: 100%;
    height: 100%;
  }
  div {
    width: 100%;
    background: #3faa98;
    text-align: center;
    padding: 100px 0;
    button {
      font-family: "Pretendard";
      width: 28.57%;
      height: 100px;
      font-size: 30px;
      border-radius: 50px;
      background: #fff;
      color: #3faa98;
      border: none;
      font-weight: 900;
      letter-spacing: -1px;
    }
  }
  @media screen and (max-width: 1050px) {
    div {
      padding: 50px 0;
      button {
        font-size: 20px;
        height: 70px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    div {
      button {
        width: 200px;
        height: 50px;
        font-size: 16px;
      }
    }
  }
`;
