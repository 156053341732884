import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { HiMiniEllipsisVertical } from "react-icons/hi2";
import { useNavigate } from "react-router";

const UnreadNotifications = ({ label, notification, className, handleButtonClick }) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  // 읽음 처리
  const readNotification = async (item) => {
    if (item.readYn === "N") {
      const url = "/api/notificationHistoryUpdate";
      const body = {
        mberNo: user.mberNo,
        allDelYn: "N",
        allReadYn: "N",
        notiNos: [item.notiNo],
        acceptYn: null,
        gbnValue: null,
        readYn: "Y",
        delYn: "N",
      };
      const res = await axios.post(url, body);

      if (res.status === 200) {
        navigateToPage(item);
      }
    } else {
      navigateToPage(item);
    }
  };

  // 페이지 이동
  const navigateToPage = (item) => {
    const navPath = {
      S: () => navigate(`/mypage/detailEvent/${item.refeNo}`),
      R: {
        C: () => navigate(`/mypage/companies`),
        G: () => navigate(`/group/${item.refeNo}`),
      },
      F: () => navigate(`/group/feed`),
      Q: () => inquiryAuthorCheck(item.refeNo),
      RA: () => navigate(`/group/${item.refeNo}`),
    };

    const { notiType, gbn } = item;
    const navFunc = navPath[notiType];

    if (typeof navFunc === "function") {
      navFunc();
    } else if (typeof navFunc === "object" && navFunc[gbn]) {
      navFunc[gbn]();
    }
  };

  const inquiryAuthorCheck = async (refeNo) => {
    if (!refeNo) return;

    const url = "/erp/inquiry";
    const body = { id: refeNo };
    try {
      const res = await axios.post(url, body);
      const data = res.data;

      if (data.mberNo === user.mberNo) {
        navigate("/help");
      } else {
        const cmpny = company.find((cmp) => cmp.cmpnyNo === data.cmpnyNo);
        if (cmpny) {
          navigate("/mypage/company", { state: { cmpny } });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="info">
        <span className="label">{label}</span>
        <button data-name={className} onClick={handleButtonClick}>
          <HiMiniEllipsisVertical />
        </button>
      </div>
      <ul>
        {notification && notification.length > 0 ? (
          notification.map((item) => (
            <li onClick={() => readNotification(item)}>
              <span>{item.title}</span>
            </li>
          ))
        ) : (
          <li>미확인 알림이 존재하지 않습니다.</li>
        )}
      </ul>
    </div>
  );
};

export default UnreadNotifications;
