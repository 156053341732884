import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

import { AnswerContainer } from "../styled/company";
import { SubmitButton } from "../../../styled/mypage";

import { useModal } from "../../../../../hooks/useModal";

import Question from "./answer/question";
import Answerform from "./answer/answerForm";
import InquiryList from "./answer/InquiryList";

const Answer = ({ company }) => {
  const setting = useSelector((state) => state?.user?.setting);
  const user = useSelector((state) => state?.user?.data[0]);

  const [content, setContent] = useState("");
  const [inquiries, setInquiries] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [showStatus, setShowStatus] = useState(true);
  const [transformValue, setTransformValue] = useState(0);

  const { modal, openModal, closeModal } = useModal();

  useEffect(() => {
    setTransformValue((selected ? 1 : 0) * -100);
  }, [selected]);

  useEffect(() => {
    if (!company) return;
    if (page !== 1) {
      setPage(1);
    }
    getInquiries(1, company.cmpnyNo);
  }, [company, showStatus]);

  // 문의 리스트 조회
  const getInquiries = async (page = 1, cmpnyNo) => {
    if (loading) return;

    const url = "/erp/inquiryList";
    const body = { cmpnyNo, page: page, limit: 10, status: showStatus ? null : false };

    try {
      setLoading(true);
      const res = await axios.post(url, body);

      console.log(res);
      if (res.status === 200) {
        setInquiries(res.data.inquiry || []);
        setTotal(res.data.totalCount || 0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 문의 선택
  const selectItem = async (id) => {
    if (!id) return;
    if (selected && selected.id === id) {
      resetState();
    } else {
      const detail = await getInquiryDetail(id);
      if (detail.answerNo) {
        setContent(detail.answer);
      }
      setSelected(detail);
    }
  };

  // 문의 상세보기
  const getInquiryDetail = async (id) => {
    if (loading) return;

    const url = "/erp/inquiry";
    const body = { id };

    try {
      setLoading(true);
      const res = await axios.post(url, body);
      return res.data;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // 페이지 변경
  const changePage = async (newPage) => {
    setPage(newPage);
    await getInquiries(newPage);
    resetState();
  };

  const submitAnswer = async () => {
    if (loading) return;
    if (!content) {
      toast.error("답변 내용이 존재하지 않습니다.");
      return;
    }

    const url = "/erp/inquiry/answer";
    const body = {
      id: selected.id,
      answerNo: user.mberNo,
      answer: content,
    };

    try {
      setLoading(true);
      const res = await axios.post(url, body);

      if (res.status === 200) {
        toast.success(res.data.message);
        getInquiries(1, company.cmpnyNo);
        resetState();
        closeModal();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const resetState = () => {
    setContent("");
    setSelected(null);
  };

  return (
    <>
      {modal}
      <AnswerContainer className={setting?.mode}>
        <div className="slideWrapper">
          <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
            <InquiryList
              inquiries={inquiries}
              page={page}
              changePage={changePage}
              total={total}
              selectItem={selectItem}
              showStatus={showStatus}
              setShowStatus={setShowStatus}
            />
          </div>
          <div className="slideContent" style={{ transform: `translateX(${transformValue}%)` }}>
            {selected && (
              <div className="viewer">
                <Question selected={selected} resetState={resetState} />
                <Answerform content={content} setContent={setContent} selected={selected} />
              </div>
            )}
          </div>
        </div>
        {selected && !selected.answerNo && (
          <SubmitButton>
            <button
              onClick={() =>
                openModal({
                  isOpen: true,
                  type: "confirmation",
                  title: "답글 작성",
                  message: [`해당 답글을 작성하시겠습니까?`],
                  handleConfirm: () => submitAnswer(),
                  handleCancel: () => closeModal(),
                })
              }>
              <span>답변완료</span>
            </button>
          </SubmitButton>
        )}
      </AnswerContainer>
    </>
  );
};

export default Answer;
