import React from "react";
import Pagination from "react-js-pagination";
import { FaqContainer, HelpItemContainer } from "../../../shopping/component/styled/defaultTheme";
import { useOutletContext } from "react-router";
import InquiryItem from "./inquiryItem";
import SectionLink from "../../../common/sectionLink";

const HelpBoard = ({
  setActive,
  inquiries,
  setImages,
  setIsDetail,
  selected,
  selectItem,
  setFormData,
  handleDeleteInquiry,
  page,
  total,
  changePage,
}) => {
  const { theme } = useOutletContext();

  const navEditPage = () => {
    setActive(1);
    setFormData({ ...selected });
  };

  return (
    <FaqContainer className={`theme-${theme}`}>
      {theme === 2 && <SectionLink path="1:1 문의" />}
      <ul>
        <HelpItemContainer className="header">
          <span className="index">번호</span>
          <span className="title">제목</span>
          <span className="status">답변상태</span>
          <span className="date">등록일</span>
        </HelpItemContainer>
        {inquiries && inquiries.length > 0 ? (
          inquiries.map((item) => (
            <InquiryItem
              key={item.id}
              item={item}
              selected={selected}
              setImages={setImages}
              setIsDetail={setIsDetail}
              navEditPage={navEditPage}
              handleDeleteInquiry={handleDeleteInquiry}
              selectItem={selectItem}
            />
          ))
        ) : (
          <HelpItemContainer className="not">문의한 내용이 없습니다.</HelpItemContainer>
        )}
      </ul>
      <div className="writing">
        <button onClick={() => setActive(1)}>작성하기</button>
      </div>
      {inquiries && inquiries.length > 0 && (
        <Pagination
          activePage={page}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={3}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={changePage}
        />
      )}
    </FaqContainer>
  );
};

export default HelpBoard;
