export const weatherStates = {
  0: { text: "맑음", icon: "2600", color: "#dab202" },
  1: { text: "구름많음", icon: "2601", color: "#0d5781" },
  2: { text: "흐림", icon: "2601", color: "#173747" },
  3: { text: "비", icon: "1F327", color: "#028bda" },
  4: { text: "비/눈", icon: "1F328", color: "#028bda" },
  5: { text: "눈", icon: "2744", color: "#243ab6" },
  6: { text: "소나기", icon: "2614", color: "#8e03aa" },
};

export const icons = [
  { src: "artist_palette_3d.png", name: "팔레트" },
  { src: "badminton_3d.png", name: "배드민턴" },
  { src: "bank_3d.png", name: "은행" },
  { src: "baseball_3d.png", name: "야구" },
  { src: "basketball_3d.png", name: "농구" },
  { src: "beer_mug_3d.png", name: "맥주잔" },
  { src: "birthday_cake_3d.png", name: "케이크" },
  { src: "bottle_with_popping_cork_3d.png", name: "병" },
  { src: "bouquet_3d.png", name: "꽃다발" },
  { src: "bowling_3d.png", name: "볼링" },
  { src: "clinking_beer_mugs_3d.png", name: "맥주" },
  { src: "credit_card_3d.png", name: "카드" },
  { src: "flag_in_hole_3d 1.png", name: "골프" },
  { src: "fork_and_knife_with_plate_3d.png", name: "접시" },
  { src: "guide_dog_3d.png", name: "강아지" },
  { src: "hospital_3d.png", name: "병원" },
  { src: "hot_beverage_3d.png", name: "커피" },
  { src: "monorail_3d.png", name: "기차" },
  { src: "newspaper_3d.png", name: "신문" },
  { src: "open_book_3d.png", name: "책" },
  { src: "pill_3d.png", name: "알약" },
  { src: "ping_pong_3d.png", name: "탁구" },
  { src: "popcorn_3d.png", name: "팝콘" },
  { src: "shopping_cart_3d.png", name: "쇼핑 카트" },
  { src: "soccer_ball_3d.png", name: "축구공" },
  { src: "teacup_without_handle_3d.png", name: "찻잔" },
  { src: "woman_with_veil_3d_light.png", name: "여성" },
  { src: "writing_hand_3d_light.png", name: "글" },
];
