import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import Pagination from "react-js-pagination";

import KioskHeader from "./component/common/kioskHeader";
import KioskFooter from "./component/common/kioskFooter";
import KioskList from "./component/common/kioskList";
import KioskTable from "./component/common/kioskTable";
import ExchangeModal from "./component/kioskCoupon/exchangeModal";

import { useDisableScroll } from "./component/function/kiosk";

import { Container } from "./component/styled/common";
import { toast } from "react-toastify";
import { KioskCouponContainer } from "./component/styled/coupon";

const KioskCouponPage = () => {
  useDisableScroll();

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const [openModal, setOpenModal] = useState(false);
  const [data, setData] = useState([]);
  const [searched, setSearched] = useState("");
  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewType, setViewType] = useState(0);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    getAllStampStatuses(1);
  }, []);

  // 검색
  const handleSearch = (flag = false) => {
    setPage(1);
    getAllStampStatuses(1, !flag);
  };

  const handleModalOpen = async (item = null) => {
    const detail = await getDetailStatus(item);
    if (!detail) {
      toast.error("잠시 후 다시 시도해주세요.");
    }
    const history = await getRewardHistory(item);
    setHistory(history);

    setSelected({ ...detail });
    setOpenModal(true);
  };

  const getDetailStatus = async (item) => {
    if (!item) {
      return null;
    }

    const url = "/erp/stamps/detail";
    const body = {
      mberNo: item.mberNo,
      regionNo: item.regionNo,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  };

  const getRewardHistory = async (item) => {
    if (!item) {
      return null;
    }

    const url = "/erp/getRewardHistory";
    const body = {
      mberNo: item.mberNo,
      regionNo: item.regionNo,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      return res.data;
    } else {
      return null;
    }
  };

  const getAllStampStatuses = async (current = 1, flag = false) => {
    if (loading) return;

    setLoading(true);

    const url = "/erp/stamps/all";
    const body = {
      regionNo: "042",
      page: current,
      limit: 10,
    };

    if (flag && searched) {
      body.keyword = searched;
    }

    try {
      const res = await axios.post(url, body);
      console.log(res.data);

      if (res.status === 200) {
        setData(res.data.stampStatuses);
        setTotal(res.data.totalCount);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleModalClose = async () => {
    await getAllStampStatuses(1);
    setOpenModal(false);
    setViewType(0);
    setSelected(null);
    setPage(1);
  };

  const handleExchange = async () => {
    const url = "/erp/addReward";
    const body = {
      mberNo: selected.mberNo,
      regionNo: selected.regionNo,
    };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        toast.success("보상이 지급되었습니다.");

        const detail = await getDetailStatus(selected);
        setSelected({ ...detail });

        const history = await getRewardHistory(selected);
        setHistory(history);

        setViewType(0);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error?.[0]?.message || "알 수 없는 에러가 발생했습니다.";
      toast.error(errorMessage);
    }
  };

  return (
    <Container>
      <KioskCouponContainer>
        <KioskHeader
          title={"스탬프"}
          handleSearch={handleSearch}
          searched={searched}
          setSearched={setSearched}
        />
        <div
          className="content"
          style={{ height: isTablet ? "calc(100% - 30vh)" : "calc(100% - 9vh - 9vw)" }}>
          {isTablet ? (
            <KioskList data={data} type="coupon" handleModalOpen={handleModalOpen} />
          ) : (
            <KioskTable data={data} type="coupon" handleModalOpen={handleModalOpen} />
          )}
        </div>
        <Pagination
          activePage={page}
          itemsCountPerPage={10}
          totalItemsCount={total}
          pageRangeDisplayed={10}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={(activePage) => {
            setPage(activePage);
            getAllStampStatuses(activePage);
          }}
        />
        <KioskFooter />
        {openModal && (
          <ExchangeModal
            selected={selected}
            handleModalClose={handleModalClose}
            handleExchange={handleExchange}
            viewType={viewType}
            setViewType={setViewType}
            history={history}
          />
        )}
      </KioskCouponContainer>
    </Container>
  );
};

export default KioskCouponPage;
