import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";

import ProductAddForm from "./component/product/productAddForm";
import ProductForm from "./component/product/productForm";
import ProductMenu from "./component/product/productMenu";
import ProductSyncForm from "./component/product/productSyncForm";
import HomePageSettingMenu from "./component/common/homepageSettingMenu";

import { setProductIndex } from "../../../store/compoIndex";

import { Container } from "../styled/mypage";
import PageInfo from "../pageInfo";
import { useNavigate } from "react-router";

export default function Product() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cmpnyNo, setCmpnyNo] = useState();
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoriesObj, setCategoriesObj] = useState({});
  const [synced, setSynced] = useState({});
  const [filtered, setFiltered] = useState({
    minBasePrice: 0,
    maxBasePrice: 0,
    brand: "",
    name: "",
    categoryId: null,
  });

  useEffect(() => {
    const company = JSON.parse(sessionStorage.getItem("homepageCompany"));

    if (!company) {
      console.log("선택된 회사가 존재하지 않습니다.");
      navigate("/mypage");
    } else {
      setCmpnyNo(company.cmpnyNo);
    }

    dispatch(setProductIndex(0));
    getSyncProduct(company.cmpnyNo);
    getCategory();
  }, []);

  const user = useSelector((state) => state?.user?.data[0]);
  const index = useSelector((state) => state.compoIndex.product);
  const setting = useSelector((state) => state?.user?.setting);

  const getCategory = async () => {
    const url = "/api/categories";
    const res = await axios.get(url);

    if (res.status === 200) {
      const data = res.data || [];
      const sortedCategories = data.sort((a, b) => {
        return a.path.localeCompare(b.path, undefined, { numeric: true });
      });

      setCategories(sortedCategories);
      setCategoriesObj(
        data.reduce((acc, ele) => {
          acc[ele.categoryId] = ele.categoryName;
          return acc;
        }, {}),
      );
    }
  };

  const getSyncProduct = async (cmpnyNo) => {
    try {
      const url = "/erp/vendorProducts/get";
      const query = `
        query VendorProducts(
          $cmpnyNo: Int!
          $name: String
          $brand: String
          $categoryId: Int
          $minPrice: Int
          $maxPrice: Int
          $limit: Int
          $offset: Int
        ) {
          vendorProducts(
            filter: {
              cmpnyNo: $cmpnyNo
              name: $name
              brand: $brand
              categoryId: $categoryId
              minPrice: $minPrice
              maxPrice: $maxPrice
              limit: $limit
              offset: $offset
            }
          ) {
            totalCount
            products {
              id
              cmpnyNo
              name
              description
              brand
              specifications
              categoryId
              media
              tags
              quantity
              price
              createdAt
              updatedAt
              cloudProduct {
                id
              }
            }
          }
        }
      `;

      const variables = {
        cmpnyNo: parseInt(cmpnyNo, 10),
      };

      console.log(variables);

      Object.entries(filtered).forEach(([key, value]) => {
        if (value) {
          if (key === "minBasePrice" || key === "maxBasePrice") {
            variables[key === "minBasePrice" ? "minPrice" : "maxPrice"] = parseInt(
              value.toString().replace(/,/g, ""),
            );
          } else {
            variables[key] = key === "categoryId" ? parseInt(value, 10) : value;
          }
        }
      });

      const body = { query, variables };
      const res = await axios.post(url, body);

      if (res.status === 200) {
        const { products } = res.data;
        setProducts(products);
        setSynced(
          products.reduce((acc, ele) => {
            acc[ele.cloudProduct.id] = true;
            return acc;
          }, {}),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container className={setting.mode}>
      <div className="content">
        <PageInfo label="제품 연동" />
        <HomePageSettingMenu />
        <ProductMenu index={index} />
        <Components
          index={index}
          cmpnyNo={cmpnyNo}
          products={products}
          setProducts={setProducts}
          categories={categories}
          categoriesObj={categoriesObj}
          synced={synced}
          getSyncProduct={getSyncProduct}
          filtered={filtered}
          setFiltered={setFiltered}
        />
      </div>
    </Container>
  );
}

function Components({
  index,
  cmpnyNo,
  products,
  setProducts,
  categories,
  categoriesObj,
  synced,
  getSyncProduct,
  filtered,
  setFiltered,
}) {
  return (
    <>
      {
        [
          <ProductForm
            getSyncProduct={getSyncProduct}
            categories={categories}
            categoriesObj={categoriesObj}
            products={products}
            filtered={filtered}
            setFiltered={setFiltered}
            cmpnyNo={cmpnyNo}
          />,
          <ProductSyncForm
            categories={categories}
            categoriesObj={categoriesObj}
            synced={synced}
            syncProduct={products}
            getSyncProduct={getSyncProduct}
            cmpnyNo={cmpnyNo}
          />,
          <ProductAddForm
            categoriesObj={categoriesObj}
            getSyncProduct={getSyncProduct}
            cmpnyNo={cmpnyNo}
          />,
        ][index]
      }
    </>
  );
}
