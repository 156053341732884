import React from "react";
import { TbArrowBackUp } from "react-icons/tb";

const Question = ({ selected, resetState }) => {
  return (
    <div className="question">
      <div className="label">
        <span>문의내용</span>
        <button onClick={() => resetState()}>
          <TbArrowBackUp />
        </button>
      </div>
      <span className="tit">제목</span>
      <div className="title">{selected.title}</div>
      <span className="tit">내용</span>
      <div className="content" dangerouslySetInnerHTML={{ __html: selected.content }}></div>
    </div>
  );
};

export default Question;
