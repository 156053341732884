import React from "react";
import { StampListContainer } from "../styled/coupon";
import { Buttons } from "../styled/common";
import moment from "moment";
import { toast } from "react-toastify";

const StampList = ({ selected, transformValue, setViewType, rewardAvailableCnt }) => {
  return (
    <StampListContainer
      className="slideContent"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <ul className="stamps">
        {selected.stamps && (
          <>
            <li className="header">
              <span className="idx"></span>
              <span className="con">적립날짜</span>
              <span className="tit">가게명</span>
            </li>
            {selected.stamps.map((item, index) => (
              <li>
                <span className="idx">{selected.stamps.length - index}</span>
                <span className="con">{moment(item.createdAt).format("YYYY-MM-DD HH:mm")}</span>
                <span className="tit">
                  {item.cmpnyNm && item.cmpnyNm !== "Unknown" ? item.cmpnyNm : "-"}
                </span>
              </li>
            ))}
          </>
        )}
      </ul>
      <Buttons>
        <button
          onClick={() =>
            !rewardAvailableCnt
              ? toast.error("교환 가능한 개수가 존재하지 않습니다.")
              : setViewType(2)
          }
          className="submit"
          style={{ backgroundColor: !rewardAvailableCnt && "#999" }}>
          교환
        </button>
      </Buttons>
    </StampListContainer>
  );
};

export default StampList;
