import React, { useEffect, useRef } from "react";
import { SelectedProductContainer } from "../../styled/salesCheck";
import { Chart, ArcElement, Tooltip, Legend, DoughnutController } from "chart.js";

Chart.register(ArcElement, Tooltip, Legend, DoughnutController);

const SelectedProduct = ({ hoverInfo }) => {
  const max = 120;
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const createDoughnut = (canvas) => {
    if (!canvas) return;
    if (chartInstance.current) chartInstance.current.destroy();

    chartInstance.current = new Chart(canvas, {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [hoverInfo ? hoverInfo.value : 0, hoverInfo ? max - hoverInfo.value : max],
            backgroundColor: ["#4E5AC3", "#E0E0E0"],
            borderWidth: 0,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: "80%",
        plugins: {
          legend: { display: false },
          tooltip: { enabled: false },
        },
      },
    });
  };

  useEffect(() => {
    createDoughnut(chartRef?.current);

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [hoverInfo]);

  return (
    <SelectedProductContainer className={`hoverProductDetail ${hoverInfo ? "active" : "inactive"}`}>
      <div className="doughnut">
        <canvas ref={chartRef} />
      </div>
      <div className="info">
        <strong>{hoverInfo ? hoverInfo.value : ""}</strong>
        <span>{hoverInfo ? hoverInfo.label : ""}</span>
      </div>
    </SelectedProductContainer>
  );
};

export default SelectedProduct;
