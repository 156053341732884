import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { SlArrowDown, SlArrowUp } from "react-icons/sl";
import { SideMenuCalendar } from "../../styled/scheduler";
import EventCalendar from "./calendar/eventCalendar";
import MonthSelector from "./calendar/monthSelector";

const CombinedCalendar = ({
  isAnnualCalendar,
  setIsAnnualCalendar,
  handleDateClick,
  onDate,
  data,
  clickDate,
  sideCalendarRef,
  version,
  userDate,
  setUserDate,
}) => {
  const calendarArea = useRef();
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  const toggleAnnualCalendar = () => setIsAnnualCalendar((prev) => !prev);

  const handleMonthClick = (month) => {
    handleDateClick(`${userDate.year}-${String(month).padStart(2, "0")}-01`);
    setIsAnnualCalendar(false);
  };

  const changeYear = (offset) => {
    setUserDate((prev) => ({ ...prev, year: prev.year + offset }));
  };

  return (
    <SideMenuCalendar className={isDarkMode}>
      <button
        className="select"
        onClick={toggleAnnualCalendar}
        style={{ left: isAnnualCalendar ? 70 : 95 }}>
        {isAnnualCalendar ? <SlArrowUp /> : <SlArrowDown />}
      </button>

      {isAnnualCalendar ? (
        <MonthSelector
          toggleAnnualCalendar={toggleAnnualCalendar}
          userDate={userDate}
          changeYear={changeYear}
          handleMonthClick={handleMonthClick}
        />
      ) : (
        <EventCalendar
          calendarArea={calendarArea}
          onDate={onDate}
          handleDateClick={handleDateClick}
          sideCalendarRef={sideCalendarRef}
          clickDate={clickDate}
          data={data}
          version={version}
        />
      )}
    </SideMenuCalendar>
  );
};

export default CombinedCalendar;
