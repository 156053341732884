import { useOutletContext } from "react-router";

import CartForm from "./component/cartForm";

import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";

import { ChicCartContaienr } from "../shopping/component/styled/chicTheme";
import { ModernCartContaienr } from "../shopping/component/styled/modernTheme";
import { CuteCartContaienr } from "../shopping/component/styled/cuteTheme";
import { SimpleCartContaienr } from "../shopping/component/styled/simpleTheme";
import { SmCartContaienr } from "../shopping/component/styled/smTheme";
import { DefaultCartContainer } from "../shopping/component/styled/defaultTheme";
import ChicBanner from "../shopping/component/themes/chic/banner";
import SmBanner from "../shopping/component/themes/sm/banner";

const Cart = () => {
  const { theme } = useOutletContext();

  const containers = {
    0: DefaultCartContainer,
    1: ChicCartContaienr,
    2: ModernCartContaienr,
    3: CuteCartContaienr,
    4: SimpleCartContaienr,
    sm: SmCartContaienr,
  };

  const banners = {
    1: ChicBanner,
    2: ModernBanner,
    3: CuteBanner,
    4: SimpleBanner,
    sm: SmBanner,
  };

  const renderComponent = () => {
    const Container = containers[theme] || DefaultCartContainer;
    const Banner = banners[theme];

    const getBannerInfo = (theme) => {
      if (theme === "sm") {
        return {
          page: `장바구니`,
          message: `고객님의 편리함을 위해\n최선을 다하겠습니다.`,
        };
      }
      return { imgage: "cart.jpg", text: "cart" };
    };

    return (
      <Container>
        {Banner && <Banner info={getBannerInfo(theme)} />}
        <CartForm />
      </Container>
    );
  };

  return <>{renderComponent()}</>;
};

export default Cart;
