import React from "react";
import { FiMoreVertical, FiSend } from "react-icons/fi";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";

import FeedSwiper from "../feedSwiper";
import Comment from "./comment";
import FeedHeader from "./feedHeader";
import Comments from "./comments";
import FeedContent from "./feedContent";

const FeedItem = ({
  index,
  feed,
  setFeedList,
  setIsBigImages,
  text,
  setText,
  active,
  setActive,
  handleDeleteModal,
  editFeed,
  onSubmit,
  menuRef,
  feedRefs,
}) => {
  const { id } = useParams();

  //  피드 댓글 더보기
  const commentShowMore = (feedNo, state) => {
    setFeedList((feed) =>
      feed.map((item) =>
        item.feedNo === feedNo
          ? { ...item, showComments: state === "fold" ? 3 : item.showComments + 3 }
          : item,
      ),
    );
  };

  //  피드 본문내용 더보기
  const feedShowMore = (feedNo) => {
    setFeedList((feed) =>
      feed.map((item) =>
        item.feedNo === feedNo ? { ...item, showContent: !item.showContent } : item,
      ),
    );
  };

  const handleClickImage = (feed) => {
    const { imgUrl, nickNm } = feed;
    const image = JSON.parse(imgUrl);

    if (!image) return;

    const images = image.map((item) => ({
      ...item,
      path: item,
      createNm: nickNm ? nickNm : "닉네임 미설정",
      createDt: "",
    }));

    setIsBigImages([...images]);
  };

  return (
    <li ref={(el) => (feedRefs.current[index] = el)}>
      <FeedHeader
        feed={feed}
        id={id}
        active={active}
        setActive={setActive}
        editFeed={editFeed}
        handleDeleteModal={handleDeleteModal}
        menuRef={menuRef}
      />
      <FeedContent feed={feed} handleClickImage={handleClickImage} feedShowMore={feedShowMore} />
      <Comments
        feed={feed}
        commentShowMore={commentShowMore}
        onSubmit={onSubmit}
        text={text}
        setText={setText}
        handleDeleteModal={handleDeleteModal}
      />
    </li>
  );
};

export default FeedItem;
