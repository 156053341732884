import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { JoinFormContainer } from "../../styled/groupDetails";

const JoinForm = ({ id, grp, getGrpMbers }) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const navigate = useNavigate();
  const [gbnValue, setGbnValue] = useState("");
  const [nickNm, setNickNm] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isChkDuplNickNm, setIsChkDuplNickNm] = useState(false);

  // 모임 가입
  const signGroup = async () => {
    if (grp?.gbn && !gbnValue) {
      toast.error("기수를 입력해주세요.");
      return;
    }
    if (!isChkDuplNickNm) {
      toast.error("닉네임 중복 확인을 해주세요.");
      return;
    }

    if (isSubmitting) {
      toast.error("가입이 진행 중입니다.");
      return;
    }
    setIsSubmitting(true);

    const url = "/api/groupMber";
    const body = {
      groupNo: id,
      mberNo: user.mberNo,
      auth: 4,
      createMberId: user.userName,
      updateMberId: user.userName,
      delYn: "N",
      nickNm: nickNm,
    };

    // 명칭 사용
    if (grp?.gbn) {
      body.gbnValue = gbnValue;
    }
    // 가입 승인제 사용
    if (grp?.approvalUseYn === "Y") {
      body.isApplication = "Y";
    }

    const res = await axios.put(url, body);

    if (res.status === 200) {
      if (grp.approvalUseYn === "Y") {
        toast.success("모임 가입이 신청되었습니다.");
        navigate("/group");
      } else {
        toast.success("모임 가입이 완료되었습니다.");
        getGrpMbers(id);
      }
    } else {
      alert("잠시후 다시 시도해주세요");
    }
    setIsSubmitting(false);
  };

  // 닉네임 중복 체크
  const chkDuplNickNm = async () => {
    if (!nickNm || !nickNm.trim()) {
      toast.error("닉네임을 입력해주세요.");
      return;
    }
    if (nickNm.length < 2 || nickNm.length > 12) {
      toast.error("닉네임의 길이는 2~12자리여야 합니다.");
      return;
    }

    const url = "/api/duplNickName";
    const body = {
      groupNo: id,
      delYn: "N",
      nickNm: nickNm,
    };

    const res = await axios.post(url, body);
    if (res.data.success) {
      toast.success("사용 가능한 닉네임입니다.");
      setIsChkDuplNickNm(true);
    } else {
      toast.error("이미 사용 중인 닉네임입니다.");
      setIsChkDuplNickNm(false);
    }
  };

  // 닉네임 변경
  const changeNickName = (e) => {
    setNickNm(e.target.value);
    setIsChkDuplNickNm(false);
  };

  const FormInput = ({ label, type, placeholder, value, onChange, extra }) => (
    <div>
      <p>{label}</p>
      <label>
        <input type={type} placeholder={placeholder} value={value} onChange={onChange} />
        {extra}
      </label>
    </div>
  );

  return (
    <JoinFormContainer>
      <div className="label">
        <div className="image">
          <img src={grp?.imgUrl ? imgHandler(grp.imgUrl) : "/img/group/group_singup.png"} />
        </div>
        <span>
          <strong>{grp?.groupName}</strong>에 오신걸 환영합니다!
        </span>
      </div>
      {(grp?.gbn || grp?.nickUseYn === "Y") && (
        <div className="form">
          {grp?.gbn && (
            <div>
              <p>명칭</p>
              <label>
                <input
                  type="text"
                  value={gbnValue}
                  onChange={(e) => setGbnValue(e.target.value)}
                  placeholder="명칭을 입력해주세요."
                />
                <span>{grp?.gbn}</span>
              </label>
            </div>
          )}
          {grp?.nickUseYn === "Y" && (
            <div>
              <p>닉네임</p>
              <label>
                <input
                  type="text"
                  onChange={(e) => changeNickName(e)}
                  value={nickNm}
                  placeholder="닉네임을 입력해주세요."
                />
                <button onClick={chkDuplNickNm}>중복 확인</button>
              </label>
            </div>
          )}
        </div>
      )}
      <button onClick={signGroup} className="submit">
        <span>가입하기</span>
      </button>
    </JoinFormContainer>
  );
};

export default JoinForm;
