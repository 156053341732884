import React from "react";
import ScheduleSwiper from "./swiper/scheduleSwiper";
import { HiOutlinePlus } from "react-icons/hi2";
import { useMediaQuery } from "react-responsive";

const Scheduler = ({ label, schedules, className, handleButtonClick }) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  return (
    <div>
      <div className="info">
        <span className="label">{label}</span>
        <button data-name={className} onClick={handleButtonClick}>
          <HiOutlinePlus />
        </button>
      </div>
      {schedules && schedules.length > 0 ? (
        <ScheduleSwiper data={schedules} perView={isMobile ? 1.5 : 2.2} />
      ) : (
        <span>일정이 존재하지 않습니다.</span>
      )}
    </div>
  );
};

export default Scheduler;
