import { useSelector } from "react-redux";
import RegisterNoticeWriteForm from "./component/register/registerNoticeWriteForm";
import { Container } from "../styled/mypage";
import PageInfo from "../pageInfo";

export default function RegisterNoticeWrite() {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <Container className={setting?.mode}>
      <PageInfo label="공지사항 등록" />
      <RegisterNoticeWriteForm />
    </Container>
  );
}
