import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import Modal from "../../../../../../common/modal";

import { GoAlertFill } from "react-icons/go";
import { DeleteGroupContainer } from "../../../styled/groupDetails";

const Withdrawal = ({ member, openModal, closeModal }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  // 모임 탈퇴
  const onClickModalCheck = async () => {
    const url = "/api/groupMber";
    const req = {
      groupNo: parseInt(id),
      mberNo: user.mberNo,
      auth: member.auth,
      createMberId: user.userName,
      updateMberId: user.userName,
      delYn: "Y",
      seq: member.seq,
    };

    const res = await axios.put(url, req);
    if (res.status === 200) {
      toast.success("모임 탈퇴가 완료되었습니다.");
      navigate("/group");
    } else {
      toast.error("잠시후 다시 시도해주세요");
    }
  };

  return (
    <DeleteGroupContainer>
      <div className="msg">
        <div className="title">
          <GoAlertFill />
          <span>모임 탈퇴 안내</span>
        </div>
        <div className="info">
          <p>
            모임을 탈퇴하더라도 이전에 작성하신 게시글은 <strong>삭제되지 않고 유지</strong>
            됩니다.
          </p>
          <p>
            해당 모임이 <strong>비공개</strong> 설정일 경우
          </p>
          <p>
            추후 다시 가입하고자 할 경우 초대를 받아야만
            <strong>재가입이 가능</strong>합니다.
          </p>
          <p>
            또한, 이 모임이 <strong>가입 승인제</strong>로 운영되고 있는 경우
          </p>
          <p>
            재가입 시에는 <strong>모임장의 승인</strong>이 필요함을 유념해 주시기 바랍니다.
          </p>
          <p>탈퇴 결정이 확정적이라면, 아래의 버튼을 클릭하여 진행해 주십시오.</p>
        </div>
        <div className="btnArea">
          <button
            onClick={() =>
              openModal({
                isOpen: true,
                type: "confirmation",
                title: "모임 탈퇴",
                message: [`모임을 탈퇴하시겠습니까?`],
                handleConfirm: () => onClickModalCheck(),
                handleCancel: () => closeModal(),
              })
            }>
            탈퇴하기
          </button>
        </div>
      </div>
    </DeleteGroupContainer>
  );
};

export default Withdrawal;
