import styled from "styled-components";

export const TitleWrap = styled.section`
  display: block;
  margin-top: 7rem;
`;

export const TitleText = styled.span`
  font-size: 1.4rem;
  font-weight: 800;
`;
