import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useLocation, useNavigate } from "react-router";
import { Link, useNavigate, useParams } from "react-router-dom";

import { handleLogin } from "../../../../store/user";
import { getSetting } from "../../../../store/scheduler";

import axios from "axios";

import LoginForm from "../../../login/component/loginForm";
import JoinForm from "./join/joinForm";
import { JoinContainer } from "../styled/groupDetails";
import useScrollLock from "../../../../hooks/useScrollLock";

const Join = ({ grp, getGrpMbers, getGroupDetail, isJoin, setIsJoin }) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const user = useSelector((state) => state?.user?.data[0]);

  // 캘린더 설정 조회
  const getSchedulerSetting = async (mber) => {
    const url = "/api/toktokSettingList";
    const body = { mberNo: mber.mberNo };
    const res = await axios.post(url, body);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));
    }
  };

  useScrollLock(isJoin);

  return (
    <JoinContainer className={!isJoin ? "" : "form"}>
      {!isJoin ? (
        <button className="join" onClick={() => setIsJoin(true)}>
          가입하기
        </button>
      ) : (
        <>
          {user ? (
            <JoinForm id={id} grp={grp} getGrpMbers={getGrpMbers} getGroupDetail={getGroupDetail} />
          ) : (
            <LoginForm getSchedulerSetting={getSchedulerSetting} />
          )}
        </>
      )}
    </JoinContainer>
  );
};

export default Join;
