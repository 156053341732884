import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import { GroupContainer } from "./component/styled/common";
import { DeleteMessage, GroupTitle } from "./component/styled/groupDetails";

import { hideLoading, showLoading } from "../../store/loading";

import PageInfo from "./component/common/pageInfo";
import Join from "./component/groupDetails/join";
import HamburgerMenu from "./component/common/hamburgerMenu";
import DetailViewer from "./component/groupDetails/detailViewer";
import moment from "moment";

import { FiHome, FiSearch, FiSettings, FiUsers } from "react-icons/fi";
import { IoIosChatboxes } from "react-icons/io";

const GroupDetails = () => {
  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const display = location.state?.display;
  const isFeed = location.state?.isFeed;

  const user = useSelector((state) => state?.user?.data[0]);

  const [group, setGroup] = useState({});
  const [members, setMembers] = useState([]);
  const [active, setActive] = useState(0);
  const [viewJoinButton, setViewJoinButton] = useState(false);
  const [isJoin, setIsJoin] = useState(false);
  const [member, setMember] = useState(null);

  useEffect(() => {
    if (!checkParam()) {
      return;
    }

    setActive(0);
    getGroupDetail();

    if (isFeed) {
      setActive(1);
    }
  }, [id]);

  const checkParam = () => {
    if (isNaN(id) || id === "0") {
      navigate("/group");
      return false;
    }
    return true;
  };

  // 모임 조회
  const getGroupDetail = async () => {
    dispatch(showLoading());
    const url = "/api/groupList";
    const req = getDataCond();

    try {
      const res = await axios.post(url, req);
      const data = res?.data?.content[0];

      if (data) {
        setGroup(data);
        getGroupMembers(data);
      } else {
        toast.error("존재하지 않는 모임입니다.");
        navigate("/group");
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 모임 멤버 조회
  const getGroupMembers = async (grp) => {
    const url = "/api/groupMberList";
    const req = getDataCond();
    try {
      const res = await axios.post(url, req);
      const data = res?.data?.content;

      if (data) {
        setMembers(data);

        const mber = data?.filter((item) => item?.mberNo === user?.mberNo)?.[0];
        if (mber) {
          setMember(mber);
        } else {
          setMember(null);

          if (grp.displayYn === "N" && !display) {
            toast.error("해당 모임은 비공개 모임입니다.");
            navigate("/group");
          }

          getApplyMembers(grp);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  const getApplyMembers = async () => {
    const url = "/api/groupMberList";
    const body = getDataCond();
    body.approvalYn = "N";

    try {
      const res = await axios.post(url, body);
      const data = res?.data?.content || [];
      const mber = data?.filter((item) => item?.mberNo === user?.mberNo)?.[0];

      if (!mber) {
        setViewJoinButton(true);
      } else {
        // setPendingMember(mber);
      }
    } catch (error) {}
  };

  const getDataCond = () => {
    return {
      groupNo: id,
      offset: 0,
      delYn: "N",
      pageNumber: 0,
      pageSize: 1,
      paged: false,
    };
  };

  const tabs = [
    { name: "홈", icon: FiHome },
    { name: "커뮤니티", icon: IoIosChatboxes },
    { name: "검색", icon: FiSearch },
    { name: "멤버", icon: FiUsers },
    { name: "설정", icon: FiSettings },
  ];

  const tabClick = (idx) => {
    setActive(idx);
  };

  return (
    <GroupContainer>
      <div className="inner">
        <>
          <PageInfo title={group.groupName} setActive={setActive} />
          {group.delDt && (
            <DeleteMessage>
              <span>해당 모임은 </span>
              <span>
                <strong className="time">{moment(group.delDt).format("M월 D일 H시 m분")}</strong>에
                삭제될 예정입니다.
              </span>
            </DeleteMessage>
          )}
          <GroupTitle>{tabs[active].name}</GroupTitle>
          <HamburgerMenu active={active} member={member} tabs={tabs} tabClick={tabClick} />
          <DetailViewer
            active={active}
            members={members}
            group={group}
            member={member}
            setActive={setActive}
            getGroupDetail={getGroupDetail}
            getGroupMembers={getGroupMembers}
            setMembers={setMembers}
            tabClick={tabClick}
          />
        </>
        {viewJoinButton && (
          <Join
            member={member}
            grp={group}
            getGrpMbers={getGroupMembers}
            getGroupDetail={getGroupDetail}
            isJoin={isJoin}
            setIsJoin={setIsJoin}
          />
        )}
      </div>
    </GroupContainer>
  );
};

export default GroupDetails;
