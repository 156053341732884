import React, { useEffect } from "react";
import { AppInstallContainer } from "./styled/common";

const AppInstall = () => {
  const url = getAppStoreUrl();

  useEffect(() => {
    if (url) {
      window.location.href = url;
    }
  }, [url]);

  return (
    <AppInstallContainer>
      <button
        onClick={() => {
          if (url) {
            window.location.href = url;
          }
        }}>
        앱 설치
      </button>
    </AppInstallContainer>
  );
};

export default AppInstall;
