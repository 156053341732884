import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";

import { MakeCompanyDomainContainer } from "./component/styled/homepage";
import { Container, SubmitButton } from "../styled/mypage";

import PageInfo from "../pageInfo";

const MakeDomainPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const inputRef = useRef(null);

  const cmpnyNo = location.state?.cmpnyNo;

  const setting = useSelector((state) => state?.user?.setting);
  const user = useSelector((state) => state?.user?.data[0]);

  const [inputDomain, setInputDomain] = useState("");
  const [domainCheck, setDomainCheck] = useState(false);

  useEffect(() => {
    checkedDomain();
  }, []);

  const checkedDomain = async (flag) => {
    if (!cmpnyNo) return;

    const url = "/api/domainSettingLoad";
    const body = {
      delYn: "N",
    };

    if (flag) {
      body.domain = inputDomain;
    } else {
      body.cmpnyNo = cmpnyNo;
    }

    if (flag && !inputDomain) {
      toast.error("도메인을 입력해주세요.");
      return;
    }

    const response = await axios.post(url, body);
    if (flag) {
      if (response?.data?.domain || inputDomain === "") {
        toast.error("사용 할 수 없는 도메인입니다.");
        inputRef.current.focus();
        setDomainCheck(false);
      } else {
        toast.success("사용 할 수 있는 도메인입니다.");
        setDomainCheck(true);
      }
    } else {
      setInputDomain(response?.data?.domain);
    }
  };

  const handleInputDomain = (value) => {
    setDomainCheck(false);
    setInputDomain(value);
  };

  const makeHomepage = () => {
    if (!domainCheck) {
      toast.error("홈페이지 생성에 실패하였습니다.");
      inputRef.current.focus();
    } else {
      saveDomain();
    }
  };

  const saveDomain = async () => {
    const url = "/api/domainSettingSave";
    const body = {
      cmpnyNo: cmpnyNo,
      domain: inputDomain,
      delYn: "N",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    const response = await axios.put(url, body);
    if (response?.data?.success) {
      navigate("/mypage/homepage", { state: { saveMessage: true } });
    } else {
      toast.error("에러가 발생 했습니다 잠시후 다시 시도해주세요.");
    }
  };

  return (
    <Container className={setting?.mode}>
      <div className="content">
        <PageInfo label="도메인 설정" />
        <MakeCompanyDomainContainer className={setting?.mode}>
          <div className="makeForm">
            <p>https://1472.ai/shop/</p>
            <input
              ref={inputRef}
              type="text"
              placeholder="abcd"
              value={inputDomain}
              onChange={(e) => handleInputDomain(e.target.value)}
            />
            <button
              type="button"
              name="button"
              className="btn_line gray btn_duplicate"
              onClick={() => checkedDomain(true)}>
              중복체크
            </button>
          </div>
          <SubmitButton>
            <button onClick={makeHomepage}>생성하기</button>
          </SubmitButton>
        </MakeCompanyDomainContainer>
      </div>
    </Container>
  );
};

export default MakeDomainPage;
