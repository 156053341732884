import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { HamburgerMenuContainer } from "../styled/groupDetails";
import { Hamburger } from "../styled/common";

import useOutsideClick from "../../../../hooks/useOutsideClick";
import { toast } from "react-toastify";
import { useParams } from "react-router";

const HamburgerMenu = ({ active, member, tabs, tabClick }) => {
  const tabRef = useRef();
  const { id } = useParams();

  const isNotDesktop = useMediaQuery({ query: "(max-width:1700px)" });
  const [openTab, setOpenTab] = useState(false);

  useOutsideClick(tabRef, () => {
    setOpenTab(false);
  });

  useEffect(() => {
    setOpenTab(false);
  }, [isNotDesktop]);

  const clickMenu = (idx) => {
    tabClick(idx);
    setOpenTab(false);
  };

  return (
    <HamburgerMenuContainer ref={tabRef}>
      <ul className={openTab && isNotDesktop ? "open" : "close"}>
        {isNotDesktop && (
          <li
            onClick={() =>
              !member && id ? toast.error("그룹 가입 후 이용 가능합니다.") : setOpenTab(!openTab)
            }>
            <Hamburger className={openTab ? "open" : "close"}>
              <span></span>
              <span></span>
              <span></span>
            </Hamburger>
          </li>
        )}
        {tabs.map((item, idx) => (
          <li
            className={active === idx ? "active" : "inactive"}
            key={`${item.name}-${idx}`}
            onClick={() =>
              !member && id ? toast.error("그룹 가입 후 이용 가능합니다.") : clickMenu(idx)
            }>
            <item.icon />
            <span className="readOnly">{item.name}</span>
          </li>
        ))}
      </ul>
    </HamburgerMenuContainer>
  );
};

export default HamburgerMenu;
