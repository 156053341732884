import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProjectDetailParticipantModal from "./projectDetailParticipants/projectDetailParticipantModal";
import { ProjectDetailAttendees } from "../../common/styled/project";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { isOutsideCmpny } from "../../../../scheduler/component/function/common";

const ProjectDetailParticipants = ({
  participantList,
  project,
  participant,
  isInviteOpen,
  setIsInviteOpen,
  showParticipants,
  setShowParticipants,
}) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [participants, setParticipants] = useState({});

  useEffect(() => {
    splitMberList();
  }, [participantList]);

  const splitMberList = () => {
    const newParticipants = {};

    participantList.forEach((mber) => {
      if (newParticipants[mber.orgNo]) {
        newParticipants[mber.orgNo].mber.push(mber);
      } else {
        newParticipants[mber.orgNo] = {
          orgNm: mber.orgNm,
          orgNo: mber.orgNo,
          mber: [mber],
        };
      }
    });

    setParticipants(newParticipants);
  };

  const isMyCmpnyProject = (orgNo) => {
    if (project.gbn === "G") return true;
    if (parseInt(project.orgNo) !== parseInt(orgNo)) {
      return false;
    }
    return true;
  };

  const isInvitableParticipant = () => {
    if (!participant?.projectParticipantNo || isOutsideCmpny(project, company)) {
      toast.error("초대 권한이 존재하지 않습니다.");
      return;
    }

    setShowParticipants(false);
    setIsInviteOpen(!isInviteOpen);
  };

  return (
    <ProjectDetailAttendees
      style={{ transform: `translateX(${!isMobile || showParticipants ? "0%" : "100%"})` }}>
      {isMobile && <div className="bg" onClick={() => setShowParticipants(false)}></div>}
      <div className="list">
        <p>참여자</p>
        <ul>
          {Object.keys(participants).map((orgNo) => (
            <li
              key={orgNo}
              style={{ background: isMyCmpnyProject(orgNo) ? "transparent" : "#efefef" }}>
              <div className="orgName">
                {participants[orgNo].orgNm ? participants[orgNo].orgNm : "회사명 미설정"}
              </div>
              <ul>
                {participants[orgNo].mber.map((mber) => (
                  <li key={mber.mberNo}>
                    <div className="userProfileImage">
                      <img
                        src={
                          mber.imgPath
                            ? imgHandler(mber.imgPath, false)
                            : "/img/icon_function_2.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="userInfo">
                      <span
                        style={{ color: mber.mberNo === user.mberNo ? "#0074ca" : "#000" }}
                        className="name">
                        {mber.mberNm}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
        <div className="invite">
          <button onClick={isInvitableParticipant}>참여자 초대</button>
        </div>
      </div>
    </ProjectDetailAttendees>
  );
};

export default ProjectDetailParticipants;
