import React, { useState } from "react";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";

import { GoPlus } from "react-icons/go";

import Member from "./member";
import SearchForm from "./searchForm";

import { MemberListContainer } from "../../styled/company";

const MemberList = ({
  company,
  members,
  editWorkStatus,
  page,
  total,
  changePages,
  setIsInvite,
  openModal,
  closeModal,
  deleteMber,
}) => {
  const setting = useSelector((state) => state?.user?.setting);
  const [searched, setSearched] = useState("");

  return (
    <MemberListContainer className={setting.mode}>
      <div className="viewer">
        <div className="info">
          <SearchForm searched={searched} setSearched={setSearched} />
          <button className="add" onClick={() => setIsInvite(true)}>
            <GoPlus />
          </button>
        </div>
        <div className="members">
          <ul>
            <li className="label">
              <div className="dot">
                <div></div>
              </div>
              <div className="mberNm">이름</div>
              <div className="username">아이디</div>
              <div className="moblphonNo">휴대번호</div>
              <div className="work">
                <span>근무여부</span>
                <i>?</i>
              </div>
              <div className="delete"></div>
            </li>
            {members.length > 0 ? (
              members.filter((item) => item.mberNm.includes(searched)).length > 0 ? (
                members
                  .filter((item) => item.mberNm.includes(searched))
                  .map((item) => (
                    <Member
                      company={company}
                      item={item}
                      editWorkStatus={editWorkStatus}
                      openModal={openModal}
                      closeModal={closeModal}
                      deleteMber={deleteMber}
                    />
                  ))
              ) : (
                <li>직원이 존재하지 않습니다.</li>
              )
            ) : (
              <li>직원이 존재하지 않습니다.</li>
            )}
          </ul>
        </div>
      </div>
      <Pagination
        activePage={page + 1}
        itemsCountPerPage={10}
        totalItemsCount={total}
        pageRangeDisplayed={5}
        prevPageText={"‹"}
        nextPageText={"›"}
        onChange={(e) => changePages(e)}
      />
    </MemberListContainer>
  );
};

export default MemberList;
