import React, { useRef } from "react";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { FaAngleRight } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";

const Members = ({ members, group, member, tabClick }) => {
  const swiperRef = useRef();

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const clickSwiperButton = (isPrev) => {
    if (swiperRef.current) {
      if (isPrev) {
        swiperRef.current.swiper.slidePrev();
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  return (
    <div className="members">
      <span className="label">
        {group.groupName} 멤버들 🙋‍♂️
        <button
          onClick={() => {
            member ? tabClick(3) : toast.error("그룹 가입 후 이용 가능합니다.");
          }}>
          <FaAngleRight />
        </button>
      </span>
      <div>
        <button onClick={() => clickSwiperButton(true)}>
          <GrLinkPrevious />
        </button>
        <Swiper
          className="mySwiper"
          ref={swiperRef}
          loop={true}
          modules={[Navigation]}
          // navigation={true}
          slidesPerView={isMobile ? 3.5 : isTablet ? 5 : 8}
          spaceBetween={isMobile ? 30 : isTablet ? 50 : 70}
          autoplay={{ delay: 2000, disableOnInteraction: false }}
          speed={1000}>
          {members && members.length > 0 ? (
            members.map((item, i) => (
              <SwiperSlide key={i}>
                <div className="image">
                  <img src={item.mberImgPath ? imgHandler(item.mberImgPath) : "/img/user2.png"} />
                </div>
                <div className="content">{item.nickNm ? item.nickNm : "닉네임 미설정"}</div>
              </SwiperSlide>
            ))
          ) : (
            <SwiperSlide>
              <div className="not">
                <span>새로운 모임을 만들어보세요!</span>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
        <button onClick={() => clickSwiperButton()}>
          <GrLinkNext />
        </button>
      </div>
    </div>
  );
};

export default Members;
