import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { CalendarContainer } from "../styled/scheduler";

import { FiHome, FiSearch } from "react-icons/fi";

import { tallyEvents } from "../function/common";

import DateCell from "./calendar/dateCell";
import Actions from "./calendar/actions";
import CustomEvent from "./calendar/customEvent";
import HamburgerMenu from "./hamburgerMenu";
import SearchEvent from "./searchEvent";

export default function Calendar({
  data,
  handleDateClick,
  calendarRef,
  handleEventClick,
  onDate,
  toggleAgendaContent,
  handleEventDrag,
  setIsBurgerOpen,
  isBurgerOpen,
  // displayDateInfo,
  clickEvent,
  isSearch,
  setIsSearch,
  searchTxt,
  setSearchTxt,
  holidays,
  getWeatherForDate,
  events,
  isHolidayOpen,
  addContextMenuListener,
}) {
  const { mySchedSet, isDarkMode } = useSelector((state) => state?.scheduler);
  const isByType = mySchedSet.viewMode === "C";
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const user = useSelector((state) => state?.user?.data[0]);
  const [viewEvents, setViewEvents] = useState([]);

  useEffect(() => {
    let schedules = [];

    if (isByType) {
      schedules = tallyEvents(events);
    } else {
      schedules = isMobile ? [...events, ...holidays] : events;
    }

    setViewEvents(schedules);

    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(schedules);
    }
  }, [isMobile, events, mySchedSet]);

  const clickDate = (info) => {
    handleDateClick(info.date);
    toggleAgendaContent(true);
  };

  const handleSearchClick = () => {
    if (isSearch) {
      setSearchTxt("");
    }
    setIsSearch(!isSearch);
  };

  return (
    <CalendarContainer className={isDarkMode}>
      <div className="mainCalendar" style={{ padding: isSearch ? "4em 0 0" : 0 }}>
        <div className={`searchBtn ${isSearch && isMobile ? "active" : ""}`}>
          <button onClick={handleSearchClick}>{isSearch ? <FiHome /> : <FiSearch />}</button>
        </div>
        {!isSearch && (
          <Actions clickDate={clickDate} handleDateClick={handleDateClick} onDate={onDate} />
        )}
        {isSearch ? (
          <SearchEvent
            data={data}
            clickEvent={clickEvent}
            searchTxt={searchTxt}
            setSearchTxt={setSearchTxt}
            handleDateClick={handleDateClick}
            onDate={onDate}
          />
        ) : (
          <div className={`calendarView-${mySchedSet.viewMode}`}>
            <FullCalendar
              ref={calendarRef}
              initialDate={onDate}
              initialView="dayGridMonth"
              plugins={[dayGridPlugin, interactionPlugin]}
              locale={"ko"}
              contentHeight={"auto"}
              droppable={true}
              events={viewEvents}
              eventDurationEditable={false}
              eventStartEditable={true}
              eventClick={(info) => handleEventClick(info.el)}
              dateClick={(info) => clickDate(info)}
              eventDrop={handleEventDrag}
              dayMaxEvents={3}
              moreLinkContent={(info) => `+${info.num}`}
              dragScroll={true}
              eventClassNames={(info) =>
                `schedule-${info.event.id}${
                  info.event.extendedProps.completeYn === "Y" ? " complete" : ""
                }`
              }
              datesSet={addContextMenuListener}
              dayCellContent={(info) => (
                <DateCell
                  info={info}
                  holidays={holidays}
                  isHolidayOpen={isHolidayOpen}
                  getWeatherForDate={getWeatherForDate}
                  handleEventClick={handleEventClick}
                />
              )}
              eventContent={(eventInfo) => <CustomEvent eventInfo={eventInfo} />}
              eventOverlap={false}
              eventOrderStrict={true}
              slotEventOverlap={false}
            />
          </div>
        )}
      </div>
      {isTablet && !isSearch && (
        <HamburgerMenu
          onDate={moment().format("YYYY-MM-DD hh:mm:00")}
          isBurgerOpen={isBurgerOpen}
          setIsBurgerOpen={setIsBurgerOpen}
        />
      )}
    </CalendarContainer>
  );
}
