import React from "react";
import { SecretPostContainer } from "../styled/notice";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import ImageDetailViewer from "../../../../common/imageDetailViewer";

const SecretPost = ({ selected, handleDeleteInquiry, setIsDetail, setImages, navEditPage }) => {
  const contentRef = useRef();

  useEffect(() => {
    const images = contentRef.current?.querySelectorAll("img");
    images?.forEach((img) => {
      img.style.cursor = "pointer";
      img.addEventListener("click", (e) => handleImageClick(img.src));
    });

    return () => {
      images?.forEach((img) => {
        img.removeEventListener("click", () => handleImageClick(img.src));
      });
    };
  }, [selected.content]);

  const handleImageClick = (src) => {
    setImages([src]);
    setIsDetail(true);
  };

  return (
    <>
      {!selected.answer && (
        <div className="actions">
          <button onClick={navEditPage}>수정</button>
          <button onClick={handleDeleteInquiry}>삭제</button>
        </div>
      )}
      <div
        className="question"
        ref={contentRef}
        dangerouslySetInnerHTML={{ __html: selected.content }}></div>
      {selected.answer && <div className="answer">{selected.answer}</div>}
    </>
  );
};

export default SecretPost;
