import styled from "styled-components";

export const QnaTitleContainer = styled.div`
  .inner_title_menu {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    li {
      &:hover {
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .inner_title_menu {
      justify-content: flex-start;
      li {
        box-sizing: border-box;
        margin-bottom: -1px;
        &:nth-child(even) {
          border-left: none;
        }
      }
    }
  }
`;

export const GroupReqContents = styled.div`
  button.btn_normal {
    border: none;
  }
`;

export const ReleaseNotesContainer = styled.div`
  .section {
    margin: 50px 0;
  }
  .inner {
    margin: auto;
    max-width: 1400px;
    > ul {
      > li {
        border-radius: 20px;
        padding: 15px;
        box-sizing: border-box;
        margin: 10px 0;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
        .devNotifyWrapper {
          max-height: 35px;
          overflow-y: hidden;
          transition: 0.3s;
          cursor: pointer;
          &.active {
            max-height: 200vh;
          }
          .title {
            display: flex;
            align-items: center;
            gap: 15px;
            position: relative;
            img {
              width: 35px;
              height: 35px;
              object-fit: cover;
              border-radius: 50%;
            }
            span {
              &.noticeSj {
                font-weight: 800;
              }
            }
            .date {
              color: #999;
            }
            .toggleCollapseButton {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translateY(-50%);
              border: none;
              background: transparent;
              font-size: 20px;
              display: flex;
            }
            .editButton {
              position: absolute;
              right: 30px;
              top: 50%;
              transform: translateY(-50%);
              border: none;
              background: #efefef;
              padding: 5px 10px;
              display: flex;
              border-radius: 10px;
              font-size: 13px;
            }
          }
          .devNoticeContent {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #efefef;
          }
        }
        .decisionBtnArea {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          align-items: center;
          overflow: hidden;
          transition: 0.3s;
          height: 0;
          padding: 0;
          button {
            width: 50%;
            border-radius: 20px;
            border: none;
            padding: 5px;
            font-size: 14px;
            background: #efefef;
            &:first-child {
              background: #0074ca;
              color: #fff;
            }
          }
          &.inv {
            padding: 20px 0 0;
            height: 47px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .notifyInner {
      padding: 15px;
    }
    .notify .notifyBody .notifyInner .section ul li .notifyWrapper .content .infoBox .title {
      max-width: calc(100% - 60px);
    }
    .notify .notifyHeader .notifyInner .msgSetting .settingBtnArea .setMenus {
      transform: translate(0, 105%);
    }
  }
  @media screen and (max-width: 768px) {
    .notify {
      .notifyBody {
        .notifyInner {
          .section {
            > ul {
              > li {
                .notifyWrapper {
                  gap: 10px;
                  img {
                    width: 50px;
                    height: 50px;
                  }
                  .content {
                    width: calc(100% - 100px);
                    .infoBox {
                      flex-direction: column;
                      gap: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .notify .notifyHeader .notifyInner .title a img {
      width: 100px;
      height: 35px;
    }
    .notify .notifyBody .notifyInner .notifyTabMenu li {
      padding: 10px;
    }
  }
`;

export const PageContainer = styled.div`
  min-height: 100vh;
  padding: 50px 20px;
  max-width: 1440px;
  margin: 0 auto;
  width: 100%;
  font-size: 16px;
  .pagination {
    gap: 10px;
    li {
      background: transparent;
      width: 40px;
      height: 40px;
      &:hover,
      &.active {
        background: #e2e2e2;
        border-color: #e2e2e2;
        a {
          color: #000;
        }
      }
    }
  }
`;

export const PageInfoContainer = styled.div`
  .tit {
    font-size: 36px;
    font-weight: 800;
    display: block;
    margin-bottom: 20px;
  }
  .cont {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const SearchFormContainer = styled.div`
  width: 100%;
  position: relative;
  margin: 50px 0;
  input[type="text"] {
    width: 100%;
    height: 40px;
    padding: 0 20px;
    border: 1px solid #a99f9f;
    border-radius: 5px;
  }
  button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    height: 100%;
    aspect-ratio: 1;
    font-size: 30px;
    border: none;
    color: #a99f9f;
    background: transparent;
    svg {
      display: block;
      margin: auto;
    }
  }
`;

export const NoticeListContainer = styled.ul`
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 50px;
  li {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    &:nth-child(odd) {
      background: #f0f0f0;
    }
    &.not {
      background: transparent;
      justify-content: center;
      padding: 100px 0;
    }
  }
`;

export const FaqMenuContainer = styled.ul`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: -20px;
  margin-bottom: 50px;
  li {
    width: 120px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border: 1px solid #7a7a7a;
    color: #7a7a7a;
    transition: 0.3s;
    cursor: pointer;
    &.active {
      background: #15181e;
      color: #fff;
      border-color: #15181e;
    }
  }
`;

export const QnaFormContainer = styled.form`
  margin: 50px 0;
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  input[type="text"] {
    width: 100%;
    resize: none;
    border: 1px solid #a99f9f;
    outline: none;
    border-radius: 5px;
    padding: 0 20px;
    &:focus {
      border: 1px solid #a99f9f !important;
      box-shadow: none;
    }
  }
  .title {
    height: 40px;
  }
  .content {
    height: 500px;
  }
  .title,
  .content {
    width: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    &.half {
      width: calc(50% - 15px);
    }
    &:last-child {
      margin-bottom: 0;
      height: 120px;
      .cont textarea {
        padding: 20px;
      }
    }
    .tit {
      width: 200px;
      font-weight: 500;
    }
    .cont {
      width: calc(100% - 200px);
      height: 100%;
      input[type="text"] {
        height: 100%;
      }
    }
    .ck.ck-toolbar {
      border-color: rgb(169, 159, 159);
    }
    .ck.ck-content {
      height: 400px;
    }
    .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
    .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
      border-color: rgb(169, 159, 159);
      padding: 0 20px;
    }
  }
  .action {
    text-align: center;
    width: 100%;
    button {
      width: 200px;
      height: 60px;
      background: rgb(16, 16, 16);
      color: rgb(255, 255, 255);
      border-radius: 10px;
      border: none;
    }
  }
  @media screen and (max-width: 1050px) {
    ul li.half {
      width: 100%;
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      gap: 15px;
      li {
        font-size: 14px;
        flex-wrap: wrap;
        height: auto;
        gap: 10px;
        .tit {
          width: 100px;
        }
        .cont {
          width: 100%;
          height: auto;
          input[type="text"],
          input[type="email"],
          select,
          textarea {
            height: 30px;
            padding: 0 10px;
          }
          textarea {
          }
        }
        &:last-child .cont textarea {
          height: 100px;
          padding: 10px;
        }
      }
    }
  }
  > div button {
    font-size: 20px;
  }
`;

export const SecretPostContainer = styled.div`
  width: 100%;
  overflow: hidden;
  height: auto;
  max-height: 0;
  text-align: left;
  font-size: 18px;
  padding: 0 20px;
  transition: 0.5s;
  &.open {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    max-height: 50vh;
    overflow-y: auto;
    padding: 20px;
    cursor: default;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      border: 2px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }
  }
  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    button {
      padding: 2px 10px;
      border: 1px solid #000;
      background: rgb(25, 31, 40);
      color: #fff;
      font-size: 12px;
    }
  }
  img {
    max-width: 100%;
  }
  .answer {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    white-space: pre-line;
  }
`;

export const HelpList = styled.ul`
  li {
    display: flex;
    align-items: center;
    height: auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    cursor: pointer;
    flex-wrap: wrap;
    &.not {
      padding: 20px 0;
      justify-content: center;
    }
    &.header {
      cursor: default;
      background: rgba(0, 0, 0, 0.1);
      font-weight: 700;
      font-size: 20px;
      border-top: 1px solid rgba(0, 0, 0, 0.3);
      color: #000;
      .tit {
        text-align: center;
      }
      .date {
        color: #000;
      }
    }
    .title {
      width: 100%;
      display: flex;
    }
    .tit {
      width: 65%;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 20px 10px;
    }
    .status {
      width: 10%;
      padding: 20px 10px;
      span {
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 14px;
      }
      &.pending {
        span {
          color: #f1541d;
          background: #fef6f4;
        }
      }
      &.completed {
        span {
          color: #429b8c;
          background: #e7f5f2;
        }
      }
    }
    .date {
      width: 20%;
      padding: 20px 10px;
      color: #999;
    }
    .toggle {
      width: 5%;
      padding: 20px 10px;
      font-size: 20px;
      color: #999;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
  @media screen and (max-width: 767px) {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    li {
      font-size: 14px;
      gap: 20px;
      padding-top: 20px;
      &.header {
        display: none;
      }
      .status,
      .user,
      .date {
        width: auto;
        padding: 0;
      }
      .status span {
        padding: 3px 10px;
        font-size: 12px;
      }
      .tit {
        width: 100%;
        padding: 0;
      }
    }
  }
`;

export const HelpButton = styled.div`
  margin-bottom: 50px;
  text-align: right;
  button {
    width: 202px;
    height: 40px;
    border: none;
    color: #fff;
    background: #15181e;
    font-size: 16px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 30px;
    button {
      width: 150px;
      font-size: 14px;
    }
  }
`;

export const PasswordFieldContainer = styled.div`
  .message {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 20px 0;
    i {
      font-size: 30px;
      svg {
        display: block;
      }
    }
    span {
      font-weight: 300;
    }
  }
  .field {
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    input,
    button {
      border: 1px solid #999;
      padding: 0 20px;
      height: 40px;
      border-radius: 10px;
    }
    input {
    }
    button {
      background: #000;
      color: #fff;
    }
  }
`;
