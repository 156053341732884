import React, { useEffect, useState } from "react";
import { GroupContainer } from "./component/styled/common";
import PageInfo from "./component/common/pageInfo";
import Search from "./component/common/search";
import axios from "axios";
import Pagination from "react-js-pagination";
import GroupItem from "./component/common/groupItem";
import GroupList from "./component/common/groupList";
import { useMediaQuery } from "react-responsive";

const GroupAll = () => {
  const [group, setGroup] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [searched, setSearched] = useState("");

  useEffect(() => {
    getGroup();
  }, []);

  // 모임 조회
  const getGroup = async (page = 1) => {
    const pageSize = 12;
    const pageNumber = page - 1;

    const url = "/api/groupList";
    const body = {
      delYn: "N",
      displayYn: "Y",
      offset: pageNumber * pageSize,
      pageNumber: pageNumber,
      pageSize,
      paged: true,
    };

    if (searched) {
      body.groupName = searched;
    }

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setGroup(res.data.content);
        setTotal(res.data.totalElements);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changePage = (page) => {
    setPage(page);
    getGroup(page);
  };

  const handleSearch = () => {
    setPage(1);
    getGroup();
  };

  return (
    <GroupContainer>
      <div className="inner">
        <PageInfo title="MOIM - 협력과 소통의 공간" />
        <Search
          placeholder="모임명을 검색해주세요."
          searched={searched}
          setSearched={setSearched}
          handleSearch={handleSearch}
        />
        <GroupList data={group} type="all" />
        <Pagination
          activePage={page}
          itemsCountPerPage={12}
          totalItemsCount={total}
          pageRangeDisplayed={5}
          prevPageText={"‹"}
          nextPageText={"›"}
          onChange={changePage}
        />
      </div>
    </GroupContainer>
  );
};

export default GroupAll;
