import styled from "styled-components";

export const ContentsWrapper = styled.div`
  margin-bottom: 1rem;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
`;

export const MenuWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const ImgWrap = styled.p`
  margin-bottom: 2rem;
  display: flex;
  justify-content: center;
`;

export const BtnWrapper1 = styled.div`
  display: flex;
  justify-content: center;
`;

export const BtnWrapper2 = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 10px;
`;

export const BackBtn = styled.div`
  background-color: #4e515d;
  color: white;
  padding: 7px 13px;
  border-radius: 3px;
  font-size: 1rem;
  text-decoration: none;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
`;

export const EditBtn = styled.div`
  background-color: rgb(0, 116, 202);
  color: white;
  padding: 7px 13px;
  border-radius: 3px;
  font-size: 1rem;
  text-decoration: none;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
`;

export const SecondImgWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
