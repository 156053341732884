import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import axios from "axios";
import uuid from "react-uuid";
import moment from "moment";

import { IoMdMenu } from "react-icons/io";
import { FiMoon, FiSun } from "react-icons/fi";

import { getSetting } from "../../../store/scheduler";

import { SchedulerPageContainer, MobileMenu } from "./component/styled/scheduler";

import {
  isSameDay,
  modalPosition,
  eventsSorted,
  keysToRemove,
  getProjectMbers,
  transformTodo,
} from "./component/function/scheduler";
import {
  isSameUser,
  isWeather,
  getDateRange,
  transformEvents,
  getIdFromClass,
  isMonthDifferent,
} from "./component/function/common";
import { useModal } from "../../../hooks/useModal";

import Agenda from "./component/scheduler/agenda";
import Calendar from "./component/scheduler/calendar";
import EventLayer from "./component/scheduler/eventLayer";
import Header from "./component/scheduler/header";
import SideMenu from "./component/scheduler/sideMenu";
import HamburgerMenu from "./component/scheduler/hamburgerMenu";
import HolidayLayer from "./component/scheduler/holidayLayer";
import ContextMenu from "./component/common/contextMenu";

const Scheduler = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { modal, openModal, closeModal } = useModal();

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const user = useSelector((state) => state?.user?.data[0]);
  const { mySchedSet, isDarkMode } = useSelector((state) => state.scheduler);
  const company = useSelector((state) => state?.company?.data);

  const calendarRef = useRef(null);
  const sideCalendarRef = useRef(null);

  const [onDate, setOnDate] = useState(moment().format("YYYY-MM-DD"));
  const [onPrevDate, setOnPrevDate] = useState(null);
  const [showEvent, setShowEvent] = useState(false);
  const [eventInfo, setEventInfo] = useState({});
  const [data, setData] = useState([]);
  const [userDate, setUserDate] = useState({
    year: Number(moment().format("YYYY")),
    month: Number(moment().format("MM")),
  });
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [showHoliday, setShowHoliday] = useState(false);
  const [holiday, setHoliday] = useState({});
  const [weather, setWeather] = useState([]);
  const [todayWeather, setTodayWeater] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [images, setImages] = useState([]);
  const [originalImages, setOriginalImages] = useState([]);
  const [context, setContext] = useState({ x: 0, y: 0, date: "" });

  const [date, setDate] = useState(getDateRange());
  const [holidays, setHolidays] = useState([]);

  const [events, setEvents] = useState([]);
  const [todayEvents, setTodayEvents] = useState([]);

  useEffect(() => {
    if (user) {
      getWeather();
      initSettings();
    }
    if (location.search) {
      setIsSearch(true);
    }
    if (date) {
      getSchedule();
    }
  }, []);

  const initSettings = async () => {
    const url = "/api/toktokSettingList";
    const req = {
      mberNo: user.mberNo,
    };
    const res = await axios.post(url, req);
    if (res.request.status === 200) {
      dispatch(getSetting(res.data));
    }
  };

  // 캘린더 일정 조회
  const getSchedule = async () => {
    try {
      const filterOrg = (org) => {
        if (!org) return [];
        return org.filter((item) => item.check).map((item) => item.orgNo);
      };

      const url = "/api/scheduleList";
      const body = {
        mberNo: user.mberNo,
        showPersonalYn: mySchedSet.personalYn ? "Y" : "N",
        cmpnyNos: filterOrg(mySchedSet.companyYnList),
        groupNos: filterOrg(mySchedSet.groupYnList),
        completeYn: mySchedSet.completeYn || "D",
        holidayYn: "Y",
        startDt: date.startDt,
        endDt: date.endDt,
      };

      const res = await axios.post(url, body);
      const schedule = res.data;

      const { events, holidays } = await transformEvents(schedule, company);

      console.log(events);
      setEvents(events);
      setHolidays(holidays);
      setData([...events, ...holidays]);

      // setData(events);
      isToday(events);
    } catch (error) {
      console.error(error);
    }
  };

  // 날씨 정보 가져오기
  const getWeather = async () => {
    const url = "/api/weather";
    const req = {};
    const res = await axios.post(url, req);
    if (res.request.status === 200) {
      setWeather(res.data);
      updateWeather(moment(), res.data);
    }
  };

  // 오늘 날짜 데이터 가져오기
  const isToday = (events) => {
    const target = moment(onDate);

    const data = events.filter((event) => {
      const start = moment(event.startDt);
      const end = event.endDt ? moment(event.endDt) : null;
      return isSameDay(target, start, end);
    });
    const sorted = eventsSorted(data);

    setTodayEvents(sorted);
  };

  // 클릭 시 해당 날짜 배경색 변경
  useEffect(() => {
    if (onDate) {
      updateDate(onDate);
    }

    const isDifferent = isMonthDifferent(onDate, date);
    if (isDifferent) {
      setDate(getDateRange(onDate));
    }
  }, [onDate]);

  useEffect(() => {
    getSchedule();
  }, [date, mySchedSet]);

  const updateDate = (onDate) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(onDate);
    }
    if (sideCalendarRef.current) {
      const sideCalendarApi = sideCalendarRef.current.getApi();
      sideCalendarApi.gotoDate(onDate);
    }

    const prevDateElements = document.querySelectorAll(
      `[data-date="${moment(onPrevDate).format("YYYY-MM-DD")}"]`,
    );
    const currentDateElements = document.querySelectorAll(
      `[data-date="${moment(onDate).format("YYYY-MM-DD")}"]`,
    );

    prevDateElements.forEach((item) => item.classList.remove("selected"));
    currentDateElements.forEach((item) => item.classList.add("selected"));

    setOnPrevDate(onDate);
  };

  // 클릭한 날짜 날씨
  const updateWeather = (date, data) => {
    const today = moment(date).format("YYYY-MM-DD");
    const weather = data.filter((item) => item.date === today);

    if (weather.length) {
      setTodayWeater(weather[0]);
    } else {
      setTodayWeater(null);
    }
  };

  // 날짜 클릭
  const handleDateClick = async (arg, isSearchReset = false) => {
    const target = moment(arg);

    let sameDate = data.filter((event) =>
      isSameDay(target, moment(event.startDt), event.end ? moment(event.endDt) : null),
    );

    if (isSearch && !isSearchReset && searchTxt) {
      sameDate = sameDate.filter((event) => event.title.includes(searchTxt));
    }
    const sorted = eventsSorted(sameDate);

    setTodayEvents(sorted);
    setOnDate(arg);
    updateWeather(arg, weather);

    const [year, month] = target.format("YYYY-MM").split("-");
    setUserDate({
      year: Number(year),
      month: Number(month),
    });
  };

  // 컴포넌트 애니메이션
  const toggleAgendaContent = (flag) => {
    const agenda = document.querySelector(".agenda").style;
    agenda.transform = `translateY(${!isTablet ? "0" : flag ? "0" : "150%"})`;
  };
  const toggleSideMenu = (flag) => {
    const sidemenu = document.querySelector(".sidemenu").style;
    sidemenu.transform = `translateX(${!isMobile ? "0" : flag ? "0" : "-150%"})`;
  };

  // 이벤트 클릭
  const handleEventClick = (item) => {
    const classNames = Array.from(item.classList);

    console.log(classNames);

    const event = getIdFromClass(data, item);

    if (!event) return;

    if (isTablet && !["S", "H"].includes(event.gbn)) {
      navigate(`/mypage/detailEvent/${event.scheduleNo}`);
      return;
    }

    setEventInfo(event);

    const rect = item.getBoundingClientRect();
    const position = modalPosition(rect, event, user);

    onEventModal(position);
  };

  // 이벤트 드래그앤드롭
  const handleEventDrag = (info) => {
    const event = getIdFromClass(data, info.el);

    if (!event) {
      info.revert();
      return;
    }

    if (!isSameUser(user, event)) {
      info.revert();
      toast.error("작성자가 아닙니다.");
      return;
    } else {
      const isDday = event.dday === "Y";
      openModal({
        isOpen: true,
        type: "confirmation",
        title: `날짜 변경`,
        message: [`${isDday ? "디데이를" : "일정을"} 변경하시겠습니까?`],
        handleConfirm: () => handleEventDrop(false, info),
        handleCancel: () => handleEventDrop(true, info),
      });
    }
  };

  // 이벤트 드래그앤드롭 취소/확인 버튼
  const handleEventDrop = (flag, info) => {
    if (flag) {
      info.revert();
    } else {
      const event = getIdFromClass(data, info.el);
      if (!event) return;

      const { delta } = info;

      const calculateNewDate = (date) => {
        return moment(date)
          .add(delta.days, "days")
          .add(delta.months, "months")
          .add(delta.years, "years")
          .format("YYYY-MM-DD HH:mm:ss");
      };

      const newStart = calculateNewDate(event.startDt);
      const newEnd = calculateNewDate(event.endDt);

      event.startDt = newStart;
      event.endDt = newEnd;

      submitEventDrop(event);
    }

    closeModal();
  };

  // agenda 이벤트 커스텀 모달
  const onEventModal = () => {
    setShowEvent(true);
  };

  // 일정 복사
  const todoCopy = (event) => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `메모 복사`,
      message: [`일정의 메모를 복사하시겠습니까?`],
      handleConfirm: () => handleScheduleCopy(event, true),
      handleCancel: () => handleScheduleCopy(event),
    });
  };

  // 일정 삭제 모달
  const deleteEvent = (event) => {
    const isDday = event.dday === "Y";
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `${isDday ? "디데이" : "일정"} 삭제`,
      message: [`${isDday ? "디데이를" : "일정을"} 삭제하시겠습니까?`],
      handleConfirm: () => submitDelete(event),
      handleCancel: () => closeModal(),
    });
  };

  const isUpload = () => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `이미지 업로드`,
      message: [`업로드되지 않은 이미지가 있습니다.`, `업로드하시겠습니까?"`],
      handleConfirm: () => uploadImages(true),
      handleCancel: () => {
        setImages([]);
        setOriginalImages([]);
        setShowEvent(false);
        closeModal();
      },
    });
  };

  // 모달 확인 - 일정 완료
  const handleEventComplete = async (state) => {
    const url = "/api/schedule";
    const body = keysToRemove(eventInfo);
    body.completeYn = state;

    const res = await axios.put(url, body);
    if (res.status === 200) {
      getSchedule();
      setEventInfo({ ...eventInfo, completeYn: state });
    }
  };

  // 모달 확인 - 이벤트 드래그앤드롭
  const submitEventDrop = async (eventInfo) => {
    const url = "/api/schedule";
    const body = keysToRemove(eventInfo);

    if (body.workerList && Object.keys(body.workerList).length !== 0) {
      const workers = Object.keys(body.workerList);
      body.workerNos = workers;
    }

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success("일정이 변경되었습니다.");
      setShowEvent(false);
      getSchedule();
    }
  };

  // 이벤트 삭제
  const submitDelete = async (event) => {
    const url = "/api/schedule";
    const body = keysToRemove(event);
    body.delYn = "Y";

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success(`${event.dday === "Y" ? "일정이" : "디데이가"} 삭제되었습니다.`);
      setShowEvent(false);
      getSchedule();
      closeModal();
    }
  };

  // 시스템 화면 모드 변경
  const setScreenMode = async (screenMode) => {
    const url = "/api/toktokSetting";
    const body = {
      ...mySchedSet,
      screenMode,
    };
    const res = await axios.put(url, body);
    if (res.status === 200) {
      dispatch(getSetting(body));
    }
  };

  // 날씨 가져오기
  const getWeatherForDate = (info) => {
    const date = moment(info.date).format("YYYY-MM-DD");

    const matchedDate = weather.find((item) => item.date === date);

    if (matchedDate) {
      return isWeather(matchedDate.weather);
    } else {
      return <></>;
    }
  };

  // 공휴일 팝업
  const isHolidayOpen = (target = null) => {
    if (target) {
      setHoliday(target);
    }
    if (isTablet) {
      toggleAgendaContent(false);
    }
    setShowHoliday((holiday) => !holiday);
  };

  // Agenda/SearchEvent 일정 클릭
  const clickEvent = async (item, searchTxt = "") => {
    setEventInfo(item);

    if (isTablet) {
      if (searchTxt) {
        const encoded = encodeURIComponent(searchTxt);
        navigate(`/mypage/detailEvent/${item.scheduleNo}?keyword=${encoded}`);
      } else {
        navigate(`/mypage/detailEvent/${item.scheduleNo}`);
      }
    } else {
      setShowEvent(true);
    }
  };

  // 일정 복사
  const handleScheduleCopy = async (event, flag = false) => {
    const item = keysToRemove(event, ["completeYn", "mberNo", "scheduleNo"]);

    let body = { ...item, mberNo: user.mberNo };

    if (item.projectNo) {
      const isExist = await getProjectMbers(item.projectNo, user);

      if (!isExist) {
        body = {
          ...body,
          workerList: null,
          managerNo: null,
          managerNm: "",
          projectNo: null,
          projectNm: null,
        };
      }
    }

    navigate("/mypage/calendarEntry", {
      state: {
        eventInfo: body,
        todoInfo: flag ? transformTodo(body.todo) : null,
        infoState: "copy",
      },
    });
  };

  // 이미지 업로드
  const uploadImages = async (isCloseModal = false) => {
    if (originalImages.length < 1) {
      toast.error("업로드 할 이미지가 존재하지 않습니다.");
      return;
    }

    try {
      const formData = new FormData();
      originalImages.forEach((image) => formData.append("images", image));

      if (eventInfo.projectNo) {
        formData.append("gbn", "P");
      } else {
        formData.append("gbn", "S");
      }
      formData.append("refeNo", eventInfo.scheduleNo);
      formData.append("mberNo", user.mberNo);

      const response = await axios.post("/api/photos", formData, {});

      if (response.status === 200) {
        toast.success("이미지가 성공적으로 업로드되었습니다.");
        if (isCloseModal) {
          setImages([]);
          setShowEvent(false);
        } else {
          getImages();
        }
        setOriginalImages([]);
      } else {
        console.error("업로드 실패", response.data);
      }
    } catch (error) {
      console.error("업로드 중 에러 발생", error);
    } finally {
      closeModal();
    }
  };

  // 이미지 조회
  const getImages = async () => {
    const url = "/api/photoList";
    const body = {
      refeNo: eventInfo.scheduleNo,
    };

    const res = await axios.post(url, body);
    setImages(res.data);
  };

  const addContextMenuListener = () => {
    const calendarEl = calendarRef.current?.getApi()?.el;
    if (calendarEl) {
      const handleContextMenu = (e) => {
        e.preventDefault();
        const clickedElement = e.target;

        if (clickedElement.classList.contains("fc-daygrid-day-frame")) {
          const date = clickedElement.closest(".fc-daygrid-day").getAttribute("data-date");
          setContext({ x: e.clientX, y: e.clientY, date: date });
        } else {
          setContext({ x: 0, y: 0, date: "" });
        }
      };

      calendarEl.removeEventListener("contextmenu", handleContextMenu);
      calendarEl.addEventListener("contextmenu", handleContextMenu);
    }
  };

  return (
    <SchedulerPageContainer className={isDarkMode} onContextMenu={(e) => e.preventDefault()}>
      <Header setScreenMode={setScreenMode} />
      <div className="inner_content" id="schduler_content">
        {isMobile && !isSearch && (
          <MobileMenu className={isDarkMode}>
            <button onClick={() => toggleSideMenu(true)}>
              <IoMdMenu />
            </button>
            <button onClick={() => setScreenMode(isDarkMode ? 0 : 1)}>
              {isDarkMode ? <FiSun /> : <FiMoon />}
            </button>
          </MobileMenu>
        )}
        <SideMenu
          sideCalendarRef={sideCalendarRef}
          data={events}
          handleDateClick={handleDateClick}
          userDate={userDate}
          setUserDate={setUserDate}
          toggleSideMenu={toggleSideMenu}
          toggleAgendaContent={toggleAgendaContent}
          onDate={onDate}
        />
        <Calendar
          calendarRef={calendarRef}
          data={data}
          setOnPrevDate={setOnPrevDate}
          handleDateClick={handleDateClick}
          handleEventClick={handleEventClick}
          handleEventDrag={handleEventDrag}
          showEvent={showEvent}
          toggleAgendaContent={toggleAgendaContent}
          onDate={onDate}
          isBurgerOpen={isBurgerOpen}
          setIsBurgerOpen={setIsBurgerOpen}
          clickEvent={clickEvent}
          isSearch={isSearch}
          setIsSearch={setIsSearch}
          searchTxt={searchTxt}
          setSearchTxt={setSearchTxt}
          getWeatherForDate={getWeatherForDate}
          isHolidayOpen={isHolidayOpen}
          holidays={holidays}
          events={events}
          addContextMenuListener={addContextMenuListener}
        />
        <Agenda
          selected={todayEvents}
          toggleAgendaContent={toggleAgendaContent}
          isTablet={isTablet}
          onEventModal={onEventModal}
          setEventInfo={setEventInfo}
          onDate={onDate}
          isBurgerOpen={isBurgerOpen}
          setIsBurgerOpen={setIsBurgerOpen}
          isHolidayOpen={isHolidayOpen}
          todayWeather={todayWeather}
          clickEvent={clickEvent}
        />
        {showEvent && (
          <EventLayer
            eventInfo={eventInfo}
            setShowEvent={setShowEvent}
            handleEventComplete={handleEventComplete}
            handleScheduleCopy={handleScheduleCopy}
            searchTxt={searchTxt}
            images={images}
            setImages={setImages}
            originalImages={originalImages}
            setOriginalImages={setOriginalImages}
            uploadImages={uploadImages}
            getImages={getImages}
            todoCopy={todoCopy}
            deleteEvent={deleteEvent}
            isUpload={isUpload}
          />
        )}
        {showHoliday && <HolidayLayer holiday={holiday} isHolidayOpen={isHolidayOpen} />}
        {!isTablet && (
          <HamburgerMenu
            onDate={moment(onDate).format("YYYY-MM-DD hh:00:00")}
            isBurgerOpen={isBurgerOpen}
            setIsBurgerOpen={setIsBurgerOpen}
          />
        )}
      </div>
      {modal}
      {context.x && context.y && <ContextMenu context={context} setContext={setContext} />}
    </SchedulerPageContainer>
  );
};

export default Scheduler;
