import React, { useState } from "react";

import { SettingContainer } from "../../styled/groupDetails";

import TabMenu from "../../common/tabMenu";
import EditProfile from "./setting/editProfile";
import EditGroup from "./setting/editGroup";
import Members from "./setting/members";
import InviteModal from "./members/inviteModal";
import Membership from "./setting/membership";
import DeleteGroup from "./setting/deleteGroup";
import Withdrawal from "./setting/withdrawal";

const Setting = ({
  member,
  group,
  members,
  setActive,
  getGroupDetail,
  getGroupMembers,
  openModal,
  closeModal,
}) => {
  const [view, setView] = useState(0);
  const [isInvite, setIsInvite] = useState(false);

  const tabs = [
    "프로필 변경",
    "모임 변경",
    "멤버 관리",
    "가입 승인 관리",
    member?.auth === 0 ? "모임 삭제" : "모임 탈퇴",
  ];

  const tabChange = (idx) => {
    setView(idx);
  };

  const Components = ({ index }) => {
    const commonProps = { openModal, closeModal };

    return [
      <EditProfile
        {...commonProps}
        member={member}
        group={group}
        setActive={setActive}
        getGroupMembers={getGroupMembers}
      />,
      <EditGroup
        {...commonProps}
        group={group}
        setActive={setActive}
        getGroupDetail={getGroupDetail}
      />,
      <Members
        {...commonProps}
        member={member}
        members={members}
        group={group}
        getGroupMembers={getGroupMembers}
        setIsInvite={setIsInvite}
      />,
      <Membership {...commonProps} group={group} members={members} />,
      member?.auth === 0 ? (
        <DeleteGroup
          {...commonProps}
          group={group}
          getGroupDetail={getGroupDetail}
          setActive={setActive}
        />
      ) : (
        <Withdrawal {...commonProps} member={member} />
      ),
    ][index];
  };

  return (
    <SettingContainer>
      {isInvite && <InviteModal members={members} setIsInvite={setIsInvite} group={group} />}
      <TabMenu tabChange={tabChange} tabs={tabs} active={view} />
      <div className="content">{<Components index={view} />}</div>
    </SettingContainer>
  );
};

export default Setting;
