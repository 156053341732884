import React from "react";

const ProductDetail = ({ createMarkup }) => {
  return (
    <section id="secDetail">
      <div class="info">
        <img src="/img/shop_delivery_infomation.jpg" alt="shop delivery infomation" />
      </div>
      <div dangerouslySetInnerHTML={createMarkup()} className="prd_img_wrapper" />
    </section>
  );
};

export default ProductDetail;
