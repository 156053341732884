import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";

import { AddScheduleContainer, ScheduleFormContainer } from "./component/styled/calendarEntry";
import { GoToBackHeader } from "./component/styled/common";

import { keysToRemove } from "./component/function/scheduler";
import { isSameUser } from "./component/function/common";
import { useModal } from "../../../hooks/useModal";

import { IoMdArrowBack } from "react-icons/io";

import DdayForm from "./component/ddayEntry/ddayForm";

const DdayEntry = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { modal, openModal, closeModal } = useModal();

  const user = useSelector((state) => state?.user?.data[0]);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  const [formData, setFormData] = useState({
    mberNo: user.mberNo,
    title: "",
    startDt: "",
    gbn: "P",
    pushMinute: 900,
    memo: "",
    dday: "Y",
  });
  const [modalMsg, setModalMsg] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");

  const ddayInfo = location.state?.eventInfo || {};

  const isEditable = Object.keys(ddayInfo).length > 3 && isSameUser(user, ddayInfo);

  const pushMinutes = {
    "하루 전 오전 9시": 900,
    "하루 전 오후 12시": 720,
    "당일 오전 9시": -540,
    "당일 오후 12시": -720,
  };

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    isURLSearched();

    if (Object.keys(ddayInfo).length !== 0) {
      const item = keysToRemove(ddayInfo);
      const date = moment(item.startDt).format("YYYY-MM-DD 00:00:00");
      const push =
        !item.pushMinute || !Object.values(pushMinutes).includes(item.pushMinute)
          ? "notPush"
          : item.pushMinute;

      setFormData((data) => ({
        ...data,
        ...item,
        pushMinute: push,
        startDt: date,
      }));
    } else {
      const date = moment().format("YYYY-MM-DD");
      setFormData((data) => ({
        ...data,
        startDt: date,
      }));
    }
  }, []);

  const isURLSearched = () => {
    const params = new URLSearchParams(location.search);
    const keywordEncoded = params.get("keyword");

    if (keywordEncoded) {
      const keyword = decodeURIComponent(keywordEncoded);
      setSearchTxt(keyword);
    }
  };

  const deleteModal = () => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `디데이 삭제`,
      message: ["디데이를 삭제하시겠습니까?"],
      handleConfirm: () => handleDelete(),
      handleCancel: () => closeModal(),
    });
  };

  const exitModal = () => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `나가기`,
      message: ["작성을 취소하시겠습니까?"],
      handleConfirm: () => navigate(`/mypage/scheduler${searchTxt ? `?keyword=${searchTxt}` : ""}`),
      handleCancel: () => closeModal(),
    });
  };

  const saveModal = (type) => {
    const modalType = {
      eventEdit: "수정",
      eventSave: "저장",
    };

    openModal({
      isOpen: true,
      type: "confirmation",
      title: `디데이 ${modalType[type]}`,
      message: [`디데이를 ${modalType[type]}하시겠습니까?`],
      handleConfirm: () => handleSubmit(),
      handleCancel: () => closeModal(),
    });
  };

  const handleSubmit = async () => {
    const url = "/api/schedule";
    const body = {
      ...formData,
      delYn: "N",
    };

    if (!isNaN(body.pushMinute)) {
      body.pushMinute = parseInt(body.pushMinute, 10);
    } else {
      body.pushMinute = null;
    }

    body.startDt = moment(body.startDt).format("YYYY-MM-DD 00:00:00");
    body.endDt = moment(body.startDt).format("YYYY-MM-DD 23:59:59");

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success(`디데이가 ${isEditable ? "수정" : "등록"}되었습니다.`);
      navigate(`/mypage/scheduler${searchTxt ? `?keyword=${searchTxt}` : ""}`);
    }
  };

  const handleDelete = async () => {
    console.log;
    const url = "/api/schedule";
    const body = {
      ...formData,
      scheduleNo: formData.scheduleNo,
      delYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success("디데이가 삭제되었습니다.");
      navigate(`/mypage/scheduler${searchTxt ? `?keyword=${searchTxt}` : ""}`);
    }
  };

  const checkSubmitCond = (type) => {
    if (!formData.title) {
      toast.error("제목이 존재하지 않습니다");
      return;
    }

    saveModal(type);
  };

  return (
    <>
      {modal}
      <AddScheduleContainer className={`inner_content ${isDarkMode}`} id="schduler_content">
        <GoToBackHeader className={isDarkMode}>
          <span className="icon" onClick={exitModal}>
            <IoMdArrowBack />
          </span>
          <span>디데이 {isEditable ? "수정" : "등록"}</span>
        </GoToBackHeader>
        <ScheduleFormContainer>
          <DdayForm
            formData={formData}
            setFormData={setFormData}
            isEditable={isEditable}
            checkSubmitCond={checkSubmitCond}
            pushMinutes={pushMinutes}
            deleteModal={deleteModal}
          />
        </ScheduleFormContainer>
      </AddScheduleContainer>
    </>
  );
};

export default DdayEntry;
