import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";

import { EditMemberContainer } from "../../../styled/groupDetails";

import Search from "../../../common/search";
import TableMember from "./members/tableMember";
import { useMediaQuery } from "react-responsive";
import ListMember from "./members/listMember";
import { NoData } from "../../../styled/common";

const Members = ({
  member,
  members,
  group,
  getGroupMembers,
  setIsInvite,
  openModal,
  closeModal,
}) => {
  const user = useSelector((state) => state?.user?.data[0]);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [groupMembers, setGroupMembers] = useState([...members]);
  const [searched, setSearched] = useState("");

  useEffect(() => {
    setGroupMembers([...members]);
  }, [members]);

  // 권한 변경
  const handleAuth = (item, newAuth) => {
    if (member.auth >= item.auth) {
      toast.error("해당 멤버의 권한을 변경할 수 없습니다.");
      return;
    }
    if (member.auth >= newAuth) {
      toast.error("해당 권한으로는 변경할 수 없습니다.");
      return;
    }

    openModal({
      isOpen: true,
      type: "confirmation",
      title: "멤버 삭제",
      message: [`멤버 ${item.nickNm || "닉네임 미설정"}의 권한을`, "변경하시겠습니까?"],
      handleConfirm: () =>
        updateAuth({
          nickNm: item.nickNm || "",
          seq: item.seq,
          groupNo: item.groupNo,
          mberNo: item.mberNo,
          delYn: "N",
          gbnValue: item.gbnValue,
          auth: newAuth,
          isAuthChanged: "Y",
        }),
      handleCancel: () => closeModal(),
    });
  };

  // 멤버 삭제
  const handleDelete = (item) => {
    if (member.auth >= item.auth) {
      toast.error("해당 멤버는 삭제할 수 없습니다.");
      return;
    }

    openModal({
      isOpen: true,
      type: "confirmation",
      title: "멤버 삭제",
      message: [
        `멤버 ${chooseParticle(item.nickNm || "닉네임 미설정", "을를")}  삭제하시겠습니까?`,
      ],
      handleConfirm: () =>
        deleteMember({
          seq: item.seq,
          groupNo: item.groupNo,
          mberNo: item.mberNo,
          delYn: "Y",
          auth: item.auth,
          gbnValue: item.gbnValue,
        }),
      handleCancel: () => closeModal(),
    });
  };

  // 멤버 권한 변경
  const updateAuth = async (update) => {
    const url = "/api/groupMber";
    const body = {
      ...update,
      createMberId: user.userName,
      updateMberId: user.userName,
      isAuthChanged: "Y",
    };
    const res = await axios.put(url, body);

    if (res.data.success) {
      toast.success("멤버 권한이 변경되었습니다.");
      getGroupMembers(group);
      closeModal();
    }
  };

  // 멤버 삭제
  const deleteMember = async (update) => {
    const url = "/api/groupMber";
    const body = {
      ...update,
      createMberId: user.userName,
      updateMberId: user.userName,
    };
    const res = await axios.put(url, body);

    if (res.data.success) {
      toast.success("모임 멤버가 삭제되었습니다.");
      getGroupMembers(group);
      closeModal();
    }
  };

  // 가입한 멤버 검색
  const handleSearch = () => {
    if (searched) {
      setGroupMembers(
        members.filter(
          (user) =>
            (user.nickNm && user.nickNm.includes(searched)) ||
            (user.moblphonNo && user.moblphonNo.includes(searched)),
        ),
      );
    } else {
      setGroupMembers([...members]);
    }
  };

  return (
    <EditMemberContainer>
      <Search
        placeholder="닉네임을 입력해주세요."
        searched={searched}
        setSearched={setSearched}
        handleSearch={handleSearch}
      />
      <button className="invite" onClick={() => setIsInvite(true)}>
        멤버 초대
      </button>
      {isMobile ? (
        groupMembers && groupMembers.length > 0 ? (
          <ul>
            {groupMembers.map((item) => (
              <ListMember
                group={group}
                item={item}
                handleAuthChange={handleAuth}
                handleDeleteChange={handleDelete}
              />
            ))}
          </ul>
        ) : (
          <NoData>멤버가 존재하지 않습니다.</NoData>
        )
      ) : (
        <table>
          <thead>
            <tr>
              <th>이름</th>
              <th>가입날짜</th>
              <th>권한</th>
              <th>삭제</th>
            </tr>
          </thead>
          <tbody>
            {groupMembers && groupMembers.length > 0 ? (
              groupMembers.map((item) => (
                <TableMember
                  group={group}
                  item={item}
                  handleAuthChange={handleAuth}
                  handleDeleteChange={handleDelete}
                />
              ))
            ) : (
              <tr>
                <td colSpan={4}>멤버가 존재하지 않습니다.</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </EditMemberContainer>
  );
};

export default Members;
