import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { LuChevronDown, LuChevronUp } from "react-icons/lu";

const ReleaseNoteItem = ({ item, isOpenNotice, setOpenNotice }) => {
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  return (
    <li>
      <div
        className={`devNotifyWrapper${isOpenNotice(item.noticeNo) ? " active" : ""}`}
        onClick={() => setOpenNotice(isOpenNotice(item.noticeNo) ? null : item.noticeNo)}>
        <div className="title font_16">
          <img src={item.imgPath ? imgHandler(item.imgPath) : "/img/application_price_1.png"} />
          <span className="noticeSj">{item.noticeSj}</span>
          <span>{item.mberNm}</span>
          <div className="date">{item.noticeDe}</div>
          <button className="toggleCollapseButton">
            {isOpenNotice(item.noticeNo) ? <LuChevronUp /> : <LuChevronDown />}
          </button>
          {user && company.some((item) => item.cmpnyNo === 16421) && (
            <button
              onClick={() =>
                navigate("/mypage/createReleaseNotes", {
                  state: { releaseNote: item },
                })
              }
              className="editButton">
              수정
            </button>
          )}
        </div>
        <div
          className={`devNoticeContent font_14`}
          dangerouslySetInnerHTML={{ __html: item.noticeCn }}></div>
      </div>
    </li>
  );
};

export default ReleaseNoteItem;
