import { useState, useEffect } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useRegex } from "../../../../hooks/useRegex";
import { CategoryList, PaginationContainer } from "../../styled/shopping";
import Loading from "../../common/ShopLoading.js";
import Pagination from "react-js-pagination";
import useQueryString from "../../../../hooks/useQueryString.jsx";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import axios from "axios";
import ShoppingSectionSwiper from "../../shoppingSectionSwiper.jsx";
import { findTargetMenuName } from "../../common/function/shopping.jsx";

export default function ProductListForm({ main = false }) {
  const { cmpnyNo, url, theme, menus } = useOutletContext();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const navigater = useNavigate();

  const queryPage = Number(useQueryString("page"));

  const [prduct, setPrduct] = useState([]);
  const [currentPage, setCurrentPage] = useState(queryPage || 0);
  const [totalCount, setTotalCount] = useState(0);
  const [selectCount, setSelectCount] = useState(0);
  const [selected, setSelected] = useState({
    codeNm: "",
    brand: "",
    code: "",
  });
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    if (cmpnyNo) {
      getPrductList();
    }
  }, [cmpnyNo]);

  useEffect(() => {
    if (flag) {
      getPrductList();
    }
  }, [flag]);

  useEffect(() => {
    if (cmpnyNo && flag) {
      getQueryUrl();
    }
  }, [currentPage]);

  const getPrductList = async () => {
    try {
      const url = "/erp/vendorProducts/get";
      const query = `
        query VendorProducts(
          $cmpnyNo: Int!
        ) {
          vendorProducts(
            filter: {
              cmpnyNo: $cmpnyNo
            }
          ) {
            totalCount
            products {
              id
              name
              brand
              categoryId
              media
              price
            }
          }
        }
      `;

      const variables = { cmpnyNo: parseInt(cmpnyNo, 10) };
      const body = { query, variables };
      const res = await axios.post(url, body);

      if (res.status === 200) {
        setPrduct(res.data.products);
        setTotalCount(res.data.totalCount);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const moveToPage = (prdNo) => {
    navigater(`/shop/${url}/productsView/${prdNo}`, {
      state: { selected: selected, page: currentPage },
    });
  };

  const nextPage = (num) => {
    getPrductList(num - 1);
  };

  return (
    <main
      id="contProductsList"
      className="sub_page_mobile_mt"
      style={main ? { marginTop: "0" } : {}}>
      {theme === 1 && (
        <div className="subTop_bnr subTop_bnr_01">
          <span>PRODUCT</span>
        </div>
      )}
      <article className={isMobile && main ? "boundary pdb_70" : "boundary pdt_70 pdb_70"}>
        <h2 className="font_36 pdb_30 font600 text_center">
          {findTargetMenuName(menus, "제품소개")}
        </h2>
        {/* {!main && (
          <section id="secBrdcrmb" className="font_18 pdb_20 text_right">
            <Link to={`/shop/${url}`}>
              <i className="xi-home-o font_20"></i>
            </Link>
            <span>제품소개</span>
          </section>
        )} */}
        <section className="font_14">
          <div className="font_color_gray_light">
            {!main && (
              <div className="count">
                <i className="fa fa-bars"></i>
                상품
                <strong className="font_color_red"> {totalCount}</strong>
                개가 등록되어 있습니다.
              </div>
            )}
          </div>
        </section>
        <section
          id="secList"
          className="pdt_30"
          style={{
            justifyContent: "flex-start",
          }}>
          {prduct?.length !== 0 ? (
            <>
              {prduct.map((product) => (
                <div className="col_3" key={product.id}>
                  <a onClick={() => moveToPage(product.id)} style={{ cursor: "pointer" }}>
                    <div className="img">
                      <img src={imgHandler(product.media)} onError={onErrorImg} alt="" />
                    </div>
                    <div>
                      <p className="font_18 font600 pdt_10 pdb_20">
                        {product?.name ? product.name : ""}
                      </p>
                      <p className="font_16">
                        {product?.price ? `${product.price.toLocaleString()}원` : "가격 문의"}
                      </p>
                    </div>
                  </a>
                </div>
              ))}
              {!main && (
                <PaginationContainer>
                  <Pagination
                    activePage={currentPage + 1}
                    itemsCountPerPage={12}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={5}
                    prevPageText={"‹"}
                    nextPageText={"›"}
                    onChange={nextPage}
                  />
                </PaginationContainer>
              )}
            </>
          ) : (
            <p className="font_18 font600 pdt_10" style={{ width: "100%", textAlign: "center" }}>
              상품이 존재하지 않습니다.
            </p>
          )}
        </section>
      </article>
    </main>
  );
}
