import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Error = () => {
  const navi = useNavigate();
  useEffect(() => {
    const currentUrl = window.location.href;
    const origin = window.location.origin;

    const boardIndex = currentUrl.indexOf("/board/");
    if (boardIndex !== -1) {
      const redirectUrl = currentUrl.substring(0, boardIndex).replace(origin, "");
      navi(redirectUrl);
    } else {
      // setTimeout(() => {
      //   navi("/");
      // }, 3000);
    }
  }, [navi]);

  return <h2>error: 404 존재하지 않는 페이지 입니다 잠시후 메인페이지로 이동 됩니다.</h2>;
};

export default Error;
