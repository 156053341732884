import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { toast } from "react-toastify";

import { GroupContainer } from "./component/styled/common";

import PageInfo from "./component/common/pageInfo";
import Schedule from "./component/group/schedule";
import Slide from "./component/common/slide";
import Banner from "./component/group/banner";

const Group = () => {
  const navigate = useNavigate();
  const [group, setGroup] = useState([]);

  useEffect(() => {
    getGroup();
  }, []);

  // 모임 조회
  const getGroup = async () => {
    const total = 261;
    const totalPages = Math.ceil(total / 20);

    const url = "/api/groupList";
    const req = {
      delYn: "N",
      displayYn: "Y",
      approvalUseYn: "N",
      offset: Math.floor(Math.random() * 14),
      pageNumber: Math.floor(Math.random() * totalPages),
      pageSize: 20,
      paged: true,
    };
    try {
      const res = await axios.post(url, req);
      if (res.status === 200) {
        console.log(res.data);
        setGroup(res.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clickSlide = (item) => {
    if (!item || !item.groupNo) {
      toast.error("잠시후 다시 시도해주세요.");
      return;
    }
    navigate(`/group/${item.groupNo}`);
  };

  return (
    <GroupContainer>
      <div className="inner">
        <PageInfo title="MOIM - 협력과 소통의 공간" />
        <Banner />
        <Schedule />
        <Slide
          info={{
            title: "다양한 모임을\n추천할게요😉",
            description: "재밌는 모임들 엄선!",
            nav: { label: "모임 보러가기", func: () => navigate("/group/all") },
            notDataMessage: "새로운 모임을 만들어보세요.",
          }}
          clickSlide={clickSlide}
          data={group}
          type="group"
        />
      </div>
    </GroupContainer>
  );
};

export default Group;
