import React, { useEffect, useState } from "react";
import HomepageForm from "./component/homepageForm";
import { useSelector } from "react-redux";

import { Container } from "../styled/mypage";

import PageInfo from "../pageInfo";

export default function Hompage() {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <Container className={setting?.mode}>
      <div className="content">
        <PageInfo label="홈페이지 목록" />
        <HomepageForm />
      </div>
    </Container>
  );
}
