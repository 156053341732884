import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { BlogItem } from "../search/searchItem";
import { BlogContainer, NoData } from "../../../styled/common";

const Blog = () => {
  const { id } = useParams();
  const [blogList, setBlogList] = useState([]);
  const [cmpnys, setCmpnys] = useState({});

  useEffect(() => {
    getGroupCompany();
    getBlog();
  }, []);

  const getGroupCompany = async () => {
    const url = "/api/groupCmpnyList";
    const body = { groupNos: [parseInt(id, 10)] };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      setCmpnys(res.data.result);
      console.log(res.data.result);
    }
  };

  const getBlog = async () => {
    const url = "/api/combineSearch";
    const body = {
      groupNo: id,
      searchType: "B",
    };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        setBlogList(res.data.blogList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BlogContainer>
      {blogList && blogList.length > 0 ? (
        <ul>
          {blogList.map((item) => (
            <BlogItem item={item} cmpnys={cmpnys} />
          ))}
        </ul>
      ) : (
        <NoData>소식이 존재하지 않습니다.</NoData>
      )}
    </BlogContainer>
  );
};

export default Blog;
