import { useLocation } from "react-router";
import RegisterPhotoUploadForm from "./component/register/registerPhotoUploadForm";
import { Container } from "../styled/mypage";
import PageInfo from "../pageInfo";
import { useSelector } from "react-redux";

export default function RegisterPhotoUpload() {
  const location = useLocation();
  const fileBoxImages = location?.state?.images || [];
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <Container className={setting?.mode}>
      <PageInfo label="포트폴리오 등록" />
      <RegisterPhotoUploadForm fileBoxImages={fileBoxImages} />
    </Container>
  );
}
