import PortfolioList from "./portfolioPage/portfolioList";
import { useOutletContext } from "react-router";

import ModernBanner from "../shopping/component/themes/modern/banner";
import CuteBanner from "../shopping/component/themes/cute/banner";
import SimpleBanner from "../shopping/component/themes/simple/banner";

import { ChicPortfolioContainer } from "../shopping/component/styled/chicTheme";
import { ModernProductsContainer } from "../shopping/component/styled/modernTheme";
import { CuteProductsContainer } from "../shopping/component/styled/cuteTheme";
import { SimplePortfolioContainer } from "../shopping/component/styled/simpleTheme";
import { SmPortfolioContainer } from "../shopping/component/styled/smTheme";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";
import SmBanner from "../shopping/component/themes/sm/banner";
import ChicBanner from "../shopping/component/themes/chic/banner";
import ShoppingSectionSwiper from "../shoppingSectionSwiper";

const containers = {
  0: DefaultContaienr,
  1: ChicPortfolioContainer,
  2: ModernProductsContainer,
  3: CuteProductsContainer,
  4: SimplePortfolioContainer,
  sm: SmPortfolioContainer,
};

const banners = {
  0: ShoppingSectionSwiper,
  1: ChicBanner,
  2: ModernBanner,
  3: CuteBanner,
  4: SimpleBanner,
  sm: SmBanner,
};

export default function PortfolioPage() {
  const { theme } = useOutletContext();

  const getBannerInfo = (theme) => {
    if (theme === "sm") {
      return {
        page: `게시판`,
        message: `고객님을 위한 정책과\n더 나은 서비스를 제공합니다.`,
      };
    }
    return { imgage: "port.png", text: "portfolio" };
  };

  const renderComponent = () => {
    const Container = containers[theme] || DefaultContaienr;
    const Banner = banners[theme];

    return (
      <Container>
        {Banner && <Banner info={getBannerInfo(theme)} />}
        <PortfolioList />
      </Container>
    );
  };

  return <>{renderComponent()}</>;
}
