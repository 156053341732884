import React from "react";
import { InfoContainer } from "../styled/feedWrite";
import { useParams } from "react-router";
import { RiImageAddFill } from "react-icons/ri";

const Info = ({ myGrp, changeImg, feed, setFeed }) => {
  return (
    <InfoContainer>
      <div>
        <span>{`새소식 ${feed.createDt ? "수정" : "작성"}`}</span>
        <select
          value={String(feed.gbn) || "0"}
          onChange={(e) => {
            const selectedOption = e.target.options[e.target.selectedIndex];
            setFeed((data) => ({
              ...data,
              gbn: selectedOption.value,
              groupName: selectedOption.dataset.groupName,
            }));
          }}>
          <option value="0" data-group-name="전체선택">
            전체선택
          </option>
          {myGrp.map((item) => (
            <option key={item.groupNo} value={item.groupNo} data-group-name={item.groupName}>
              {item.groupName}
            </option>
          ))}
        </select>
      </div>
      <label>
        <input type="file" onChange={changeImg} multiple accept=".jpg, .png"></input>
        <RiImageAddFill />
      </label>
    </InfoContainer>
  );
};

export default Info;
