import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DaumPostcode from "react-daum-postcode";
import { toast } from "react-toastify";
import axios from "axios";
import moment from "moment";
import "moment/locale/ko";

import ScheduleFormDetails from "./scheduleForm/scheduleFormDetails";
import ScheduleFormBasic from "./scheduleForm/scheduleFormBasic";

import { ScheduleFormContainer } from "../styled/calendarEntry";
import { AddrSearchModal } from "../styled/calendarEntry";

import { transformDate } from "../function/scheduler";

const ScheduleForm = ({
  date,
  setDate,
  isAllTime,
  setIsAllTime,
  formData,
  setFormData,
  myAffil,
  isEditable,
  handleChangeGbn,
  handleChangeOrgNo,
  toggleSideForm,
  checkSubmitCond,
  saveToLocalStorage,
  projectList,
  setProjectList,
  getProjectMbers,
  setActSelType,
  infoState,
  eventInfo,
  projectName,
  setProjectName,
  todos,
  setTodos,
  deleteModal,
  // isRecurring,
}) => {
  const [showPostCode, setShowPostCode] = useState(false);
  const openPostCode = () => setShowPostCode(true);
  const closePostCode = () => setShowPostCode(false);
  const user = useSelector((state) => state?.user?.data[0]);
  const [titles, setTitles] = useState([]);
  const [scheduleTitles, setScheduleTitles] = useState([]);
  const [showTitles, setShowTitles] = useState(false);
  const [titlesPosition, setTitlesPosition] = useState({});
  const [titleWidth, setTitleWidth] = useState();
  const CHOSUNG = [
    "ㄱ",
    "ㄲ",
    "ㄴ",
    "ㄷ",
    "ㄸ",
    "ㄹ",
    "ㅁ",
    "ㅂ",
    "ㅃ",
    "ㅅ",
    "ㅆ",
    "ㅇ",
    "ㅈ",
    "ㅉ",
    "ㅊ",
    "ㅋ",
    "ㅌ",
    "ㅍ",
    "ㅎ",
  ];

  useEffect(() => {
    getScheduleTitleList();
  }, []);

  // 일정 제목 조회
  const getScheduleTitleList = async () => {
    const url = "/api/scheduleTitleList";
    const body = { mberNo: user.mberNo };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setScheduleTitles([...res.data]);
    }
  };

  // 제목 변경
  const changeScheduleNm = async (e) => {
    const { name, value } = e.target;

    setFormData((data) => {
      let updated = { ...data, [name]: value };
      saveToLocalStorage(updated);
      return updated;
    });

    if (!value || !value.trim() || !value.length) {
      setTitles([]);
      return;
    }

    setShowTitles(true);
    const searched = searchScheduleTitles(value, scheduleTitles);
    setTitles(searched);

    const targetElement = e.target;
    const rect = targetElement.getBoundingClientRect();
    setTitlesPosition({ top: rect.bottom, left: rect.left });
    const width = rect.width;
    setTitleWidth(width);
  };

  // 한글 여부 확인
  const isHangul = (char) => {
    const code = char.charCodeAt(0);
    return (code >= 0xac00 && code <= 0xd7a3) || (code >= 0x3131 && code <= 0x314e);
  };

  // 문자 초성 추출
  const getChosung = (char) => {
    if (isHangul(char)) {
      const code = char.charCodeAt(0) - 0xac00;
      if (code >= 0) {
        const chosungIndex = Math.floor(code / (21 * 28));
        return CHOSUNG[chosungIndex];
      } else {
        return char;
      }
    }
    return char;
  };

  // 각 글자를 초성으로 변환
  const itemToChosung = (item) => {
    return Array.from(item).map(getChosung).join("");
  };

  // 제목 검색
  const searchScheduleTitles = (query, items) => {
    const lowerQuery = query.toLowerCase();
    const queryChosung = Array.from(lowerQuery).map(getChosung).join("");

    return items.filter((item) => {
      const lowerItem = item.toLowerCase();
      const itemChosung = itemToChosung(lowerItem);

      if (isHangul(lowerQuery[0])) {
        return itemChosung.includes(queryChosung) || lowerItem.includes(lowerQuery);
      } else {
        return lowerItem.includes(lowerQuery);
      }
    });
  };

  // 프로젝트 변경
  const changeProject = (value) => {
    setTodos((data) => [...data].map((item) => ({ ...item, mentionUserIds: [] })));

    setFormData((data) => {
      let updated = { ...data, projectNo: value, workerList: {} };

      if (!isNaN(value) && updated.gbn === "C") {
        getProjectMbers(value);
      }

      saveToLocalStorage(updated);
      return updated;
    });
  };

  // 구분 변경
  const changeGbn = (e) => {
    const { name, value } = e.target;
    setTodos((data) => [...data].map((item) => ({ ...item, mentionUserIds: [] })));

    const color = {
      P: "139859",
      C: "0074CA",
      G: "F46F02",
    };

    setFormData((data) => {
      let updated = { ...data, [name]: value, color: color[value] };

      if (value === "P") {
        toggleSideForm(false);
      }
      if (value === "C" || value === "G") {
        setProjectList({});
        handleChangeGbn(value);
      }

      updated = resetScheduleData(updated);
      saveToLocalStorage(updated);
      return updated;
    });
  };

  // 특정 회사 및 모임 변경
  const changeOrgNo = (e) => {
    const { name, value } = e.target;
    setTodos((data) => [...data].map((item) => ({ ...item, mentionUserIds: [] })));

    setFormData((data) => {
      let updated = { ...data, [name]: value };

      if (!isNaN(value)) {
        updated = resetManagement(updated);
        const { gbn } = updated;
        console.log(!isNaN(value));

        if (gbn === "C" || gbn === "G") {
          handleChangeOrgNo(gbn, value);
        } else {
          toggleSideForm(false);
        }
      } else {
        updated = resetScheduleData(updated);
        setProjectList({});
        toggleSideForm(false);
      }

      saveToLocalStorage(updated);
      return updated;
    });
  };

  const changeInput = (e) => {
    const { name, value } = e.target;

    if (!name) return;

    const isDateField = date.hasOwnProperty(name);
    if (isDateField) {
      if (!value) return;

      setDate((prevDate) => {
        let updated = {
          ...prevDate,
          [name]: value,
        };

        if (name === "dateStart") {
          updated = {
            ...updated,
            dateEnd: moment(value).add(1, "days").format("YYYY-MM-DD"),
          };
        } else if (name === "timeStartHour") {
          updated = {
            ...updated,
            timeEndHour: moment().hour(parseInt(value, 10)).add(1, "hours").format("HH"),
          };
        } else if (name === "timeStartMinute") {
          updated = {
            ...updated,
            timeEndMinute: moment().minute(parseInt(value, 10)).format("mm"),
          };
        }

        const formDataDate = transformDate(updated, isAllTime);
        saveToLocalStorage(formDataDate);

        return updated;
      });
    } else {
      if (isEditable && (name === "gbn" || name === "orgNo")) {
        toast.error("이 일정은 수정할 수 없습니다.");
        return;
      }

      setFormData((data) => {
        let updated = { ...data, [name]: value };
        saveToLocalStorage(updated);
        return updated;
      });
    }
  };

  const resetScheduleData = (data) => {
    setProjectName("");

    return {
      ...data,
      managerNo: null,
      managerNm: null,
      orgNo: null,
      orgNm: null,
      projectNo: "notProject",
      workerList: {},
    };
  };

  const resetManagement = (data) => {
    setProjectName("");

    return {
      ...data,
      managerNo: user.mberNo,
      managerNm: user.name,
      projectNo: "notProject",
      workerList: {},
    };
  };

  // 카카오 주소
  const userAddress = (data) => {
    const extraAddress = [data.bname, data.buildingName].filter(Boolean).join(", ");
    const fullAddress = data.address + (extraAddress ? ` (${extraAddress})` : "");

    setFormData((data) => {
      const updated = { ...data, place: fullAddress };
      saveToLocalStorage(updated);
      return updated;
    });

    closePostCode();
  };

  return (
    <ScheduleFormContainer>
      {showPostCode && (
        <AddrSearchModal
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              closePostCode();
            }
          }}>
          <div className="modalContent">
            <DaumPostcode onComplete={userAddress} />
            <button onClick={closePostCode}>닫기</button>
          </div>
        </AddrSearchModal>
      )}
      <ScheduleFormBasic
        setIsAllTime={setIsAllTime}
        myAffil={myAffil}
        changeInput={changeInput}
        changeGbn={changeGbn}
        changeOrgNo={changeOrgNo}
        isAllTime={isAllTime}
        formData={formData}
        setFormData={setFormData}
        date={date}
        setDate={setDate}
        saveToLocalStorage={saveToLocalStorage}
        projectList={projectList}
        changeProject={changeProject}
        setActSelType={setActSelType}
        changeScheduleNm={changeScheduleNm}
        showTitles={showTitles}
        titles={titles}
        titlesPosition={titlesPosition}
        setShowTitles={setShowTitles}
        setTitlesPosition={setTitlesPosition}
        titleWidth={titleWidth}
        toggleSideForm={toggleSideForm}
        isEditable={isEditable}
        eventInfo={eventInfo}
        projectName={projectName}
        setProjectName={setProjectName}
      />
      <ScheduleFormDetails
        formData={formData}
        setFormData={setFormData}
        changeInput={changeInput}
        openPostCode={openPostCode}
        saveToLocalStorage={saveToLocalStorage}
        todos={todos}
        setTodos={setTodos}
        // isRecurring={isRecurring}
      />
      <div className="btnArea font_14">
        <button
          onClick={() =>
            checkSubmitCond(isEditable ? (infoState ? "eventCopy" : "eventEdit") : "eventSave")
          }>
          저장
        </button>
        {isEditable && (
          <button onClick={() => deleteModal()} className="delBtn">
            삭제
          </button>
        )}
      </div>
    </ScheduleFormContainer>
  );
};

export default ScheduleForm;
