import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { ProjectCheckBoxLabel } from "../common/styled/project";

const ProjectCreateSecInputField = ({
  isAddDesc,
  setIsAddDesc,
  formData,
  changeInput,
  setFormData,
  handleFocus,
  handleBlur,
  projectInfo,
  checkDuplProjectNm,
}) => {
  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  const [options, setOptions] = useState([]);

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const onClickButton = (gbn) => {
    if (projectInfo) {
      toast.error("프로젝트 생성 후에는 수정이 불가합니다.");
      return;
    }
    setFormData((data) => ({ ...data, gbn: gbn }));
  };

  const getMyGrp = async () => {
    const url = "/api/groupCondList";
    const body = {
      mberNo: user.mberNo,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, body);
    const myGrp = res.data.content;

    if (myGrp.length > 0) {
      setOptions(myGrp.map((item) => ({ name: item.groupName, value: item.groupNo })));
      setFormData((data) => ({ ...data, orgNo: myGrp[0].groupNo }));
    }
  };

  const getMyCmpny = async () => {
    setOptions(company.map((item) => ({ name: item.cmpnyNm, value: item.cmpnyNo })));
    setFormData((data) => ({ ...data, orgNo: company[0].cmpnyNo }));
  };

  useEffect(() => {
    if (formData.gbn === "G") {
      getMyGrp();
    } else {
      getMyCmpny();
    }
  }, [formData.gbn]);

  return (
    <ul className="projectInputField">
      <li className="projectName">
        <input
          type="text"
          placeholder="프로젝트 명을 입력하세요."
          name="projectNm"
          value={formData.projectNm}
          onChange={changeInput}
          onFocus={() => handleFocus("projectNm")}
          onBlur={() => handleBlur("projectNm")}
          ref={inputRef}
        />
        <button onClick={checkDuplProjectNm}>중복 확인</button>
      </li>
      <li className="selectButtons">
        <span>어느 프로젝트인지 설정해주세요.</span>
        <div>
          <button
            onClick={() => onClickButton("C")}
            className={formData.gbn === "C" ? "active" : ""}>
            업무
          </button>
          <button
            onClick={() => onClickButton("G")}
            className={formData.gbn === "G" ? "active" : ""}>
            모임
          </button>
        </div>
      </li>
      <li>
        <select name="orgNo" onChange={changeInput}>
          {options.length > 0 &&
            options.map((item) => <option value={item.value}>{item.name}</option>)}
        </select>
      </li>
      <li>
        <button className="descAddButton" onClick={() => setIsAddDesc(!isAddDesc)}>
          설명 입력{isAddDesc ? " 취소" : ""}
        </button>
      </li>
      <li>
        {isAddDesc && (
          <textarea
            placeholder="프로젝트 설명을 입력해주세요."
            value={formData.projectDesc}
            name="projectDesc"
            onChange={changeInput}
            onFocus={() => handleFocus("projectDesc")}
            onBlur={() => handleBlur("projectDesc")}></textarea>
        )}
      </li>
      <li>
        <ProjectCheckBoxLabel>
          <span>참여자에게만 공개</span>
          <input
            type="checkbox"
            checked={formData.publicYn === "N"}
            onChange={changeInput}
            name="publicYn"
          />
        </ProjectCheckBoxLabel>
      </li>
    </ul>
  );
};

export default ProjectCreateSecInputField;
