import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import InviteModal from "./members/inviteModal";
import MemberList from "./members/memberList";
import { deleteCompany } from "../../../../../store/company";
import { useNavigate } from "react-router";

const Members = ({ company, closeModal, openModal }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state?.user?.data[0]);

  const [members, setMembers] = useState([]);
  const [moblphon, setMoblphon] = useState("");
  const [searched, setSearched] = useState([]);
  const [isSearched, setIsSearched] = useState(false);
  const [isInvite, setIsInvite] = useState(false);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (!company) return;
    getCmpnyMber(0, company.cmpnyNo);
  }, [company]);

  // 근태상태 변경
  const editWorkStatus = async (item) => {
    await updateMember(item, { isWorking: !item.isWorking }, () => {
      toast.success("근태 상태가 업데이트 되었습니다.");
    });
  };

  const changePages = (pages) => {
    const newPage = pages - 1;

    setPage(newPage);
    getCmpnyMber(newPage, company.cmpnyNo);
  };

  const getCmpnyMber = async (page = 0, cmpnyNo) => {
    if (!cmpnyNo) return;

    const url = "/api/cmpnyMbers";
    const body = { cmpnyNo: cmpnyNo, size: 10, page };
    try {
      const res = await axios.post(url, body);
      if (res.data) {
        setMembers(res.data.content);
        setTotal(res.data.totalElements);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const sendKakaoMessage = () => {
    window.Kakao.Share.sendCustom({
      templateId: 89755,
      templateArgs: {
        company: company.cmpnyNm,
        unique: company.cmpnyNo,
        name: user.name,
      },
    });
  };

  const handleSearch = async () => {
    const phoneRegex = /^(010\d{8}|011\d{7})$/;

    if (!phoneRegex.test(moblphon)) {
      toast.error("전화번호 양식이 일치하지 않습니다.");
      return;
    }

    const url = "/api/mberList";
    const body = {
      moblphonNo: moblphon,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      console.log(res);
      setSearched(res.data.content);
      setIsSearched(true);
    }
  };

  const handleInvite = async (mberNo) => {
    if (members.some((user) => parseInt(user.mberNo) === parseInt(mberNo))) {
      toast.error("이미 가입된 멤버입니다.");
      return;
    }

    const url = "/api/notification";
    const body = {
      gbn: "I",
      invite: {
        gbn: "C",
        mberNoList: [mberNo],
        orgNo: company.cmpnyNo,
      },
    };
    const res = await axios.post(url, body);

    if (res.data.body.success) {
      toast.success("초대 알림이 발송되었습니다.");
      setIsInvite(false);
    }
  };

  const updateMember = async (item, updates, successFunc) => {
    const url = "/api/cmpnyMber";
    const body = {
      id: item.id,
      cmpnyNo: item.cmpnyNo,
      mberNo: item.mberNo,
      mberNm: item.mberNm,
      mberTel: item.mberTel,
      auth: item.auth,
      isWorking: item.isWorking,
      isDeleted: item.isDeleted,
      userId: item.userId,
      createMberId: user.userName,
      updateMberId: user.userName,
      ...updates,
    };

    try {
      const res = await axios.put(url, body);

      if (res.status === 200) {
        successFunc();
        await getCmpnyMber(0, company.cmpnyNo);
      }
    } catch (error) {
      console.error("서버 요청 중 오류 발생:", error);
    }
  };

  // 멤버 삭제
  const deleteMber = async (item) => {
    const isCurrentUser = item.mberNo === user.mberNo;
    const memberDeletion = () => {
      if (isCurrentUser) {
        dispatch(deleteCompany(item.cmpnyNo));
        navigate("/mypage");
      } else {
        toast.success("멤버가 삭제되었습니다.");
      }
    };
    await updateMember(item, { isDeleted: true }, () => memberDeletion());
  };

  return (
    <>
      {isInvite && (
        <InviteModal
          setMoblphon={setMoblphon}
          moblphon={moblphon}
          handleSearch={handleSearch}
          isSearched={isSearched}
          searched={searched}
          sendKakaoMessage={sendKakaoMessage}
          setIsInvite={setIsInvite}
          handleInvite={handleInvite}
        />
      )}
      <MemberList
        company={company}
        members={members}
        editWorkStatus={editWorkStatus}
        page={page}
        total={total}
        changePages={changePages}
        setIsInvite={setIsInvite}
        openModal={openModal}
        closeModal={closeModal}
        deleteMber={deleteMber}
      />
    </>
  );
};

export default Members;
