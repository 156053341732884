import React from "react";
import moment from "moment";
import { AiOutlineClose } from "react-icons/ai";
import { useSelector } from "react-redux";
import { isAuth } from "../../function/group";

const Comment = ({ item, handleDeleteModal, feed }) => {
  const user = useSelector((state) => state?.user?.data[0]);

  // 댓글 시간
  function getTime(time) {
    const now = moment();
    const target = moment(time);

    const years = now.diff(target, "years");
    if (years > 0) {
      return `${years}년 전`;
    }
    const months = now.diff(target, "months");
    if (months > 0) {
      return `${months}달 전`;
    }
    const days = now.diff(target, "days");
    if (days > 0) {
      return `${days}일 전`;
    }
    const hours = now.diff(target, "hours");
    if (hours > 0) {
      return `${hours}시간 전`;
    }
    const minutes = now.diff(target, "minutes");
    if (minutes > 0) {
      return `${minutes}분 전`;
    }
    return "방금 전";
  }

  return (
    <li key={item.feedRecommentNo}>
      <img src={item.mberImgPath ? imgHandler(item.mberImgPath) : "/img/user2.png"} />
      <div>
        <span>{item.nickNm ? item.nickNm : "닉네임 미설정"}</span>
        <p>{item.content}</p>
      </div>
      {isAuth(feed, user) && (
        <button onClick={() => handleDeleteModal(item, "comment")} className="delete">
          <AiOutlineClose />
        </button>
      )}
      <div className="date">{getTime(item.createDt)}</div>
    </li>
  );
};

export default Comment;
