import styled from "styled-components";

export const HeaderContainer = styled.header`
  font-family: "Pretendard";
  .inner {
    max-width: 1440px;
    padding: 20px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #000;
    width: 100%;
  }
  .shopLogo {
    width: 150px;
    aspect-ratio: 2 / 1;
    padding-bottom: 0;
    cursor: pointer;
    color: #000;
    font-size: 30px;
    &.text {
      display: flex;
      justify-content: center;
      width: auto;
      aspect-ratio: auto;
    }
    span {
      white-space: nowrap;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .gnb,
  .tnb {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      cursor: pointer;
      user-select: none;
    }
  }
  .gnb {
    font-size: 20px;
    font-weight: 500;
    gap: 2rem;
  }
  .tnb {
    font-size: 14px;
    gap: 10px;
    .tnb_btn {
      a:hover {
        color: #000;
      }
    }
    a {
      transition: 0.3s;
    }
    i {
      font-size: 16px;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
  &.theme-0 {
    .inner {
      position: relative;
      .gnb,
      .tnb {
        position: absolute;
        right: 20px;
      }
      .gnb {
        font-size: 18px;
        top: 50%;
        flex-wrap: nowrap;
        transform: translateY(50%);
        max-width: calc(100% - 200px);
        overflow-x: auto;
        &::-webkit-scrollbar {
          width: 0;
        }
        li {
          flex: 0 0 auto;
        }
      }
      .tnb {
        gap: 20px;
        bottom: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &.theme-1 {
    font-family: "Prompt", "Pretendard";
    .gnb {
      font-weight: 500;
      li {
        transition: 0.3s;
        text-transform: uppercase;
        &:hover {
          color: rgb(255, 65, 145);
        }
      }
    }
  }
  &.theme-2 {
    .gnb {
      font-weight: 300;
      gap: 4rem;
    }
  }
  &.theme-3 {
    background: #ffc96f;
    .inner {
      position: relative;
      .gnb,
      .tnb {
        position: absolute;
        right: 20px;
      }
      .gnb {
        font-size: 18px;
        top: 50%;
        flex-wrap: nowrap;
        transform: translateY(50%);
        max-width: calc(100% - 200px);
        overflow-x: auto;
        &::-webkit-scrollbar {
          width: 0;
        }
        li {
          flex: 0 0 auto;
        }
      }
      .tnb {
        bottom: 50%;
        transform: translateY(-50%);
      }
    }
  }
  &.theme-4 {
    .gnb {
      font-weight: 700;
      li {
        transition: 0.3s;
        text-transform: uppercase;
        &:hover {
          color: rgb(0, 33, 83);
        }
      }
    }
  }
  &.theme-sm {
    background: rgba(0, 0, 0, 0.8);
    .shopLogo {
      width: 100px;
    }
    .inner {
      color: #fff;
      a {
        color: #fff;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .shopLogo.text {
      font-size: 20px;
    }
    .inner {
      padding: 10px 20px;
    }
    .gnb {
      display: none;
    }
    .tnb {
      font-size: 16px;
      i {
        font-size: 24px;
      }
    }
    .shopLogo {
      width: 100px;
    }
    &.theme-0 {
      .inner {
        .gnb {
          display: none;
        }
        .tnb {
          position: relative;
          transform: none;
          right: 0;
          bottom: 0;
        }
      }
    }
    &.theme-3 {
      .inner {
        flex-wrap: wrap;
        justify-content: center;
        gap: 10px;
        .gnb {
          width: 100%;
          max-width: 100%;
          display: flex;
          position: relative;
          justify-content: center;
          top: 0;
          right: 0;
          transform: none;
          gap: 15px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .shopLogo.text {
      font-size: 16px;
    }
    .tnb {
      font-size: 12px;
      li {
        display: none;
        &.tnb_btn {
          display: block;
        }
      }
    }
    &.theme-0 {
      .inner {
        padding: 0 20px;
        .gnb {
          display: none;
        }
      }
    }
    &.theme-3 {
      .inner {
        .gnb {
          font-size: 14px;
          gap: 10px;
        }
      }
    }
  }
`;
