import React, { useState } from "react";
import MemberSearchUsername from "./memberSearchUsername";
import MemberSearchPassword from "./memberSearchPassword";
import { MemberSearchContainer } from "./styled/memberSearch";
import { PageContainer } from "./styled/common";
import PageInfo from "../../notice/component/common/pageInfo";

const MemberSearch = () => {
  const [isSearch, setIsSearch] = useState(true);

  return (
    <PageContainer>
      <MemberSearchContainer>
        <PageInfo
          title={isSearch ? "아이디찾기" : "비밀번호찾기"}
          content={"가입 시 입력하신 이메일을 통해 계정 정보를 찾을 수 있습니다."}
        />
        <div className="inner_content">
          <div className="tab_menu">
            <ul className="tab">
              <li className={`tab_item ${isSearch && " active"}`} onClick={() => setIsSearch(true)}>
                아이디 찾기
              </li>
              <li
                className={`tab_item ${!isSearch && " active"}`}
                onClick={() => setIsSearch(false)}>
                비밀번호 찾기
              </li>
            </ul>
            <div className="tab_inner_wrap">
              {isSearch ? <MemberSearchUsername /> : <MemberSearchPassword />}
            </div>
          </div>
        </div>
      </MemberSearchContainer>
    </PageContainer>
  );
};

export default MemberSearch;
