import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { AiOutlineClose } from "react-icons/ai";
import { useLocation } from "react-router";

const FeedSwiper = ({ img, handleModal }) => {
  const location = useLocation();

  return (
    <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
      {img.map((item, i) => (
        <SwiperSlide key={i}>
          <div className="image">
            {location.pathname.includes("write") && (
              <button className="delete" onClick={() => handleModal(item)}>
                <AiOutlineClose />
              </button>
            )}
            <img src={imgHandler(typeof item === "string" ? item : item.path) || ""} />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default FeedSwiper;
