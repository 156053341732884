import React from "react";
import { isOutsideCmpny } from "../../function/common";
import { isEventCategory, timeByDate } from "../../function/scheduler";
import { useSelector } from "react-redux";

const Event = ({ item, clickEvent, onDate, index }) => {
  const company = useSelector((state) => state?.company?.data);

  return (
    <li
      key={index}
      style={{ borderLeft: `5px solid #${item.color}` }}
      onClick={() => clickEvent(item)}
      className={isOutsideCmpny(item, company) ? "outside" : ""}>
      <div className="eventContent">
        <div className="title">
          {item.icon && (
            <img src={`/img/scheduler/scheduler_icon_${item.icon}`} alt="" style={{ width: 20 }} />
          )}
          <span
            className="font600 font_16"
            style={{
              textDecoration: item.completeYn === "Y" ? "line-through" : "none",
            }}>
            {item.title}
          </span>
        </div>
        {item.dday === "N" && <p>{timeByDate(onDate, item.startDt, item.endDt)}</p>}
        <p>
          <span>{`${isEventCategory(item.gbn)}일정`}</span>
          {item.gbn !== "P" && <span>: {item.orgNm}</span>}
        </p>
      </div>
    </li>
  );
};

export default Event;
