import styled from "styled-components";

const darkColor = "#533b70";
const companyColor = "#0074ca";
const groupColor = "#F46F02";
const maxWidth = "1400px";
const contentBorderRadius = "5px";
const pageHeight = "calc(100vh - 73px)";

// common
export const ProjectSection = styled.div`
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;
export const ProjectCloseButton = styled.button`
  position: absolute;
  font-size: 30px;
  border: none;
  background: transparent;
  top: 20px;
  right: 20px;
  z-index: 10;
  @media screen and (max-width: 767px) {
    top: 10px;
    right: 10px;
  }
`;
export const NotMessage = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ProjectCheckBoxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  input[type="checkbox"] {
    appearance: none;
    background: #efefef;
    width: 42px;
    height: 22px;
    border-radius: 10px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 14px;
      height: 14px;
      top: 4px;
      left: 4px;
      background: #9a9a9a;
      border-radius: 50%;
      transition: 0.3s;
    }
    &:checked::after {
      transform: translateX(150%);
      background: #a78aca;
    }
  }
`;
export const ProjectInner = styled.div`
  position: relative;
  max-width: 1400px;
  margin: auto;
  overflow-y: hidden;
`;
export const ProjectDetailSection = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 5px;
  font-size: 16px;
  max-height: calc(-275px + 100vh);
  &:not(.projectFeed) {
    min-height: calc(-275px + 100vh);
  }
  font-size: 16px;
  overflow: hidden auto;
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${companyColor};
    border: 3px solid #fff;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    height: calc(100% - 81px);
    min-height: auto;
    max-height: calc(100% - 81px);
  }
`;

// ProjectCreate
export const ProjectCreateContainer = styled.div`
  background: rgba(83, 59, 112, 0.5);
  position: relative;
  overflow: hidden;
  &::after,
  &::before {
    content: "";
    position: absolute;
    display: block;
  }
  &::after {
    background: transparent;
    width: 60vw;
    height: 70vh;
    top: 0px;
    left: 0px;
    transform: translate(0%, -5%) skew(-20deg);
    border-radius: 50% 20% 30% 40%;
    border: 50px solid rgb(239 239 239 / 50%);
  }
  &::before {
    background: rgba(0, 116, 202, 0.8);
    width: 40vw;
    height: 30vw;
    bottom: 0px;
    right: 0px;
    transform: translate(10%, 45%) skew(-20deg);
    border-radius: 20% 40% 50% 30%;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    max-width: ${maxWidth};
    margin: auto;
    .info,
    .selectButtons {
      font-size: 20px;
    }
    .info {
      z-index: 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 20px;
      span {
        font-size: 35px;
        z-index: 1;
      }
      p {
        color: #555;
      }
    }
    .selectButtons {
      background: #fff;
      padding: 20px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      z-index: 2;
      button {
        border: none;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 150px 20px 20px;
        gap: 20px;
        font-weight: 600;
        border-radius: 25px;
        .icon {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .inner {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;
      .info {
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .inner .selectButtons button {
      padding: 15px 70px 15px 15px;
      font-size: 18px;
    }
  }
`;

// ProjectCreateSec
export const ProjectCreateSecContainer = styled.div`
  .inner {
    position: relative;
    max-width: ${maxWidth};
    margin: auto;
    display: flex;
    justify-content: center;
    height: 100vh;
    flex-direction: column;
    > h2 {
      font-size: 25px;
      margin-bottom: 25px;
      span {
        color: ${companyColor};
      }
    }
    .createProjectContent {
      display: flex;
      gap: 20px;
      width: 100%;
      > div {
        flex: 1;
        &.slideWrapper {
          flex-flow: row nowrap;
          display: flex;
          flex: 1;
          width: 200%;
          transition: transform 0.5s ease;
          overflow: hidden;
          .slideContent {
            flex: 0 0 50%;
            transform: translateX(0);
            flex-basis: 100%;
            flex-shrink: 0;
            transition: 0.3s;
            opacity: 0;
            width: 100%;
          }
        }
      }
    }
    .projectInputField {
      font-size: 16px;
      li {
        margin-bottom: 10px;
        &.projectName {
          display: flex;
          button {
            height: 41px;
            font-size: 16px;
            line-height: 44px;
            width: 110px;
            line-height: 54px;
            font-size: 18px;
            font-weight: 300;
            color: #fff;
            background: #333333;
            border: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
        &.selectButtons {
          div {
            display: flex;
            border: 1px solid #efefef;
            margin-top: 5px;
            span {
              font-size: 14px;
            }
            button {
              width: 50%;
              background: transparent;
              border: none;
              padding: 10px;
              text-align: center;
              &.active {
                color: #fff;
              }
              &:first-child.active {
                background: ${companyColor};
              }
              &:last-child.active {
                background: ${groupColor};
              }
            }
          }
        }
      }
      input[type="text"],
      textarea,
      select {
        border: 1px solid #efefef;
        padding: 10px 15px;
        width: 100%;
        resize: none;
      }
      select:focus {
        outline: none;
      }
      textarea {
      }
      .descAddButton {
        border: none;
        background: none;
        display: block;
        border-bottom: 1px solid #000;
        font-size: 14px;
      }
    }
    .moveAndSaveButtons {
      margin-top: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      button {
        border: none;
        padding: 15px 40px;
        font-size: 16px;
        background: #533b70;
        color: #fff;
        border-radius: 7px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .inner {
      padding: 15px;
      height: 100%;
      > h2 {
        margin-bottom: 10px;
      }
    }
  }
`;

// ProjectCreateSec Preview
export const ProjectCreateSecPreviewContainer = styled.div`
  border: 1px solid #efefef;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  font-size: 20px;
  background: #efefef;
  .active {
    border: 3px solid #533b70;
  }
  > div {
    padding: 15px;
  }
  .projectInfoBody {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    background: #efefef;
    height: calc(100% - 83px);
    overflow: hidden;
    .con {
      max-height: 250px;
      min-height: 250px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 9px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: ${companyColor};
        border: 3px solid #ffffff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      width: calc(100% - 120px);
      gap: 15px;
      height: 100%;
      .projectFeed {
        background: #fff;
        border-radius: ${contentBorderRadius};
        font-size: 16px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        height: calc(100% - 76px);
        .projectFeedHeader {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #efefef;
          padding: 10px;
          .writerInfo {
            display: flex;
            font-size: 13px;
            align-items: center;
            gap: 10px;
            img {
              width: 40px;
              height: 40px;
            }
            div span {
              font-weight: 700;
            }
          }
          .projectFeedHeaderButton {
            display: flex;
            align-items: center;
            gap: 5px;
            position: relative;
            button {
              border: none;
              background: transparent;
              display: flex;
            }
          }
        }
        .projectFeedBody {
          padding: 10px;
          .tit {
            font-weight: 600;
            margin-bottom: 5px;
            width: 100%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
          .con {
            font-size: 14px;
            img {
              display: block;
              max-width: 80%;
            }
          }
        }
      }
    }
    .users {
      font-size: 12px;
      background: #fff;
      padding: 15px;
      border-radius: ${contentBorderRadius};
      height: 200px;
      li {
        gap: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .userProfileImage {
          width: 28px;
          height: 28px;
          background: #ddd;
          border-radius: ${contentBorderRadius};
        }
        .userInfo {
          span {
            font-weight: 700;
            font-size: 14px;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

// ProjectList
export const ProjectListContainer = styled.div`
  position: relative;
  height: 100vh;
  margin: auto;
  .inner {
    display: flex;
  }
`;

// ProjectSideMenu
export const ProjectSideMenuContainer = styled.div`
  .sideMenuContent {
    width: 240px;
    min-height: ${pageHeight};
    border-right: 1px solid #efefef;
    font-size: 15px;
    .createProjectButton {
      border: none;
      background: #efefef;
      width: 100%;
      padding: 10px 0;
      border-radius: 5px;
    }
    .projectSection {
      padding: 20px;
      position: relative;
      border-bottom: 1px solid #efefef;
    }
    ul.menus {
      position: relative;
      border-bottom: 1px solid #efefef;
      padding: 15px 0;
      li {
        padding: 5px 20px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        transition: 0.2s;
        &:hover {
          background: #0074ca33;
        }
      }
    }
  }

  @media screen and (max-width: 1050px) {
    position: fixed;
    z-index: 3;
    width: 100vw;
    transition: 0.3s;
    transform: translateX(-100%);
    top: 0;
    left: 0;
    display: flex;
    .sideMenuContent {
      height: 100vh;
      background: #fff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    }
    .bg {
      width: calc(100% - 240px);
      background: transparent;
    }
  }
`;

// ProjectListView
export const ProjectListViewContainer = styled.div`
  width: calc(100vw - 240px);
  min-height: ${pageHeight};
  max-height: ${pageHeight};
  overflow-y: auto;
  font-size: 15px;
  padding: 20px;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${companyColor};
    border: 3px solid #ffffff;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  .contentInfo {
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 40px;
    .projectListHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .info {
      display: flex;
      gap: 20px;
      div {
        font-size: 14px;
        display: flex;
        font-weight: 400;
      }
    }
    .search {
      display: flex;
      input[type="text"] {
        border: 1px solid #efefef;
        padding: 6px;
        font-size: 15px;
      }
      button {
        background: #0074ca;
        color: #efefef;
        border: none;
        font-size: 16px;
        padding: 0 20px;
      }
    }
  }
  .contentsLayer {
    max-width: 1200px;
  }
  .projectContent {
    width: 100%;
    margin-bottom: 20px;
    .projectContentInfo {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
      margin: 10px 0;
      .label {
        display: flex;
        gap: 10px;
        align-items: center;
        span {
          font-weight: 700;
        }
        select {
          padding: 0px 20px 0px 5px;
          font-size: 14px;
          height: 23px;
          border: 1px solid #d5d5d5;
          border-radius: 5px;
          &:focus {
            outline: none;
            border: 1px solid #d5d5d5 !important;
            box-shadow: none;
          }
        }
      }
      button {
        border: none;
        display: flex;
        padding: 5px;
      }
    }
    ul {
      display: flex;
      gap: 20px;
      width: 100%;
      flex-wrap: wrap;
      overflow: hidden;
      transition: 0.3s;
      li {
        padding: 10px;
        outline: 1px solid #efefef;
        border-radius: 5px;
        width: calc((100% - 90px) / 5);
        position: relative;
        cursor: pointer;
        margin: 1px;
        .favoritesButton {
          position: absolute;
          top: 10px;
          right: 10px;
          border: none;
          background: transparent;
          font-size: 18px;
        }
        p {
          width: 100%;
          margin: 30px 0 15px;
          font-size: 15px;
          position: relative;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          height: 38px;
        }
        .projectInfoFooter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .userLength {
            display: flex;
            align-items: center;
            gap: 5px;
            svg {
              font-size: 20px;
            }
            display: flex;
            align-items: center;
          }
          .unReadMsgCnt {
            background: rgb(229, 90, 64);
            color: rgb(255, 255, 255);
            font-size: 10px;
            width: 23px;
            height: 23px;
            align-items: center;
            justify-content: center;
            display: flex;
            border-radius: 50%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .projectContent ul li {
      width: calc((100% - 68px) / 4);
    }
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
    .contentInfo {
      display: flex;
      justify-content: space-between;
      .projectListHeader {
        flex-direction: column;
        align-items: baseline;
        gap: 10px;
        position: relative;
      }
      .sideMenuButtons {
        display: flex;
        gap: 10px;
        align-items: center;
        button {
          display: flex;
          border: none;
          padding: 5px;
          background: #0074ca;
          color: #fff;
        }
      }
    }
    .projectContent ul li {
      width: calc((100% - 46px) / 3);
    }
  }
  @media screen and (max-width: 767px) {
    padding: 10px;
    min-height: 100vh;
    max-height: 100vh;
    .contentInfo {
      align-items: flex-start;
      .info {
        font-size: 18px;
        align-items: center;
        flex-direction: column;
        align-items: baseline;
        gap: 0;
        margin-bottom: 20px;
      }
    }
    .projectContent ul li {
      width: calc(50% - 12px);
    }
  }
`;

// ProjectDetailPage
export const ProjectDetailContainer = styled.div`
  position: relative;
  height: 100vh;
  margin: auto;
  .inner {
    display: flex;
    height: calc(100vh - 73px);
    overflow: hidden;
  }
  @media screen and (max-width: 767px) {
    .inner {
      height: 100vh;
    }
  }
`;

// ProjectDetailView
export const ProjectDetailViewContainer = styled.div`
  width: calc(100vw - 240px);
  min-height: ${pageHeight};
  /* overflow-y: auto; */
  overflow: hidden;
  font-size: 15px;
  > div {
    padding: 20px;
  }
  .projectInfoBody {
    display: flex;
    gap: 15px;
    background: #efefef;
    height: calc(100% - 81px);
    .content {
      display: flex;
      flex-direction: column;
      max-width: 1200px;
      width: calc(100% - 215px);
      gap: 15px;
      .projectFeed {
        .slideWrapper {
          flex-flow: row nowrap;
          display: flex;
          flex: 1;
          width: 100%;
          transition: transform 0.5s ease;
          overflow: hidden;
          .slideContent {
            flex: 0 0 50%;
            transform: translateX(0);
            flex-basis: 100%;
            flex-shrink: 0;
            transition: 0.3s;
            height: calc(-275px + 100vh);
            width: 100%;
            overflow-x: hidden;
            &::-webkit-scrollbar {
              width: 8px;
            }
            &::-webkit-scrollbar-thumb {
              background-color: ${companyColor};
              border: 2px solid #fff;
            }
            &::-webkit-scrollbar-track {
              background-color: transparent;
            }
          }
        }
        .projectFeedHeader {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #efefef;
          padding: 15px;
          height: 76px;
          align-items: center;
          .writerInfo {
            display: flex;
            font-size: 13px;
            align-items: center;
            gap: 10px;
            div span {
              font-weight: 700;
            }
          }
          .projectInfo {
            background: #efefef;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 13px;
          }
          .projectFeedHeaderButton {
            display: flex;
            align-items: center;
            gap: 5px;
            position: relative;
            button {
              border: none;
              background: transparent;
              display: flex;
            }
          }
        }
        .projectFeedToggle {
          display: flex;
          justify-content: end;
          margin: 15px;
          button {
            font-size: 20px;
            display: flex;
            background: transparent;
            border: none;
          }
        }
        .projectFeedBody {
          transition: 0.3s;
          overflow: hidden;
          .tit {
            font-weight: 600;
            margin-bottom: 5px;
          }
          .con {
            font-size: 14px;
            min-height: 200px;
            figure {
              margin: 10px 0;
              width: 100%;
              text-align: center;
              &.image-style-side {
                text-align: right;
              }
            }
            img {
              max-width: 80%;
            }
          }
          .files {
            width: 100%;
            margin-top: 10px;
            ul {
              background: rgb(239, 239, 239);
              padding: 3px;
              li {
                font-size: 13px;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 10px;
                margin-bottom: 3px;
                background: rgb(255, 255, 255);
                padding: 3px;
                position: relative;
                &:last-child {
                  margin-bottom: 0;
                }
                .icon {
                  display: flex;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    width: 100%;
    height: 100vh;
    .projectInfoBody {
      width: 100%;
      .content {
        width: calc(100% - 215px);
        .projectFeed .slideWrapper {
          height: 100%;
          width: 100%;
          .slideContent {
            height: 100%;
            width: 100%;
            flex-basis: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    > div {
      padding: 10px;
    }
    .projectInfoBody {
      height: calc(100% - 51px);
      .content {
        width: 100%;
        .projectFeed .slideWrapper {
          height: 100%;
          width: 100%;
          .slideContent {
            height: 100%;
            width: 100%;
            flex-basis: 100%;
          }
        }
      }
    }
  }
`;

// ProjectDetailView Nav
export const ContentNavigation = styled.ul`
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  width: 100%;
  background: #fff;
  padding: 10px 0;
  border-radius: ${contentBorderRadius};
  gap: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  font-size: 25px;
  li {
    display: flex;
    align-items: center;
    gap: 3px;
    flex-direction: column;
    cursor: pointer;
    span {
      font-size: 14px;
    }
  }
`;

// ProjectDetailInfo
export const ProjectDetailHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  gap: 10px;
  background: #fff;
  .projectHeaderGroup {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 22px;
    .sideMenuButton,
    .gbn {
      width: 40px;
      height: 40px;
      border-radius: 5px;
      display: flex;
      background: #ccc;
      align-items: center;
      justify-content: center;
      color: rgb(255, 255, 255);
      font-size: 25px;
    }
  }
  .projectTitle {
    font-size: 18px;
    width: calc(100% - 80px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    h2,
    p {
      width: 100%;
    }
    p {
      font-size: 12px;
      min-height: 20px;
      display: inline-block;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .participantButton {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    background: #999;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 25px;
  }
  .feedMenu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    ul {
      position: absolute;
      z-index: 1;
      background: #fff;
      font-size: 13px;
      width: 50px;
      transform: translate(-50%, 20px);
      li {
        text-align: center;
        border: 1px solid #efefef;
        button {
          width: 100%;
          padding: 3px 10px;
          justify-content: center;
        }
        &:last-child {
          border-top: none;
        }
      }
    }
  }
  button {
    border: none;
    background: transparent;
    display: flex;
  }
  @media screen and (max-width: 767px) {
    .projectHeaderGroup {
      .gbn,
      .sideMenuButton {
        width: 30px;
        height: 30px;
        font-size: 25px;
      }
    }
    .projectTitle {
      width: calc(100% - 177px);
      font-size: 14px;
    }
  }
`;

// ProjectDetailComments
export const ProjectDetailCommentContainer = styled.div`
  height: 100%;
  .roomInput {
    display: none;
  }
  .list {
    padding: 15px;
    height: calc(100vh - 351px);
    overflow-y: auto;
    width: 100%;
    display: block;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${companyColor};
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${companyColor};
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  .field {
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    background: #fff;
    textarea {
      width: calc(100% - 35px);
      border: none !important;
      outline: none !important;
      resize: none;
      &:focus {
        border: none !important;
        outline: none !important;
        box-shadow: none;
      }
    }
    label {
      position: absolute;
      top: 50%;
      right: 10px;
      background: #efefef;
      padding: 5px;
      transform: translateY(-50%);
      cursor: pointer;
      display: flex;
    }
    input[type="file"] {
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
  @media screen and (max-width: 767px) {
    .list {
      height: calc(100% - 76px);
    }
  }
`;

// ProjectDetailParticipants
export const ProjectDetailAttendees = styled.div`
  font-size: 12px;
  p {
    font-size: 15px;
    margin-bottom: 10px;
    padding: 15px 15px 0px;
  }
  .list {
    background: #fff;
    border-radius: ${contentBorderRadius};
    height: 400px;
    width: 200px;
    position: relative;
    > ul {
      height: calc(100% - 120px);
      overflow-y: auto;
      margin-bottom: 10px;
      &::-webkit-scrollbar {
        width: 9px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 3px solid #ffffff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      > li {
        gap: 10px;
        display: flex;
        flex-direction: column;
        padding: 5px;
        margin: 10px;
        border-radius: 5px;
        ul li {
          display: flex;
          gap: 10px;
          align-items: center;
          margin-bottom: 5px;
          margin-left: 10px;
        }
        .userProfileImage {
          width: 35px;
          height: 35px;
          background: #a7a7a7;
          border-radius: ${contentBorderRadius};
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .userInfo {
          span {
            font-weight: 700;
            font-size: 14px;
          }
        }
      }
    }
    .invite {
      padding: 15px;
    }
    button {
      width: 100%;
      border: none;
      background: #0074ca;
      color: #fff;
      padding: 10px;
      border-radius: 5px;
    }
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    right: 0;
    height: 100vh;
    top: 0;
    z-index: 100;
    transform: translateX(100%);
    display: flex;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    .list {
      height: 100%;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .bg {
      width: calc(100% - 200px);
    }
  }
`;

export const ProjectEditorContainer = styled.div`
  width: 100%;
  input[type="text"] {
    border: 1px solid #ccced1;
    padding: 10px 15px;
    width: 100%;
    resize: none;
    margin-bottom: 10px;
  }
  .ck-content {
    max-height: 400px;
    min-height: 400px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 9px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${companyColor};
      border: 3px solid #ffffff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }
  .fileImport {
    margin: 10px 0;
    display: flex;
    gap: 10px;
    label {
      height: 41px;
      width: 110px;
      line-height: 54px;
      font-size: 18px;
      font-weight: 300;
      color: rgb(255, 255, 255);
      background: rgb(51, 51, 51);
      border: 0px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
    }
    input[type="file"] {
      display: none;
    }
    ul {
      width: calc(100% - 120px);
      max-height: 100px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      li {
        width: 100%;
        font-size: 14px;
        background: #efefef;
        padding: 5px;
        border-radius: 5px;
        position: relative;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          max-width: calc(100% - 20px);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        button {
          display: flex;
          font-size: 20px;
          border: none;
          background: transparent;
        }
      }
    }
  }
`;

export const ProjectDetailFiles = styled.div`
  padding: 15px;
  flex-direction: column;
  display: flex;
  gap: 10px;
  .filesHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .filePath {
      display: flex;
      gap: 5px;
      align-items: center;
      span {
        &.icon {
          display: flex;
        }
        &.length {
          color: #999;
          font-size: 14px;
        }
      }
    }
    .fileButtons {
      display: flex;
      align-items: center;
      gap: 15px;
      button {
        border: none;
        padding: 3px 10px;
        font-size: 14px;
        background: #0074ca;
        color: #fff;
        border-radius: 5px;
        width: max-content;
      }
    }
  }
  .folders {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px;
    margin-bottom: 20px;
    li {
      border: 1px solid #efefef;
      width: 200px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s;
      position: relative;
      gap: 5px;
      &:hover,
      &.active {
        background: #0074ca;
        color: #fff;
      }
      i {
        font-size: 12px;
        font-style: normal;
      }
    }
  }
  .filesContainer {
    width: 100%;
    height: calc(100% - 63px);
    overflow-y: auto;
    .content {
      min-height: calc(100% - 10px);
    }
    input[type="checkbox"] {
      appearance: none;
      width: 20px;
      height: 20px;
      position: relative;
      margin-right: 5px;
      padding: 0;
      transition: 0.3s;
      background: #fff;
      border: 1px solid #ccc;
      &::after {
        content: "";
        background: #0074ca;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        transform: translate(-50%, -50%);
        transition: 0.3s;
      }
      &:checked {
        &::after {
          content: "";
          background: #0074ca;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 10px;
          height: 10px;
          transform: translate(-50%, -50%);
        }
      }
    }
    .dateGroup {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 15px;
      .dateHeader {
        display: flex;
        align-items: center;
        gap: 5px;
        span {
          color: #999;
          font-size: 13px;
        }
      }
      .fileGroup {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        .fileItem {
          width: calc((100% - 50px) / 6);
          height: 100px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          .preview {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            overflow: hidden;
            position: relative;
            .tit {
              position: absolute;
              width: 100%;
              bottom: 0;
              left: 0;
              overflow: hidden;
              background: rgba(0, 0, 0, 0.7);
              color: #fff;
              white-space: nowrap;
              overflow: hidden;
              font-size: 11px;
              padding: 2px;
              text-overflow: ellipsis;
              height: 18px;
              display: flex;
              align-items: center;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .selected {
            position: absolute;
            top: 5px;
            right: 5px;
            padding: 3px;
            background: #fff;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            border-radius: 50%;
            font-size: 13px;
            border: 1px solid #cccccc;
            opacity: 0;
            transition: 0.3s;
            i {
              font-style: normal;
            }
            &:has(i) {
              opacity: 1;
              background: #0074ca;
              color: #fff;
            }
          }
          &:hover span {
            opacity: 1;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .filesHeader {
      flex-direction: column;
      align-items: baseline;
      gap: 10px;
      margin-bottom: 10px;
    }
    .filesContainer .dateGroup .fileGroup .fileItem {
      width: calc((100% - 30px) / 4);
    }
  }
`;

export const DropdownMenuContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  .dropdownMenu {
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    button {
      padding: 3px 20px;
      border: none;
      background: #efefef;
      font-size: 12px;
      border: 1px solid #efefef;
      border-radius: 0.25em;
      margin: 0.5px;
    }
  }
`;

export const ProjectSchedulerContainer = styled.div`
  height: 100%;
  position: relative;
  .addScheduler {
    position: absolute;
    bottom: 15px;
    right: 15px;
    display: flex;
    background: rgb(0, 116, 202);
    color: rgb(255, 255, 255);
    font-size: 35px;
    padding: 5px;
    border: none;
    border-radius: 50%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    z-index: 3;
  }
  ul {
    padding: 15px;
    height: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 9px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${companyColor};
      border: 3px solid #ffffff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    li {
      background: #efefef;
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      span {
        display: block;
        font-size: 13px;
        &.tit {
          font-weight: 700;
          font-size: 15px;
        }
      }
    }
  }
`;

export const ParticipantInviteModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3;
  .modalContainer {
    width: 900px;
    height: 700px;
    position: absolute;
    top: 50%;
    left: 50%;
    background: #fff;
    transform: translate(-50%, -50%);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    border-radius: 10px;
    > div {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 30px;
      overflow-y: auto;
    }
    .close {
      position: absolute;
      top: 0px;
      right: 0px;
      display: flex;
      border: none;
      background: transparent;
      padding: 5px;
      font-size: 20px;
    }
    .tabMenu {
      display: flex;
      justify-content: end;
      margin: 10px 0;
      li {
        border: 1px solid #efefef;
        padding: 10px;
        width: 150px;
        text-align: center;
        cursor: pointer;
        &.active {
          border: 1px solid #0074ca;
          background: #0074ca;
          color: #fff;
        }
      }
    }
    .search {
      width: 80%;
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 0 auto 30px;
      input[type="text"] {
        border: 1px solid #efefef;
        padding: 10px;
        width: calc(100% - 110px);
      }
      button {
        border: none;
        background: #0074ca;
        color: #fff;
        padding: 10px 0;
        width: 100px;
      }
    }
    .search {
      margin: 0 auto 10px;
    }
    .searched {
      display: flex;
      gap: 10px 20px;
      flex-wrap: wrap;
      li {
        padding: 3px 10px;
        background: #efefef;
        border-radius: 10px;
        cursor: pointer;
      }
    }
    .section {
      height: calc(100% - 200px);
      margin: 10px 0px;
      table {
        width: 100%;
        border-collapse: collapse;
        &.grp {
          margin-top: 30px;
        }
        input[type="checkbox"] {
          appearance: none;
          width: 20px;
          height: 20px;
          border: 1px solid #ccc;
          margin-right: 5px;
          padding: 0;
          &:checked {
            border: 0.3em solid #fff;
            outline: 1px solid #ccc;
            background-color: #0074ca;
            box-shadow: none;
          }
        }
        tr th {
          &:first-child {
            width: 70px;
          }
          &:last-child {
            width: 130px;
          }
        }
        &.cmpny {
          tr th {
            &:nth-child(2) {
              width: calc(100% - 450px);
            }
            &:nth-child(3) {
              width: 150px;
            }
            &:nth-child(4) {
              width: 200px;
            }
          }
        }
        th,
        td {
          border: 1px solid #efefef;
          text-align: center;
          padding: 5px 20px;
        }
        tbody {
          tr {
            &:nth-child(even) {
              td {
                background: #efefef;
              }
            }
            td {
              button {
                border: none;
                width: 100%;
                height: 100%;
                background: #0074ca;
                color: #fff;
                padding: 5px 0;
                border-radius: 5px;
              }
            }
          }
        }
      }
    }
    .inviteArea {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 30px;
      > div {
        position: relative;
      }
      button {
        border-radius: 5px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        border: none;
        background: rgb(0, 116, 202);
        color: rgb(255, 255, 255);
        padding: 10px 0px;
        width: 100px;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .modalContainer {
      width: 100%;
      height: 80vh;
      .searched {
        gap: 7px 10px;
      }
      .section {
        table.cmpny {
          input[type="checkbox"] {
            width: 17px;
            height: 17px;
          }
          tr {
            th {
              padding: 5px;
            }
            th:first-child {
              padding: 5px;
            }
            th:nth-child(2) {
              width: 150px;
            }
            th:nth-child(3) {
              width: 70px;
            }
            th:nth-child(4) {
              width: 70px;
            }
            th:last-child {
              width: 100px;
            }
          }
          tbody tr td {
            padding: 0;
            &:last-child {
              padding: 5px;
            }
            button {
              min-width: 50px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .modalContainer {
      > div {
        padding: 15px;
        .tabMenu {
          li {
            width: 135px;
            padding: 5px;
          }
        }
        .section {
          height: calc(100% - 230px);
        }
        .inviteArea {
          padding: 15px;
          button {
            position: relative;
            transform: translateY(0);
          }
          ul.pagination {
            margin-top: 10px;
            li {
              height: 30px;
              a {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
`;

export const ProjectCalendarContainer = styled.div`
  > div {
    width: 100%;
    border: none;
    position: relative;
    > div {
      top: 20px;
      right: 20px;
      button {
        font-size: 13px;
      }
    }
    .fc {
      .fc-toolbar-title {
        font-size: 20px;
      }
      table tbody tr td .fc-daygrid-day-frame {
        min-height: 80px;
        height: calc(-68px + 16.6667vh);
      }
      table tbody tr td .fc-daygrid-day-top .fc-daygrid-day-number {
        font-size: 12px;
        justify-content: end;
      }
    }
  }
  @media screen and (max-width: 767px) {
    > div {
      .fc .fc-header-toolbar .fc-toolbar-chunk:nth-child(1) {
        margin: 0 0 0 20px;
      }
    }
  }
`;

export const ProjectDetailChatRoomContainer = styled.div`
  border-top: 1px solid rgb(239, 239, 239);
  border-bottom: 1px solid rgb(239, 239, 239);
  h3 {
    border-bottom: 1px solid #efefef;
    padding: 15px;
  }
  .list {
    li {
      padding: 15px;
      border-bottom: 1px solid #efefef;
      &:last-child {
        border-bottom: none;
      }
      cursor: pointer;
      .chatContent {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        > div:not(.imgs) {
          display: flex;
          flex-direction: column;
          gap: 5px;
          &.info {
            width: calc(100% - 160px);
            span {
              width: 100%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
          &.chatInfo {
            align-items: center;
            width: 100px;
            font-size: 14px;
            span {
              color: #979797;
            }
            i {
              background: rgb(229, 90, 64);
              color: rgb(255, 255, 255);
              border-radius: 21px;
              font-size: 10px;
              width: 23px;
              height: 23px;
              align-items: center;
              justify-content: center;
              display: flex;
              font-style: normal;
            }
          }
        }
        .tit {
          font-weight: 600;
        }
        .msg {
          font-size: 13px;
        }
        img.msg {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
      }
    }
  }
`;

export const CommentButtonsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  ul {
    position: absolute;
    background: #fff;
    border: 1px solid #efefef;
    li {
      background: #ffffff;
      width: 70px;
      font-size: 14px;
      padding: 2px 0;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #dbdbdb;
      display: flex;
      cursor: pointer;
      &:last-child {
        border-bottom: none;
      }
      button {
        border: none;
        background: transparent;
        width: 100%;
        height: 100%;
      }
    }
  }
`;

export const ProfileImages = styled.div`
  width: 45px;
  height: 45px;
  div {
    width: 100%;
    height: 100%;
    &.one {
      outline: 1px solid #efefef;
      border-radius: 50%;
      box-sizing: border-box;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    &.two {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        position: absolute;
        width: 65%;
        height: 65%;
        border-radius: 50%;
        outline: 1px solid #efefef;
        &:nth-child(1) {
          left: 0;
          top: 0;
        }
        &:nth-child(2) {
          right: 0;
          bottom: 0;
        }
      }
    }
    &.three {
      width: 100%;
      height: 100%;
      position: relative;
      img {
        position: absolute;
        width: 65%;
        height: 65%;
        border-radius: 50%;
        outline: 1px solid #efefef;
        &:nth-child(1) {
          left: 50%;
          transform: translateX(-50%);
          top: 0;
        }
        &:nth-child(2) {
          left: 0;
          bottom: 0;
        }
        &:nth-child(3) {
          right: 0;
          bottom: 0;
        }
      }
    }
    &.four {
      display: flex;
      flex-wrap: wrap;
      img {
        width: 50%;
        height: 50%;
        border-radius: 50%;
        outline: 1px solid #efefef;
      }
    }
  }
`;

export const ProjectCommentItem = styled.li`
  max-width: 100%;
  min-width: 150px;
  /* 공통 */
  .profileImage {
    user-select: none;
    pointer-events: none;
  }
  .commentContainer {
    width: 100%;
    display: flex;
    position: relative;
    padding: 0 10px;
    margin-bottom: 10px;
  }
  .date {
    font-size: 12px;
    margin: 3px 0;
  }
  .commentContainer {
    gap: 10px;
  }
  .commentInfo {
    width: calc(100% - 40px);
    .topBar {
      display: flex;
      width: 100%;
      gap: 10px;
      align-items: center;
      margin-bottom: 3px;
    }
    .commentContent {
      padding: 10px;
      .origin {
        font-size: 13px;
        padding-bottom: 5px;
        margin-bottom: 5px;
        span {
          font-weight: 700;
        }
      }
      .comment {
        font-size: 14px;
        > div {
          white-space: pre-wrap;
          word-wrap: break-word;
        }
      }
    }
  }
  /* 작성자 X */
  &.theirs {
    margin-right: auto;
    .commentContainer {
      gap: 10px;
    }
    .profileImage {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .commentInfo {
      width: calc(100% - 40px);
      .topBar {
        display: flex;
        width: 100%;
        gap: 10px;
        justify-content: space-between;
        .writer {
          font-size: 13px;
        }
      }
      .commentContent {
        background: #efefef;
        border-radius: 10px 10px 10px 0;
        padding: 10px;
        .origin {
          border-bottom: 1px solid #999;
          color: #000;
          span {
            font-weight: 700;
          }
          .reply {
            color: #999;
            white-space: pre-wrap;
          }
        }
        .comment {
          font-size: 14px;
        }
      }
    }
  }
  /* 작성자 */
  &.mine {
    margin-left: auto;
    .commentContainer {
      gap: 10px;
    }
    .profileImage {
      display: none;
    }
    .commentInfo {
      width: 100%;
      .topBar {
        display: none;
      }
      .commentContent {
        background: #0074ca;
        color: #efefef;
        border-radius: 10px 10px 0px 10px;
        padding: 10px;
        .origin {
          border-bottom: 1px solid #dfdddd;
          color: #dfdddd;
          span {
            font-weight: 700;
          }
          .reply {
            white-space: pre-wrap;
          }
        }
        .comment {
          font-size: 14px;
        }
      }
    }
    .date {
      text-align: right;
    }
  }
`;

export const ProjectCommentFile = styled.div`
  .mySwiper {
    margin-top: 10px;
    .image {
      position: relative;
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
      }
      .deleteBox {
        position: absolute;
        top: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        color: #fff;
        display: flex;
      }
    }
  }
  .fileList {
    margin-top: 10px;
    ul {
      background: #efefef;
      padding: 3px;
      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 3px;
        background: #fff;
        padding: 3px;
        position: relative;
        .icon {
          display: flex;
        }
        .tit {
          font-size: 13px;
        }
        .delete {
          border: none;
          background: transparent;
          position: absolute;
          top: 50%;
          right: 5px;
          transform: translateY(-50%);
          display: flex;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

export const ProjectNewComment = styled.li`
  background: #efefef;
  border-radius: 10px;
  margin-top: auto;
  width: 100%;
  .replyOrigin {
    padding: 10px;
    border-bottom: 1px solid #fff;
    position: relative;
    font-size: 13px;
    width: 100%;
    .info span {
      font-weight: 700;
    }
    .originContent {
      width: 100%;
    }
    .replyContent {
      color: #999;
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    button {
      display: flex;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      background: transparent;
      border: none;
      font-size: 24px;
    }
  }
  .commentContainer {
    padding: 10px;
    width: 100%;
  }
  .commentContent {
    display: flex;
    background: #fff;
    justify-content: space-between;
    gap: 10px;
    padding: 5px;
    align-items: flex-start;
    input {
      display: none;
    }
    label,
    .submit {
      padding: 8px;
      border-radius: 5px;
      display: flex;
    }
    textarea,
    > div {
      width: calc(100% - 84px);
      outline: none;
      padding: 6px 0;
      white-space: pre-wrap;
      border: none !important;
      resize: none;
      overflow: hidden;
      &:focus {
        box-shadow: none;
        border: none;
        outline: none;
      }
    }
    label {
      background: #efefef;
    }
    .submit {
      background: #0074ca;
      color: #fff;
      border: none;
    }
  }
`;

export const ProjectFileBoxViewContainer = styled.div`
  width: 100%;
  height: calc(100vh - 73px);
  font-size: 15px;
  padding: 20px;
  > div {
    height: 100%;
    padding: 0;
    width: 100%;
    .folders {
      flex-wrap: wrap;
      li {
        text-align: center;
        &.P {
          background: rgba(19, 152, 89, 0.8);
          color: #fff;
        }
        &.C {
          background: rgba(0, 116, 202, 0.8);
          color: #fff;
        }
        &.G {
          background: rgba(244, 111, 2, 0.8);
          color: #fff;
        }
      }
    }
    .fileInfo {
      width: 100%;
      .filesTop {
        display: flex;
        justify-content: space-between;
        align-content: center;
        button {
          border: none;
          display: flex;
          padding: 5px;
          font-size: 24px;
          background: #0074ca;
          color: #fff;
        }
      }
    }
    .filesContainer {
      &::-webkit-scrollbar {
        width: 9px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 3px solid #ffffff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
    .filesContainer .dateGroup .fileGroup .fileItem {
      width: calc((100% - 90px) / 10);
    }
  }
  @media screen and (max-width: 1050px) {
    > div .folders li {
      width: calc((100% - 45px) / 4);
    }
  }
  @media screen and (max-width: 767px) {
    height: 100%;
    > div {
      .fileInfo .filesTop {
        align-items: flex-end;
        margin-bottom: 10px;
      }
      .folders {
        gap: 10px;
        flex-wrap: wrap;
        li {
          height: 70px;
          width: calc((100% - 20px) / 3);
          padding: 5px;
          font-size: 13px;
        }
      }
      .filesHeader .filePath {
        flex-wrap: wrap;
        gap: 5px 2px;
        h3 {
          width: 100%;
        }
        span {
          font-size: 13px;
        }
      }
      .filesContainer .dateGroup .fileGroup .fileItem {
        width: calc((100% - 30px) / 4);
      }
    }
  }
`;

export const ProjectHeaderContainer = styled.header`
  &#schduler_header {
    padding: 10px 20px;
    box-sizing: border-box;
    z-index: 2;
    .float_left {
      img {
        max-width: 40px;
        margin-right: 15px;
        border-radius: 5px;
      }
    }
    .float_right {
      gap: 10px;
      .schduler_header_menu li {
        margin: 0;
        font-size: 16px;
      }
      .backToList {
        font-size: 14px;
      }
      > button,
      .navBtn,
      .notify {
        font-size: 34px;
        padding: 0;
        background: transparent;
        display: flex;
        border: none;
        margin-left: 15px;
        color: #efefef;
      }
      .menuNav {
        position: relative;
        .bg {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vh;
          ul {
            position: absolute;
            top: 60px;
            right: 15px;
            background: #fff;
            display: flex;
            align-items: center;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            border-radius: 0.25em;
            width: 280px;
            flex-wrap: wrap;
            li {
              padding: 5px 0;
              display: flex;
              align-items: center;
              flex-direction: column;
              width: 70px;
              color: #111111;
              button {
                border: none;
                background: transparent;
              }
              .icon {
                font-size: 20px;
                border-radius: 50%;
                background: #efefef;
                margin: 5px auto;
                padding: 10px;
                box-sizing: border-box;
                width: 40px;
                svg {
                  display: block;
                }
              }
              p {
                width: 100%;
                font-size: 12px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    &#schduler_header .float_left {
      width: 30%;
    }
    &#schduler_header .float_right {
      width: 70%;
    }
  }
`;
