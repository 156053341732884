import React from "react";
import { LoadingContainer } from "./styled/common";
import { AiOutlineLoading } from "react-icons/ai";

const Loading = () => {
  return (
    <LoadingContainer>
      <span>
        <AiOutlineLoading />
      </span>
    </LoadingContainer>
  );
};

export default Loading;
