import { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import axios from "axios";

import DefaultTheme from "./component/defaultTheme";
import ChicTheme from "./component/chicTheme";
import ModernTheme from "./component/modernTheme";
import CuteTheme from "./component/cuteTheme";
import SimpleTheme from "./component/simpleTheme";
import SmTheme from "./component/smTheme";

export default function ShoppingPage() {
  const { cmpnyNo, theme } = useOutletContext();
  const [homepageInfo, setHomepageInfo] = useState();

  useEffect(() => {
    getMySetting();
  }, [cmpnyNo]);

  // 홈페이지 정보 조회
  const getMySetting = async () => {
    if (!cmpnyNo) return;

    const url = "/api/infoSettingLoad";
    const request = { cmpnyNo, delYn: "N" };

    const response = await axios.post(url, request);

    if (!response.data) {
      response.data = {};
      response.data.cmpnyNo = cmpnyNo;
    }

    const homepageInfo = response.data;

    setHomepageInfo(homepageInfo);
  };

  const themes = {
    sm: SmTheme,
    0: DefaultTheme,
    1: ChicTheme,
    2: ModernTheme,
    3: CuteTheme,
    4: SimpleTheme,
  };

  const renderComponent = () => {
    const Component = themes[theme.toString()] || DefaultTheme;
    return <Component homepageInfo={homepageInfo} />;
  };

  return <div>{renderComponent()}</div>;
}
