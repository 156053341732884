import styled from "styled-components";

export const DefaultCartContainer = styled.div`
  article.boundary {
    max-width: 1440px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  .products {
    font-size: 20px;
    label {
      display: flex;
      gap: 5px;
      align-items: center;
      cursor: pointer;
      input[type="checkbox"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        padding: 0;
        width: 0;
        height: 0;
      }
      .checkmark {
        position: relative;
        top: 0;
        left: 0;
        display: inline-block;
        height: 25px;
        width: 25px;
        border: 1px solid #000;
        /* border-radius: 10px; */
        background-color: transparent;
        transition: all 0.3s ease;
        &:after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg) scale(0);
          opacity: 0;
          width: 10px;
          height: 15px;
          border: solid #fff;
          border-width: 0px 3px 3px 0px;
          transition: 0.3s;
        }
      }
      input[type="checkbox"]:checked ~ .checkmark {
        background: #000;
        border: 1px solid #000;
        &:after {
          opacity: 1;
          transform: translate(-50%, -65%) rotate(45deg) scale(1);
          border-color: #fff;
        }
      }
      &:hover .checkmark {
        background-color: #efefef;
      }
    }
    li {
      width: 100%;
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
      &.header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          user-select: none;
          font-size: 16px;
          padding: 5px 20px;
          border: 1px solid #ddd;
          color: #000;
          background: transparent;
          border-radius: 10px;
        }
      }
      &:not(.header) {
        position: relative;
        display: flex;
        align-items: center;
        gap: 20px;
        .image {
          width: 120px;
          height: 120px;
          border-radius: 10px;
          overflow: hidden;
          user-select: none;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .productInfo {
          width: calc(100% - 220px);
          > span {
            display: block;
            margin-bottom: 5px;
          }
          .productName {
            font-size: 18px;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .brand {
            display: none;
          }
          .price {
            font-size: 22px;
            font-weight: 700;
          }
          .amount {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #ddd;
            width: 120px;
            border-radius: 10px;
            span {
              display: inline-block;
              width: 60px;
              text-align: center;
            }
            button {
              user-select: none;
              width: 40px;
              height: 30px;
              border: none;
              background: transparent;
            }
          }
        }
        > button {
          user-select: none;
          font-size: 14px;
          background: transparent;
          position: absolute;
          top: 30px;
          right: 0;
        }
      }
    }
  }
  .total {
    padding: 30px 0;
    .tb_footer {
      font-size: 20px;
    }
    dl {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 15px;
      dt {
        font-size: 15px;
      }
      dd {
        font-size: 18px;
      }
    }
    button {
      display: block;
      margin: 30px auto 0;
      padding: 15px 40px;
      color: #fff;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
      background: #03c75a;
      border-radius: 5px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 1050px) {
    .products {
      font-size: 14px;
      li:not(.header) {
        gap: 10px;
        button {
          top: 20px;
        }
        .image {
          width: 100px;
          height: 100px;
        }
        .productInfo {
          width: calc(100% - 180px);
          .price {
            font-size: 16px;
          }
        }
      }
      label .checkmark {
        width: 20px;
        height: 20px;
      }
      li {
        padding: 20px 0;
      }
    }
  }
  @media screen and (max-width: 767px) {
    article.boundary {
      margin-bottom: 100px;
    }
    .products {
      border: 1px solid #000;
      border-right: 1px solid #000;
      border-top: 1px solid #000;
      label {
        input[type="checkbox"]:checked ~ .checkmark::after {
          transform: translate(-50%, -53%) rotate(45deg) scale(1);
        }
        .checkmark {
          width: 15px;
          height: 15px;
          &::after {
            width: 7px;
            height: 12px;
          }
        }
      }
      li {
        padding: 10px;
      }
      li:not(.header) {
        align-items: flex-start;
        padding-top: 36px;
        label {
          position: absolute;
          top: 10px;
          left: 10px;
        }
        font-size: 12px;
        > button {
          font-size: 12px;
          top: 10px;
          right: 10px;
          border-bottom: none;
        }
        .productInfo {
          > span {
            margin-bottom: 3px;
          }
          .amount button {
            height: 22px;
          }
        }
      }
      li:not(.header) .image {
        width: 60px;
        height: 60px;
      }
      li:not(.header) .productInfo {
        width: calc(100% - 70px);
      }
      li:last-child {
        border-bottom: none;
      }
    }
  }
  #contCart #secTable {
    border-top: none;
  }
  .tb_footer {
    font-size: 14px;
    padding: 20px 0;
  }
`;

export const DefaultContaienr = styled.div`
  article.boundary {
    max-width: 1440px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    .company_address_h1,
    .company_content,
    .address_btn_wrapper,
    .row,
    .shop_port_menu,
    .shop_port_detail_content {
      width: 100%;
    }
    .subTop_bnr {
      display: none;
    }
    .address_btn_wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
  #contCompany #secMap .addr_copy {
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    article.boundary {
      margin-bottom: 100px;
      .totalSumm {
        width: 100%;
      }
    }
    #contNoticeList tr {
      padding: 10px 0;
    }
  }
`;

export const FaqContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 70px 20px;
  .search {
    display: flex;
    align-items: center;
    height: 38px;
    gap: 10px;
    font-size: 14px;
    margin-bottom: 25px;
    input,
    button {
      height: 100%;
    }
    input {
      width: 186px;
      padding: 0 20px;
      border: 1px solid #7a7a7a;
      &:focus {
        border: 1px solid #7a7a7a !important;
        box-shadow: none;
      }
    }
    button {
      width: 38px;
      font-size: 24px;
      background: #000;
      color: #fff;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
  .writing {
    margin-top: 70px;
    font-size: 14px;
    width: 100px;
    height: 38px;
    margin-left: auto;
    button {
      width: 100%;
      height: 100%;
      background: #000;
      color: #fff;
    }
  }
  ul {
    border-top: 1px solid #000;
    li.not {
      width: 100%;
      font-size: 24px;
      border-bottom: 1px solid rgb(152, 152, 152);
      padding: 30px 10px;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      gap: 30px 10px;
    }
  }
  .pagination {
    border-top: none;
    margin-top: 60px;
  }
  &.theme-1 {
    .search {
      font-size: 20px;
      height: 40px;
      input {
        width: 250px;
      }
      button {
        width: 40px;
      }
    }
    ul li {
      font-size: 20px;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #000;
        border: 3px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: #fff;
        border-radius: 10px;
      }
    }
    .writing {
      width: 200px;
      height: 42px;
      font-size: 20px;
    }
    .pagination {
      gap: 10px;
      li {
        border: 1px solid #000;
        border-radius: 0;
        &.active,
        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }
  }
  &.theme-2 {
    font-family: "Pretendard";
    .pagination {
      border-top: none;
      li {
        border: none;
        &.active,
        &:hover {
          background: rgba(192, 165, 165, 0.5);
          color: #000;
        }
        a[aria-label*="page number"] {
          font-family: "Prata";
        }
      }
    }
    .search {
      font-size: 16px;
      input {
        border-radius: 20px;
        border: 1px solid rgba(192, 165, 165, 0.3);
        &:focus {
          border: 1px solid rgba(192, 165, 165, 0.6) !important;
        }
      }
      button {
        background: rgba(192, 165, 165, 0.3);
        color: #000;
        border-radius: 10px;
      }
    }
    ul {
      border-top: 1px solid #ddd;
      li {
        font-size: 20px;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ddd;
          border-radius: 10px;
          border: 2px solid #fff;
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
          border-radius: 10px;
        }
        .actions button {
          background: rgba(192, 165, 165, 0.3);
          color: #000;
          padding: 3px 15px;
          border-radius: 15px;
          font-size: medium;
        }
      }
      li.content.open {
        border-bottom: 1px solid rgb(221, 221, 221);
        background: rgb(255, 249, 241);
        .answer {
          background: #fff;
        }
      }
      li.question,
      li.header,
      li.not {
        border-bottom: 1px solid #ddd;
      }
      li.response {
        opacity: 0;
        &.open {
          opacity: 1;
          border-bottom: 1px solid #ddd;
          font-size: 16px;
          background: rgb(255, 249, 241);
        }
      }
    }
    .writing {
      width: 200px;
      height: 42px;
      font-size: 20px;
      button {
        background: #ddd;
        color: #333;
        border-radius: 20px;
      }
    }
  }
  &.theme-3 {
    font-family: "Pretendard";
    .search {
      font-size: 16px;
      input {
        width: 220px;
      }
      button {
        background: rgb(255, 201, 111);
        color: #000;
      }
    }
    ul {
      border-top: 1px solid rgb(255, 201, 111);
      border-bottom: none;
      li {
        font-size: 16px;
        &::-webkit-scrollbar {
          width: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgb(255, 201, 111);
          border: 3px solid #fff;
        }
        &::-webkit-scrollbar-track {
          background-color: #fff;
          border-radius: 10px;
        }
      }
      li.content.open {
        border-bottom: 1px solid rgb(221, 221, 221);
        .answer {
          background: rgba(255, 201, 111, 0.1);
        }
      }
      li.header {
        font-size: 20px;
        border-bottom: 1px solid rgb(255, 201, 111);
        font-weight: 700;
      }
      li.question,
      li.not {
        border-bottom: 1px solid rgb(221, 221, 221);
      }
    }
    .writing {
      width: 200px;
      height: 40px;
      font-size: 16px;
      button {
        background: rgb(255, 201, 111);
        color: #000;
      }
    }
    ul.pagination {
      border-top: none;
      li {
        border: none;
      }
      li.active,
      li:hover {
        background: #ffc96f;
        a {
          color: #333;
        }
      }
    }
  }
  &.theme-4 {
    font-family: "Pretendard";
    .search {
      font-size: 20px;
      justify-content: flex-end;
      input {
        border-radius: 5px;
        width: 350px;
        &:focus {
          border: 1px solid rgba(192, 165, 165, 0.6) !important;
        }
      }
      button {
        border-radius: 5px;
      }
    }
    ul {
      li {
        font-size: 24px;
        &::-webkit-scrollbar-track {
          background-color: transparent;
          border-radius: 10px;
        }
        &::-webkit-scrollbar {
          width: 10px;
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          border: 2px solid #fff;
          background: rgb(0, 33, 83);
        }
      }
      li.question,
      li.header,
      li.not {
      }
      li.question {
        span.category {
          color: rgb(196, 196, 196);
          font-weight: 700;
        }
      }
      li.response {
        opacity: 0;
        &.open {
          opacity: 1;
          font-size: 16px;
          background: rgba(0, 33, 83, 0.1);
        }
      }
      li.content {
        background: rgba(0, 33, 83, 0.1);
        .answer {
          border-radius: 5px;
        }
      }
    }
    .writing {
      width: 200px;
      height: 42px;
      font-size: 20px;
      button {
        border-radius: 5px;
      }
    }
    ul.pagination {
      li {
        border: none;
        a {
          color: #bfbfbf;
        }
      }
      li.active,
      li:hover {
        background: transparent;
        a {
          color: #333;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    &.theme-3 ul li {
      font-size: 14px;
      &.header {
        font-size: 16px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 100px;
    li.header {
      font-size: 16px;
    }
    &.theme-1 {
      ul li {
        font-size: 14px;
      }
      span.category {
        color: #000;
        font-weight: 700;
      }
    }
    &.theme-2 {
      ul li {
        font-size: 16px;
        &.response.open {
          padding: 20px;
        }
      }
      div.status span {
        font-size: 14px;
        padding: 2px;
      }
      span.category {
        color: rgb(192, 165, 165);
        font-weight: 700;
      }
      .writing {
        font-size: 14px;
        font-weight: 700;
        width: 170px;
      }
    }
    &.theme-3 {
      .title {
        font-weight: 700;
      }
    }
    &.theme-4 {
      ul li {
        font-size: 14px;
        &.question {
          height: auto;
          padding: 30px 10px;
        }
        &.content {
          &.open {
            padding: 20px 10px;
            .question {
              padding-bottom: 20px;
            }
          }
        }
      }
      .writing {
        width: 150px;
        font-size: 14px;
      }
    }
  }
`;

export const HelpContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 70px 20px;
  font-size: 16px;
  > div {
    margin-bottom: 30px;
    .tit {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 5px;
    }
    input[type="text"] {
      border: 1px solid #000;
      width: 100%;
      padding: 15px;
      &:focus {
        border: 1px solid #000 !important;
        box-shadow: none;
      }
    }
    .ck.ck-toolbar {
      border-color: rgb(0, 0, 0);
    }
    .ck.ck-content {
      height: 400px;
    }
    .ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
    .ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
      border-color: rgb(0, 0, 0);
      padding: 0 20px;
    }
  }
  .buttons {
    margin-top: 70px;
    margin-bottom: 0;
    text-align: center;
    button {
      width: 130px;
      font-size: 16px;
      height: 40px;
      &:first-child {
        margin-right: 30px;
        background: #000;
        color: #fff;
      }
      &:last-child {
        border: 1px solid #000;
        background: transparent;
      }
    }
  }
`;

export const FaqItemContainer = styled.li`
  font-size: 16px;
  transition: 0.3s;
  height: 70px;
  user-select: none;
  text-align: center;
  &.header {
    display: flex;
    align-items: center;
    font-size: 20px;
    border-bottom: 1px solid #000;
    font-weight: 700;
  }
  &.question {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #000;
  }
  &.response {
    overflow: hidden;
    height: auto;
    max-height: 0;
    text-align: left;
    padding: 0;
    cursor: default;
    border: none;
    &.open {
      max-height: 30vh;
      border-bottom: 1px solid #000;
      padding: 20px;
      overflow-y: auto;
    }
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.index {
      width: 5%;
    }
    &.category {
      width: 20%;
    }
    &.title {
      width: 70%;
    }
    &.toggle {
      width: 5%;
    }
  }
  @media screen and (max-width: 1050px) {
    span.toggle {
      display: none;
    }
    span.title {
      width: 75%;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
    height: 40px;
    &.header {
      display: none;
    }
    &.response.open {
      padding: 20px;
    }
    span.index,
    span.toggle {
      display: none;
    }
    span.title {
      width: 80%;
    }
    span.category {
      text-align: left;
      color: #0074ca;
    }
  }
`;

export const HelpItemContainer = styled.li`
  font-size: 16px;
  transition: 0.3s;
  height: 70px;
  user-select: none;
  &.header {
    font-size: 20px;
    font-weight: 700;
  }
  &.header,
  &.question,
  &.not {
    display: flex;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #000;
  }
  &.question {
    cursor: pointer;
  }
  &.content {
    overflow: hidden;
    height: auto;
    max-height: 0;
    text-align: left;
    padding: 0 20px;
    cursor: default;
    &.open {
      max-height: 30vh;
      border-bottom: 1px solid #000;
      padding: 20px;
      overflow-y: auto;
    }
    img {
      max-width: 70%;
    }
    .question {
      padding-bottom: 20px;
    }
    .answer {
      padding: 20px;
      background: rgba(0, 0, 0, 0.1);
      white-space: pre-line;
    }
  }
  .actions {
    text-align: right;
    button {
      border: none;
      background: #000;
      color: #fff;
      padding: 2px 10px;
      &:first-child {
        margin-right: 10px;
      }
    }
  }
  span.index {
    width: 5%;
  }
  span.status,
  div.status {
    width: 20%;
  }
  div.status span {
    padding: 2px 10px;
    border-radius: 5px;
    &.pending {
      color: #f1541d;
      background: #fef6f4;
    }
    &.completed {
      color: #429b8c;
      background: #e7f5f2;
    }
  }
  span.title {
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span.date {
    width: 15%;
  }
  span.toggle {
    width: 10%;
    background: transparent;
  }
  @media screen and (max-width: 1050px) {
    span.toggle {
      display: none;
    }
    span.date {
      width: 25%;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
    height: auto;
    padding: 10px 0;
    gap: 5px 10px;
    &.header {
      display: none;
    }
    &.question {
      flex-wrap: wrap;
    }
    &.content.open {
      padding: 10px;
      .question {
        padding-bottom: 10px;
      }
      .answer {
        padding: 10px;
      }
    }
    div.status {
      text-align: right;
    }
    span {
      text-align: left;
      &.index {
        width: 10%;
      }
      &.title {
        width: calc(70% - 20px);
      }
      &.date {
        width: 100%;
        color: #999;
      }
      &.toggle {
        display: none;
      }
    }
  }
`;

export const DefaultContainer = styled.main`
  #contProductsList article.boundary {
    max-width: 1440px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const SecionSwiperContainer = styled.div`
  &#sec0 {
    position: relative;
    display: flex;
    width: 100%;
    height: 400px;
    .mySwiper {
    }
    .not {
      background: url("/img/shop_main_bg3.jpg") no-repeat center center / cover;
      width: 100%;
    }
  }
`;
