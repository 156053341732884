import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useRegex } from "../../../../hooks/useRegex";
import { HomepageFormContainer } from "./styled/homepage";
import { useMediaQuery } from "react-responsive";

export default function HomepageForm() {
  const navigate = useNavigate();

  const { BussinessFormat } = useRegex();
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const setting = useSelector((state) => state?.user?.setting);
  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  const [companys, setCompanys] = useState([]);

  useEffect(() => {
    checkedDomain();
  }, []);

  const checkedDomain = async () => {
    const url = "/api/domainSettingLoad";

    const promises = company.map((item) => {
      const body = { cmpnyNo: item.cmpnyNo, delYn: "N" };
      return axios.post(url, body);
    });

    try {
      const res = await Promise.all(promises);

      const updatedData = company.map((item) => {
        const response = res.find((r) => r.data.cmpnyNo === item.cmpnyNo);
        return {
          ...item,
          domain: response?.data?.domain,
        };
      });

      updatedData.sort((a, b) => {
        const aHasDomain = a.domain;
        const bHasDomain = b.domain;

        if (aHasDomain && !bHasDomain) return -1;
        if (!aHasDomain && bHasDomain) return 1;
        return 0;
      });

      setCompanys(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const openNewTab = (item) => {
    window.open(`https://1472.ai/shop/${item}`);
    user && localStorage.setItem("user", JSON.stringify(user));
  };

  const navSettingPage = (item) => {
    sessionStorage.setItem("homepageCompany", JSON.stringify({ ...item }));
    navigate("/mypage/homepageSetting");
  };

  return (
    <HomepageFormContainer className={setting?.mode}>
      <div className="viewer">
        <table>
          <colgroup>
            <col />
            <col />
          </colgroup>
          <thead>
            <tr>
              <th>사업자번호</th>
              <th>홈페이지 관리</th>
            </tr>
          </thead>
          <tbody id="place_tbody">
            {companys.map((item) => (
              <tr>
                <td>
                  <span>{item.cmpnyNm}</span>
                  <span>{`(${BussinessFormat(item.bizrno)})`}</span>
                </td>
                {item.domain ? (
                  <td>
                    <Link onClick={() => openNewTab(item.domain)}>
                      <i className="fa fa-laptop" aria-hidden="true" /> 홈페이지 바로가기
                    </Link>
                    <button onClick={() => navSettingPage(item)}>
                      <i className="fa fa-gear" aria-hidden="true" /> 관리
                    </button>
                  </td>
                ) : (
                  <td>
                    <button
                      onClick={() =>
                        navigate("/mypage/makeDomain", { state: { cmpnyNo: item.cmpnyNo } })
                      }>
                      <i className="fa fa-plus" aria-hidden="true" /> 생성
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </HomepageFormContainer>
  );
}
