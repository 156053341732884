import React from "react";
import { useNavigate } from "react-router";
import { ActionsContainer } from "../styled/feedWrite";

const Actions = ({ closeModal, handleSubmit, openModal }) => {
  const navigate = useNavigate();
  return (
    <ActionsContainer>
      <button
        onClick={() =>
          openModal({
            isOpen: true,
            type: "confirmation",
            title: "작성 취소",
            message: [`작성을 취소하시겠습니까?`],
            handleConfirm: () => navigate(-1),
            handleCancel: () => closeModal(),
          })
        }>
        취소
      </button>
      <button
        onClick={() =>
          openModal({
            isOpen: true,
            type: "confirmation",
            title: "피드 작성",
            message: [`피드를 작성하시겠습니까?`],
            handleConfirm: () => handleSubmit(),
            handleCancel: () => closeModal(),
          })
        }>
        등록
      </button>
    </ActionsContainer>
  );
};

export default Actions;
