import React, { useEffect, useState } from "react";
import PageInfo from "../pageInfo";
import HomePageSettingMenu from "./component/common/homepageSettingMenu";
import RegisterTitle from "./component/register/registerTitle";
import RegisterMenu from "./component/register/registerMenu";
import RegisterForm from "./component/register/registerForm";
import RegisterIntroForm from "./component/register/registerIntroForm";
import RegisterPortForm from "./component/register/registerPortForm";
import RegisterNoticeForm from "./component/register/registerNoticeForm";

import { useSelector } from "react-redux";
import { Container } from "../styled/mypage";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

export default function Register() {
  const navigate = useNavigate();

  const index = useSelector((state) => state.compoIndex.register);
  const setting = useSelector((state) => state?.user?.setting);

  const [cmpnyNo, setCmpnyNo] = useState();

  useEffect(() => {
    const company = JSON.parse(sessionStorage.getItem("homepageCompany"));

    if (!company) {
      console.log("선택된 회사가 존재하지 않습니다.");
      navigate("/mypage");
    } else {
      setCmpnyNo(company.cmpnyNo);
    }
  }, []);

  const Components = ({ index, cmpnyNo }) => {
    return [
      <RegisterForm cmpnyNo={cmpnyNo} />,
      <RegisterIntroForm cmpnyNo={cmpnyNo} />,
      <RegisterPortForm cmpnyNo={cmpnyNo} />,
      <RegisterNoticeForm cmpnyNo={cmpnyNo} />,
    ][index];
  };

  return (
    <Container className={setting?.mode}>
      <PageInfo label="정보 등록" />
      <div className="content">
        <HomePageSettingMenu />
        <RegisterTitle />
        <RegisterMenu index={index} />
        <Components index={index} cmpnyNo={cmpnyNo} />
      </div>
    </Container>
  );
}
