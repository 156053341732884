import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

import Map from "./eChanggo/map";
import Product from "./eChanggo/product";

import { SearcedEChanggo } from "../../../styled/groupDetails";

const EChanggo = ({
  cmpnys,
  searchWord,
  productList,
  totalProductPage,
  cmpnyNos,
  pageNumber,
  setPageNumber,
  selectedCmpny,
  setSelectedCmpny,
  selectedCmpnyPorts,
  setSelectedCmpnyPorts,
  getProductSearch,
  searched,
}) => {
  const [companys, setCompanys] = useState([]);
  const [cmpnyProductList, setCmpnyProductList] = useState([]);

  const swiperRef = useRef(null);

  useEffect(() => {
    getProductSearch();

    const observer = new IntersectionObserver(handleObserver, { threshold: 0.5 });
    const observerTarget = document.getElementById("observer");
    if (observerTarget) {
      observer.observe(observerTarget);
    }
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (pageNumber > totalProductPage) return;
    getProductSearch(pageNumber);
  }, [pageNumber]);

  useEffect(() => {
    if (cmpnyNos.length) {
      getCmpnyInfos(cmpnyNos);
    } else {
      setCompanys([]);
    }
  }, [cmpnyNos]);

  useEffect(() => {
    if (selectedCmpny) {
      getCmpnyPrducts();
      getCmpnyPort(selectedCmpny.cmpnyNo);
    }
  }, [selectedCmpny]);

  // 포트폴리오 조회
  const getCmpnyPort = async (cmpnyNo) => {
    const url = "/api/portMgmtList";
    const body = {
      cmpnyNo,
      useYn: "Y",
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 5,
      paged: true,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setSelectedCmpnyPorts([...res.data.content]);
      if (swiperRef.current) {
        swiperRef.current.swiper.slideTo(0);
      }
    }
  };

  // 회사 목록 데이터 조회
  const getCmpnyInfos = async (cmpnyNos) => {
    const url = "/api/cmpnyInfos";
    const body = { cmpnyNos };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      setCompanys(res.data);
    }
  };

  const handleObserver = (entries) => {
    const target = entries[0];
    if (target.isIntersecting && !selectedCmpny) {
      setPageNumber((page) => page + 1);
    }
  };

  // 특정 회사 상품 조회
  const getCmpnyPrducts = async () => {
    const url = "/api/prductStock";
    const body = {
      cmpnyNo: selectedCmpny.cmpnyNo,
      prductNm: searchWord,
      notitleYn: "Y",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      setCmpnyProductList(res.data.content);
    }
  };

  return (
    <SearcedEChanggo>
      <Map
        swiperRef={swiperRef}
        companys={companys}
        searched={searched}
        searchWord={searchWord}
        setSelectedCmpny={setSelectedCmpny}
        selectedCmpny={selectedCmpny}
        cmpnys={cmpnys}
        selectedCmpnyPorts={selectedCmpnyPorts}
      />
      <Product productList={selectedCmpny ? cmpnyProductList : productList} cmpnys={cmpnys} />
      <div id="observer" style={{ height: "10px" }}></div>
    </SearcedEChanggo>
  );
};

export default EChanggo;
