import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import { SmContainer } from "./styled/smTheme";
import HeroImage from "./themes/sm/heroImage";
import { useMediaQuery } from "react-responsive";

const SmTheme = () => {
  const { cmpnyNo } = useOutletContext();
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const footerElement = document.querySelector(isMobile ? "div.navbar" : "footer");

    if (footerElement) {
      const updateHeight = () => setFooterHeight(footerElement.offsetHeight);

      const mutationObserver = new MutationObserver(updateHeight);
      mutationObserver.observe(footerElement, { childList: true, subtree: true });

      const resizeObserver = new ResizeObserver(updateHeight);
      resizeObserver.observe(footerElement);

      updateHeight();

      return () => {
        mutationObserver.disconnect();
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <SmContainer style={{ height: `calc(100vh - ${footerHeight}px)` }}>
      <HeroImage cmpnyNo={cmpnyNo} />
    </SmContainer>
  );
};

export default SmTheme;
