import moment from "moment";
import React from "react";
import { useNavigate } from "react-router";

const GroupItem = ({ item }) => {
  const navigate = useNavigate();

  return (
    <li onClick={() => navigate(`/group/${item.groupNo}`)}>
      <div className="image">
        <img src={item.imgUrl ? imgHandler(item.imgUrl) : "/img/group/group.png"} a />
      </div>
      <div className="content">
        <span>{item.groupName}</span>
        <span>{moment(item.createDt).format("YYYY.MM.DD")}</span>
        <button>모임 바로가기</button>
      </div>
    </li>
  );
};

export default GroupItem;
