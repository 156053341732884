import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { FaChevronDown } from "react-icons/fa6";
import { FaqContainer, FaqItemContainer } from "../../shopping/component/styled/defaultTheme";
import { useOutletContext } from "react-router";
import SectionLink from "../../common/sectionLink";

const Faq = () => {
  const { theme, url } = useOutletContext();
  const temp = [];
  const [active, setActive] = useState(null);

  const toggleIndex = (index) => {
    setActive((idx) => (idx === index ? null : index));
  };

  return (
    <FaqContainer className={`theme-${theme}`}>
      {theme === 2 && <SectionLink url={url} path="자주 묻는 질문" />}
      <ul>
        <FaqItemContainer className="header">
          <span className="index">번호</span>
          <span className="category">분류</span>
          <span className="title">제목</span>
        </FaqItemContainer>
        {temp && temp.length > 0 ? (
          temp.map((_, idx) => (
            <>
              <FaqItemContainer onClick={() => toggleIndex(idx)} className="question">
                <span className="index">{idx}</span>
                <span className="category">회원</span>
                <span className="title">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Hic commodi maiores
                  perferendis facilis laboriosam esse repellendus reiciendis. Qui error animi, quas
                  temporibus, neque odit ab, repudiandae sit delectus saepe cumque.
                </span>
                <span className="toggle">
                  <FaChevronDown />
                </span>
              </FaqItemContainer>
              <FaqItemContainer className={`response ${idx === active ? "open" : ""}`}>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Beatae molestias
                  praesentium dicta sequi, libero necessitatibus itaque odit commodi doloremque a,
                  excepturi, ullam corrupti? Ipsam tenetur nostrum ratione quae alias libero. Lorem
                  ipsum dolor, sit amet consectetur adipisicing elit. Beatae molestias praesentium
                  dicta sequi, libero necessitatibus itaque odit commodi doloremque a, excepturi,
                  ullam corrupti? Ipsam tenetur nostrum ratione quae alias libero. Lorem ipsum
                  dolor, sit amet consectetur adipisicing elit. Beatae molestias praesentium dicta
                  sequi, libero necessitatibus itaque odit commodi doloremque a, excepturi, ullam
                  corrupti? Ipsam tenetur nostrum ratione quae alias libero.
                </p>
              </FaqItemContainer>
            </>
          ))
        ) : (
          <li className="not">자주 묻는 질문이 없습니다.</li>
        )}
      </ul>
    </FaqContainer>
  );
};

export default Faq;
