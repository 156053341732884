import React from "react";
import { StorageDetailContainer } from "../styled/pickUp";
import { Buttons } from "../styled/common";

const Confirm = ({ transformValue, setViewType, handleExchange }) => {
  return (
    <StorageDetailContainer
      className="slideContent center"
      style={{ transform: `translateX(${transformValue}%)` }}>
      <span>보상을 지급하시겠습니까?</span>
      <Buttons>
        <button onClick={() => setViewType(0)}>취소</button>
        <button onClick={() => handleExchange()}>확인</button>
      </Buttons>
    </StorageDetailContainer>
  );
};

export default Confirm;
