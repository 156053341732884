import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { handleLogin } from "../../../store/user";
import { useDispatch } from "react-redux";
import Modal from "../../../common/modal";
import { Link } from "react-router-dom";
import { LoginFormContainer } from "./styled/login";
import { useMediaQuery } from "react-responsive";
import { createCompany, createCompanyInfo } from "../../../store/company";
import { toast } from "react-toastify";
import Message from "./message";

export default function LoginForm({ getSchedulerSetting, url = "", logo = null }) {
  const location = useLocation();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: "(max-width:768px)" });

  const [loginInfo, setLoginInfo] = useState({ userId: "", password: "" });
  const [rememberId, setRememberId] = useState(false);

  // 초대 받은 경우
  const handleInvite = async (mberNo) => {
    const params = JSON.parse(sessionStorage.getItem("params"));

    const url = "/api/notification";
    const body = {
      gbn: "I",
      invite: {
        gbn: params.gbn,
        mberNoList: [mberNo],
        orgNo: params.orgNo,
      },
    };

    for (const key in body.invite) {
      if (body.invite.hasOwnProperty(key) && !body.invite[key]) {
        console.log("object contains empty values");
        return;
      }
    }

    const res = await axios.post(url, body);
    if (res.data.body.success) {
      sessionStorage.removeItem("params");
    }
  };

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const params = {};
      searchParams.forEach((value, key) => {
        params[key] = value;
      });
      sessionStorage.setItem("params", JSON.stringify(params));
    }

    const storedData = localStorage.getItem("monoti");

    if (storedData) {
      const id = JSON.parse(storedData);

      const decodedId = atob(id);
      setLoginInfo((data) => ({ ...data, userId: decodedId }));
      setRememberId(true);
    }

    initKakao();
  }, []);

  // 카카오 SDK 초기화
  const initKakao = async () => {
    if (!Kakao.isInitialized()) {
      // Kakao.init("b9886faecde9faa67a51166600a9d107"); // 개발 환경
      Kakao.init("4970bf08e349ccf4b295296035944317"); // 운영 환경
    }
  };

  // 카카오 로그인 처리
  const loginWithKakao = () => {
    Kakao.Auth.login({
      success: function (authObj) {
        const token = authObj.access_token;
        axios
          .request({
            method: "POST",
            url: "https://kapi.kakao.com/v2/user/me",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          })
          .then(async (response) => {
            const { data, status } = response;
            if (status === 200) {
              await kakaoSignIn(data);
            }
          })
          .catch((e) => {
            console.error(e);
          });
      },
      fail: function (err) {
        alert("error!!!!" + JSON.stringify(err));
      },
    });
  };

  // 카카오 로그인 후 사용자 정보 처리
  const kakaoSignIn = async (user) => {
    const url = "/api/kakaoSignIn";
    const req = {
      email: user.kakao_account.email,
      id: user.id,
      name: user.kakao_account.name,
      phoneNumber: user.kakao_account.phone_number,
    };
    const res = await axios.post(url, req);
    if (!res?.data?.userName) {
      return false;
    } else {
      handlePostLogin(res);
    }
  };

  // 아이디 저장
  const handleSaveId = (id) => {
    if (rememberId && !isMobile) {
      const encodedId = btoa(id);
      localStorage.setItem("monoti", JSON.stringify(encodedId));
    } else {
      localStorage.removeItem("monoti");
    }
  };

  // 로그인
  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = "/api/signIn";
    const req = {
      id: loginInfo.userId,
      pwd: loginInfo.password,
    };

    if (!loginInfo.userId || !loginInfo.password) {
      toast.error("아이디 또는 비밀번호를 입력해주세요.");
      return;
    }

    if (localStorage.getItem("msgToken")) {
      req.fcmToken = localStorage.getItem("msgToken");
    }

    const res = await axios.post(url, req);

    if (res.data.success === false) {
      toast.error(res.data.message);
    } else {
      handlePostLogin(res);
    }
  };

  // 로그인 후 이벤트
  const handlePostLogin = async (res) => {
    const data = res.data;

    dispatch(handleLogin(data));
    await getCmpnyNos(data);

    if (sessionStorage.getItem("params")) {
      await handleInvite(data.mberNo);
    }
    handleSaveId(loginInfo.userId);
    await getSchedulerSetting(data);
  };

  // 회사 정보 조회
  const getCmpnyNos = async (mber) => {
    const url = "/api/cmpnyMbers";
    const body = { mberNo: mber.mberNo };

    try {
      const res = await axios.post(url, body);
      if (res.status === 200) {
        const data = res?.data?.content ?? [];
        const cmpnyNos = [
          ...new Set(
            data
              .filter((cmpny) => !cmpny.isDeleted)
              .map((cmpny) => cmpny.cmpnyNo)
              .concat(mber.cmpnyNo),
          ),
        ].filter(Boolean);

        dispatch(createCompanyInfo([...data]));
        await getCmpny(cmpnyNos);
      }
    } catch (error) {
      return false;
    }
  };

  const getCmpny = async (cmpnys) => {
    const url = "/api/cmpny";

    const promises = cmpnys.map((item) => {
      const body = { cmpnyNo: item };
      return axios.post(url, body);
    });

    try {
      const res = await Promise.all(promises);
      const data = res.map((item) => item.data);
      console.log(data);
      dispatch(createCompany([...data]));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LoginFormContainer>
      <Message url={url} logo={logo} />
      <form onSubmit={handleSubmit}>
        <label htmlFor="userId" className="label">
          아이디
        </label>
        <input
          type="text"
          id="userId"
          name="userId"
          value={loginInfo.userId}
          onChange={(e) => setLoginInfo((data) => ({ ...data, userId: e.target.value }))}
        />
        <label htmlFor="member_pw" className="label">
          비밀번호
        </label>
        <input
          type="password"
          id="password"
          name="password"
          value={loginInfo.password}
          onChange={(e) => setLoginInfo((data) => ({ ...data, password: e.target.value }))}
        />
        <div className="actions">
          <div className="options">
            {!isMobile && (
              <label>
                <input
                  type="checkbox"
                  name="rememberId"
                  checked={rememberId}
                  onChange={(e) => setRememberId(e.target.checked)}
                />
                <span className="checkmark"></span>
                <span>아이디 저장</span>
              </label>
            )}
            <Link to="/memberSearch">아이디·비밀번호 찾기</Link>
          </div>
          <button type="submit">로그인</button>
          <div className="line">
            <span></span>
            <span>또는</span>
            <span></span>
          </div>
          <button type="button" onClick={loginWithKakao} className="kakao">
            <span>카카오톡 로그인</span>
          </button>
          <div className="join">
            <span>아직 모노티 회원이 아니시라면</span>
            <Link to="/type">회원가입</Link>
          </div>
        </div>
      </form>
    </LoginFormContainer>
  );
}
