import React, { useEffect, useRef, useState } from "react";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, rectSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import axios from "axios";
import moment from "moment";

import UnreadNotifications from "./widgets/unreadNotifications";
import Mentions from "./widgets/mentions";
import Scheduler from "./widgets/scheduler";
import Dday from "./widgets/dday";
import Projects from "./widgets/projects";
import Homepage from "./widgets/homepage";
import ContextMenu from "./widgets/contextMenu";
import Company from "./widgets/company";

import { DashboardContainer } from "../styled/dashboard";
import useOutsideClick from "../../../../../hooks/useOutsideClick";
import useScrollLock from "../../../../../hooks/useScrollLock";
import { useMediaQuery } from "react-responsive";

const widgetIdMap = {
  0: "unreadNotifications",
  1: "mentions",
  2: "scheduler",
  3: "dday",
  5: "projects",
  6: "homepage",
  7: "company",
};

const Widgets = ({ isDragEnabled, setting, setSetting }) => {
  const navigate = useNavigate();
  const menuRef = useRef();

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const user = useSelector((state) => state?.user?.data[0]);
  const { mySchedSet } = useSelector((state) => state?.scheduler);

  const [ddays, setDdays] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [widgetOrder, setWidgetOrder] = useState([]);

  const [notification, setNotification] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [currentMenuItems, setCurrentMenuItems] = useState([]);
  const [activeId, setActiveId] = useState(null);

  const widgetConfig = {
    unreadNotifications: {
      component: UnreadNotifications,
      label: "미확인 알림",
      id: 0,
      gridColumnSpan: 2,
      data: { notification },
    },
    mentions: {
      component: Mentions,
      label: "@ 나를 언급",
      id: 1,
      gridColumnSpan: 2,
      data: {},
    },
    scheduler: {
      component: Scheduler,
      label: "나의 일정",
      id: 2,
      gridColumnSpan: 2,
      data: { schedules: schedules.scheduler },
    },
    dday: {
      component: Dday,
      label: "나의 디데이",
      id: 3,
      gridColumnSpan: 2,
      data: { ddays: ddays },
    },
    projects: {
      component: Projects,
      label: "나의 프로젝트",
      id: 5,
      gridColumnSpan: isMobile ? 2 : 4,
      data: {},
    },
    homepage: {
      component: Homepage,
      label: "나의 홈페이지",
      id: 6,
      gridColumnSpan: 2,
      data: {},
    },
    company: {
      component: Company,
      label: "회사 관리",
      id: 7,
      gridColumnSpan: 2,
      data: {},
    },
  };

  useScrollLock(isMenuOpen);
  useOutsideClick(menuRef, () => setIsMenuOpen(false));

  useEffect(() => {
    setWidgetOrder(
      setting.widgets.filter((widget) => widget.isActive).map((widget) => widgetIdMap[widget.id]),
    );
  }, [setting.widgets]);

  useEffect(() => {
    if (user) {
      getNotification();
      getSchedule();
    }
  }, []);

  // 미확인 알림 조회
  const getNotification = async () => {
    try {
      const res = await axios.post("/api/notificationHistory", { mberNo: user.mberNo });
      setNotification((res?.data || []).filter((not) => not.readYn === "N"));
    } catch (error) {
      console.error(error);
    }
  };

  // 일정 조회
  const getSchedule = async () => {
    const isOrgNo = (org) => (org ? org.map((item) => item.orgNo) : []);
    const url = "/api/scheduleList";
    const body = {
      mberNo: user.mberNo,
      showPersonalYn: "Y",
      cmpnyNos: isOrgNo(mySchedSet.companyYnList),
      groupNos: isOrgNo(mySchedSet.groupYnList),
      completeYn: "D",
      startDt: moment().format("YYYY-MM-DD 00:00:00"),
      endDt: moment().format("YYYY-MM-DD 23:59:59"),
    };
    const res = await axios.post(url, body);
    if (res.status === 200 && res.data && res.data.length > 0) {
      const dday = [];
      const schedule = [];

      res.data.forEach((ele) => {
        if (ele.dday === "N") {
          schedule.push(ele);
        } else {
          if (ele.completeYn === "N") {
            dday.push(ele);
          }
        }
      });
      setDdays(dday);
      setSchedules(schedule);
    }
  };

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setWidgetOrder((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const newOrder = arrayMove(items, oldIndex, newIndex);

        const updatedWidgets = newOrder.map((widgetKey) =>
          setting.widgets.find((widget) => widgetIdMap[widget.id] === widgetKey),
        );

        setSetting((data) => ({ ...data, widgets: updatedWidgets }));
        return newOrder;
      });
    }

    setActiveId(null);
  };

  // 메뉴 버튼 클릭
  const handleButtonClick = (e) => {
    if (isDragEnabled) return;

    const button = e.target.closest("button");

    if (button) {
      const rect = button.getBoundingClientRect();
      setMenuPosition({ x: rect.left, y: rect.top });

      const name = button?.dataset?.name;
      if (name) {
        const config = menuConfig[name];
        if (config) {
          setCurrentMenuItems(config);
        }
      }
    }

    setIsMenuOpen((menu) => !menu);
  };

  // 미확인 알림 모두 읽기
  const readAllNotis = async () => {
    setIsMenuOpen(false);
    if (!notification || !notification.length) {
      toast.error("미확인 알림이 존재하지 않습니다.");
      return;
    }

    const url = "/api/notificationHistoryUpdate";
    const body = {
      mberNo: user.mberNo,
      allDelYn: "N",
      allReadYn: "Y",
      notiNos: [],
      acceptYn: null,
      gbnValue: null,
      readYn: "N",
      delYn: "Y",
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      getNotification();
    }
  };

  // 알림 전체 삭제
  const deleteAllNotis = async () => {
    setIsMenuOpen(false);
    if (!notification || !notification.length) {
      toast.error("미확인 알림이 존재하지 않습니다.");
      return;
    }

    const url = "/api/notificationHistoryUpdate";
    const body = {
      mberNo: user.mberNo,
      allDelYn: "Y",
      allReadYn: "N",
      notiNos: [],
      acceptYn: null,
      gbnValue: null,
      readYn: "N",
      delYn: "Y",
    };
    const res = await axios.post(url, body);
    if (res.status === 200) {
      toast.success("전체 알림이 삭제되었습니다.");
      getNotification();
    }
  };

  const handleMarkAllMentionsAsRead = () => {};
  const handleDeleteAllMentions = () => {};

  // 메뉴 리스트
  const menuConfig = {
    unreadNotifications: [
      { name: "모두 읽기", action: readAllNotis },
      { name: "모두 삭제", action: deleteAllNotis },
    ],
    mentions: [
      { name: "모두 읽기", action: handleMarkAllMentionsAsRead },
      { name: "모두 삭제", action: handleDeleteAllMentions },
    ],
    scheduler: [{ name: "일정 생성", action: () => navigate("/mypage/calendarEntry") }],
    dday: [{ name: "디데이 생성", action: () => navigate("/mypage/ddayEntry") }],
    projects: [{ name: "프로젝트 생성", action: () => navigate("/mypage/projectCreate") }],
    homepage: [{ name: "홈페이지 목록", action: () => navigate("/mypage/homepage") }],
    company: [
      { name: "회사 목록", action: () => navigate("/mypage/companies") },
      { name: "회사 추가", action: () => navigate("/mypage/addComapny") },
    ],
  };

  return (
    <DashboardContainer className={setting.mode} id="widgets">
      {isMenuOpen && (
        <ContextMenu
          setting={setting}
          menuPosition={menuPosition}
          currentMenuItems={currentMenuItems}
          menuRef={menuRef}
          setIsMenuOpen={setIsMenuOpen}
        />
      )}
      <DndContext
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={isDragEnabled ? handleDragEnd : undefined}>
        <SortableContext items={widgetOrder} strategy={rectSortingStrategy}>
          <div className="widget" style={{ minHeight: "50px", display: "" }}>
            {widgetOrder.map((key) => {
              if (widgetConfig[key]) {
                const { component: Component, label, data, gridColumnSpan } = widgetConfig[key];
                return (
                  <SortableItem
                    key={key}
                    id={key}
                    className={key}
                    isDragEnabled={isDragEnabled}
                    gridColumnSpan={gridColumnSpan}
                    activeId={activeId}>
                    <Component
                      label={label}
                      className={key}
                      handleButtonClick={handleButtonClick}
                      {...data}
                    />
                  </SortableItem>
                );
              }
              return null;
            })}
          </div>
        </SortableContext>
        <DragOverlay>
          {activeId ? (
            <div className="widget overlay">
              <div
                className={activeId}
                style={{ gridColumn: `span ${widgetConfig[activeId].gridColumnSpan}` }}>
                {React.createElement(widgetConfig[activeId].component)}
              </div>
            </div>
          ) : null}
        </DragOverlay>
      </DndContext>
    </DashboardContainer>
  );
};

const SortableItem = ({ id, children, className, isDragEnabled, gridColumnSpan, activeId }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const style = {
    transform: `translate(${transform?.x || 0}px, ${transform?.y || 0}px)`,
    transition,
    cursor: isDragEnabled ? "pointer" : "default",
    gridColumn: `span ${gridColumnSpan}`,
    minHeight: "150px",
    opacity: activeId === id ? 0.5 : 1,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...(isDragEnabled ? attributes : {})}
      {...(isDragEnabled ? listeners : {})}
      className={`sortable-item ${className}`}>
      {children}
    </div>
  );
};

export default Widgets;
