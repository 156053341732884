import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import uuid from "react-uuid";
import { useNavigate, useLocation } from "react-router";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import { IoMdArrowBack } from "react-icons/io";

import { AddScheduleContainer } from "./component/styled/calendarEntry";
import { GoToBackHeader } from "./component/styled/common";

import ScheduleForm from "./component/calendarEntry/scheduleForm";
import SideForm from "./component/calendarEntry/sideForm";

import { isEventAllTime } from "./component/function/common";
import SelectedScope from "./component/common/selectedScope";
import {
  keysToRemove,
  transformDate,
  setDateObject,
  roundTime,
} from "./component/function/scheduler";
import { useModal } from "../../../hooks/useModal";

const CalendarEntry = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state?.user?.data[0]);
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const company = useSelector((state) => state?.company?.data);

  const { modal, openModal, closeModal } = useModal();

  const eventInfo = location?.state?.eventInfo || {};
  const todoInfo = location?.state?.todoInfo || [];
  const infoState = location?.state?.infoState || "";

  const [isAllTime, setIsAllTime] = useState(false);
  const [date, setDate] = useState({});
  const [formData, setFormData] = useState({
    mberNo: user.mberNo,
    title: "",
    startDt: "",
    endDt: "",
    gbn: "P",
    color: "139859",
    dday: "N",
    todo: [],
    publicYn: "Y",
    pushMinute: 60,
    projectNo: null,
    // recur: {
    //   freq: "notRepeat",
    //   until: "",
    // },
  });
  const [myAffil, setMyAffil] = useState([]);
  const [myAffilEmp, setMyAffilEmp] = useState([]);
  const [originMyAffilEmp, setOriginMyAffilEmp] = useState([]);
  const [actSelType, setActSelType] = useState(true);
  const [isSideMenuOpen, setIsSideFormOpen] = useState(false);
  const [isSelectedScope, setIsSelectedScope] = useState(false);
  const isEditable =
    Object.keys(eventInfo).length > 3 && eventInfo.scheduleNo && eventInfo.scheduleNo > 0;
  const [projectList, setProjectList] = useState({});
  const [projectMberInfo, setProjectMberInfo] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [todos, setTodos] = useState([
    { id: uuid(), value: "", showBullet: false, mentionUserIds: [] },
  ]);

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }

    setInitialDate();

    if (Object.keys(eventInfo).length !== 0) {
      setEventInfoDate(eventInfo);
    }

    if (todoInfo.length > 0) {
      const todoData = todoInfo.map((item) => ({ ...item, id: item.id ? item.id : uuid() }));
      setTodos(todoData);
    }

    if (eventInfo) {
      const item = keysToRemove(eventInfo);
      const { gbn, orgNo, pushMinute, projectNo } = item;

      if (gbn && gbn !== "P") {
        handleChangeGbn(gbn);
      }
      if (orgNo) {
        handleChangeOrgNo(gbn, orgNo);
      }
      if (projectNo) {
        getProjectMbers(projectNo);
        setProjectName(
          formData.projectNo === "notProject"
            ? ""
            : Object.entries(projectList).find(([_, v]) => v === formData.projectNo)?.[0],
        );
      }

      const todo =
        Array.isArray(item.todo) && item.todo.length > 0
          ? item.todo.map((item) => ({ ...item, tempId: uuid() }))
          : [];
      const push = !item.pushMinute ? "notPush" : pushMinute;
      const project = item.projectNo || "notProject";

      // const recur = item.recur ? { ...item.recur } : { freq: "", until: "" };

      setFormData({
        ...formData,
        ...item,
        // recur,
        todo,
        pushMinute: push,
        projectNo: project,
      });
    } else {
      const local = JSON.parse(localStorage.getItem("calendar"));

      if (local && local.mberNo === user.mberNo) {
        openModal({
          isOpen: true,
          type: "confirmation",
          title: `일정 복구`,
          message: ["작성하던 내용이 있습니다.", "이어서 작성하시겠습니까?"],
          handleConfirm: () => handleOverwrite(),
          handleCancel: () => deleteLocalStorage(),
        });
      }
    }
  }, []);

  useEffect(() => {
    if (myAffil.length === 1) {
      const { gbn } = formData;
      const orgNo = gbn === "C" ? myAffil[0].cmpnyNo : myAffil[0].groupNo;

      setFormData((data) => {
        let updated = {
          ...data,
          orgNo,
        };
        saveToLocalStorage(updated);
        return updated;
      });

      handleChangeOrgNo(gbn, orgNo);
    }
  }, [myAffil]);

  const navigatePath = () => {
    if (location.search) {
      const params = new URLSearchParams(location.search);

      const keyword = params.get("keyword");
      const path = params.get("path");
      const encodedKeyword = keyword ? encodeURIComponent(keyword) : null;

      let basePath = path ? path : "/mypage/scheduler";
      let queryParams = [];

      if (encodedKeyword) {
        queryParams.push(`keyword=${encodedKeyword}`);
      }

      const queryString = queryParams.length ? `?${queryParams.join("&")}` : "";
      return `${basePath}${queryString}`;
    } else {
      navigate("/mypage/scheduler");
    }
  };

  // 로컬 스토리지 저장
  const saveToLocalStorage = (updated) => {
    if (!isEditable) {
      if (!updated.startDt) {
        delete updated.startDt;
      }
      if (!updated.endDt) {
        delete updated.endDt;
      }

      let calendar = JSON.parse(localStorage.getItem("calendar")) || {
        ...formData,
        ...transformDate(date, isAllTime),
      };

      calendar = { ...calendar, ...updated };
      if (!calendar.startDt || !calendar.endDt) {
        const formDate = transformDate(date, isAllTime);

        calendar.startDt = formDate.startDt;
        calendar.endDt = formDate.endDt;
      }
      localStorage.setItem("calendar", JSON.stringify(calendar));
    }
  };

  // 일정 생성 타입
  const infoStateType = () => {
    if (!isEditable || infoState === "write") {
      return "등록";
    }
    if (infoState === "copy") {
      return "복사";
    }
    return "수정";
  };

  // 오늘 날짜
  const setInitialDate = () => {
    const now = moment().format("YYYY-MM-DD HH:30:00");
    const endTime = moment(now).clone().add(1, "hours").format("YYYY-MM-DD HH:30:00");

    setDate(
      setDateObject(
        now.split(" ")[0],
        endTime.split(" ")[0],
        now.split(" ")[1],
        endTime.split(" ")[1],
      ),
    );
  };

  // 수정본 날짜 수정
  const setEventInfoDate = (info) => {
    if (!info?.startDt || !info?.endDt) return;

    const [dateStart, timeStart] = info.startDt.split(" ");
    const [dateEnd, timeEnd] = info.endDt.split(" ");

    setIsAllTime(isEventAllTime(info));
    setDate(setDateObject(dateStart, dateEnd, roundTime(timeStart), roundTime(timeEnd)));
  };

  const deleteModal = () => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `일정 삭제`,
      message: ["일정을 삭제하시겠습니까?"],
      handleConfirm: () => handleDelete(),
      handleCancel: () => closeModal(),
    });
  };

  const exitModal = () => {
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `나가기`,
      message: ["작성을 취소하시겠습니까?"],
      handleConfirm: () => navigate(navigatePath()),
      handleCancel: () => closeModal(),
    });
  };

  const saveModal = (type) => {
    const modalType = {
      eventEdit: "수정",
      eventSave: "저장",
      eventCopy: "복사",
    };

    openModal({
      isOpen: true,
      type: "confirmation",
      title: `일정 ${modalType[type]}`,
      message: [`일정을 ${modalType[type]}하시겠습니까?`],
      handleConfirm: () => handleSubmit(),
      handleCancel: () => closeModal(),
    });
  };

  const deleteLocalStorage = () => {
    closeModal();

    const localData = JSON.parse(localStorage.getItem("calendar"));

    if (localData) {
      localStorage.removeItem("calendar");
    }
  };

  // 로컬 스토리지 덮어쓰기
  const handleOverwrite = () => {
    const localData = JSON.parse(localStorage.getItem("calendar"));
    setFormData(localData);
    setEventInfoDate(localData);

    if (localData) {
      const { gbn, orgNo, projectNo } = localData;

      if (gbn && gbn !== "P") {
        handleChangeGbn(gbn);
      }
      if (orgNo) {
        handleChangeOrgNo(gbn, orgNo);
      }
      if (projectNo && !isNaN(projectNo)) {
        getProjectMbers(projectNo);
      }

      localStorage.removeItem("calendar");
    }
    closeModal();
  };

  // 저장 조건 확인
  const checkSubmitCond = (type) => {
    const isGbn = {
      C: "업무",
      G: "모임",
      O: "외부업체",
    };

    if (!formData.title) {
      toast.error("제목이 존재하지 않습니다");
      return;
    }

    const calendarDate = transformDate(date, isAllTime);

    setFormData((prev) => {
      return {
        ...prev,
        ...calendarDate,
      };
    });

    if (
      moment(calendarDate.endDt).isBefore(moment(calendarDate.startDt)) ||
      moment(calendarDate.endDt).isSame(moment(calendarDate.startDt))
    ) {
      toast.error("종료날짜는 시작날짜보다 커야 합니다.");
      return;
    }

    if (formData.gbn !== "P") {
      if (!formData.orgNo || isNaN(formData.orgNo)) {
        toast.error(`${chooseParticle(isGbn[formData.gbn], "을를")} 선택해주세요.`);
        return;
      }
    }

    if (formData.gbn === "C") {
      if (!formData.managerNo || !formData.managerNm) {
        toast.error("담당자가 존재하지 않습니다.");
        return;
      }
    }

    if (formData.projectNo && projectName && !Object.keys(projectList).includes(projectName)) {
      toast.error("선택하신 프로젝트가 존재하지 않습니다.");
      return;
    }

    saveModal(type);
  };

  const splitTodos = () => {
    let todo = [];
    let memo = [];

    todos.forEach((item, index) => {
      if (item.showBullet) {
        todo.push({ ...item, index });
      } else {
        memo.push(item);
      }
    });

    return { todo, memo: memo.map((item) => `\uffff${item.value}`).join("\n") };
  };

  // 일정 저장
  const handleSubmit = async () => {
    const url = "/api/schedule";
    const body = {
      ...formData,
      delYn: "N",
    };

    if (formData.workerList) {
      if (Object.keys(formData.workerList).length) {
        const workers = Object.keys(formData.workerList);
        body.workerNos = workers;
      } else {
        body.workerNos = [];
      }
    }

    if (body.gbn === "P") {
      body.managerNo = null;
    }
    if (body.gbn === "G") {
      body.color = "F46F02";
    }
    if (body.gbn === "C") {
      body.color = "0074CA";
    }

    if (!isNaN(body.pushMinute)) {
      body.pushMinute = parseInt(body.pushMinute, 10);
    } else {
      body.pushMinute = null;
    }

    if (!isNaN(body.projectNo)) {
      body.projectNo = parseInt(body.projectNo);
    } else {
      body.projectNo = null;
    }

    const { todo, memo } = splitTodos();
    body.memo = memo;

    // if (body.recur.freq === "notRepeat" || !body.recur.freq) {
    //   body.recur = null;
    // }

    // if (!body.recur || !body.recur.until) {
    //   body.recur = null;
    // }

    /*
     *
     *
     */

    const { toUpdate, toCreate } = todo.reduce(
      (result, item) => {
        const target = todoInfo.find((todoItem) => todoItem.id === item.id);

        if (target) {
          if (target.value !== item.value || target.mentionUserIds !== item.mentionUserIds) {
            result.toUpdate.push(item);
          }
        } else {
          result.toCreate.push(item);
        }

        return result;
      },
      { toUpdate: [], toCreate: [] },
    );

    const toDelete = todoInfo.filter((todoItem) => {
      const notInTodos = todoItem.id && !todos.some((item) => item.id === todoItem.id);
      const showBulletChanged = todos.some(
        (item) => item.id === todoItem.id && todoItem.showBullet && !item.showBullet,
      );

      return notInTodos || showBulletChanged;
    });

    const res = await axios.put(url, body);

    if (res.status === 200) {
      const workerList = [
        ...(body.workerList ? Object.keys(body.workerList) : []),
        ...(body.managerNo ? [body.managerNo] : []),
      ];

      deleteLocalStorage();

      // 회사 및 프로젝트 일정이고 workerList가 있을 경우
      if (body.gbn === "C" && body.workerList && workerList.length > 0 && body.projectNo) {
        console.log(body);
        const myCmpny = projectMberInfo.find((cmpny) => cmpny.orgNo === body.orgNo)?.mbers;
        let nonProjectMbers = [];

        if (myCmpny) {
          const stringWorkerList = workerList.map(String);

          myAffilEmp.forEach((mber) => {
            const mberNoStr = String(mber.mberNo);

            // 멤버가 workerList에 있고, 프로젝트에 있지 않은 경우
            if (
              stringWorkerList.includes(mberNoStr) &&
              !projectMberInfo.some((member) => String(member.mberNo) === mberNoStr)
            ) {
              nonProjectMbers.push(mberNoStr);
            }
          });
        }

        if (nonProjectMbers.length > 0) {
          await handleParticipant(nonProjectMbers);
        }
      }

      if (toCreate.length > 0) {
        await createTodo(res.data.message, toCreate);
      }
      if (toDelete.length + toUpdate.length > 0) {
        console.log(toUpdate, toDelete);
        await updateTodo(res.data.message, { toUpdate, toDelete });
      }

      navigate(navigatePath());
      toast.success(`일정이 ${infoStateType()}되었습니다.`);
    }
  };

  const updateTodo = async (scheduleNo, todo) => {
    const url = "/api/todo";
    const body = ["toUpdate", "toDelete"].flatMap((key) =>
      todo[key].map((item) => ({
        id: item.id,
        scheduleNo,
        content: `\uffff${item.value || ""}`,
        index: item.index ? item.index : 0,
        isDeleted: key === "toDelete",
        isCompleted: item.isChecked,
        mentionUserIds: item.mentionUserIds || [],
      })),
    );

    try {
      const res = await axios.put(url, body);
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  const createTodo = async (scheduleNo, todos) => {
    const url = "/api/todo";
    const body = todos.map((item) => ({
      scheduleNo: scheduleNo,
      content: `\uffff${item.value || ""}`,
      index: item.index,
      isDeleted: false,
      isCompleted: false,
      mentionUserIds: [...item.mentionUserIds],
    }));

    try {
      const res = await axios.post(url, body);
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  };

  // 프로젝트 초대
  const handleParticipant = async (mberNos = []) => {
    if (mberNos.length === 0) {
      return;
    }

    const url = "/api/participants";
    const body = [];

    for (let i = 0; i < mberNos.length; i++) {
      body.push({
        projectNo: formData.projectNo,
        gbn: formData.gbn,
        mberNo: mberNos[i],
        orgNo: formData.orgNo,
        partiType: "I",
        pinnedYn: "N",
        delYn: "N",
        createMberId: user.userName,
        updateMberId: user.userName,
      });
    }

    const res = await axios.put(url, body);

    if (res.status === 200) {
      console.log(res);
    }
  };

  // 일정 삭제
  const handleDelete = async () => {
    const url = "/api/schedule";
    const body = {
      ...formData,
      projectNo: null,
      pushMinute: null,
      delYn: "Y",
    };

    const res = await axios.put(url, body);
    if (res.status === 200) {
      toast.success("일정이 삭제되었습니다.");
      navigate(navigatePath());
    }
  };

  // 애니메이션
  const toggleSideForm = (flag) => {
    if (isSideMenuOpen && flag) {
      handleSideMenuAni();
      return;
    }

    handleToggleSideMenu(flag);
  };

  const handleToggleSideMenu = (flag) => {
    // handleSideMenuAni();

    const outsideArea = document.querySelector(".scheduleSide");

    outsideArea.style.transform = `translateX(${flag ? "0%" : "100%"})`;
    outsideArea.style.opacity = flag ? "1" : "0";

    setIsSideFormOpen(flag);
  };

  const handleSideMenuAni = () => {
    const outsideArea = document.querySelector(".scheduleSide");
    outsideArea.animate(
      [
        { transform: "translateX(0%)", opacity: 1 },
        { transform: "translateX(100%)", opacity: 0 },
        { transform: "translateX(0%)", opacity: 1 },
      ],
      600,
    );
  };

  // 회사 조회
  const getMyCmpny = async () => {
    setMyAffil([...company]);
    toggleSideForm(false);

    if (company.length === 1) {
      getMyCmpnyMbers(company[0].cmpnyNo);
    }
  };

  // 모임 조회
  const getMyGrp = async () => {
    const url = "/api/groupCondList";
    const body = {
      mberNo: user.mberNo,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      const content = res.data.content;
      setMyAffil(content);
      toggleSideForm(false);

      if (content.length === 1) {
        getMyGrpMbers(content[0].groupNo);
      }
    }
  };

  // 회사 멤버 조회
  const getMyCmpnyMbers = async (orgNo) => {
    const url = "/api/cmpnyMbers";
    const body = { cmpnyNo: orgNo };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const data = res?.data?.content || [];
      const mbers = data
        .filter((mber) => mber.mberNm && mber.mberTel)
        .map((mber) => ({
          mberNo: mber.mberNo,
          mberNm: mber.mberNm,
          tel: mber.mberTel,
        }));

      setOriginMyAffilEmp(mbers);
      setMyAffilEmp(mbers);
      toggleSideForm(true);
    }
  };

  // 모임 멤버 조회
  const getMyGrpMbers = async (orgNo) => {
    const url = "/api/groupMberList";
    const body = {
      groupNo: orgNo,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, body);

    if (res.status === 200) {
      const mbers = res.data.content
        .filter((mber) => mber.nickNm)
        .map((mber) => ({
          mberNo: mber.mberNo,
          mberNm: mber.nickNm,
          tel: null,
        }));

      setOriginMyAffilEmp(mbers);
      setMyAffilEmp(mbers);
      toggleSideForm(true);
    }
  };

  // 업무 및 모임 선택
  const handleChangeGbn = async (gbn) => {
    if (gbn === "P") return;
    if (gbn === "C") {
      getMyCmpny();
    }
    if (gbn === "G") {
      getMyGrp();
    }
  };

  // 특정 업무 및 모임 선택
  const handleChangeOrgNo = async (gbn, orgNo) => {
    if (gbn === "P") return;
    if (gbn === "C") {
      getMyCmpnyMbers(orgNo);
    }
    if (gbn === "G") {
      getMyGrpMbers(orgNo);
    }

    setActSelType(true);
    await getMyProjects(gbn, orgNo);
  };

  // 프로젝트 목록 조회
  const getMyProjects = async (gbn, orgNo) => {
    if (!gbn || !orgNo) {
      return;
    }

    const url = "/api/projectList";
    const body = {
      mberNo: user.mberNo,
      gbn,
      orgNo,
      delYn: "N",
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      const data = res.data;

      let list = [];
      if (data.fixedProjectList && Array.isArray(data.fixedProjectList)) {
        list = list.concat(data.fixedProjectList);
      }

      const filtered = list.filter((item) => item.projectParticipantNo);

      const result = {};
      filtered.forEach((item) => {
        if (parseInt(item.orgNo) === parseInt(orgNo)) {
          result[item.projectNm] = item.projectNo;
        }
      });

      setProjectList(result);
    }
  };

  // 프로젝트 참여자 조회
  const getProjectMbers = async (projectNo) => {
    if (isNaN(projectNo)) return;

    const url = "/api/participantList";
    const body = {
      projectNo,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };

    const res = await axios.post(url, body);
    if (res.status === 200) {
      const list = res.data.content;

      const result = [];

      list.forEach((item) => {
        let existingOrg = result.find((org) => org.orgNo === item.orgNo);
        if (!existingOrg) {
          existingOrg = { orgNm: item.orgNm, orgNo: item.orgNo, mbers: [] };
          result.push(existingOrg);
        }
        existingOrg.mbers.push(item);
      });

      setProjectMberInfo(result);
    }
  };

  return (
    <>
      {modal}
      <AddScheduleContainer className={`inner_content ${isDarkMode}`} id="schduler_content">
        <GoToBackHeader className={isDarkMode}>
          <span className="icon" onClick={exitModal}>
            <IoMdArrowBack />
          </span>
          <span>일정 {infoStateType()}</span>
        </GoToBackHeader>
        <div className="addScheduleBody">
          <ScheduleForm
            date={date}
            setDate={setDate}
            isAllTime={isAllTime}
            setIsAllTime={setIsAllTime}
            toggleSideForm={toggleSideForm}
            affilEmp={myAffilEmp}
            formData={formData}
            setFormData={setFormData}
            myAffil={myAffil}
            isEditable={isEditable}
            handleChangeOrgNo={handleChangeOrgNo}
            handleChangeGbn={handleChangeGbn}
            checkSubmitCond={checkSubmitCond}
            saveToLocalStorage={saveToLocalStorage}
            projectList={projectList}
            setProjectList={setProjectList}
            getProjectMbers={getProjectMbers}
            setMyAffilEmp={setMyAffilEmp}
            originMyAffilEmp={originMyAffilEmp}
            setActSelType={setActSelType}
            infoState={infoState} // isRecurring={isRecurring}
            eventInfo={eventInfo}
            projectName={projectName}
            setProjectName={setProjectName}
            todos={todos}
            setTodos={setTodos}
            deleteModal={deleteModal}
          />
          <SideForm
            formData={formData}
            setFormData={setFormData}
            affilEmp={myAffilEmp}
            actSelType={actSelType}
            setActSelType={setActSelType}
            saveToLocalStorage={saveToLocalStorage}
            projectMberInfo={projectMberInfo}
            toggleSideForm={toggleSideForm}
            todos={todos}
            setTodos={setTodos}
          />
        </div>
        {isSelectedScope && (
          <SelectedScope
            type="update"
            item={formData}
            setItem={setFormData}
            isModalOpen={setIsSelectedScope}
            submit={handleSubmit}
          />
        )}
      </AddScheduleContainer>
    </>
  );
};

export default CalendarEntry;
