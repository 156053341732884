import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { useDispatch } from "react-redux";

import { FeedListContainer, GroupContainer, NoData } from "./component/styled/common";

import { hideLoading, showLoading } from "../../store/loading";
import { useModal } from "../../hooks/useModal";
import { mergeAuth, mergeComment, mergeGbnValueName } from "./component/function/group";

import Loading from "../../common/loading";
import ImageDetailViewer from "../../common/imageDetailViewer";
import PageInfo from "./component/common/pageInfo";
// import Advertisement from "./component/common/feed/advertisement";
import FeedItem from "./component/common/feed/feedItem";
import HamburgerMenu from "./component/common/hamburgerMenu";

import { LuRefreshCw } from "react-icons/lu";
import { HiMiniPencilSquare } from "react-icons/hi2";

const Feeds = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const feedRefs = useRef([]);

  const user = useSelector((state) => state?.user?.data[0]);
  const isLoading = useSelector((state) => state?.loading?.isLoading);

  const { modal, openModal, closeModal } = useModal();

  const [feedList, setFeedList] = useState([]);
  const [my, setMy] = useState([]);
  const [text, setText] = useState({});
  const [active, setActive] = useState();
  const [detailImages, setDetailImages] = useState([]);

  useEffect(() => {
    if (user) {
      getMyGrp();
    } else {
      navigate("/group");
    }
  }, []);

  useEffect(() => {
    if (!my || !my.length) return;
    getFeedList(my);
  }, [my]);

  // 내 모임
  const getMyGrp = async () => {
    const url = "/api/groupCondList";
    const req = {
      mberNo: user.mberNo,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };
    const res = await axios.post(url, req);
    setMy(res?.data?.content);
  };

  // 댓글 조회
  const getCommentList = async (feedNo) => {
    const url = "/api/feedRecomment";
    const req = {
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      delYn: "N",
      paged: false,
    };
    if (feedNo) {
      req.feedNo = feedNo;
    }
    const res = await axios.post(url, req);
    return res?.data?.content;
  };

  const getFeedList = async (my) => {
    dispatch(showLoading());

    const url = "/api/grpFeedList";
    const grpNo = my.map((grp) => grp.groupNo);

    if (!Array.isArray(grpNo) || grpNo.length <= 0) {
      dispatch(hideLoading());
      return;
    }

    const req = {
      gbn: grpNo ?? [0],
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 100,
      paged: true,
    };

    try {
      const res = await axios.post(url, req);
      const feeds = res?.data?.content || [];

      const comments = await getCommentList();

      const mergedFeeds = await [mergeAuth, mergeComment, mergeGbnValueName].reduce(
        async (accPromise, mergeFn) => {
          const acc = await accPromise;
          return mergeFn(acc, mergeFn === mergeComment ? comments : [...my]);
        },
        Promise.resolve(feeds),
      );

      setFeedList(mergedFeeds);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(hideLoading());
    }
  };

  // 댓글 추가
  const addComment = async (feedNo) => {
    if (feedNo) {
      const comment = await getCommentList(feedNo);
      const target = feedList.find((item) => item.feedNo === feedNo);
      if (target) {
        target.comments = Array.isArray(comment) ? [...comment] : [];
        setFeedList([...feedList]);
        setText({});
      }
    }
  };

  const onSubmit = async (e, feedNo) => {
    e.preventDefault();

    if (!text[feedNo].trim()) {
      return;
    }
    const url = "/api/feedRecomment";
    const req = {
      feedNo,
      mberNo: user.mberNo,
      mberNm: user.name,
      createMberId: user.userName,
      updateMberId: user.userName,
      content: text[feedNo],
      delYn: "N",
    };
    await axios.put(url, req);
    await addComment(feedNo);
  };

  // 피드 삭제
  const handleDeleteModal = (target, category) => {
    const isFeed = category === "feed";
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `${isFeed ? "피드" : "댓글"} 삭제`,
      message: [`${isFeed ? "피드를" : "댓글을"} 삭제하시겠습니까?`],
      handleConfirm: () => (isFeed ? deleteFeed(target) : deleteComment(target)),
      handleCancel: () => closeModal(),
    });
  };

  // 피드 삭제
  const deleteFeed = async (target) => {
    const url = "/api/feedList";
    const req = {
      feedNo: target.feedNo,
      content: target.content,
      imgUrl: target.imgUrl,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    await axios.put(url, req);
    toast.success(`게시글이 삭제되었습니다.`);
    getFeedList(my);
  };

  // 피드 댓글 삭제
  const deleteComment = async (target) => {
    const url = "/api/feedRecomment";
    const req = {
      feedRecommentNo: target.feedRecommentNo,
      feedNo: target.feedNo,
      content: target.content,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    await axios.put(url, req);
    toast.success(`댓글이 삭제되었습니다.`);
    addComment(target.feedNo);
  };

  // 피드 수정
  const editFeed = (target) => {
    navigate(`/group/feed/write`, {
      state: {
        feed: target,
      },
    });
  };

  const tabs = [
    { name: "새로고침", icon: LuRefreshCw, func: () => getFeedList(my) },
    { name: "새글작성", icon: HiMiniPencilSquare, func: () => navigate("/group/feed/write") },
  ];

  const tabClick = (idx) => {
    tabs[idx].func();
  };

  return (
    <GroupContainer>
      {modal}
      {detailImages && detailImages.length > 0 && (
        <ImageDetailViewer images={detailImages} setIsDetail={setDetailImages} />
      )}
      {isLoading && <Loading />}
      <div className="inner">
        <PageInfo title="MOIM - 협력과 소통의 공간" />
        {!my.length ? (
          <NoData>가입된 모임이 존재하지 않습니다.</NoData>
        ) : !feedList || !feedList.length ? (
          <NoData>소식이 존재하지 않습니다.</NoData>
        ) : (
          <>
            <HamburgerMenu tabs={tabs} tabClick={tabClick} />
            <FeedListContainer>
              {feedList.map((feed, index) => (
                <FeedItem
                  index={index}
                  feedRefs={feedRefs}
                  feed={feed}
                  setFeedList={setFeedList}
                  setIsBigImages={setDetailImages}
                  text={text}
                  setText={setText}
                  active={active}
                  setActive={setActive}
                  handleDeleteModal={handleDeleteModal}
                  editFeed={editFeed}
                  onSubmit={onSubmit}
                />
              ))}
            </FeedListContainer>
          </>
        )}
      </div>
    </GroupContainer>
  );
};

export default Feeds;
