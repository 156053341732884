import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { GrNotification } from "react-icons/gr";
import { SlGrid } from "react-icons/sl";
import { RxExit } from "react-icons/rx";

import { SchdulerHeader } from "../styled/scheduler";
import TooltipSideMenu from "../../../../../common/tooltipSideMenu";

export default function Header({ setScreenMode }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const [clickSideMenu, setClickSideMenu] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);
  const [, setCompanyInfo] = useState("");

  const openWindow = () => {
    user && localStorage.setItem("user", JSON.stringify(user));
    window.open(`https://1472.ai/mypage/notify`);
  };

  return (
    <SchdulerHeader className={isDarkMode}>
      {clickSideMenu && !isMobile && (
        <TooltipSideMenu
          clickSideMenu={clickSideMenu}
          setClickSideMenu={setClickSideMenu}
          setScreenMode={setScreenMode}
          isMargin={false}
        />
      )}
      <h1>모노티에 오신것을 환영합니다.</h1>
      <div className="info">
        <img src="/img/scheduler/calendar.png" alt="logo" />
        <span>모노티 캘린더</span>
      </div>
      <div className="menus">
        <button onClick={() => navigate("/mypage")}>
          <RxExit />
        </button>
        <button onClick={openWindow}>
          <GrNotification />
        </button>
        <button className="navBtn" onClick={() => setClickSideMenu(!clickSideMenu)}>
          <SlGrid />
        </button>
      </div>
    </SchdulerHeader>
  );
}
