import styled from "styled-components";

export const SearchContainer = styled.div`
  .searchSection {
    margin: 0 auto;
    background: #efefef;
    padding: 20px;
    height: calc(100vh - 73px);
  }
  .searchInner {
    background: #fff;
    padding: 15px;
    border-radius: 5px;
    width: 100%;
    height: 100%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    overflow-x: hidden;
    overflow-y: auto;
    max-width: 1380px;
    margin: auto;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #0074ca;
      border: 2px solid #fff;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ul.tabs {
      display: flex;
      align-items: center;
      li {
        padding: 10px 20px;
        border: 1px solid #efefef;
        background: #fff;
        width: 100px;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
        &.active {
          background: #0074ca;
          border: 1px solid #0074ca;
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .sideMenuButton {
      margin-left: auto;
      display: flex;
      border: none;
      padding: 5px;
      background: rgb(0, 116, 202);
      color: rgb(255, 255, 255);
      font-size: 24px;
      margin-bottom: 20px;
    }
    .searchSection {
      padding: 10px;
      height: 100vh;
    }
    .searchInner {
      padding: 10px;
      ul.tabs li {
        font-size: 12px;
        padding: 10px 0;
      }
    }
  }
`;

export const SearchFormContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 30px 0;
  font-size: 14px;
  align-items: center;
  input[type="text"] {
    border: 1px solid #efefef;
    padding: 10px;
    width: 500px;
    height: 40px;
  }
  button {
    display: flex;
    font-size: 21px;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: 1px solid #0074ca;
    background: #0074ca;
    color: #efefef;
  }
  @media screen and (max-width: 767px) {
    input[type="text"] {
      width: calc(100% - 40px);
    }
  }
`;

export const SearchResults = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  .result {
    margin: 30px 0px;
    background: #ffffff;
    padding: 15px;
    border-radius: 5px;
    border: 1px solid #efefef;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: #efefef;
    .label {
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      gap: 10px;
      span {
        font-size: 18px;
        font-weight: 700;
        strong {
          margin-left: 5px;
          color: #0074ca;
        }
      }
      button {
        border: none;
        background: transparent;
        color: #999;
      }
    }
    ul {
      display: flex;
      gap: 20px;
      li {
        cursor: pointer;
        width: calc((100% - 40px) / 3);
        border-radius: 5px;
        padding: 15px;
        background: #fff;
      }
    }
    .stockList ul li {
      width: calc((100% - 80px) / 5);
    }
  }
  @media screen and (max-width: 1050px) {
    .result.stockList ul li {
      width: calc((100% - 40px) / 3);
    }
  }
  @media screen and (max-width: 767px) {
    .result {
      padding: 10px;
      ul {
        flex-wrap: wrap;
        li {
          width: 100%;
          padding: 10px;
          font-size: 12px;
        }
      }
      &.stockList ul li {
        width: 100%;
      }
    }
  }
`;

export const FeedPreviewContainer = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  justify-content: end;
  .feedImageInfo {
    width: 100%;
    height: 150px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 5px;
    }
    div {
      width: 100%;
      height: 100%;
      background: #ddd;
    }
  }
  .feedInfo {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    span.groupName {
      width: max-content;
      background: #0074ca;
      padding: 2px 10px;
      color: #fff;
      border-radius: 10px;
    }
    .content {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
    }
    .mber {
      display: flex;
      gap: 10px;
      align-items: center;
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }
  }
`;

export const GroupItemContainer = styled.li`
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: contain;
  }
  p {
    font-size: 16px;
    width: calc(100% - 110px);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  @media screen and (max-width: 767px) {
    p {
      font-size: 14px;
    }
  }
`;

export const ProjecItemContainer = styled.li`
  padding: 10px;
  border-radius: 5px;
  width: calc(20% - 18px);
  position: relative;
  cursor: pointer;
  p {
    margin: 30px 0px;
    font-size: 16px;
    font-weight: 600;
  }
  .userLength {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 15px;
    font-size: 20px;
    span {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 767px) {
    p {
      margin: 15px 0px;
      font-size: 14px;
    }
  }
`;

export const SchedulerItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
  .info {
    font-size: 16px;
    font-weight: 600;
  }
  .org {
    display: flex;
    align-items: center;
  }
  .date {
    color: #999;
    font-size: 12px;
  }
`;

export const StockItemContainer = styled.li`
  display: flex;
  flex-direction: column;
  gap: 10px;
  .preview {
    border-radius: 5px;
    overflow: hidden;
    img {
      width: 100%;
    }
  }
  .product {
    display: flex;
    flex-direction: column;
    .prductNm {
      font-size: 16px;
      font-weight: 600;
    }
    span:last-child {
      width: max-content;
      background: rgb(0, 116, 202);
      padding: 2px 10px;
      color: rgb(255, 255, 255);
      border-radius: 10px;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    flex-direction: row;
    align-items: center;
    .preview {
      width: 100px;
      height: 100px;
    }
    .product {
      width: calc(100% - 110px);
      .prductNm {
        font-size: 14px;
      }
    }
  }
`;

export const SearchedContainer = styled.div`
  margin: 30px 0px;
  background: #ffffff;
  padding: 15px;
  border-radius: 5px;
  .noData {
    text-align: center;
    font-size: 20px;
    margin-top: 100px;
  }
  .label {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    span {
      font-size: 18px;
      font-weight: 700;
      strong {
        margin-left: 5px;
        color: #0074ca;
      }
    }
  }
  ul {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    li {
      cursor: pointer;
      width: calc((100% - 40px) / 3);
      border-radius: 5px;
      padding: 15px;
      background: #fff;
      border: 1px solid #efefef;
    }
  }
  &.stockList ul li {
    width: calc((100% - 80px) / 5);
  }
  @media screen and (max-width: 1050px) {
    &.stockList ul li {
      width: calc((100% - 40px) / 3);
    }
  }
  @media screen and (max-width: 767px) {
    ul li,
    &.stockList ul li {
      width: 100%;
    }
  }
`;
