import styled from "styled-components";

export const CreateFeedContainer = styled.div`
  max-width: 700px;
  margin: 0 auto;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 50px 0 0;
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
  margin-bottom: 20px;
  > div {
    display: flex;
    gap: 20px;
    align-items: center;
    span {
      font-size: 20px;
      font-weight: 700;
    }
    select {
      cursor: pointer;
      padding: 0 10px;
      min-width: 100px;
      border: 1px solid #cacaca;
      outline: none;
      height: 30px;
      &:focus {
        border: 1px solid #cacaca !important;
        box-shadow: none;
      }
    }
  }
  label {
    width: 50px;
    height: 30px;
    background: #333;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: larger;
    border-radius: 5px;
    cursor: pointer;
    input[type="file"] {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    display: block;
    position: relative;
    font-size: 14px;
    > div {
      gap: 10px;
      flex-direction: column;
      align-items: baseline;
    }
    label {
      position: absolute;
      bottom: 20px;
      right: 0;
    }
    select {
      max-width: calc(100% - 70px);
    }
  }
`;

export const FormContainer = styled.div`
  background: #fff;
  border: 1px solid #cacaca;
  border-radius: 10px;
  .images,
  .content,
  .writer {
    padding: 10px;
  }
  .writer {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    border-bottom: 1px solid #cacaca;
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }
    .nickNm {
      font-weight: 700;
      margin: 0 5px;
    }
    p {
      color: #999;
      display: inline-block;
    }
  }
  .images {
  }
  .content {
    border-top: 1px solid #cacaca;
    textarea {
      width: 100%;
      height: 150px;
      resize: none;
      border: none;
      &:focus {
        border: none !important;
        box-shadow: none;
      }
    }
  }
`;

export const ActionsContainer = styled.div`
  margin: 50px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  button {
    height: 50px;
    background: #333;
    border-radius: 10px;
    color: #fff;
    border: none;
    width: 200px;
    height: 50px;
    &:last-child {
      background: rgb(255, 122, 0);
    }
  }
  @media screen and (max-width: 767px) {
    gap: 10px;
    button {
      width: 150px;
      height: 40px;
    }
  }
`;
