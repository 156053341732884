import React, { useEffect, useState } from "react";

import Home from "./detailViewer/home";
import Search from "./detailViewer/search";
import Members from "./detailViewer/members";
import Setting from "./detailViewer/setting";
import Community from "./detailViewer/community";

import { useModal } from "../../../../hooks/useModal";

const DetailViewer = ({
  active,
  members,
  group,
  member,
  setActive,
  getGroupDetail,
  getGroupMembers,
  tabClick,
}) => {
  const { modal, openModal, closeModal } = useModal();
  const [activeIndex, setActiveIndex] = useState(0);
  const [target, setTarget] = useState(null);

  const changeTab = (idx) => {
    setActive(1);
    setActiveIndex(idx);
  };

  const Components = ({ index }) => {
    return [
      <Home
        members={members}
        group={group}
        changeTab={changeTab}
        member={member}
        tabClick={tabClick}
      />,
      <Community
        openModal={openModal}
        closeModal={closeModal}
        group={group}
        active={activeIndex}
        setActive={setActiveIndex}
        target={target}
        setTarget={setTarget}
      />,
      <Search />,
      <Members members={members} group={group} />,
      <Setting
        members={members}
        group={group}
        member={member}
        setActive={setActive}
        getGroupDetail={getGroupDetail}
        getGroupMembers={getGroupMembers}
        openModal={openModal}
        closeModal={closeModal}
      />,
    ][index];
  };

  return (
    <>
      <Components index={active} />
      {modal}
    </>
  );
};

export default DetailViewer;
