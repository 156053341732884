import React from "react";
import { useSelector } from "react-redux";
import { HolidayLayerContainer } from "../styled/common";

const HolidayLayer = ({ holiday, isHolidayOpen }) => {
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  return (
    <HolidayLayerContainer
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          isHolidayOpen();
        }
      }}
      className={isDarkMode}>
      <div className="content">
        <div className="title">
          {holiday.icon && <img src={`/img/scheduler/${holiday.icon}`} alt={holiday.title} />}
          <h2>{holiday.title}</h2>
        </div>
        <div className="desc">{holiday.memo || ""}</div>
      </div>
    </HolidayLayerContainer>
  );
};

export default HolidayLayer;
