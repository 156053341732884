import React from "react";

const Answerform = ({ content, setContent, selected }) => {
  return (
    <div className="answer">
      <div className="label">답변내용</div>
      <textarea
        placeholder="문의 답변을 작성해주세요."
        value={content}
        onChange={(e) => setContent(e.target.value)}
        readOnly={selected.answerNo ? true : false}></textarea>
    </div>
  );
};

export default Answerform;
