import React, { useEffect, useState } from "react";
import { BlogItem, StockItem } from "./searchItem";
import {
  FeedPreviewItem,
  ProjectItem,
  SchedulerItem,
} from "../../../../../mypage/integratedSearch/component/searchItems";
import { useMediaQuery } from "react-responsive";

const Reseult = ({ data, cmpnys, searched, tabChange, active }) => {
  const [imageHeight, setImageHeight] = useState();
  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  const itemTypes = {
    stockList: { label: "e층창고", component: StockItem, tabIndex: 1 },
    blogList: { label: "친구소식", component: BlogItem, tabIndex: 2 },
    scheduleList: { label: "일정", component: SchedulerItem, tabIndex: 3 },
    feedList: { label: "소식", component: FeedPreviewItem, tabIndex: 4 },
    projectList: { label: "프로젝트", component: ProjectItem, tabIndex: 5 },
  };

  const updateImageSize = () => {
    const previewWidth = document.querySelector(".preview")?.offsetWidth;
    setImageHeight(previewWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateImageSize);
    return () => {
      window.removeEventListener("resize", updateImageSize);
    };
  }, []);

  return (
    <>
      {Object.entries(data)
        .filter(([listName]) => active === 0 || itemTypes[listName].tabIndex === active)
        .map(([listName, itemList]) => (
          <div key={listName} className={`result ${listName}`}>
            <div className="label">
              <span>
                {itemTypes[listName].label}
                {itemList.length > 0 && <strong>{itemList.length.toLocaleString()}</strong>}
              </span>
            </div>
            {itemList.length > 0 ? (
              <ul>
                {itemList.slice(0, listName === "stockList" && !isTablet ? 5 : 3).map((item) => {
                  const ItemComponent = itemTypes[listName].component;

                  return (
                    <ItemComponent
                      item={item}
                      {...(listName === "stockList" && { cmpnys })}
                      {...(listName === "feedList" && { tabChange })}
                      {...(listName === "scheduleList" && { searchWord: searched })}
                    />
                  );
                })}
              </ul>
            ) : (
              <div className="not">검색 결과가 존재하지 않습니다.</div>
            )}
          </div>
        ))}
    </>
  );
};

export default Reseult;
