import { useOutletContext } from "react-router";

import Portfolio from "./portfolio";
import CuteBanner from "../../shopping/component/themes/cute/banner";
import ModernBanner from "../../shopping/component/themes/modern/banner";
import SimpleBanner from "../../shopping/component/themes/simple/banner";
import SmBanner from "../../shopping/component/themes/sm/banner";
import ChicBanner from "../../shopping/component/themes/chic/banner";

import { CuteNoticeViewContainer } from "../../shopping/component/styled/cuteTheme";
import { ModernNoticeViewContainer } from "../../shopping/component/styled/modernTheme";
import { ChicPortfolioViewContainer } from "../../shopping/component/styled/chicTheme";
import { DefaultContaienr } from "../../shopping/component/styled/defaultTheme";
import ShoppingSectionSwiper from "../../shoppingSectionSwiper";

const PortfolioViewPage = () => {
  const { theme } = useOutletContext();

  const containers = {
    0: DefaultContaienr,
    1: ChicPortfolioViewContainer,
    2: ModernNoticeViewContainer,
    3: CuteNoticeViewContainer,
    4: CuteNoticeViewContainer,
    sm: CuteNoticeViewContainer,
  };

  const banners = {
    0: ShoppingSectionSwiper,
    1: ChicBanner,
    2: ModernBanner,
    3: CuteBanner,
    4: SimpleBanner,
    sm: SmBanner,
  };

  const getBannerInfo = (theme) => {
    if (theme === "sm") {
      return {
        page: `게시판`,
        message: `고객님을 위한 정책과\n더 나은 서비스를 제공합니다.`,
      };
    }
    return { imgage: "port.png", text: "portfolio" };
  };

  const renderComponent = () => {
    const Container = containers[theme] || DefaultContaienr;
    const Banner = banners[theme];

    return (
      <Container>
        {Banner && <Banner info={getBannerInfo(theme)} />}
        <Portfolio />
      </Container>
    );
  };

  return <>{renderComponent()}</>;
};

export default PortfolioViewPage;
