import React from "react";
import { useNavigate, useOutletContext } from "react-router";

const Total = ({ products, selected }) => {
  const { url, cmpnyNo } = useOutletContext();
  const navigate = useNavigate();

  const calcTotal = () => {
    if (!selected.length) return 0;

    return selected.reduce((acc, id) => {
      const product = products.find((item) => item.id === id);
      return product ? acc + product.price * product.amount : acc;
    }, 0);
  };

  const handleCheckout = () => {
    navigate(`/shop/${url}/order`, { state: { products: [...products] } });
  };

  return (
    <div id="secTable">
      <div class="tb_footer pdt_30 font_18 font600 text_center">
        <div class="total">Total</div>
        <div class="price_detail">
          <dl>
            <dt class="font_color_gray_light">선택상품금액</dt>
            <dd>
              <b class="font900">{calcTotal().toLocaleString()}</b>
              <small>원</small>
            </dd>
          </dl>
          <i class="xi-plus font_30"></i>
          <dl>
            <dt class="font_color_gray_light">총 배송비</dt>
            <dd>
              <b class="font900">0</b>
              <small>원</small>
            </dd>
          </dl>
          <i class="xi-minus font_30"></i>
          <dl>
            <dt class="font_color_gray_light">할인예상금액</dt>
            <dd class="font_color_red">
              <b class="font900">0</b>
              <small>원</small>
            </dd>
          </dl>
        </div>
        <div class="price_sum">
          <dl>
            <dt>주문금액</dt>
            <dd>
              <b class="font900">{calcTotal().toLocaleString()}</b>원
            </dd>
          </dl>
        </div>
        <div class="btns">
          <button class="btn_order font_16 font700" onClick={handleCheckout}>
            <b>{selected.length}</b>건 주문하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default Total;
