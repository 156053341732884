import React from "react";
import { SmBannerContainer } from "../../styled/smTheme";

const SmBanner = ({ info }) => {
  return (
    <SmBannerContainer>
      <img src="/img/shopTheme/sm_banner.png" alt="" />
      <div>
        <strong>{info.page}</strong>
        <span>
          {info.message && info.message.includes("\n")
            ? info.message.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : info.message}
        </span>
      </div>
    </SmBannerContainer>
  );
};

export default SmBanner;
