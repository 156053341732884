import React, { useState } from "react";
import { EditPickupModal } from "../styled/pickUp";
import { Modal, ModalCloseButton } from "../styled/common";
import StampList from "./stampList";
import { IoClose } from "react-icons/io5";
import Confirm from "./confirm";
import StampHistory from "./stampHistory";
import UserInfo from "./userInfo";
import { ExchangeModalContainer } from "../styled/coupon";
import { TbArrowBackUp } from "react-icons/tb";
import { calcRewardAvailable } from "../function/kiosk";

const ExchangeModal = ({
  selected,
  handleModalClose,
  handleExchange,
  viewType,
  setViewType,
  history,
}) => {
  const transformValue = viewType * -100;
  const rewardAvailableCnt = calcRewardAvailable(selected.rewardCount, selected.stampCount);

  const tab = ["스탬프 적립내역", "교환내역"];

  return (
    <ExchangeModalContainer>
      <Modal>
        <div className="modalContent">
          <ModalCloseButton onClick={() => (viewType ? setViewType(0) : handleModalClose())}>
            {viewType ? <TbArrowBackUp /> : <IoClose />}
          </ModalCloseButton>
          {viewType !== 2 && (
            <>
              <div className="modalTit">{tab[viewType]}</div>
              <UserInfo
                selected={selected}
                rewardAvailableCnt={rewardAvailableCnt}
                viewType={viewType}
                setViewType={setViewType}
                tab={tab}
              />
            </>
          )}
          <div
            className="slideWrapper"
            style={{ height: viewType !== 2 ? "calc(100% - 170px)" : "100%" }}>
            <StampList
              selected={selected}
              transformValue={transformValue}
              setViewType={setViewType}
              rewardAvailableCnt={rewardAvailableCnt}
            />
            <StampHistory history={history} selected={selected} transformValue={transformValue} />
            <Confirm
              transformValue={transformValue}
              setViewType={setViewType}
              handleExchange={handleExchange}
            />
          </div>
        </div>
      </Modal>
    </ExchangeModalContainer>
  );
};

export default ExchangeModal;
