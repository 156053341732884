import React from "react";
import { Check } from "../../../../styled/create";

const Thead = ({ isAllChecked, handleSelectAll }) => {
  return (
    <thead>
      <tr>
        <th>
          <Check>
            <input type="checkbox" checked={isAllChecked} onChange={handleSelectAll} />
          </Check>
        </th>
        <th>닉네임</th>
        <th>승인</th>
      </tr>
    </thead>
  );
};

export default Thead;
