import React from "react";
import { HelpItemContainer } from "../../../shopping/component/styled/defaultTheme";
import moment from "moment";
import SecretPost from "../../../../notice/component/helpPage/secretPost";
import { FaChevronDown } from "react-icons/fa6";
import { useOutletContext } from "react-router";

const InquiryItem = ({
  item,
  selected,
  setImages,
  setIsDetail,
  navEditPage,
  handleDeleteInquiry,
  selectItem,
}) => {
  const isSelected = (item) => selected && item && item.id === selected.id;

  return (
    <>
      <HelpItemContainer onClick={() => selectItem(item.id)} className="question">
        <span className="index">{item.id}</span>
        <span className="title">{item.title}</span>
        <div className="status">
          <span className={item.status ? "completed" : "pending"}>
            {item.status ? "답변완료" : "대기"}
          </span>
        </div>
        <span className="date">{moment(item.updateAt || item.createAt).format("YYYY-MM-DD")}</span>
        <span className="toggle">
          <FaChevronDown />
        </span>
      </HelpItemContainer>
      <HelpItemContainer className={`content${isSelected(item) ? " open" : ""}`}>
        {isSelected(item) && (
          <SecretPost
            selected={selected}
            setImages={setImages}
            setIsDetail={setIsDetail}
            navEditPage={navEditPage}
            handleDeleteInquiry={handleDeleteInquiry}
          />
        )}
      </HelpItemContainer>
    </>
  );
};

export default InquiryItem;
