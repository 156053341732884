import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import { useSelector } from "react-redux";
import { CreateContainer } from "../../../styled/create";
import ImageUploader from "../../../create/imageUploader";
import Form from "../../../create/form";
import { EditGroupContainer } from "../../../styled/groupDetails";

const EditGroup = ({ group, getGroupDetail, setActive, openModal, closeModal }) => {
  const user = useSelector((state) => state?.user?.data[0]);

  const [formData, setFormData] = useState({});
  const [isGbnChk, setIsGbnChk] = useState(group.gbn ? true : false);
  const [image, setImage] = useState();
  const [isDisplay, setIsDisplay] = useState(group.displayYn === "Y");
  const [duplGroupName, setDuplGroupName] = useState(false);
  const [isApprovalUse, setIsApprovalUse] = useState(group.approvalUseYn === "Y");

  useEffect(() => {
    setFormData({ ...group });
  }, []);

  const changeInput = (e) => {
    const { name, value } = e.target;

    if (name === "groupName" && duplGroupName) {
      setDuplGroupName(false);
    }

    setFormData((data) => ({ ...data, [name]: value }));
  };

  // 이미지 추가
  const addImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file) {
      reader.readAsDataURL(file);
    }

    reader.onloadend = () => {
      const imageUrl = reader.result;
      setFormData((data) => ({
        ...data,
        imgUrl: imageUrl,
      }));

      setImage(file);
      e.target.value = "";
    };
  };

  // 이미지값 반환
  const uploadLogoImage = async (item) => {
    const form = new FormData();
    form.append("file", item);
    form.append("ids", "N");
    form.append("mberNo", user.mberNo);

    const url = "/api/uploadFile";
    const res = await axios.post(url, form);

    return res?.data?.responseData?.fileName;
  };

  const checkSubmitCond = () => {
    if (!formData.groupName) {
      toast.error("모임 이름을 설정해주세요.");
      return;
    }

    if (isGbnChk && !formData.gbn) {
      toast.error("명칭을 설정해주세요.");
      return;
    }

    if (!duplGroupName && formData.groupName !== group.groupName) {
      toast.error("모임명 중복 확인을 해주세요.");
      return;
    }

    openModal({
      isOpen: true,
      type: "confirmation",
      title: `모임 수정`,
      message: ["모임을 수정하시겠습니까?"],
      handleConfirm: () => onSubmit(),
      handleCancel: () => closeModal(),
    });
  };

  // 저장
  const onSubmit = async () => {
    const url = "/api/group";
    let body = {
      ...formData,
      groupNo: formData.groupNo,
      groupName: formData.groupName,
      createMberId: user.userName,
      updateMberId: user.userName,
      mberNo: user.mberNo,
      mberNm: user.userName,
      delYn: formData.delYn,
      delDt: formData.delDt,
      gbn: isGbnChk ? formData.gbn : null,
      imgUrl: formData.imgUrl,
      displayYn: isDisplay ? "Y" : "N",
      approvalUseYn: !isDisplay || isApprovalUse ? "Y" : null,
    };

    if (image) {
      const uploaded = await uploadLogoImage(image);
      if (!uploaded) {
        closeModal(false);
        return;
      }
      body.imgUrl = uploaded;
    }

    const emptyValues = Object.keys(body).reduce((acc, key) => {
      acc[key] = !body[key] ? null : body[key];
      return acc;
    }, {});

    body = { ...emptyValues };

    const responses = await axios.put(url, body);
    const data = responses.data;
    if (data.success && Object.keys(data.rtnModel).length > 0) {
      await getGroupDetail();
      setActive(0);
      toast.success("모임 정보가 변경되었습니다.");
      closeModal();
    }
  };

  // 모임명 중복체크
  const checkDuplGroupName = async () => {
    if (!formData.groupName) {
      toast.error("모임명이 존재하지 않습니다.");
      return;
    }

    const url = "/api/duplGroupName";
    const body = {
      groupName: formData.groupName,
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      if (res.data.success) {
        toast.success("사용 가능한 모임명입니다.");
        setDuplGroupName(true);
      } else {
        toast.error("이미 사용 중인 모임명입니다.");
        setDuplGroupName(false);
      }
    }
  };

  return (
    <>
      <EditGroupContainer>
        <CreateContainer>
          <ImageUploader
            addImage={addImage}
            formData={formData}
            setFormData={setFormData}
            setImage={setImage}
          />
          <Form
            formData={formData}
            isGbnChk={isGbnChk}
            setIsGbnChk={setIsGbnChk}
            isDisplay={isDisplay}
            setIsDisplay={setIsDisplay}
            isApprovalUse={isApprovalUse}
            setIsApprovalUse={setIsApprovalUse}
            checkDuplGroupName={checkDuplGroupName}
            changeInput={changeInput}
          />
          <div className="submit">
            <button onClick={checkSubmitCond}>확인</button>
          </div>
        </CreateContainer>
      </EditGroupContainer>
    </>
  );
};

export default EditGroup;
