import React from "react";
import * as S from "../styled";
import { useNavigate } from "react-router";

const SearchSection = ({ title, items, url }) => {
  const isProduct = title === "상품";
  const navigate = useNavigate();

  return (
    <>
      <S.TitleWrap style={{ marginTop: "1rem" }}>
        <S.TitleText className="title">{title}</S.TitleText>
      </S.TitleWrap>
      <section id="secList" className="pdt_30" style={{ justifyContent: "left" }}>
        {items.map((item, index) => (
          <div className="col_3" key={index}>
            <a
              onClick={() =>
                navigate(`/shop/${url}/${isProduct ? "productsView" : "portfolioView"}/${item.id}`)
              }>
              <div className="img">
                <img
                  src={imgHandler(isProduct ? item.media : item.imgUrl)}
                  alt={isProduct ? item.name : item.title}
                  onError={(e) => (e.target.src = "/default-image.png")}
                />
              </div>
              <div>
                <p className="font_18 font600 pdt_10 pdb_20">{item.title || item.name}</p>
                {isProduct && <p className="font_16">{item.price.toLocaleString()}원</p>}
              </div>
            </a>
          </div>
        ))}
      </section>
    </>
  );
};

export default SearchSection;
