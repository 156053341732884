import styled from "styled-components";

export const MemberListContainer = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  &.dark {
    .viewer {
      ul li {
        color: rgba(255, 255, 255, 0.7);
        &.label {
          color: #fff;
        }
      }
    }
    .members ul li.label .work i {
      background: rgb(12, 12, 12);
    }
    .pagination li {
      background: rgb(44, 45, 48);
    }
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    .add {
      font-size: 26px;
      height: 26px;
      border: none;
      background: transparent;
    }
  }
  .members {
    font-size: 28px;
    color: rgba(0, 0, 0, 0.5);
    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 70px;
        text-align: center;
        &.label {
          font-weight: 700;
          .work {
            display: flex;
            justify-content: center;
            gap: 5px;
            i {
              width: 33px;
              height: 33px;
              font-style: normal;
              display: block;
              background: #ccc;
              border-radius: 50%;
              position: relative;
              cursor: pointer;
              &::after {
                content: "근무 중인 직원에게만 알림이 전송됩니다.";
                position: absolute;
                bottom: 110%;
                font-size: 12px;
                right: 50%;
                transform: translateX(30%);
                opacity: 0;
                transition: 0.3s;
                width: 200px;
                padding: 3px;
                border-radius: 5px;
                font-weight: 300;
              }
              &:hover::after {
                background: rgba(0, 0, 0, 0.5);
                color: #fff;
                opacity: 1;
              }
            }
          }
        }
        &:not(.label) {
          .dot {
            div {
              margin: 0 auto;
              width: 10px;
              height: 10px;
              background: #6648ff;
              border-radius: 50%;
            }
            &::after {
              content: "";
              position: absolute;
              width: 1px;
              height: 70px;
              top: 50%;
              left: 50%;
              transform: translateX(-50%);
              background: #5c5c5c;
            }
          }
          &:last-child .dot::after {
            display: none;
          }
        }
        .dot {
          width: 10%;
          position: relative;
        }
        .mberNm {
          width: 20%;
        }
        .username {
          width: 20%;
        }
        .moblphonNo {
          width: 30%;
        }
        .delete {
          width: 10%;
          button {
            background: transparent;
            border: none;
            svg {
              display: block;
              margin: auto;
            }
          }
        }
        .work {
          width: 20%;
          .tabs {
            width: 36px;
            height: 20px;
            border-radius: 20px;
            position: relative;
            margin: auto;
            cursor: pointer;
            .glider {
              display: block;
              width: 16px;
              height: 16px;
              position: absolute;
              top: 50%;
              left: 2px;
              background: #fff;
              cursor: pointer;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  .pagination {
    margin-top: 100px;
  }
  @media screen and (max-width: 767px) {
    .viewer {
      padding: 20px;
      .info {
        margin-bottom: 20px;
      }
      .members {
        font-size: 12px;
        ul li {
          height: 50px;
          .work {
            width: 25%;
            i {
              width: 16px;
              height: 16px;
              font-size: 13px;
            }
          }
          &.label .work i::after {
            right: 0;
            transform: translateX(13%);
            font-size: 12px;
          }
          &:first-child {
            font-size: 14px;
          }
          .dot {
            display: none;
          }
        }
      }
    }
  }
  .pagination {
    margin-top: 50px;
  }
`;

export const SearchFormContainer = styled.div`
  width: 450px;
  position: relative;
  height: 50px;
  input {
    font-size: 20px;
    width: 100%;
    height: 100%;
    padding: 0 60px 0 20px;
    border: none;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 24px;
    width: 50px;
    height: 50px;
    display: flex;
    border: none;
    background: transparent;
    color: rgba(0, 0, 0, 0.7);
    svg {
      display: block;
      margin: auto;
    }
  }
  @media screen and (max-width: 767px) {
    width: calc(100% - 40px);
    height: 30px;
    input {
      font-size: 12px;
      padding: 0px 40px 0px 10px;
    }
    button {
      width: 30px;
      height: 30px;
    }
  }
`;

export const CompanyInviteContainer = styled.div`
  &.dark {
    div.modal .search input[type="text"] + button {
      background: #3d3d3d;
    }
    div.modal .searched ul li {
      color: #fff;
    }
  }
  div.modal {
    width: 482px;
    height: 478px;
  }
  .closeButton {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 24px;
    button {
      border: none;
      background: transparent;
    }
    svg {
      display: block;
    }
  }
  .search {
    font-size: 20px;
    span {
      margin: 25px 0;
      display: block;
      letter-spacing: -1px;
    }
    label {
      display: flex;
    }
    input[type="text"] {
      width: calc(100% - 130px);
      height: 55px;
      margin-right: 10px;
      padding: 0 20px;
      border-radius: 10px;
      border: 1px solid #a9a9a9;
      &:focus {
        border: 1px solid #a9a9a9 !important;
        box-shadow: none;
      }
    }
    input[type="text"] + button {
      width: 120px;
      height: 55px;
      border-radius: 10px;
      background: #e5e5e5;
      border: 1px solid transparent;
      font-size: 24px;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
  .searched {
    height: calc(100% - 191px);
    margin-top: 25px;
    ul {
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #a8a8a8;
        border-radius: 10px;
        border: 1px solid #d9d9d9;
      }
      &::-webkit-scrollbar-track {
        background-color: #d9d9d9;
        border-radius: 10px;
      }
      li {
        text-align: center;
        justify-content: space-between;
        font-size: 20px;
        font-weight: 300;
        color: #7d7d7d;
        height: 50px;
        line-height: 50px;
        display: block;
        > div {
          display: inline-block;
        }
        &:first-child {
          font-weight: 500;
          color: #000;
        }
        .mberNm {
          width: 60px;
        }
        .moblphonNo {
          width: calc(100% - 150px);
        }
        .invite {
          width: 70px;
          button {
            background: #0b57d0;
            color: #fff;
            border-radius: 20px;
            font-size: 14px;
            width: 50px;
            height: 30px;
            border: none;
          }
        }
      }
    }
    .msg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 20px;
      span {
        font-size: 16px;
        color: #7d7d7d;
        text-align: center;
      }
      button {
        font-size: 18px;
        background: #0b57d0;
        border: none;
        padding: 10px 30px;
        color: #fff;
        border-radius: 30px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    div.modal {
      width: 255px;
      height: 300px;
      .modalContent {
        padding: 20px;
        .label {
          font-size: 20px;
          height: 25px;
        }
      }
      .closeButton {
        top: 20px;
        right: 20px;
      }
      .search {
        font-size: 14px;
        span {
          margin: 15px 0;
        }
        input[type="text"] {
          padding: 0 10px;
          width: calc(100% - 60px);
          height: 32px;
        }
        input[type="text"] + button {
          width: 50px;
          height: 32px;
        }
      }
      .searched {
        height: calc(100% - 120px);
        margin-top: 15px;
        .msg {
          span {
            font-size: 14px;
          }
          button {
            font-size: 14px;
            padding: 7px 20px;
          }
        }
        ul li {
          font-size: 12px;
          height: 30px;
          line-height: 30px;
          .mberNm {
            width: 40px;
          }
          .moblphonNo {
            width: calc(100% - 100px);
          }
          .invite {
            width: 50px;
            button {
              width: 40px;
              height: 20px;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
`;

export const CompanyAddFormContainer = styled.div`
  &.dark {
    ul li strong {
      color: rgba(255, 255, 255, 0.7);
    }
    .viewer {
      p em {
        color: #fff;
      }
      color: #eee;
      ul li {
        input[type="text"] {
          background: rgba(255, 255, 255, 0.1);
          color: #eee;
        }
        button {
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
        }
        &.ss label .checkmark {
          border: 2px solid rgba(255, 255, 255, 0.5);
        }
        &.ss label input[type="checkbox"]:checked ~ .checkmark {
          background: #000;
          border: 2px solid #000;
        }
        &.ss label input[type="checkbox"]:checked ~ .checkmark::after {
          border-color: #fff;
        }
      }
    }
  }
  max-width: 1440px;
  padding: 0px 20px;
  margin: 0 auto;
  font-size: 24px;
  .viewer {
    color: rgba(0, 0, 0, 0.5);
    letter-spacing: -1px;
    p {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 30px;
      gap: 10px;
      em {
        font-style: normal;
        color: #000;
      }
      span {
      }
    }
    ul {
      li {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 30px;
        button {
          width: 160px;
          height: 63px;
          background: #e5e5e5;
          border-radius: 23px;
          border: none;
          color: rgba(0, 0, 0, 0.5);
        }
        strong {
          display: block;
          font-size: 28px;
          width: 100%;
          em {
            font-weight: normal;
            font-style: normal;
            display: inline-block;
            margin-left: 5px;
          }
        }
        input[type="file"] {
          display: none;
        }
        input[type="text"] {
          width: 100%;
          border: none;
          height: 63px;
          border-radius: 20px;
          padding: 0 20px;
          background: rgba(255, 255, 255, 0.5);
          &:focus {
            border: none !important;
            box-shadow: none;
          }
        }
        &.adres {
          div {
            width: 100%;
            gap: 20px;
            display: flex;
            input {
              width: calc(100% - 180px);
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.ss {
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            input[type="checkbox"] {
              display: none;
            }
            .checkmark {
              display: inline-block;
              width: 26px;
              height: 26px;
              border: 2px solid #fff;
              border-radius: 5px;
              background-color: transparent;
              transition: all 0.3s ease;
              position: relative;
              cursor: pointer;
              &:after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(45deg) scale(0);
                opacity: 0;
                width: 10px;
                height: 17px;
                border: solid #fff;
                border-width: 0px 2px 2px 0px;
                transition: 0.3s;
              }
            }
            input[type="checkbox"]:checked ~ .checkmark {
              background: #fff;
              &:after {
                opacity: 1;
                transform: translate(-50%, -60%) rotate(45deg) scale(1);
                border-color: rgba(0, 0, 0, 0.5);
              }
            }
            &:hover .checkmark {
              background-color: #efefef;
            }
          }
        }
        &.bizrno {
          input[type="text"] {
            width: calc(100% - 180px);
          }
        }
        &.file {
          label {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 20px;
          }
          span {
            display: inline-block;
            max-width: calc(100% - 180px);
            height: 63px;
            width: 500px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 20px;
            padding: 0 20px;
          }
        }
      }
    }
    .message {
      margin-top: 50px;
      text-align: center;
    }
  }
  @media screen and (max-width: 767px) {
    .viewer {
      p {
        font-size: 14px;
        gap: 5px;
      }
      ul li {
        font-size: 14px;
        margin-bottom: 20px;
        gap: 10px;
        strong {
          font-size: 16px;
        }
        input[type="text"] {
          height: 37px;
          border-radius: 10px;
        }
        button {
          height: 37px;
          width: 96px;
          border-radius: 10px;
        }
        &.bizrno,
        &.adres div {
          gap: 10px;
          input[type="text"] {
            width: calc(100% - 106px);
          }
        }
        &.file {
          label {
            gap: 10px;
          }
          span {
            border-radius: 10px;
            max-width: none;
            height: 37px;
            width: calc(100% - 106px);
          }
        }
      }
      .message {
        font-size: 10px;
        margin-top: 30px;
      }
    }
  }
`;

export const TabMenuContainer = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  margin: 50px auto;
  letter-spacing: -1px;
  &.dark {
    ul li {
      background: rgba(0, 0, 0, 0.5);
      color: rgba(255, 255, 255, 0.5);
      &.active {
        background: rgba(0, 0, 0, 0.9);
        color: #eee;
      }
    }
  }
  ul {
    margin: auto;
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    gap: 20px;
    li {
      background: rgba(255, 255, 255, 0.5);
      border-radius: 15px;
      width: calc((100% - 40px) / 3);
      text-align: center;
      padding: 20px 0;
      color: rgba(0, 0, 0, 0.5);
      transition: 0.3s;
      cursor: pointer;
      &.active {
        background: #fff;
      }
    }
  }
  @media screen and (max-width: 767px) {
    margin: 0 auto 30px;
    ul {
      font-size: 14px;
      gap: 10px;
      width: 80%;
      li {
        width: calc((100% - 20px) / 3);
        padding: 10px 0;
      }
    }
  }
`;

export const AnswerContainer = styled.div`
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  font-size: 24px;
  letter-spacing: -1px;
  color: rgba(0, 0, 0, 0.5);
  &.dark {
    color: #eee;
    .info ul,
    .question .title,
    .question .content,
    .answer textarea,
    .inquiryList {
      color: rgba(255, 255, 255, 0.7);
      background: rgba(255, 255, 255, 0.1);
    }
  }
  .slideWrapper {
    flex-flow: row nowrap;
    display: flex;
    flex: 1;
    width: 100%;
    transition: transform 0.5s ease;
    height: 100%;
    overflow: hidden;
    margin: auto;
    .slideContent {
      flex: 0 0 50%;
      transform: translateX(0);
      flex-basis: 100%;
      flex-shrink: 0;
      transition: 0.3s;
      width: 100%;
      overflow-x: hidden;
      height: 100%;
      box-sizing: border-box;
      overflow: hidden auto;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #0074ca;
        border: 2px solid #fff;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
    }
  }
  .label {
    display: flex;
    position: relative;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 28px;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    button {
      background: transparent;
      border: none;
      svg {
        display: block;
        margin: auto;
      }
    }
  }
  .question {
    margin-bottom: 50px;
    .tit {
      display: block;
      font-size: 20px;
      margin-bottom: 5px;
      /* text-indent: 15px; */
    }
    img {
      max-width: 70%;
    }
  }
  .question,
  .answer {
    textarea,
    .title,
    .content {
      background: rgba(255, 255, 255, 0.5);
      padding: 20px;
      border-radius: 20px;
    }
    .title {
      margin-bottom: 20px;
    }
  }
  .answer {
    textarea {
      display: block;
      resize: none;
      border: none;
      width: 100%;
      min-height: 200px;
      &:focus {
        border: none !important;
        box-shadow: none;
      }
    }
  }
  @media screen and (max-width: 1050px) {
    font-size: 20px;
    .label {
      font-size: 24px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 12px;
    .label {
      font-size: 14px;
    }
    .question {
      margin-bottom: 30px;
      .tit {
        font-size: 14px;
      }
    }
  }
`;

export const InquiryListContainer = styled.div`
  margin: 50px auto;
  letter-spacing: -1px;
  .inquiryList {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 20px;
    font-size: 22px;
    li {
      cursor: pointer;
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
      &.header {
        font-weight: 700;
        .title {
          text-align: center;
        }
      }
      > div {
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.id {
          width: 10%;
        }
        &.title {
          width: 60%;
          text-align: left;
        }
        &.status {
          width: 20%;
          span {
            font-size: 18px;
            padding: 2px 10px;
            border-radius: 10px;
            &.pending {
              color: #f1541d;
              background: #fef6f4;
            }
            &.completed {
              color: #429b8c;
              background: #e7f5f2;
            }
          }
        }
        &.mberNm {
          width: 15%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .inquiryList {
      padding: 10px;
      li {
        font-size: 12px;
        > div {
          &.status {
            width: 25%;
            span {
              font-size: 12px;
            }
          }
          &.mberNm {
            width: 20%;
          }
          &.title {
            width: 50%;
            text-align: center;
          }
        }
      }
    }
  }
`;

export const Ckeckbox = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  input[type="checkbox"] {
    appearance: none;
    background: rgba(0, 0, 0, 0.2);
    width: 60px;
    height: 34px;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      width: 26px;
      height: 26px;
      top: 4px;
      left: 4px;
      background: #fff;
      border-radius: 50%;
      transition: 0.3s;
    }
    &:checked::after {
      transform: translateX(100%);
      background: rgba(0, 0, 0, 0.5);
    }
  }
  @media screen and (max-width: 767px) {
    input[type="checkbox"] {
      width: 42px;
      height: 22px;
      &::after {
        width: 14px;
        height: 14px;
      }
      &:checked::after {
        transform: translateX(150%);
      }
    }
  }
`;
