import React from "react";
import { GroupListContainer } from "../styled/common";
import GroupItem from "./groupItem";

const GroupList = ({ data, type }) => {
  return (
    <GroupListContainer>
      <ul>
        {data.map((item) => (
          <GroupItem item={item} />
        ))}
      </ul>
    </GroupListContainer>
  );
};

export default GroupList;
