import styled from "styled-components";

export const PageContainer = styled.div`
  min-height: calc(100vh - 450px);
  padding: 50px 20px;
  margin: 0 auto;
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin-bottom: 50px;
  img {
    object-fit: contain;
    &.monoti {
      width: 80px;
      height: 80px;
      border-radius: 10px;
      box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    }
    &.shop {
      width: 200px;
      height: 100px;
    }
  }
  span {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    padding: 0 20px;
  }
`;

export const Point = styled.em`
  font-style: normal;
  font-size: 18px;
  display: inline-block;
  color: #0074ca;
`;
