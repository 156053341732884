import React from "react";
import FeedSwiper from "../feedSwiper";
import { FeedContentContainer, FeedSwiperContainer } from "../../styled/common";

const FeedContent = ({ feed, handleClickImage, feedShowMore }) => {
  return (
    <FeedContentContainer>
      {feed.imgUrl && JSON.parse(feed.imgUrl).length > 0 ? (
        <FeedSwiperContainer onClick={() => handleClickImage(feed)}>
          <FeedSwiper img={JSON.parse(feed.imgUrl)} />
        </FeedSwiperContainer>
      ) : (
        <></>
      )}
      <div className="content">{feed.showContent ? feed.realContent : feed.content}</div>
      {feed.realContent && (
        <button onClick={() => feedShowMore(feed.feedNo)} className="more">
          {feed.showContent ? "접기" : "자세히 보기"}
        </button>
      )}
    </FeedContentContainer>
  );
};

export default FeedContent;
