import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import FeedItem from "../../../common/feed/feedItem";
// import Advertisement from "../../../common/feed/advertisement";
import { FeedListContainer, NoData } from "../../../styled/common";
import useOutsideClick from "../../../../../../hooks/useOutsideClick";
import ImageDetailViewer from "../../../../../../common/imageDetailViewer";
import { mergeAuth, mergeComment, mergeGbnValueName } from "../../../function/group";

const Feed = ({ openModal, closeModal, group, target, setTarget }) => {
  const { id } = useParams();

  const menuRef = useRef();

  const feedRefs = useRef([]);
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  const [feedList, setFeedList] = useState([]);
  const [text, setText] = useState({});
  const [active, setActive] = useState();
  const [isBigImages, setIsBigImages] = useState([]);

  useOutsideClick(menuRef, () => setActive(false));
  useEffect(() => {
    if (!group) return;

    getFeedList();
  }, [group]);

  const getFeedList = async () => {
    const url = "/api/grpFeedList";

    const req = {
      gbn: [group.groupNo],
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 100,
      paged: true,
    };

    try {
      const res = await axios.post(url, req);
      const feeds = res?.data?.content || [];

      const comments = await getCommentList();

      const mergedFeeds = await [mergeAuth, mergeComment, mergeGbnValueName].reduce(
        async (accPromise, mergeFn) => {
          const acc = await accPromise;
          return mergeFn(acc, mergeFn === mergeComment ? comments : [group]);
        },
        Promise.resolve(feeds),
      );

      setFeedList(mergedFeeds);

      if (target) {
        const targetIndex = mergedFeeds.findIndex((feed) => feed.feedNo === target);
        if (targetIndex !== -1 && feedRefs.current[targetIndex]) {
          feedRefs.current[targetIndex].scrollIntoView({ behavior: "smooth" });
          setTarget(null);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  // 댓글 조회
  const getCommentList = async (feedNo = null) => {
    const url = "/api/feedRecomment";
    const req = {
      feedNo,
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      delYn: "N",
      paged: false,
    };

    const res = await axios.post(url, req);
    return res?.data?.content;
  };

  // 피드 삭제
  const handleDeleteModal = (target, category) => {
    const isFeed = category === "feed";
    openModal({
      isOpen: true,
      type: "confirmation",
      title: `${isFeed ? "피드" : "댓글"} 삭제`,
      message: [`${isFeed ? "피드를" : "댓글을"} 삭제하시겠습니까?`],
      handleConfirm: () => (isFeed ? deleteFeed(target) : deleteComment(target)),
      handleCancel: () => closeModal(),
    });
  };

  // 피드 수정
  const editFeed = (target) => {
    navigate(`/group/feed/write`, {
      state: {
        feed: target,
        groupNo: id,
      },
    });
  };

  // 피드 댓글 저장
  const onSubmit = async (e, feedNo) => {
    e.preventDefault();
    if (!text[feedNo].trim()) {
      return;
    }
    const url = "/api/feedRecomment";
    const req = {
      feedNo,
      mberNo: user.mberNo,
      mberNm: user.name,
      createMberId: user.userName,
      updateMberId: user.userName,
      content: text[feedNo],
      delYn: "N",
    };
    await axios.put(url, req);
    await addComment(feedNo);
  };

  // 피드 댓글 삭제
  const deleteComment = async (target) => {
    const url = "/api/feedRecomment";
    const req = {
      feedRecommentNo: target.feedRecommentNo,
      feedNo: target.feedNo,
      content: target.content,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    await axios.put(url, req);
    toast.success(`댓글이 삭제되었습니다.`);
    addComment(target.feedNo);
    closeModal();
  };

  // 댓글 추가
  const addComment = async (feedNo) => {
    if (feedNo) {
      const comment = await getCommentList(feedNo);
      const target = feedList.find((item) => item.feedNo === feedNo);
      if (target) {
        target.comments = Array.isArray(comment) ? [...comment] : [];
        setFeedList([...feedList]);
        setText({});
      }
    }
  };

  // 피드 삭제
  const deleteFeed = async (target) => {
    const url = "/api/feedList";
    const req = {
      feedNo: target.feedNo,
      content: target.content,
      imgUrl: target.imgUrl,
      mberNo: user.mberNo,
      mberNm: user.name,
      delYn: "Y",
      createMberId: user.userName,
      updateMberId: user.userName,
    };

    await axios.put(url, req);
    toast.success(`게시글이 삭제되었습니다.`);
    getFeedList();
    closeModal();
  };

  return (
    <>
      {feedList && feedList.length > 0 ? (
        <FeedListContainer>
          {feedList.map((feed, index) => (
            <FeedItem
              index={index}
              feedRefs={feedRefs}
              feed={feed}
              setFeedList={setFeedList}
              setIsBigImages={setIsBigImages}
              text={text}
              setText={setText}
              active={active}
              setActive={setActive}
              handleDeleteModal={handleDeleteModal}
              editFeed={editFeed}
              onSubmit={onSubmit}
              menuRef={menuRef}
            />
          ))}
        </FeedListContainer>
      ) : (
        <NoData>피드가 존재하지 않습니다.</NoData>
      )}
      {isBigImages.length > 0 && (
        <ImageDetailViewer images={isBigImages} setIsDetail={setIsBigImages} />
      )}
    </>
  );
};

export default Feed;
