import React from "react";
import { Check } from "../styled/create";

const Checkbox = ({ checked, setChecked, label, calssName }) => {
  return (
    <Check>
      <input
        type="checkbox"
        id={calssName}
        checked={checked}
        onChange={(e) => setChecked(e.target.checked)}
      />
      <label htmlFor={calssName}>{label}</label>
    </Check>
  );
};

export default Checkbox;
