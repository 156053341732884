import React from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const EventCalendar = ({
  calendarArea,
  onDate,
  handleDateClick,
  sideCalendarRef,
  clickDate,
  data,
  version,
}) => {
  return (
    <div className="calendars" ref={calendarArea}>
      <div className="buttons">
        <button
          onClick={() =>
            handleDateClick(moment(onDate).subtract(1, "months").format("YYYY-MM-01"))
          }>
          <BsChevronLeft />
        </button>
        <button
          onClick={() => handleDateClick(moment(onDate).add(1, "months").format("YYYY-MM-01"))}>
          <BsChevronRight />
        </button>
      </div>
      <FullCalendar
        key={version}
        ref={sideCalendarRef}
        initialDate={onDate}
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, interactionPlugin]}
        contentHeight="auto"
        events={data}
        locale="ko"
        dateClick={clickDate}
        dayCellContent={(args) => moment(args.date).format("D")}
        eventDisplay="list-item"
        dayMaxEvents={3}
      />
    </div>
  );
};

export default EventCalendar;
