import React, { useState } from "react";
import moment from "moment";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { GoAlertFill } from "react-icons/go";
import { DeleteGroupContainer } from "../../../styled/groupDetails";
import { Check } from "../../../styled/create";

const DeleteGroup = ({ group, openModal, closeModal, getGroupDetail, setActive }) => {
  const [undoDelete, _] = useState(group.delDt ? true : false);
  const [isDelConfirm, setIsDelConfirm] = useState(false);
  const user = useSelector((state) => state?.user?.data[0]);

  // 삭제 및 되돌리기
  const onSubmit = async (state) => {
    if (!isDelConfirm && !undoDelete) {
      toast.error("삭제 절차에 동의하셔야 삭제가 진행됩니다.");
      return;
    }

    const url = "/api/group";
    const body = {
      ...group,
      mberNo: user.mberNo,
      mberNm: user.userName,
    };

    if (state === "delete") {
      body.delDt = moment().add(7, "days").format("YYYY-MM-DD HH:mm:ss");
    } else {
      body.delDt = null;
    }

    const res = await axios.put(url, body);
    const data = res.data;
    if (data.success && Object.keys(data.rtnModel).length > 0) {
      toast.success(`삭제 신청이${undoDelete ? " 취소" : " "}되었습니다.`);
      getGroupDetail();
      closeModal();
      setActive(0);
    }
  };

  const checkDelete = () => {
    if (!undoDelete && !isDelConfirm) {
      toast.error("삭제 절차를 진행하려면 동의가 필요합니다.");
      return;
    }
    openModal({
      isOpen: true,
      type: "confirmation",
      title: undoDelete ? "삭제 취소" : "그룹 삭제",
      message: [`모임 삭제를 ${undoDelete ? "취소" : "신청"}하시겠습니까?`],
      handleConfirm: () => onSubmit(undoDelete ? "cancel" : "delete"),
      handleCancel: () => closeModal(),
    });
  };

  return (
    <DeleteGroupContainer>
      <div className="msg">
        <div className="title">
          <GoAlertFill />
          <span>모임 삭제 안내</span>
        </div>
        {undoDelete ? (
          <div className="info">
            <p>
              귀하의 모임 삭제 요청이 <strong>성공적으로 접수</strong>되었습니다.
            </p>
            <p>
              현재 모임은 <strong>유예 기간</strong>에 있습니다.
            </p>
            <p>
              이 기간 동안에는 언제든지 삭제 요청을 <strong>철회</strong>하실 수 있습니다.
            </p>
            <p>
              유예 기간이 끝나면, 모임은 <strong>영구적으로 삭제</strong>되며
            </p>
            <p>
              복구는 <strong>불가능</strong>해집니다.
            </p>
          </div>
        ) : (
          <div className="info">
            <p>선택한 모임에 대한 삭제 요청 시</p>
            <p>
              <strong>일주일간의 유예 기간</strong>이 주어집니다.
            </p>
            <p>
              이 기간 동안 삭제 요청을 <strong>취소</strong>할 수 있고
            </p>
            <p>모임 활동을 계속 이어갈 수 있습니다.</p>
            <p>
              유예 기간 종료 후, 모임은 <strong>영구적으로 삭제</strong>되며
            </p>
            <p>
              복구는 <strong>불가능</strong>합니다.
            </p>
          </div>
        )}
      </div>
      <div className="confirm">
        {undoDelete ? (
          <span>삭제 예정일 {moment(group.delDt).format("YYYY년 MM월 D일 HH시 mm분")}</span>
        ) : (
          <Check>
            <label>
              <input
                type="checkbox"
                checked={isDelConfirm}
                onChange={(e) => setIsDelConfirm(e.target.checked)}
              />
              <span>위 내용을 모두 확인하였으며, 모임 삭제 절차에 동의합니다.</span>
            </label>
          </Check>
        )}
      </div>
      <button onClick={checkDelete}>삭제 {undoDelete ? "취소" : "신청"}</button>
    </DeleteGroupContainer>
  );
};

export default DeleteGroup;
