import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMediaQuery } from "react-responsive";
import { Navigation, Autoplay } from "swiper";
import moment from "moment";

import { SlideContainer } from "../styled/common";

import { GrLinkNext, GrLinkPrevious, GrPauseFill, GrPlayFill } from "react-icons/gr";
import { HiMiniChevronRight } from "react-icons/hi2";

const Slide = ({ info, data, clickSlide, type }) => {
  const navigate = useNavigate();
  const swiperRef = useRef();
  const [isAutoplay, setIsAutoplay] = useState(true);

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const isGroup = type === "group";

  const clickSwiperButton = (isPrev) => {
    if (swiperRef.current) {
      if (isPrev) {
        swiperRef.current.swiper.slidePrev();
      } else {
        swiperRef.current.swiper.slideNext();
      }
    }
  };

  const toggleAutoplay = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      if (isAutoplay) {
        swiperRef.current.swiper.autoplay.stop();
      } else {
        swiperRef.current.swiper.autoplay.start();
      }
    }
    setIsAutoplay(!isAutoplay);
  };

  return (
    <SlideContainer>
      <div className="info">
        <span>
          {!isMobile && info.title && info.title.includes("\n")
            ? info.title.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : info.title}
        </span>
        <p>{info.description}</p>
        <div className="actions">
          <button className="nav" onClick={() => info.nav.func()}>
            {info.nav.label}
            <i>
              <HiMiniChevronRight />
            </i>
          </button>
          <div className="slide">
            <button onClick={() => clickSwiperButton(true)}>
              <GrLinkPrevious />
            </button>
            <button onClick={() => toggleAutoplay()}>
              {isAutoplay ? <GrPauseFill /> : <GrPlayFill />}
            </button>
            <button onClick={() => clickSwiperButton()}>
              <GrLinkNext />
            </button>
          </div>
        </div>
      </div>
      {data && data.length > 0 ? (
        <Swiper
          className="mySwiper"
          ref={swiperRef}
          loop={true}
          modules={[Navigation, Autoplay]}
          // navigation={true}
          slidesPerView={isMobile ? 1.5 : isTablet ? 2.4 : 3.7}
          spaceBetween={isMobile ? 20 : isTablet ? 50 : 70}
          autoplay={isAutoplay ? { delay: 4500, disableOnInteraction: false } : false}
          speed={3000}>
          {data.map((item, i) => (
            <SwiperSlide key={i} onClick={() => clickSlide(item)}>
              <div className="image">
                <img src={item.imgUrl ? imgHandler(item.imgUrl) : "/img/group/group.png"} alt="" />
              </div>
              <div className="content">
                <span>{isGroup ? item.groupName : item.title}</span>
                <span>{moment(item.createDt).format("YYYY.MM.DD")}</span>
                <button>자세히보기</button>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <div className="not">{info.notDataMessage}</div>
      )}
    </SlideContainer>
  );
};

export default Slide;
