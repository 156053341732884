import React from "react";
import { PaymentTotalContainer } from "../styled/salesCheck";

const PaymentTotal = () => {
  return (
    <PaymentTotalContainer>
      총 전체금액 700,000원 - 노타이틀 수수료 100,000원 = 총 정산금액 600,000원
    </PaymentTotalContainer>
  );
};

export default PaymentTotal;
