import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

import { Container } from "../styled/mypage";

import PageInfo from "../pageInfo";
import { HomepageFormContainer } from "../homepage/component/styled/homepage";
import { useRegex } from "../../../hooks/useRegex";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

export default function CompaniesPage() {
  const navigate = useNavigate();

  const setting = useSelector((state) => state?.user?.setting);
  const company = useSelector((state) => state?.company?.data);
  const user = useSelector((state) => state?.user?.data[0]);

  const [companys, setCompanys] = useState([]);

  const { BussinessFormat } = useRegex();

  const isTablet = useMediaQuery({ query: "(max-width:1050px)" });

  useEffect(() => {
    checkedDomain();
  }, []);

  const checkedDomain = async () => {
    const url = "/api/domainSettingLoad";

    const promises = company.map((item) => {
      const body = { cmpnyNo: item.cmpnyNo, delYn: "N" };
      return axios.post(url, body);
    });

    try {
      const res = await Promise.all(promises);

      const updatedData = company.map((item) => {
        const response = res.find((r) => r.data.cmpnyNo === item.cmpnyNo);
        return {
          ...item,
          domain: response?.data?.domain,
        };
      });

      updatedData.sort((a, b) => {
        const aHasDomain = a.domain;
        const bHasDomain = b.domain;

        if (aHasDomain && !bHasDomain) return -1;
        if (!aHasDomain && bHasDomain) return 1;
        return 0;
      });

      setCompanys(updatedData);
    } catch (error) {
      console.log(error);
    }
  };

  const openNewTab = (item) => {
    window.open(`https://1472.ai/shop/${item}`);
    user && localStorage.setItem("user", JSON.stringify(user));
  };

  return (
    <Container className={setting?.mode}>
      <div className="content">
        <PageInfo label="회사 목록" />
        <HomepageFormContainer className={setting?.mode}>
          <div className="viewer">
            <table>
              <colgroup>
                <col />
                <col />
              </colgroup>
              <thead>
                <tr>
                  <th>사업자번호</th>
                  <th>홈페이지 관리</th>
                </tr>
              </thead>
              <tbody id="place_tbody">
                {companys.map((item) => (
                  <tr>
                    <td>
                      <span>{item.cmpnyNm}</span>
                      <span>{`(${BussinessFormat(item.bizrno)})`}</span>
                    </td>
                    <td>
                      <button
                        onClick={() => navigate("/mypage/company", { state: { cmpny: item } })}>
                        <i className="fa fa-gear" aria-hidden="true" /> 회사관리
                      </button>
                      {item.domain ? (
                        <Link onClick={() => openNewTab(item.domain)}>
                          <i className="fa fa-laptop" aria-hidden="true" /> 홈페이지 바로가기
                        </Link>
                      ) : (
                        <button
                          onClick={() =>
                            navigate("/mypage/makeDomain", { state: { cmpnyNo: item.cmpnyNo } })
                          }>
                          <i className="fa fa-plus" aria-hidden="true" /> 생성
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </HomepageFormContainer>
      </div>
    </Container>
  );
}
