import React, { useEffect, useState } from "react";
import { Container } from "../styled/mypage";
import { useSelector } from "react-redux";
import PageInfo from "../pageInfo";
import CompanyAddForm from "./component/companyAddPage/companyAddForm";
import { useLocation } from "react-router";
import axios from "axios";

const CompanyAddPage = () => {
  const setting = useSelector((state) => state?.user?.setting);
  const location = useLocation();
  const [formData, setFormData] = useState({
    cmpnyNm: "",
    rprNm: "",
    bizrno: "",
    adres: "",
    dtlAdres: "",
    gbn: "",
  });
  const [stampShop, setStampShop] = useState(null);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isSangsaeng, setIsSangsaeng] = useState(false);
  const [isStamp, setIsStamp] = useState(false);
  const [isPaymentYn, setIsPaymentYn] = useState(false);

  const company = location?.state?.cmpny;

  useEffect(() => {
    if (company) {
      setFormData(company);
    }
    if (company && company.bizrno) {
      setIsDuplicate(true);
    }
    console.log(company);
    if (company && company.gbn === "ss") {
      setIsSangsaeng(true);
      getStampShop(company.cmpnyNo);
    }
  }, []);

  const getStampShop = async (cmpnyNo) => {
    const url = "/api/stamp-shop/list";
    const body = { cmpnyNo };

    try {
      const res = await axios.post(url, body);
      const data = res.data?.[0];
      setStampShop(data);
      setIsStamp(data?.id ? true : false);
      setIsPaymentYn(data.paymentYn === "Y" ? true : false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container className={setting.mode}>
      <div className="content">
        <PageInfo label={`회사 ${company ? "수정" : "추가"}`} />
        <CompanyAddForm
          company={company}
          formData={formData}
          setFormData={setFormData}
          isDuplicate={isDuplicate}
          setIsDuplicate={setIsDuplicate}
          stampShop={stampShop}
          setStampShop={setStampShop}
          isSangsaeng={isSangsaeng}
          setIsSangsaeng={setIsSangsaeng}
          isStamp={isStamp}
          setIsStamp={setIsStamp}
          isPaymentYn={isPaymentYn}
          setIsPaymentYn={setIsPaymentYn}
        />
      </div>
    </Container>
  );
};

export default CompanyAddPage;
