import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import moment from "moment";
import { useNavigate } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { ScheduleSwiperContainer } from "../../../styled/dashboard";
import { isOutsideCmpny } from "../../../../../scheduler/component/function/common";

const ProjectSwiper = ({ data = [], perView = 1 }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  const displayTime = (item) => {
    const type = {
      P: "개인",
      C: "업무",
      G: "모임",
    };

    if (isOutsideCmpny(item, company)) {
      return (
        <>
          <span>{moment(item.createDt).format("M/D")}</span>
          <span className="projectType">외부업체</span>
        </>
      );
    }

    return (
      <>
        <span>{moment(item.createDt).format("M/D")}</span>
        <span className="projectType">{type[item?.gbn]}</span>
      </>
    );
  };

  const isColor = (item) => {
    if (item.pinnedYn === "Y") return "#ffc455";
    if (isOutsideCmpny(item, company)) return "#ed5583";
    if (item.gbn === "C") return "#0074ca";
    if (item.gbn === "G") return "#f06f02";

    return `#${item.color}`;
  };

  return (
    <ScheduleSwiperContainer>
      <Swiper slidesPerView={perView} spaceBetween={10} className="mySwiper">
        {data &&
          data.length > 0 &&
          data
            .sort((a, b) => a.projectNo - b.projectNo)
            .map((item, i) => (
              <SwiperSlide key={i}>
                <div
                  className="scheduleContent"
                  style={{ borderLeft: `5px solid ${isColor(item)}` }}
                  onClick={() => navigate(`/mypage/project/${item.projectNo}`)}>
                  <div className="timeArea">{displayTime(item)}</div>
                  <div className="contentArea">
                    <span
                      style={{ textDecoration: item.completeYn === "Y" ? "line-through" : null }}>
                      {item.projectNm}
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
      </Swiper>
    </ScheduleSwiperContainer>
  );
};

export default ProjectSwiper;
