import React from "react";
import { useOutletContext } from "react-router-dom";
import { CustomLink } from "./style/customLink";

const SectionLink = ({ path = "" }) => {
  const { url } = useOutletContext();

  return (
    <section id="secBrdcrmb" className="font_18 pdb_20 text_right">
      <CustomLink to={`/shop/${url}`} style={{ color: "#000" }}>
        <i className="xi-home-o font_20" style={{ marginTop: "-2px", verticalAlign: "middle" }}></i>
      </CustomLink>
      <span>{path}</span>
    </section>
  );
};

export default SectionLink;
