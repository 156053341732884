import React from "react";
import { useNavigate } from "react-router";
import { FeedHeaderContainer } from "../../styled/common";
import Dropdown from "./dropdown";
import { useSelector } from "react-redux";
import { isAuth } from "../../function/group";

const FeedHeader = ({ feed, id, active, setActive, editFeed, handleDeleteModal, menuRef }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user?.data[0]);

  const renderGbnValue = () => {
    const { groupName, gbnValue, gbnValueName } = feed;

    if (!isNaN(id)) {
      return gbnValue && gbnValueName && !isNaN(gbnValue) ? (
        <span>{gbnValue + gbnValueName}</span>
      ) : null;
    }

    return (
      <>
        <span onClick={() => navigate(`/group/${feed.gbn}`)} style={{ cursor: "pointer" }}>
          &#91;{groupName}&#93;
        </span>
        <span>
          {gbnValue && gbnValueName && !isNaN(gbnValue) ? ` ${gbnValue + gbnValueName}` : ""}
        </span>
      </>
    );
  };

  return (
    <FeedHeaderContainer>
      <div className="writer">
        <img
          src={feed.mberImgPath || "/img/user.png"}
          alt="logo"
          onError={(e) => (e.target.src = "/img/user.png")}
        />
        <div>
          {feed.groupName && renderGbnValue()}
          <span className="nickNm">{feed.nickNm || "닉네임 미설정"}</span>
        </div>
      </div>
      {isAuth(feed, user) && (
        <Dropdown
          active={active}
          feed={feed}
          setActive={setActive}
          editFeed={editFeed}
          handleDeleteModal={handleDeleteModal}
          user={user}
        />
      )}
    </FeedHeaderContainer>
  );
};

export default FeedHeader;
