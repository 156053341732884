import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import { toast } from "react-toastify";

import { categorizeItemsByStatus } from "./component/function/store";

import { PickUpContainer } from "./component/styled/pickup";
import { Container } from "./component/styled/common";

import DesktopOrderView from "./component/pickup/desktopOrderView";
import MobileOrderView from "./component/pickup/mobileOrderView";

const Pickup = ({
  getOrders,
  data,
  checkedOrders,
  mobileActive,
  setMobileActive,
  desktopActive,
  setDesktopActive,
  closeModal,
  openModal,
  selected,
  setSelected,
  selectOrder,
  changeOption,
  selectCompany,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [categorized, setCategorized] = useState({
    PENDING: [],
    CONFIRMED: [],
    READY: [],
    CANCELLED: [],
    COMPLETED: [],
    PICKUP: [],
  });

  useEffect(() => {
    const splitData = categorizeItemsByStatus(data);
    setCategorized(splitData);
  }, [data]);

  // 주문 취소
  const deleteOrder = async (reason, id) => {
    if (!reason) {
      toast.error("주문거부 사유를 선택해주세요.");
      return;
    }

    const url = "/erp/ss/delete";
    const body = { id, actionId: selectCompany.value, cancelReason: reason };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        openModal({
          type: "alert",
          title: "주문 취소",
          message: ["주문이 취소되었습니다."],
          handleConfirm: () => {
            getOrders(selectCompany);
            closeModal(true);
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 주문 수락
  const acceptOrder = async (id, pickupBy) => {
    const url = "/erp/ss/update";
    const body = { id, pickupBy, status: "READY" };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        openModal({
          type: "alert",
          title: "주문 수락",
          message: ["주문이 수락되었습니다."],
          handleConfirm: () => {
            getOrders(selectCompany);
            closeModal(true);
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 픽업 완료
  const updateOrder = async (id) => {
    const url = "/erp/ss/update";
    const body = { id, status: "PICKUP" };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        openModal({
          type: "alert",
          title: "주문 완료",
          message: ["주문이 완료되었습니다."],
          handleConfirm: () => {
            getOrders(selectCompany);
            closeModal(true);
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 상품 준비 완료
  const completedOrder = async (id) => {
    const url = "/erp/ss/update";
    const body = { id, status: "COMPLETED" };

    try {
      const res = await axios.post(url, body);

      if (res.status === 200) {
        openModal({
          type: "alert",
          title: "음식 완료",
          message: ["음식 준비가 완료되었습니다."],
          handleConfirm: () => {
            getOrders(selectCompany);
            closeModal(true);
          },
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // 주문 취소
  const rejectOrder = (item) => {
    openModal({
      type: "select",
      title: "주문 취소",
      handleChangeOption: (option) => changeOption(option),
      message: ["주문거부 사유를 선택해주세요."],
      options: ["재료소진", "조리지연", "가게사정 취소", "요청사항 불가", "직접 입력"],
      rejectionReason: "",
      handleConfirm: (reason) => deleteOrder(reason, item.id),
      handleCancel: () => closeModal(),
    });
  };

  return (
    <Container>
      <PickUpContainer>
        <div className="container">
          {isMobile ? (
            <MobileOrderView
              categorized={categorized}
              selectOrder={selectOrder}
              rejectOrder={rejectOrder}
              acceptOrder={acceptOrder}
              completedOrder={completedOrder}
              openModal={openModal}
              selected={selected}
              setSelected={setSelected}
              updateOrder={updateOrder}
              active={mobileActive}
              setActive={setMobileActive}
              selectCompany={selectCompany}
              checkedOrders={checkedOrders}
            />
          ) : (
            <DesktopOrderView
              categorized={categorized}
              selectOrder={selectOrder}
              rejectOrder={rejectOrder}
              acceptOrder={acceptOrder}
              completedOrder={completedOrder}
              openModal={openModal}
              selected={selected}
              setSelected={setSelected}
              updateOrder={updateOrder}
              active={desktopActive}
              setActive={setDesktopActive}
              checkedOrders={checkedOrders}
              selectCompany={selectCompany}
            />
          )}
        </div>
      </PickUpContainer>
    </Container>
  );
};

export default Pickup;
