import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { toast } from "react-toastify";
import axios from "axios";

import ProjectSideMenu from "./component/common/component/projectSideMenu";
import ProjectHeader from "./component/common/component/projectHeader";
import ProjectDetailView from "./component/projectDetailPage/projectDetailView";

import { ProjectDetailContainer, ProjectSection } from "./component/common/styled/project";
import Modal from "../../../common/modal";
import TooltipSideMenu from "../../../common/tooltipSideMenu";

const ProjectDetailPage = () => {
  const { projectNo } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const user = useSelector((state) => state?.user?.data[0]);
  const company = useSelector((state) => state?.company?.data);

  const isMobile = useMediaQuery({ query: "(max-width:767px)" });

  const [project, setProject] = useState();
  const [projectNotice, setProjecNotice] = useState();
  const [participantList, setParticipantList] = useState([]);
  const [projectRoomList, setProjectRoomList] = useState([]);
  const [participant, setParticipant] = useState();
  const [schedule, setSchedule] = useState([]);
  const [projectInfo, setProjectInfo] = useState({});
  const [active, setActive] = useState(0);
  const [openMenu, setOpenMenu] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [showSideMenu, setShowSideMenu] = useState(false);
  const [clickSideMenu, setClickSideMenu] = useState(false);

  useEffect(() => {
    getProject();
    if (location.search) {
      setActive(2);
    }
  }, [projectNo]);

  // 프로젝트 조회
  const getProject = async () => {
    const url = "/api/projectList";
    const body = {
      projectNo,
      mberNo: user.mberNo,
      delYn: "N",
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      const allProjects = [].concat(
        res.data.cmpnyProjectList || [],
        res.data.fixedProjectList || [],
        res.data.groupProjectList || [],
        res.data.otherProjectList || [],
      );

      const data = allProjects.filter((item) => item.projectNo === parseInt(projectNo))?.[0];

      if (data) {
        setProject(data);
        await getProjectNotiList(data.projectNo);
        await getParticipantList(data);

        setProjectInfo((info) => ({
          ...info,
          projectNo: data.projectNo,
          orgNo: data.orgNo,
          gbn: data.gbn,
          mberNo: data.mberNo,
          projectNm: data.projectNm,
          projectDesc: data.projectDesc,
          publicYn: data.publicYn,
          completeYn: data.completeYn,
          updateMberId: user.userName,
        }));
      } else {
        setProject(null);
        toast.error("해당 프로젝트가 존재하지 않거나 삭제되었습니다.");
        navigate("/mypage/projectList");
      }
    }
  };

  //일정 조회
  const getProjectSchedule = async (project, mber = user) => {
    const url = "/api/scheduleList";
    const body = {
      projectNo: project.projectNo,
      mberNo: user.mberNo,
    };

    if (project.gbn === "C") {
      body.cmpnyNos = [mber.orgNo];
    } else {
      body.groupNos = [project.orgNo];
    }

    const res = await axios.post(url, body);

    if (res.status === 200) {
      setSchedule(res.data);
    }
  };

  // 공지 조회
  const getProjectNotiList = async (projectNo) => {
    const url = "/api/notiList";
    const body = {
      projectNo,
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      setProjecNotice(res.data.rtnModel);
      const notice = res.data.rtnModel;
      setProjectInfo((info) => ({
        ...info,
        title: notice.title,
        content: notice.content,
        fileList: notice.fileList,
      }));
    }
  };

  // 프로젝트 룸 조회
  const getProjectRoom = async (data, mbers) => {
    let orgNo;

    if (data.gbn === "G") {
      orgNo = data.orgNo;
    } else {
      const target = company.find((cmpny) => cmpny.cmpnyNo === data.orgNo);

      if (target) {
        orgNo = target.cmpnyNo;
      } else {
        const matchedMber = mbers.find((mber) =>
          company.some((cmpny) => cmpny.cmpnyNo === mber.orgNo),
        );

        orgNo = matchedMber ? matchedMber.orgNo : null;
      }
    }

    if (!orgNo) {
      return;
    }

    const url = "/api/roomList";
    const body = {
      projectNo,
      orgNo: orgNo,
      mberNo: user.mberNo,
      delYn: "N",
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      setProjectRoomList(res.data);
    }
  };

  // 프로젝트 참여자 조회
  const getParticipantList = async (data) => {
    const url = "/api/participantList";
    const body = {
      projectNo,
      delYn: "N",
      offset: 0,
      pageNumber: 0,
      pageSize: 10,
      paged: false,
    };

    const res = await axios.post(url, body);

    if (res.status === 200) {
      const mberList = res.data.content;
      const mber = mberList.find((participant) => participant.mberNo === user.mberNo);

      console.log(data);

      setParticipant(mber);
      setParticipantList(mberList);

      await getProjectSchedule(data, mber);
      await getProjectRoom(data, mberList);
    }
  };

  const ModalForm = ({ type, item }) => {
    const modalDetails = {
      delete: {
        title: "프로젝트 삭제",
        body: "프로젝트를 삭제하시겠습니까?",
        onConfirm: () => handleDeleteProject(item),
      },
      default: {
        title: "오류",
        body: "알 수 없는 오류가 발생했습니다.",
        onConfirm: () => setModalOpen(false),
      },
    };

    const modalInfo = modalDetails[type] || modalDetails.default;

    return (
      <div>
        <h2 className="font_20 font500 font_color_white">{modalInfo.title}</h2>
        <button onClick={() => setModalOpen(false)} className="hp_step_setting_wrap_close">
          <i className="fa fa-times" aria-hidden="true" />
        </button>
        <span className="font_18 font300 font_color_gray pdt_20 text_center">{modalInfo.body}</span>
        <div style={{ display: "flex", justifyContent: "center", gap: "20px", marginTop: "20px" }}>
          <button
            onClick={() => setModalOpen(false)}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px", background: "#6C757D" }}>
            취소
          </button>
          <button
            onClick={modalInfo.onConfirm}
            className="hp_step_setting_cate_save"
            style={{ padding: "10px 20px", margin: "0px" }}>
            확인
          </button>
        </div>
      </div>
    );
  };

  // 프로젝트 삭제
  const handleDeleteProject = async (item) => {
    const url = "/api/project";
    const body = {
      ...item,
      delYn: "Y",
    };

    const res = await axios.put(url, body);

    if (res.status === 200) {
      toast.success("프로젝트가 삭제되었습니다.");
      navigate("/mypage/projectList");
    }
  };

  const handleModalOpen = (type, item = null) => {
    setModalContent({ type, item });
    setModalOpen(true);
  };

  return (
    <ProjectSection>
      <ProjectDetailContainer>
        <ProjectHeader clickSideMenu={clickSideMenu} setClickSideMenu={setClickSideMenu} />
        <div className="inner">
          <ProjectSideMenu showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />
          <ProjectDetailView
            project={project}
            setProject={setProject}
            projectNotice={projectNotice}
            participantList={participantList}
            projectInfo={projectInfo}
            getProject={getProject}
            active={active}
            setActive={setActive}
            openMenu={openMenu}
            setOpenMenu={setOpenMenu}
            handleModalOpen={handleModalOpen}
            getParticipantList={getParticipantList}
            participant={participant}
            projectRoomList={projectRoomList}
            getProjectRoom={getProjectRoom}
            setShowSideMenu={setShowSideMenu}
            showSideMenu={showSideMenu}
            schedule={schedule}
          />
        </div>
        <Modal
          modalState={modalOpen}
          handleModalState={() => setModalOpen(false)}
          html={<ModalForm type={modalContent?.type} item={modalContent?.item} />}
          w="300px"
          h="auto"
        />
        {clickSideMenu && !isMobile && (
          <TooltipSideMenu
            clickSideMenu={clickSideMenu}
            setClickSideMenu={setClickSideMenu}
            isMargin={false}
          />
        )}
      </ProjectDetailContainer>
    </ProjectSection>
  );
};

export default ProjectDetailPage;
