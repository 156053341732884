import React, { useState } from "react";
import TabMenu from "../../common/tabMenu";
import Feed from "./community/feed";
import Blog from "./community/blog";

const Community = ({ openModal, closeModal, group, active, setActive, target, setTarget }) => {
  const tabs = ["피드", "친구 소식"];

  const tabChange = (idx) => {
    setActive(idx);
  };

  const Components = ({ index }) => {
    return [
      <Feed
        openModal={openModal}
        closeModal={closeModal}
        group={group}
        target={target}
        setTarget={setTarget}
      />,
      <Blog />,
    ][index];
  };

  return (
    <div>
      <TabMenu tabChange={tabChange} tabs={tabs} active={active} isCreate={true} />
      {<Components index={active} />}
    </div>
  );
};

export default Community;
