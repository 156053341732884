import React from "react";
import { TabMenuContainer } from "../styled/company";
import { useSelector } from "react-redux";

const TabMenu = ({ tabMenu, active, setActive }) => {
  const setting = useSelector((state) => state?.user?.setting);

  return (
    <TabMenuContainer className={setting?.mode}>
      <ul>
        {tabMenu.map((ele, idx) => (
          <li className={active === idx ? "active" : "inactive"} onClick={() => setActive(idx)}>
            {ele}
          </li>
        ))}
      </ul>
    </TabMenuContainer>
  );
};

export default TabMenu;
