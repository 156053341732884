import styled from "styled-components";

export const NotDataList = styled.div`
  font-size: 18px;
  text-align: center;
  padding: 15px 0;
`;

//   padding: 0;
//   h1 {
//     display: none;
//   }
//   .headerInner {
//     max-width: 1400px;
//     padding: 10px;
//     margin: auto;
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     position: relative;
//     nav {
//       width: calc(100% - 500px);
//       li {
//         margin-right: 0;
//       }
//     }
//     .logo {
//       height: 40px;
//       object-fit: contain;
//       margin: 0;
//       width: auto;
//     }
//     .login_menu {
//       width: 165px;
//     }
//     .dropdown-menu {
//       width: 150%;
//       padding: 20px 0;
//       li a {
//         padding: 0;
//       }
//     }
//     .headerBtnArea {
//       display: flex;
//       .notify {
//         width: 40px;
//         height: 40px;
//         font-size: 32px;
//         border: none;
//         color: #fff;
//         margin-left: 10px;
//         position: relative;
//         background: #0074ca;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         span {
//           position: absolute;
//           top: 0;
//           right: 0;
//           transform: translate(25%, -25%);
//           background: #bd1e01;
//           font-size: 10px;
//           border-radius: 50%;
//           width: 22px;
//           height: 22px;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//         }
//       }
//       button {
//         width: 40px;
//         height: 40px;
//         font-size: 32px;
//         border: none;
//         color: #fff;
//         margin-left: 10px;
//         svg {
//           display: block;
//           margin: auto;
//         }
//         &:first-child {
//           margin-left: 20px;
//         }
//         &.logout {
//           background: #333;
//           i.icon_logout {
//             background: url("/img/icon_logout.png");
//             background-position: 50% 50%;
//             background-size: cover;
//             width: 100%;
//             height: 100%;
//             display: block;
//           }
//         }
//         &.login {
//           background: #333;
//           i.icon_login {
//             background: url("/img/icon_login.png");
//             background-position: 50% 50%;
//             background-size: cover;
//             width: 100%;
//             height: 100%;
//             display: block;
//           }
//         }
//         &.sideMenu,
//         &.mypage,
//         &.search {
//           background: #0074ca;
//         }
//       }
//     }
//   }
//   @media screen and (max-width: 1200px) {
//     nav {
//       display: none;
//     }
//   }
//   @media screen and (max-width: 768px) {
//     .headerInner {
//       .login_menu {
//         width: auto;
//         transform: translateY(0);
//       }
//       img.logo {
//         width: 100px;
//         height: 35px;
//       }
//     }
//   }
// `;

export const SlideContainer = styled.div`
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 30px;
  display: flex;
  align-items: center;
  padding: 64px 50px;
  .info {
    width: 200px;
    span {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 70px;
      display: block;
    }
    p {
      color: #888;
      margin-bottom: 15px;
      font-size: 18px;
    }
    .nav {
      color: #444;
      font-size: 16px;
      border: none;
      background: transparent;
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 30px;
      i {
        font-size: 20px;
        svg {
          display: block;
          margin: auto;
        }
      }
    }
    .slide {
      button {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: none;
        background: transparent;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
        svg {
          display: block;
          margin: auto;
        }
      }
    }
  }
  .not {
    width: calc(100% - 200px);
    text-align: center;
  }
  .mySwiper {
    width: calc(100% - 200px);
    cursor: pointer;
  }
  .image {
    width: 100%;
    padding: 10px;
    background: #efefef;
    border-radius: 10px;
    img {
      width: 100%;
      aspect-ratio: 1;
      object-fit: contain;
      overflow: hidden;
      border-radius: 10px;
    }
  }

  .not {
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    margin-top: 20px;
    span {
      display: block;
      font-size: 22px;
      font-weight: 700;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:nth-child(1) {
        color: #ff7a00;
      }
      &:nth-child(2) {
        margin-bottom: 20px;
      }
    }
    button {
      font-size: 16px;
      color: #888;
      background: transparent;
      border: none;
    }
  }
  @media screen and (max-width: 1050px) {
    padding: 40px;
    .info span {
      font-size: 26px;
    }
    .content span {
      font-size: 18px;
    }
  }
  @media screen and (max-width: 767px) {
    padding: 20px;
    flex-wrap: wrap;
    .info,
    .mySwiper {
      width: 100%;
    }
    .info {
      span {
        font-size: 20px;
        margin-bottom: 0;
      }
      p {
        font-size: 16px;
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
      }
      .slide button {
        width: 30px;
        height: 30px;
      }
      .nav {
        margin-bottom: 0;
      }
    }
    .content span,
    .content button {
      font-size: 14px;
    }
    .content span:nth-child(2) {
      margin-bottom: 10px;
      font-weight: normal;
    }
  }
`;

export const GroupContainer = styled.div`
  background: #fef9f3;
  min-height: 100vh;
  font-family: "Pretendard", "Prompt";
  letter-spacing: -1px;
  user-select: none;
  .inner {
    position: relative;
    max-width: 1440px;
    padding: 50px 20px;
    margin: 0 auto;
    width: 100%;
  }
  .pagination {
    gap: 15px;
    margin-top: 0;
    li {
      width: auto;
      border: none;
      a {
        font-size: 22px;
      }
      &:hover,
      &.active {
        background: transparent;
        border: none;
        a {
          color: #ff7a00;
          font-weight: 900;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .pagination {
      li a {
        font-size: 16px;
      }
    }
  }
`;

export const PageInfoContainer = styled.div`
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 48px;
      font-weight: 700;
      max-width: calc(100% - 350px);
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      span {
        max-width: 100%;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      i {
        cursor: pointer;
      }
      i + span {
        max-width: calc(100% - 60px);
      }
      svg {
        display: block;
        margin: auto;
      }
    }
    .actions {
      button {
        font-size: 18px;
        font-weight: 700;
        padding: 0 10px;
        height: 50px;
        margin-right: 15px;
        background: #333;
        border-radius: 5px;
        color: #fff;
        border: none;
        &:last-child {
          margin-right: 0;
          background: #ff7a00;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    .info {
      justify-content: baseline;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
      .title {
        max-width: 100%;
        font-size: 34px;
        i {
          font-size: 38px;
        }
        i + span {
          max-width: calc(100% - 50px);
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .info {
      .title {
        font-size: 24px;
        i {
          font-size: 28px;
        }
        i + span {
          max-width: calc(100% - 40px);
        }
      }
      .actions {
        button {
          font-size: 16px;
          height: 40px;
        }
      }
    }
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  border-radius: 25px;
  height: 50px;
  border: 1px solid #cacaca;
  background: #fff;
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  font-size: 18px;
  position: relative;
  img {
    width: 23px;
    height: 23px;
  }
  input {
    width: calc(100% - 33px);
    border: none;
    &:focus {
      border: none !important;
      box-shadow: none;
    }
  }
  &.select {
    input {
      width: calc(100% - 143px);
    }
    .select {
      width: 100px;
    }
    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      font-weight: 700;
    }
    ul {
      position: absolute;
      top: 100%;
      left: 43px;
      width: 100px;
      background: #fff;
      transform: translateY(10px);
      border-radius: 10px;
      padding: 10px 0;
      z-index: 100;
      li {
        padding: 5px 10px;
        transition: 0.3s;
        color: #444;
        cursor: pointer;
        font-weight: 500;
        &:hover,
        &.active {
          background: #efefef;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    &.select {
      .select {
        width: 70px;
      }
    }
    margin-top: 20px;
    font-size: 14px;
  }
`;

export const GroupListContainer = styled.div`
  margin: 40px 0;
  ul {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    li {
      width: calc((100% - 100px) / 6);
      height: 300px;
      border-radius: 20px;
      overflow: hidden;
      background: #fff;
      cursor: pointer;
      .image {
        width: 100%;
        height: calc(100% - 140px);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        padding: 20px;
        font-size: 14px;
        height: 140px;
        span {
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:first-child {
            font-size: 22px;
            margin-bottom: 10px;
            font-weight: 700;
          }
          &:nth-child(2) {
            color: #666;
            margin-bottom: 30px;
          }
        }
        button {
          color: #666;
          border: none;
          background: transparent;
        }
      }
    }
  }
  @media screen and (max-width: 1050px) {
    ul li {
      width: calc((100% - 60px) / 4);
    }
  }
  @media screen and (max-width: 767px) {
    ul {
      gap: 10px;
      li {
        width: calc(50% - 5px);
        height: 200px;
        .image {
          height: calc(100% - 82px);
        }
        .content {
          padding: 10px;
          height: 82px;
          span:first-child {
            font-size: 14px;
            margin-bottom: 0;
          }
          span:nth-child(2) {
            margin-bottom: 15px;
          }
          font-size: 12px;
        }
      }
    }
  }
`;

export const Hamburger = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: #000;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }
  span:nth-child(1) {
    top: 5px;
  }
  span:nth-child(2) {
    top: 10px;
  }
  span:nth-child(3) {
    top: 15px;
  }
  &.open span:nth-child(1) {
    top: 9px;
    transform: rotate(135deg);
  }
  &.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }
  &.open span:nth-child(3) {
    top: 9px;
    transform: rotate(-135deg);
  }
`;

export const ContextMenu = styled.div`
  width: 130px;
  position: fixed;
  transform: translateY(60px);
  z-index: 999;
  ul {
    background: #fff;
    border-radius: 5px;
    padding: 10px 0;
    li {
      font-size: 14px;
      letter-spacing: -1px;
      transition: 0.3s;
      cursor: pointer;
      padding: 5px 10px;
      &:hover {
        background: rgba(217, 217, 217, 0.5);
      }
    }
  }
  @media screen and (max-width: 767px) {
    transform: translateY(50px);
  }
`;

export const TabMenuContainer = styled.div`
  margin: 50px 0;
  font-size: 18px;
  position: relative;
  ul {
    display: flex;
    align-items: center;
    gap: 20px;
    li {
      &.active {
        background: #333;
        color: #fff;
      }
    }
  }
  li,
  button {
    width: 120px;
    background: #ccc;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s;
  }
  button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
  }
  @media screen and (max-width: 1050px) {
  }
  @media screen and (max-width: 767px) {
    margin: 30px 0;
    ul {
      gap: 10px;
      display: flex;
      overflow-x: auto;
      white-space: nowrap;
      &::-webkit-scrollbar {
        width: 1px;
      }
    }
    li,
    button {
      width: 80px;
      flex-shrink: 0;
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
    button {
    }
  }
`;

export const FeedListContainer = styled.ul`
  max-width: 700px;
  margin: 50px auto;
  font-size: 14px;
  > li {
    background: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    border: 1px solid #cacaca;
    /* overflow: hidden; */
    &.advertisement {
      overflow: hidden;
      .images {
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      div:last-child {
        border-bottom: none;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const FeedHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  .writer {
    display: flex;
    font-size: 13px;
    align-items: center;
    gap: 10px;
    width: calc(100% - 32px);
    > div {
      width: calc(100% - 40px);
      display: flex;
      align-items: center;
      gap: 5px;
    }
    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }
    .nickNm {
      max-width: calc(50%);
      width: 100%;
      font-weight: 700;
    }
  }
`;

export const DropdownContainer = styled.div`
  position: relative;
  z-index: 3;
  font-size: 18px;
  padding: 0 10px;
  cursor: pointer;
  svg {
    display: block;
    margin: auto;
  }
  ul {
    position: absolute;
    border: 0;
    left: 50%;
    width: 70px;
    background: #fff;
    text-align: center;
    transform: translateX(-50%);
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 13px;
    li {
      padding: 3px 0;
      cursor: pointer;
      &:hover {
        background: #efefef;
      }
      &:nth-child(2) {
        border-top: 1px solid #ddd;
      }
    }
  }
`;

export const FeedContentContainer = styled.div`
  background: #fff;
  border: 1px solid #cacaca;
  border-left: none;
  border-right: none;
  padding: 15px;
  .content {
    font-size: 14px;
    white-space: pre-wrap;
    margin: 10px 0;
  }
  .more {
    border: none;
    background: transparent;
    color: #999;
    margin: 5px 0;
  }
`;

export const CommentsContainer = styled.div`
  form {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    input[type="text"] {
      width: calc(100% - 40px);
      height: 30px;
      border: none;
      padding: 0 10px;
      &:focus {
        border: none !important;
        box-shadow: none;
      }
    }
    button {
      width: 30px;
      height: 30px;
      border: none;
      color: #fff;
      border-radius: 5px;
      font-size: 20px;
      background: #333;
      transition: 0.3s;
      cursor: default;
      svg {
        display: block;
        margin: auto;
      }
      &.active {
        cursor: pointer;
        background: #ff7a00;
      }
    }
  }
  .more {
    text-align: center;
    background: #efefef;
    border: none;
    font-size: 12px;
    width: 100%;
    padding: 7px;
  }
  .comments {
    border-bottom: 1px solid #efefef;
    li {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        border-radius: 50%;
        overflow: hidden;
        outline: 1px solid #d1d1d1;
      }
      > div {
        width: 70%;
        span:first-child {
          font-weight: 700;
        }
      }
      .delete {
        color: #5e5e5e;
        border: none;
        background: transparent;
        padding: 5px;
        svg {
          display: block;
          margin: auto;
        }
      }
      .date {
        flex-grow: 1;
        text-align: right;
        text-overflow: clip;
        width: 17%;
        font-size: 10px;
      }
    }
  }
`;

export const BlogContainer = styled.div`
  ul {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    li {
      width: calc((100% - 40px) / 3);
      background: #fff;
    }
  }
  @media screen and (max-width: 1050px) {
    ul li {
      width: calc((100% - 20px) / 2);
    }
  }
  @media screen and (max-width: 767px) {
    ul li {
      width: 100%;
    }
  }
`;

export const NoData = styled.div`
  min-height: 500px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export const FeedSwiperContainer = styled.div`
  height: 400px;
  width: 100%;
  .mySwiper {
    width: 100%;
    height: 100%;
  }
  .image {
    width: 100%;
    height: 100%;
  }
  .delete {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
    border: none;
    background: transparent;
    svg {
      display: block;
      margin: auto;
    }
  }
  img {
    object-fit: contain;
    width: 100%;
    height: calc(100% - 30px);
  }
`;
