import React from "react";
import { useLocation, useOutletContext } from "react-router";
import { Modal } from "./modal";
import { IoClose } from "react-icons/io5";
import { useSelector } from "react-redux";

const Confirmation = ({ title, message, handleConfirm, handleCancel }) => {
  const location = useLocation();
  const theme = useOutletContext()?.theme || "";
  const setting = useSelector((state) => state?.user?.setting);

  const isClassName = () => {
    if (theme) {
      return `theme-${theme}`;
    }
    if (location.pathname.includes("mypage") && setting) {
      return setting.mode;
    }
    return "";
  };

  return (
    <Modal className={isClassName()}>
      <div className="modal">
        <div className="info">
          <span>{title}</span>
          <button onClick={handleCancel}>
            <IoClose />
          </button>
        </div>
        <div className="message">
          {message.map((item) => (
            <span>{item}</span>
          ))}
        </div>
        <div className="actions">
          <button onClick={handleCancel}>취소</button>
          <button onClick={handleConfirm}>확인</button>
        </div>
      </div>
    </Modal>
  );
};

export default Confirmation;
