import styled from "styled-components";

const color = {
  refrigerated: `#2a3341`,
  roomTemperature: `#FFE072`,
  carrier: `#cdb5f5`,
};

export const KioskPickUp = styled.div`
  padding: 1.0833vw;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
  .pickupReqButton {
    text-align: right;
    margin-bottom: 10px;
    button {
      padding: 1.9532vh 2vh;
      background: rgb(42, 51, 65);
      color: rgb(255, 255, 255);
      border: none;
      border-radius: 31px;
      font-size: 2.7578vh;
      font-weight: 700;
      width: max-content;
    }
  }
  .content {
    height: calc(100% - 8vw - 8vh);
    width: 100%;
    padding: 0 20px;
    overflow: auto;
    position: relative;
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
  }
  @media screen and (max-width: 767px) {
    .content {
      padding: 0 10px;
    }
    .pickupReqButton {
      button {
        padding: 1.4294vh;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
`;

export const EditPickupModal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  .slideWrapper {
    flex-flow: row nowrap;
    display: flex;
    flex: 1;
    width: 100%;
    transition: transform 0.5s ease;
    height: 100%;
    overflow: hidden;
    margin: auto;
  }
  .slideContent {
    flex: 0 0 50%;
    height: 100%;
    position: relative;
    transform: translateX(0);
    flex-basis: 100%;
    flex-shrink: 0;
    transition: 0.3s;
    width: 100%;
  }
`;

export const Tabs = styled.ul`
  display: flex;
  align-items: center;
  li {
    width: calc(100% / 3);
    height: 9.5238vh;
    background: #d9d9d9;
    color: #4d4d4d;
    font-size: 3.8095vh;
    text-align: center;
    line-height: 9.5238vh;
    font-weight: 700;
    transition: 0.3s;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    &.refrigerated {
      color: #fff;
      background: ${color.refrigerated};
    }
    &.roomTemperature {
      background: ${color.roomTemperature};
    }
    &.carrier {
      background: ${color.carrier};
    }
    &.all {
      background: #333;
      color: #fff;
    }
  }
  @media screen and (max-width: 767px) {
    li {
      height: 6.5741vh;
      line-height: 6.5741vh;
      font-size: 2.7778vh;
    }
  }
`;

export const StorageDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &.between {
    justify-content: space-between;
  }
  &.center {
    justify-content: center;
    gap: 30px;
  }
  .modalTit {
    font-size: 40px;
    font-weight: 700;
  }
  .paymentMethod {
    .con {
      display: flex;
      gap: 10px;
      align-items: center;
      label {
        height: 35px;
        width: 35px;
        position: relative;
        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          padding: 0;
          width: 0;
          height: 0;
        }
        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border: 2px solid #d4d4d4;
          border-radius: 10px;
          background-color: transparent;
          transition: all 0.3s ease;
          &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg) scale(0);
            opacity: 0;
            width: 13px;
            height: 24px;
            border: solid #fff;
            border-width: 0px 3px 3px 0px;
            transition: 0.3s;
          }
        }
        input:checked ~ .checkmark {
          background: ${color.refrigerated};
          border: 1px solid ${color.refrigerated};
          &:after {
            opacity: 1;
            transform: translate(-50%, -65%) rotate(45deg) scale(1);
            border-color: #fff;
          }
        }
        &:hover .checkmark {
          background-color: #efefef;
        }
      }
      label + span {
        margin-right: 10px;
      }
    }
  }
  .userInfo {
    font-size: 24px;
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      font-size: 20px;
      display: flex;
      gap: 10px;
      label {
        input {
          display: none;
        }
        span {
          background: #d9d9d9;
          color: #4d4d4d;
          border-radius: 20px;
          width: 100px;
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        input:checked + span {
          background: #636e81;
          color: #fff;
        }
      }
      button {
        background: #2a3341;
        color: #fff;
        border-radius: 20px;
        width: 100px;
        height: 50px;
        border: none;
        font-weight: 700;
      }
    }
  }
  .storages {
    width: 100%;
    height: calc(100% - 220px);
    overflow-y: auto;
    font-size: 20px;
    .content {
      height: auto;
      max-height: none;
      .content {
        padding: 0;
      }
      h3 {
        border-left: 5px solid;
        padding-left: 5px;
        margin: 10px 0 5px;
        font-size: 24px;
      }
      &.refrigerated h3 {
        border-color: ${color.refrigerated};
      }
      &.roomTemperature h3 {
        border-color: ${color.roomTemperature};
      }
      &.carrier h3 {
        border-color: ${color.carrier};
      }
    }
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c1c1c1;
      border: 2px solid #fafafa;
    }
    &::-webkit-scrollbar-track {
      background-color: #fafafa;
      border-radius: 10px;
    }
    .userStorage > li {
      width: 49%;
      margin-bottom: 2%;
      border: 3px solid ${color.refrigerated};
      padding: 5px;
      position: relative;
      border-radius: 10px;
      display: inline-block;
      &:nth-child(odd) {
        margin-right: 2%;
      }
      ul {
        width: calc(100% - 40px);
        li {
          span {
            display: inline-block;
          }
          .tit {
            width: 150px;
          }
          .con {
            width: calc(100% - 150px);
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 20px;
    .modalTit {
      font-size: 24px;
    }
    .content {
      padding: 0;
    }
    .userInfo {
      flex-direction: column;
      width: 100%;
      align-items: baseline;
      font-size: 20px;
      ul {
        width: 100%;
        font-size: 14px;
      }
      .actions {
        width: 100%;
        margin-top: 10px;
        justify-content: end;
        label span,
        button {
          height: 30px;
          font-size: 14px;
          width: auto;
          padding: 0 5px;
        }
      }
    }
    .storages {
      .content {
        max-height: none;
      }
      .userStorage > li {
        width: 100%;
        font-size: 18px;
        &:nth-child(2n + 1) {
          margin-right: 0;
        }
      }
    }
    .radioBox {
      width: 80vw;
      label {
        width: calc(50% - 10px);
        span {
          display: block;
          padding: 15px 0;
          text-align: center;
        }
      }
    }
  }
`;
