import React from "react";
import { useOutletContext } from "react-router";

import { ChicProductsContainer } from "../shopping/component/styled/chicTheme";
import { ModernProductsContainer } from "../shopping/component/styled/modernTheme";
import { CuteProductsContainer } from "../shopping/component/styled/cuteTheme";
import { SmProductsContainer } from "../shopping/component/styled/smTheme";
import { DefaultContaienr } from "../shopping/component/styled/defaultTheme";
import { SimpleProductsContainer } from "../shopping/component/styled/simpleTheme";

import CuteBanner from "../shopping/component/themes/cute/banner";
import ModernBanner from "../shopping/component/themes/modern/banner";
import ProductListForm from "./component/productListForm";
import SimpleBanner from "../shopping/component/themes/simple/banner";
import SmBanner from "../shopping/component/themes/sm/banner";
import ChicBanner from "../shopping/component/themes/chic/banner";
import ShoppingSectionSwiper from "../shoppingSectionSwiper";

const ProductsList = () => {
  const { theme } = useOutletContext();

  const banners = {
    0: ShoppingSectionSwiper,
    1: ChicBanner,
    2: ModernBanner,
    3: CuteBanner,
    4: SimpleBanner,
    sm: SmBanner,
  };

  const containers = {
    0: DefaultContaienr,
    1: ChicProductsContainer,
    2: ModernProductsContainer,
    3: CuteProductsContainer,
    4: SimpleProductsContainer,
    sm: SimpleProductsContainer,
  };

  const renderContent = () => {
    const getBannerInfo = (theme) => {
      if (theme === "sm") {
        return {
          page: "제품소개",
          message: "에스엠정보통신의 제품을\n 소개해드립니다.",
        };
      }
      return { imgage: "prd.png", text: "product" };
    };

    const Container = containers[theme] || DefaultContaienr;
    const Banner = banners[theme];

    return (
      <Container>
        {Banner && <Banner info={getBannerInfo(theme)} />}
        <ProductListForm />
      </Container>
    );
  };

  return <>{renderContent()}</>;
};

export default ProductsList;
