import React from "react";
import { SmTabMenuContainer } from "../../../shopping/component/styled/smTheme";
import { useOutletContext } from "react-router";

const TabMenu = ({ active, setActive, menus }) => {
  const { theme } = useOutletContext();

  return (
    <SmTabMenuContainer className={`theme-${theme}`}>
      {menus.map((ele, idx) => (
        <li onClick={() => setActive(idx)} className={active === idx ? "active" : ""}>
          {ele}
        </li>
      ))}
    </SmTabMenuContainer>
  );
};

export default TabMenu;
