import React from "react";
import { UserInfoContainer } from "../styled/coupon";

const UserInfo = ({ selected, rewardAvailableCnt, viewType, setViewType, tab }) => {
  const rewards = Array.from({ length: 5 }, (_, i) => ({
    count: (i + 1) * 10,
    reward: [
      "튀김소보루세트",
      "순수롤티라미수",
      "튀김소보루 더블세트",
      "튀김소보루세트 + 순수롤티라미수",
      "딸기시루케이크",
    ][i],
  }));

  return (
    <UserInfoContainer>
      <ul>
        <li>
          <span className="tit">이름: </span>
          <span className="con">{selected.mberNm}</span>
        </li>
        <li>
          <span className="tit">스탬프: </span>
          <span className="con">{selected.stampCount}개</span>
        </li>
        <li>
          <span className="tit">지급횟수: </span>
          <span className="con">{selected.rewardCount}개</span>
        </li>
        <li className="rewardAvailableCnt">
          <span className="tit">교환가능개수: </span>
          <span className="con">{rewardAvailableCnt}개</span>
        </li>
      </ul>
      <div className="actions">
        <ul className="tabmenu">
          {tab.map((ele, idx) => (
            <li onClick={() => setViewType(idx)} className={idx === viewType ? "active" : ""}>
              {ele}
            </li>
          ))}
        </ul>
      </div>
    </UserInfoContainer>
  );
};

export default UserInfo;
