import styled from "styled-components";

export const Modal = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #000;
  button {
    color: #000;
  }
  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 10px;
    max-width: 90%;
    min-width: 300px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    font-size: 18px;
    overflow: hidden;
  }
  .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px 20px;
    background: #000;
    color: #fff;
    border-radius: 10px 10px 0 0;
    font-weight: 700;
    button {
      background: transparent;
      border: none;
      color: #fff;
      svg {
        font-size: 30px;
        display: block;
        margin: auto;
        stroke-width: 20px;
      }
    }
  }
  .message {
    padding: 20px;
    width: 100%;
    span {
      display: block;
    }
  }
  .actions {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    button {
      width: 100px;
      border-radius: 5px;
      border: 1px solid #000;
      padding: 5px 0;
      background: transparent;
      font-weight: 500;
      &:last-child {
        background: #000;
        color: #fff;
      }
    }
  }
  &.theme-2 {
    .info {
      color: #000;
      background: #c0a5a5;
      button {
        color: #000;
      }
    }
    .actions button {
      border: 1px solid #c0a5a5;
      &:last-child {
        background: #c0a5a5;
        color: #000;
      }
    }
  }
  &.theme-3 {
    .info {
      color: #000;
      background: #ffc96f;
      button {
        color: #000;
      }
    }
    .actions button {
      border: 1px solid #ffc96f;
      &:last-child {
        background: #ffc96f;
        color: #000;
      }
    }
  }
  &.theme-4 {
    .info {
      background: #002153;
    }
    .actions button {
      &:last-child {
        background: #002153;
        border: 1px solid #002153;
      }
    }
  }
  &.theme-sm {
    .info {
      background: #333333;
    }
    .actions button {
      border: 1px solid #333333;
      &:last-child {
        background: #333333;
      }
    }
  }
  &.dark {
    color: #000;
    .actions button,
    .message {
      color: #000;
      &:last-child {
        color: #fff;
      }
    }
  }
`;
