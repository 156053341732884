import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { NotifyBenefitContainer } from "../styled/dashboard";
import { useSelector } from "react-redux";

const NotifyBenefit = () => {
  const [temp, setTemp] = useState([]);
  const user = useSelector((state) => state?.user?.data[0]);

  useEffect(() => {
    createAdData();
  }, []);

  function createAdData() {
    const adData = [];
    const tags = ["10% 할인 쿠폰", "에어팟 맥스 응모권", "성심당"];

    for (let i = 1; i <= 2; i++) {
      adData.push({
        adName: `스탬프 모으면 선물이 팡팡`,
        adDescription: `상생매장 스탬프 투어! 스탬프를 모아 상생센터로 가져오시면 다양한 선물을 제공해드립니다!`,
        tag: tags[Math.floor(Math.random() * tags.length)],
        storeNm: "모노티 스탬프 투어",
        createdAt: new Date().toISOString(),
        category: "대전",
        virtualImage: `/img/sungsimdang/sungsimdang_coupon.png`,
      });
    }

    setTemp(adData);
  }

  const breakpointColumnsObj = {
    default: 3,
    1400: 3,
    1050: 2,
    767: 1,
  };

  const openAdvertisementPage = async () => {
    user && localStorage.setItem("user", JSON.stringify(user));
    window.open(`https://1472.ai/shop/lotteria-daejeon`);
  };

  return (
    <NotifyBenefitContainer>
      {temp && temp.length > 0 && (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          <div className="benefitItem" onClick={openAdvertisementPage}>
            <div className="storeContainer">
              <div className="storeThumb">
                <img src="/img/tempAdvertisementLogo.png" alt="" />
              </div>
              <div className="storeNm">롯데리아</div>
              <div className="category">브랜드</div>
            </div>
            <div className="productContainer">
              <div className="info">
                <span className="title">&#91;남대전고&#93; 14기 조원선</span>
                <span className="desc">쿠폰을 가져오시는 분들께 모든 상품 10%</span>
                <span className="tag">10% 할인 쿠폰</span>
              </div>
              <div className="thumb">
                <img src="/img/temp.png" alt="" />
              </div>
            </div>
          </div>
          {temp.map((item) => (
            <div className="benefitItem" onClick={() => alert("준비 중입니다.")}>
              <div className="storeContainer">
                <div className="storeThumb">
                  <img src="/img/logo.png" alt="" />
                </div>
                <div className="storeNm">{item.storeNm}</div>
                <div className="category">{item.category}</div>
              </div>
              <div className="productContainer">
                <div className="info">
                  <span className="title">{item.adName}</span>
                  <span className="desc">{item.adDescription}</span>
                  <span className="tag">{item.tag}</span>
                </div>
                <div className="thumb">
                  <img src={item.virtualImage} alt="" />
                </div>
              </div>
            </div>
          ))}
        </Masonry>
      )}
    </NotifyBenefitContainer>
  );
};

export default NotifyBenefit;
