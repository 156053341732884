import axios from "axios";
import React, { useEffect, useState } from "react";

const Banners = ({ cmpnyNo }) => {
  const [banner, setBanner] = useState([]);
  const [text, setText] = useState([]);

  const handleScroll = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (cmpnyNo) {
      getMyBanner(cmpnyNo);
    }
  }, [cmpnyNo]);

  const getMyBanner = async (cmpnyNo) => {
    const url = "/api/firstSettingLoad";
    const body = {
      cmpnyNo,
      delYn: "N",
    };
    try {
      const res = await axios.post(url, body);

      const images = JSON.parse(res.data.bannerImgList) || [];
      const text = JSON.parse(res.data.bannerImgTextList) || [];
      const filteredText = text.filter((item) => item.trim() !== "");

      setText(filteredText);
      setBanner(images);
    } catch (error) {
      console.log(error);
    }
  };

  const formattedText = (text) => {
    return text.map((item, index) => (
      <React.Fragment key={index}>
        {item}
        {index < text.length - 1 && <br />}
      </React.Fragment>
    ));
  };
  return (
    <div className="banner">
      <div className="bannerInfo">
        <div className="tit">
          <span>{formattedText(text)}</span>
        </div>
        <div className="scroll">
          <span>SCROLL</span>
          <button className="scroll-button" onClick={handleScroll}>
            <div className="dot"></div>
          </button>
        </div>
      </div>
      <div className="banners">
        <div className="left">
          {[0, 1].map((index) => (
            <div className="box" key={index}>
              {banner[index] ? (
                <img src={imgHandler(banner[index])} alt={`banner${index + 1}`} />
              ) : null}
            </div>
          ))}
        </div>
        <div className="right">
          {banner[2] && <img src={imgHandler(banner[2])} alt="banner3" />}
        </div>
      </div>
    </div>
  );
};

export default Banners;
