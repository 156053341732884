import moment from "moment";
import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";
import { CalendarActions } from "../../styled/scheduler";
import { useSelector } from "react-redux";

const Actions = ({ clickDate, handleDateClick, onDate }) => {
  const { isDarkMode } = useSelector((state) => state?.scheduler);

  return (
    <CalendarActions className={isDarkMode}>
      <button onClick={() => clickDate({ date: moment().format("YYYY-MM-DD") })} className="today">
        오늘
      </button>
      <div className="month">
        <button
          onClick={() =>
            handleDateClick(moment(onDate).subtract(1, "months").format("YYYY-MM-01"))
          }>
          <BsChevronLeft />
        </button>
        <button
          onClick={() => handleDateClick(moment(onDate).add(1, "months").format("YYYY-MM-01"))}>
          <BsChevronRight />
        </button>
      </div>
    </CalendarActions>
  );
};

export default Actions;
