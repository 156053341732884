import React from "react";
import { FiMinus, FiPlus } from "react-icons/fi";

const ProductInfo = ({ product, amount, handleCheckout, handleCart, updateCount }) => {
  const totalPrice = amount * product?.price;
  const deliveryFee = 0;

  return (
    <div className="totalSumm" style={{ display: "block", marginLeft: "auto" }}>
      <h3 className="font_32">{product?.name || ""}</h3>
      <ul className="summ font_18 pdb_30">
        <li className="font_24 pdb_10">
          {product?.price ? (
            <>
              판매가: {product.price.toLocaleString()}
              <b>원</b>
            </>
          ) : (
            "가격 문의"
          )}
        </li>
      </ul>
      <div className="options font_18 pdb_30">
        <dl className="opt_amount">
          <dt>수량</dt>
          <dd>
            <div className="control">
              <button className="font_14" onClick={() => updateCount(false)}>
                <FiMinus />
              </button>
              <span>{amount}</span>
              <button className="font_14" onClick={() => updateCount()}>
                <FiPlus />
              </button>
            </div>
            <div className="sms">
              {/* <button>
            <i className="xi-bell-o font_18"></i> 재입고 알림 SMS신청
          </button> */}
            </div>
          </dd>
        </dl>
        {/* <dl className="opt_selct">
  <dt>상품선택[필수]</dt>
  <dd>
    <select>
      <option>상품선택[필수]</option>
      <option>상품선택1</option>
      <option>상품선택2</option>
    </select>
  </dd>
</dl>
<dl className="opt_selct">
  <dt>
  색상선택[필수]</dt>
  <dd>
    <select>
      <option>색상선택[필수]</option>
      <option>색상선택2</option>
      <option>색상선택3</option>
    </select>
  </dd>
</dl> */}
      </div>
      <ul
        style={{
          display: "flex",
          justifyContent: "flex-end",
          fontSize: "1.25em",
        }}>
        {/* <li className="pdb_10">배송비 : {deliveryFee.toLocaleString()}원</li> */}
      </ul>
      <dl className="total font_30 font600 pdt_20 pdb_30">
        <dt>총 상품금액</dt>
        <dd>{product?.price ? `${(totalPrice + deliveryFee).toLocaleString()}원` : "가격 문의"}</dd>
      </dl>
      <div className="btns pdt_10">
        {/* <button className="btn_wht font_36"><i className="xi-heart-o"></i></button> */}
        <button
          className="btn_wht font_24"
          onClick={() => alert("준비 중입니다.")}
          // onClick={handleCart}
        >
          장바구니
        </button>
        <button
          className="btn_blk font_24"
          onClick={() => alert("준비 중입니다.")}
          // onClick={handleCheckout}
        >
          바로구매
        </button>
      </div>
    </div>
  );
};

export default ProductInfo;
