import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "moment/locale/ko";

import { AgendaContainer } from "../styled/scheduler";
import { solarToLunar } from "../../../../../hooks/useLunar";
import { isWeather } from "../function/common";

import HamburgerMenu from "./hamburgerMenu";
import Holiday from "./agenda/holiday";
import DDay from "./agenda/dDay";
import Event from "./agenda/event";

const Agenda = ({
  selected,
  toggleAgendaContent,
  isTablet,
  clickEvent,
  onDate,
  setIsBurgerOpen,
  isBurgerOpen,
  isHolidayOpen,
  todayWeather,
}) => {
  const isDarkMode = useSelector((state) => state?.scheduler?.isDarkMode);

  const changeLunar = (date) => {
    const [year, month, day] = moment(date).format("YYYY-MM-DD").split("-");
    return solarToLunar(year, month, day);
  };

  return (
    <AgendaContainer
      className={`agenda ${isDarkMode}`}
      style={{ transform: `translateY(${isTablet ? "150%" : "0"})` }}
      onClick={(e) => e.target === e.currentTarget && toggleAgendaContent(false)}>
      <div className="agendaInner">
        <div className="lunar">
          <div>
            <span className="font600">{moment(onDate).format("MM월 DD일 dddd")}</span>
            <span>{changeLunar(onDate)}</span>
          </div>
          {todayWeather && <div className="weather">{isWeather(todayWeather.weather)}</div>}
        </div>
        <ul className="constractList">
          <ul>
            {selected
              ?.filter((item) => ["S", "H"].includes(item.gbn))
              .map((item, index) => (
                <Holiday holiday={item} index={index} isHolidayOpen={isHolidayOpen} />
              ))}
          </ul>
          {selected
            ?.filter((item) => !["S", "H"].includes(item.gbn))
            .map((item, index) =>
              item.dday !== "Y" ? (
                <Event item={item} clickEvent={clickEvent} onDate={onDate} index={index} />
              ) : (
                <DDay item={item} clickEvent={clickEvent} index={index} />
              ),
            )}
        </ul>
      </div>
      {isTablet && (
        <HamburgerMenu
          onDate={moment(onDate).format("YYYY-MM-DD hh:00:00")}
          isBurgerOpen={isBurgerOpen}
          setIsBurgerOpen={setIsBurgerOpen}
        />
      )}
    </AgendaContainer>
  );
};

export default Agenda;
