import React from "react";

const Holiday = ({ holiday, index, isHolidayOpen }) => {
  return (
    <li key={index} onClick={() => isHolidayOpen(holiday)}>
      {holiday.icon && (
        <img
          src={`/img/scheduler/${holiday.icon}`}
          alt={holiday.title}
          style={{ width: 20, height: 20, marginRight: 5 }}
        />
      )}
      <p className="font600">{holiday.title}</p>
    </li>
  );
};

export default Holiday;
