import { useState, useEffect, useMemo } from "react";
import Confirmation from "../common/modal/confirmation";

export const defaultModalConfig = {
  isOpen: false,
  type: "",
  title: "",
  message: [],
  handleConfirm: null,
  handleCancel: null,
};

export const getModalContent = (modalConfig) => {
  if (!modalConfig?.isOpen) return null;

  const modalProps = {
    ...modalConfig,
    title: modalConfig.title,
    message: modalConfig.message,
    handleConfirm: modalConfig.handleConfirm,
    handleCancel: modalConfig.handleCancel,
  };

  switch (modalConfig.type) {
    case "confirmation":
      return <Confirmation {...modalProps} />;
    default:
      return null;
  }
};

export const useModal = () => {
  const [modalConfig, setModalConfig] = useState({ ...defaultModalConfig });
  const [modal, setModal] = useState(null);

  useEffect(() => {
    setModal(getModalContent(modalConfig));
  }, [modalConfig]);

  const openModal = (options) => {
    setModalConfig({
      isOpen: true,
      ...options,
    });
  };

  const closeModal = () => {
    setModalConfig(() => ({ ...defaultModalConfig }));
  };

  return { modal, openModal, closeModal };
};
